import React, { useContext } from "react";
import { View, StyleSheet, Text } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";

import { fillThemes } from "../../data/FillThemes";
import { ThemeContext } from "../../context/ThemeContext";

const FillTheme = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const navigation = useNavigation();

  return (
    <ScrollView style={styles.screenStyle}>
      <View style={styles.themeViewStyle}>
        {fillThemes.map((item, i) => (
          <TouchableOpacity
            key={i}
            style={{
              margin: 15,
              width: 100,
              height: 150,
              backgroundColor: item.color,
              borderRadius: 20,
              justifyContent: "space-around",
            }}
            onPress={() => {
              changeTheme(item.changeTo);
              navigation.navigate("Themes");
            }}
          >
            <Text style={styles.themeTextStyle}>{item.label}</Text>
          </TouchableOpacity>
        ))}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  screenStyle: {
    flex: 1,
    backgroundColor: "white",
  },
  themeViewStyle: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  themeTextStyle: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    alignSelf: "center",
    color: "white",
  },
});
export default FillTheme;
