import React, { useContext } from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { ScrollView } from "react-native-gesture-handler";

import { ThemeContext } from "../../context/ThemeContext";

import Feedback from "../feedback/Feedback";

import KampusAccount from "../kampus/kampusAccount/KampusAccount";
import KampusEventDetails from "../kampus/kampusAccount/KampusEventDetails";

import ChooseSettingsForKampusEvent from "../kampus/kampusEvent/ChooseSettingsForKampusEvent";
import ReviewAndConfirmKampusEvent from "../kampus/kampusEvent/ReviewAndConfirmKampusEvent";
import SelectKardForKampusEvent from "../kampus/kampusEvent/SelectKardForKampusEvent";
import SetDetailsForKampusEvent from "../kampus/kampusEvent/SetDetailsForKampusEvent";
import SetAreaForKampusEvent from "../kampus/kampusEvent/SetAreaForKampusEvent";
import SetViewsForKampusEvent from "../kampus/kampusEvent/SetViewsForKampusEvent";

import ChooseSettingsForKampusKard from "../kampus/kampusKard/ChooseSettingsForKampusKard";
import ReviewAndConfirmKampusKard from "../kampus/kampusKard/ReviewAndConfirmKampusKard";
import SelectKampusKard from "../kampus/kampusKard/SelectKampusKard";

import MarkInstitution from "../kampus/kampusRegistration/MarkInstitution";
import MarkBoundaries from "../kampus/kampusRegistration/MarkBoundaries";
import KampusRegistration from "../kampus/kampusRegistration/KampusRegistration";

import EnrollVolunteers from "../kampus/volunteers/EnrollVolunteers";

import InformationBrochure from "../kampus/InformationBrochure";
import KampusHome from "../kampus/KampusHome";

import SideMenuContent from "./SideMenuContent";

import ChangeDisplayArea from "../myKards/ChangeDisplayArea";
import ChangeKard from "../myKards/ChangeKard";
import CurrentKardDetails from "../myKards/CurrentKardDetails";
import CurrentKards from "../myKards/CurrentKards";
import PreviousKardDetails from "../myKards/PreviousKardDetails";
import PreviousKards from "../myKards/PreviousKards";

import MyLocation from "../myLocation/MyLocation";
import ChangeMyLocation from "../myLocation/ChangeMyLocation";

import BillingDetailsForNewKard from "../newKard/BillingDetailsForNewKard";
import ChooseTypeForNewKard from "../newKard/ChooseTypeForNewKard";
import ChooseSettingsForNewKard from "../newKard/ChooseSettingsForNewKard";
import ReviewAndConfirmNewKard from "../newKard/ReviewAndConfirmNewKard";
import SelectNewKard from "../newKard/SelectNewKard";
import SetAreaForNewKard from "../newKard/SetAreaForNewKard";

import PendingFeedback from "../office/feedback/PendingFeedback";

import PendingKampusEventDetails from "../office/kampus/PendingKampusEventDetails";
import PendingKampusEvents from "../office/kampus/PendingKampusEvents";
import PendingKampusRegistrationDetails from "../office/kampus/PendingKampusRegistrationDetails";
import PendingKampusRegistrations from "../office/kampus/PendingKampusRegistrations";

import ChooseSettingsForPromoKard from "../office/promoKard/ChooseSettingsForPromoKard";
import ReviewAndConfirmPromoKard from "../office/promoKard/ReviewAndConfirmPromoKard";
import SelectPromoKard from "../office/promoKard/SelectPromoKard";
import SetAreaForPromoKard from "../office/promoKard/SetAreaForPromoKard";

import PaymentTesting from "../office/paymentTesting/PaymentTesting";
import PaymentOptions from "../office/paymentTesting/PaymentOptions";

import PendingTransactions from "../office/transactions/PendingTransactions";
import Dashboard from "../office/Dashboard";

import KampusCreditCardPayment from "../payment/creditCard/KampusCreditCardPayment";
import KardCreditCardPayment from "../payment/creditCard/KardCreditCardPayment";
import TestCreditCardPayment from "../payment/creditCard/TestCreditCardPayment";

import KampusDebitCardPayment from "../payment/debitCard/KampusDebitCardPayment";
import KardDebitCardPayment from "../payment/debitCard/KardDebitCardPayment";
import TestDebitCardPayment from "../payment/debitCard/TestDebitCardPayment";

import KampusLinkPayment from "../payment/linkPayment/KampusLinkPayment";
import KardLinkPayment from "../payment/linkPayment/KardLinkPayment";
import TestLinkPayment from "../payment/linkPayment/TestLinkPayment";

import KampusNetBankingPayment from "../payment/netBanking/KampusNetBankingPayment";
import KardNetBankingPayment from "../payment/netBanking/KardNetBankingPayment";
import TestNetBankingPayment from "../payment/netBanking/TestNetBankingPayment";

import KampusQrCodePayment from "../payment/qrCode/KampusQrCodePayment";
import KardQrCodePayment from "../payment/qrCode/KardQrCodePayment";
import TestQrCodePayment from "../payment/qrCode/TestQrCodePayment";

import KampusTransactionSuccess from "../payment/transaction/KampusTransactionSuccess";
import KampusTransactionFailure from "../payment/transaction/KampusTransactionFailure";
import KardTransactionSuccess from "../payment/transaction/KardTransactionSuccess";
import KardTransactionFailure from "../payment/transaction/KardTransactionFailure";
import TestTransactionSuccess from "../payment/transaction/TestTransactionSuccess";
import TestTransactionFailure from "../payment/transaction/TestTransactionFailure";

import KampusUpiIdPayment from "../payment/upi/KampusUpiIdPayment";
import KampusUpiPollingScreen from "../payment/upi/KampusUpiPollingScreen";
import KardUpiIdPayment from "../payment/upi/KardUpiIdPayment";
import KardUpiPollingScreen from "../payment/upi/KardUpiPollingScreen";
import TestUpiIdPayment from "../payment/upi/TestUpiIdPayment";
import TestUpiPollingScreen from "../payment/upi/TestUpiPollingScreen";

import HtmlFormPost from "../payment/HtmlFormPost";
import SelectKardPaymentMethod from "../payment/SelectKardPaymentMethod";
import SelectKampusPaymentMethod from "../payment/SelectKampusPaymentMethod";
import SelectTestPaymentMethod from "../payment/SelectTestPaymentMethod";

import AddNewAddress from "../profile/AddNewAddress";
import Profile from "../profile/Profile";

import Registration from "../registration/Registration";
import SignOut from "../registration/SignOut";

import Themes from "../theme/Themes";

import AboutUs from "../AboutUs";
import ContactUs from "../ContactUs";
import DisplayKards from "../DisplayKards";
import Help from "../Help";
import Home from "../Home";
import Privacy from "../Privacy";
import UseCases from "../UseCases";
import ShareUs from "../ShareUs";
import Terms from "../Terms";

// import Refund from "../office/Refund";

const SideMenuLayout = () => {
  const Drawer = createDrawerNavigator();
  const { theme, changeTheme } = useContext(ThemeContext);
  // console.log(user.signInUserSession.accessToken.payload["cognito:groups"]);
  return (
    <Drawer.Navigator
      backBehavior="history"
      // drawerType="permanent"
      drawerContent={() => (
        <ScrollView>
          <SideMenuContent />
        </ScrollView>
      )}
      screenOptions={{
        drawerType: "permanent",
        // headerTitle: () => null,
        header: () => null,
      }}
    >
      <Drawer.Screen name="Home" component={Home} />

      <Drawer.Screen name="UseCases" component={UseCases} />

      <Drawer.Screen name="Feedback" component={Feedback} />

      <Drawer.Screen name="KampusAccount" component={KampusAccount} />
      <Drawer.Screen name="KampusEventDetails" component={KampusEventDetails} />

      <Drawer.Screen
        name="ChooseSettingsForKampusEvent"
        component={ChooseSettingsForKampusEvent}
      />
      <Drawer.Screen
        name="ReviewAndConfirmKampusEvent"
        component={ReviewAndConfirmKampusEvent}
      />
      <Drawer.Screen
        name="SelectKardForKampusEvent"
        component={SelectKardForKampusEvent}
      />
      <Drawer.Screen
        name="SetDetailsForKampusEvent"
        component={SetDetailsForKampusEvent}
      />
      <Drawer.Screen
        name="SetAreaForKampusEvent"
        component={SetAreaForKampusEvent}
      />
      <Drawer.Screen
        name="SetViewsForKampusEvent"
        component={SetViewsForKampusEvent}
      />

      <Drawer.Screen
        name="ChooseSettingsForKampusKard"
        component={ChooseSettingsForKampusKard}
      />
      <Drawer.Screen
        name="ReviewAndConfirmKampusKard"
        component={ReviewAndConfirmKampusKard}
      />
      <Drawer.Screen name="SelectKampusKard" component={SelectKampusKard} />

      <Drawer.Screen name="MarkInstitution" component={MarkInstitution} />
      <Drawer.Screen name="MarkBoundaries" component={MarkBoundaries} />
      <Drawer.Screen name="KampusRegistration" component={KampusRegistration} />

      <Drawer.Screen name="EnrollVolunteers" component={EnrollVolunteers} />

      <Drawer.Screen
        name="InformationBrochure"
        component={InformationBrochure}
      />
      <Drawer.Screen name="KampusHome" component={KampusHome} />

      <Drawer.Screen name="ChangeDisplayArea" component={ChangeDisplayArea} />
      <Drawer.Screen name="ChangeKard" component={ChangeKard} />
      <Drawer.Screen name="CurrentKardDetails" component={CurrentKardDetails} />
      <Drawer.Screen name="CurrentKards" component={CurrentKards} />
      <Drawer.Screen
        name="PreviousKardDetails"
        component={PreviousKardDetails}
      />
      <Drawer.Screen name="PreviousKards" component={PreviousKards} />

      <Drawer.Screen name="MyLocation" component={MyLocation} />
      <Drawer.Screen name="ChangeMyLocation" component={ChangeMyLocation} />

      <Drawer.Screen
        name="BillingDetailsForNewKard"
        component={BillingDetailsForNewKard}
      />
      <Drawer.Screen
        name="ChooseTypeForNewKard"
        component={ChooseTypeForNewKard}
      />
      <Drawer.Screen
        name="ChooseSettingsForNewKard"
        component={ChooseSettingsForNewKard}
      />
      <Drawer.Screen
        name="ReviewAndConfirmNewKard"
        component={ReviewAndConfirmNewKard}
      />
      <Drawer.Screen name="SelectNewKard" component={SelectNewKard} />
      <Drawer.Screen name="SetAreaForNewKard" component={SetAreaForNewKard} />

      <Drawer.Screen name="PendingFeedback" component={PendingFeedback} />

      <Drawer.Screen
        name="PendingKampusEventDetails"
        component={PendingKampusEventDetails}
      />
      <Drawer.Screen
        name="PendingKampusEvents"
        component={PendingKampusEvents}
      />
      <Drawer.Screen
        name="PendingKampusRegistrationDetails"
        component={PendingKampusRegistrationDetails}
      />
      <Drawer.Screen
        name="PendingKampusRegistrations"
        component={PendingKampusRegistrations}
      />

      <Drawer.Screen
        name="ChooseSettingsForPromoKard"
        component={ChooseSettingsForPromoKard}
      />
      <Drawer.Screen
        name="ReviewAndConfirmPromoKard"
        component={ReviewAndConfirmPromoKard}
      />
      <Drawer.Screen name="SelectPromoKard" component={SelectPromoKard} />
      <Drawer.Screen
        name="SetAreaForPromoKard"
        component={SetAreaForPromoKard}
      />

      <Drawer.Screen name="PaymentTesting" component={PaymentTesting} />
      <Drawer.Screen name="PaymentOptions" component={PaymentOptions} />

      <Drawer.Screen
        name="PendingTransactions"
        component={PendingTransactions}
      />
      <Drawer.Screen name="Dashboard" component={Dashboard} />

      <Drawer.Screen
        name="KampusCreditCardPayment"
        component={KampusCreditCardPayment}
        options={{
          title: "Credit Card Payment",
          headerBackTitle: "Credit Card Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="KardCreditCardPayment"
        component={KardCreditCardPayment}
        options={{
          title: "Credit Card Payment",
          headerBackTitle: "Credit Card Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="TestCreditCardPayment"
        component={TestCreditCardPayment}
        options={{
          title: "Credit Card Payment",
          headerBackTitle: "Credit Card Payment",
          headerLeft: null,
        }}
      />

      <Drawer.Screen
        name="KampusDebitCardPayment"
        component={KampusDebitCardPayment}
        options={{
          title: "Debit Card Payment",
          headerBackTitle: "Debit Card Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="KardDebitCardPayment"
        component={KardDebitCardPayment}
        options={{
          title: "Debit Card Payment",
          headerBackTitle: "Debit Card Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="TestDebitCardPayment"
        component={TestDebitCardPayment}
        options={{
          title: "Debit Card Payment",
          headerBackTitle: "Debit Card Payment",
          headerLeft: null,
        }}
      />

      <Drawer.Screen
        name="KampusLinkPayment"
        component={KampusLinkPayment}
        options={{
          title: "Payment Link",
          headerBackTitle: "Payment Link",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="KardLinkPayment"
        component={KardLinkPayment}
        options={{
          title: "Payment Link",
          headerBackTitle: "Payment Link",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="TestLinkPayment"
        component={TestLinkPayment}
        options={{
          title: "Payment Link",
          headerBackTitle: "Payment Link",
          headerLeft: null,
        }}
      />

      <Drawer.Screen
        name="KampusNetBankingPayment"
        component={KampusNetBankingPayment}
        options={{
          title: "Net Banking Payment",
          headerBackTitle: "Net Banking Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="KardNetBankingPayment"
        component={KardNetBankingPayment}
        options={{
          title: "Net Banking Payment",
          headerBackTitle: "Net Banking Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="TestNetBankingPayment"
        component={TestNetBankingPayment}
        options={{
          title: "Net Banking Payment",
          headerBackTitle: "Net Banking Payment",
          headerLeft: null,
        }}
      />

      <Drawer.Screen
        name="KampusQrCodePayment"
        component={KampusQrCodePayment}
        options={{
          title: "QR Code Payment",
          headerBackTitle: "QR Code Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="KardQrCodePayment"
        component={KardQrCodePayment}
        options={{
          title: "QR Code Payment",
          headerBackTitle: "QR Code Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="TestQrCodePayment"
        component={TestQrCodePayment}
        options={{
          title: "QR Code Payment",
          headerBackTitle: "QR Code Payment",
          headerLeft: null,
        }}
      />

      <Drawer.Screen
        name="KampusTransactionSuccess"
        component={KampusTransactionSuccess}
      />
      <Drawer.Screen
        name="KampusTransactionFailure"
        component={KampusTransactionFailure}
      />
      <Drawer.Screen
        name="KardTransactionSuccess"
        component={KardTransactionSuccess}
      />
      <Drawer.Screen
        name="KardTransactionFailure"
        component={KardTransactionFailure}
      />
      <Drawer.Screen
        name="TestTransactionSuccess"
        component={TestTransactionSuccess}
      />
      <Drawer.Screen
        name="TestTransactionFailure"
        component={TestTransactionFailure}
      />

      <Drawer.Screen
        name="KampusUpiIdPayment"
        component={KampusUpiIdPayment}
        options={{
          title: "UPI Payment",
          headerBackTitle: "UPI Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="KampusUpiPollingScreen"
        component={KampusUpiPollingScreen}
        options={{
          title: "Waiting For Payment",
          headerBackTitle: "Waiting For Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="KardUpiIdPayment"
        component={KardUpiIdPayment}
        options={{
          title: "UPI Payment",
          headerBackTitle: "UPI Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="KardUpiPollingScreen"
        component={KardUpiPollingScreen}
        options={{
          title: "Waiting For Payment",
          headerBackTitle: "Waiting For Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="TestUpiIdPayment"
        component={TestUpiIdPayment}
        options={{
          title: "UPI Payment",
          headerBackTitle: "UPI Payment",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="TestUpiPollingScreen"
        component={TestUpiPollingScreen}
        options={{
          title: "Waiting For Payment",
          headerBackTitle: "Waiting For Payment",
          headerLeft: null,
        }}
      />

      <Drawer.Screen
        name="HtmlFormPost"
        component={HtmlFormPost}
        options={{
          title: "Processing Transaction",
          headerBackTitle: "Processing Transaction",
          headerLeft: null,
        }}
      />

      <Drawer.Screen
        name="SelectKardPaymentMethod"
        component={SelectKardPaymentMethod}
        options={{
          title: "Select Payment Method",
          headerBackTitle: "Select Payment Method",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="SelectKampusPaymentMethod"
        component={SelectKampusPaymentMethod}
        options={{
          title: "Select Payment Method",
          headerBackTitle: "Select Payment Method",
          headerLeft: null,
        }}
      />
      <Drawer.Screen
        name="SelectTestPaymentMethod"
        component={SelectTestPaymentMethod}
        options={{
          title: "Select Payment Method",
          headerBackTitle: "Select Payment Method",
          headerLeft: null,
        }}
      />

      <Drawer.Screen name="AddNewAddress" component={AddNewAddress} />
      <Drawer.Screen name="Profile" component={Profile} />

      <Drawer.Screen name="Registration" component={Registration} />
      <Drawer.Screen name="SignOut" component={SignOut} />

      <Drawer.Screen name="Themes" component={Themes} navigation />

      <Drawer.Screen name="AboutUs" component={AboutUs} />
      <Drawer.Screen name="ContactUs" component={ContactUs} />
      <Drawer.Screen name="DisplayKards" component={DisplayKards} />
      <Drawer.Screen name="Help" component={Help} />
      <Drawer.Screen name="Privacy" component={Privacy} />
      <Drawer.Screen name="ShareUs" component={ShareUs} />
      <Drawer.Screen name="Terms" component={Terms} />

      {/* <Drawer.Screen name="UploadNewKard" component={UploadNewKard} /> */}

      {/* <Drawer.Screen name="Refund" component={Refund} /> */}
    </Drawer.Navigator>
  );
};

export default SideMenuLayout;
