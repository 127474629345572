import React, { useState, useContext } from "react";
import {
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
  TextInput,
  Image,
} from "react-native";

import { API } from "aws-amplify";
import { useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";

import { Kabutar } from "../../../assets/index";
import { ThemeContext } from "../../../context/ThemeContext";
import { UserContext } from "../../../context/UserContext";
import { OrderContext } from "../../../context/OrderContext";
import * as queries from "../../../src/graphql/queries";

const KampusLinkPayment = () => {
  const { orderId, amount } = useContext(OrderContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const navigation = useNavigation();
  const [mobileNumber, setMobileNumber] = useState("");
  const [linkId, setLinkId] = useState("");
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [verifyButton, setVerifyButton] = useState(false);
  const [paymentPending, setPaymentPending] = useState(false);
  const [sendLinkSuccessAlert, setSendLinkSuccessAlert] = useState(false);
  const [sendLinkFailureAlert, setSendLinkFailureAlert] = useState(false);
  const [sendLinkMessage, setSendLinkMessage] = useState("");
  const [resendLinkSuccessAlert, setResendLinkSuccessAlert] = useState(false);
  const [resendLinkFailureAlert, setResendLinkFailureAlert] = useState(false);
  const [resendLinkMessage, setResendLinkMessage] = useState("");
  const [expireLinkSuccessAlert, setExpireLinkSuccessAlert] = useState(false);
  const [expireLinkFailureAlert, setExpireLinkFailureAlert] = useState(false);
  const [expireLinkMessage, setExpireLinkMessage] = useState("");

  const validateMobileNumber = () => {
    if (mobileNumber.length == 10) {
      return true;
    } else {
      return false;
    }
  };

  const sendPaymentLink = async () => {
    const token = user.signInUserSession.idToken.jwtToken;
    const isValid = validateMobileNumber();
    if (isValid) {
      var createLinkData = {
        body: {
          orderId: orderId,
          value: amount,
          mobileNumber: mobileNumber,
        },
        headers: {
          Authorization: token,
        },
      };
      const createLinkResponse = await API.post(
        "paymentRestApi",
        "/kampus/createLink",
        createLinkData
      );
      if (createLinkResponse.status == "success") {
        setLinkId(createLinkResponse.data.linkId);
        setSendLinkMessage(createLinkResponse.message);
        setSendLinkSuccessAlert(true);
        setVerifyButton(true);
      } else {
        setSendLinkMessage(createLinkResponse.message);
        setSendLinkFailureAlert(true);
      }
    } else {
      setMobileNumberError(true);
    }
  };

  const verifyPaymentStatus = async () => {
    const getPaymentStatus = await API.graphql({
      query: queries.getPayment,
      variables: {
        id: orderId,
      },
    });
    if (getPaymentStatus.data.getPayment == null) {
      setPaymentPending(true);
    } else {
      const paymentData = JSON.parse(getPaymentStatus.data.getPayment.data);
      const txnAmount = paymentData.TXNAMOUNT;
      const txnId = paymentData.TXNID;
      const status = paymentData.STATUS;
      setMobileNumber("");
      setVerifyButton(false);
      if (status == "TXN_SUCCESS") {
        navigation.navigate("KampusTransactionSuccess", {
          orderId: orderId,
          txnAmount: txnAmount,
          txnId: txnId,
        });
      } else {
        navigation.navigate("KampusTransactionFailure", {
          orderId: orderId,
          message: "Payment was not succesfull. Please try again.",
        });
      }
    }
  };

  const resendLink = async () => {
    const token = user.signInUserSession.idToken.jwtToken;
    var resendLinkData = {
      body: {
        linkId: linkId,
        mobileNumber: mobileNumber,
      },
      headers: {
        Authorization: token,
      },
    };
    const resendLinkResponse = await API.post(
      "paymentRestApi",
      "/kampus/resendLink",
      resendLinkData
    );

    // console.log(resendLinkResponse);
    if (resendLinkResponse.status == "success") {
      setResendLinkMessage(resendLinkResponse.message);
      setResendLinkSuccessAlert(true);
    }
    if (resendLinkResponse.status == "failure") {
      setResendLinkMessage(resendLinkResponse.message);
      setResendLinkFailureAlert(true);
    }
  };

  const expireLink = async () => {
    const token = user.signInUserSession.idToken.jwtToken;
    var expireLinkData = {
      body: {
        linkId: linkId,
      },
      headers: {
        Authorization: token,
      },
    };
    const expireLinkResponse = await API.post(
      "paymentRestApi",
      "/kampus/expireLink",
      expireLinkData
    );
    // console.log(expireLinkResponse);
    if (expireLinkResponse.status == "success") {
      setExpireLinkMessage(expireLinkResponse.message);
      setExpireLinkSuccessAlert(true);
    }
    if (expireLinkResponse.status == "failure") {
      setExpireLinkMessage(expireLinkResponse.message);
      setExpireLinkFailureAlert(true);
    }
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Image source={Kabutar} style={styles(theme).logoStyle} />
      <Text style={styles(theme).headerStyle}>Pay ₹ {amount}</Text>

      {verifyButton == true ? (
        <>
          <View style={styles(theme).boxStyle}>
            <Text style={styles(theme).textJustifyStyle}>
              The payment link has been sent to your mobile number -
              <Text style={styles(theme).textBoldStyle}>{mobileNumber}</Text>.
              Please make the payment from your mobile and verify payment status
              using Verify Payment Status button below. If you have not received
              the payment link in your mobile, click / press the Resend Link
              button to resend the payment link. To cancel payment, click /
              press Cancel Link button.
            </Text>
          </View>
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => {
              verifyPaymentStatus();
            }}
          >
            <Text style={styles(theme).buttonTextStyle}>
              Verify Payment Status
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => resendLink()}
          >
            <Text style={styles(theme).buttonTextStyle}>Resend Link</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => expireLink()}
          >
            <Text style={styles(theme).buttonTextStyle}>Cancel Link</Text>
          </TouchableOpacity>
        </>
      ) : (
        <>
          <Text style={styles(theme).textCenterStyle}>Enter Mobile Number</Text>

          <TextInput
            onChangeText={(e) => setMobileNumber(e)}
            name="mobileNumber"
            style={styles(theme).inputStyle}
          />
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => sendPaymentLink()}
          >
            <Text style={styles(theme).buttonTextStyle}>Send Payment Link</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => setChangePaymentMethod(true)}
          >
            <Text style={styles(theme).buttonTextStyle}>
              Change Payment Method
            </Text>
          </TouchableOpacity>
        </>
      )}

      <AwesomeAlert
        show={changePaymentMethod}
        title="CONFIRM CHANGE PAYMENT METHOD"
        message="Are you sure you want to change payment method ?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, change it"
        onCancelPressed={() => setChangePaymentMethod(false)}
        onConfirmPressed={() => {
          setMobileNumber("");
          setChangePaymentMethod(false);
          setVerifyButton(false);
          navigation.navigate("SelectKampusPaymentMethod");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />

      <AwesomeAlert
        show={mobileNumberError}
        title="MOBILE NUMBER ERROR"
        message={"The mobile number is not valid. Please check again."}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setMobileNumberError(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={sendLinkSuccessAlert}
        title="SEND PAYMENT LINK SUCCESS"
        message={sendLinkMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setSendLinkMessage("");
          setSendLinkSuccessAlert(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={sendLinkFailureAlert}
        title="SEND PAYMENT LINK FAILURE"
        message={sendLinkMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setSendLinkMessage("");
          setSendLinkFailureAlert(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={paymentPending}
        title="PAYMENT PENDING"
        message="The payment is pending. If you have already paid, verify payment status after a few seconds. If you have not yet paid, please make payment and verify payment status. "
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setPaymentPending(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={resendLinkSuccessAlert}
        title="RESEND LINK SUCCESS"
        message={resendLinkMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setResendLinkMessage("");
          setResendLinkSuccessAlert(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={resendLinkFailureAlert}
        title="RESEND LINK FAILURE"
        message={resendLinkMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setResendLinkMessage("");
          setResendLinkFailureAlert(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={expireLinkSuccessAlert}
        title="CANCEL LINK SUCCESS"
        message={expireLinkMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setMobileNumber("");
          setVerifyButton(false);
          setExpireLinkMessage("");
          setExpireLinkSuccessAlert(false);
          navigation.navigate("SelectKampusPaymentMethod");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={expireLinkFailureAlert}
        title="CANCEL LINK FAILURE"
        message={expireLinkMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setExpireLinkMessage("");
          setExpireLinkFailureAlert(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    logoStyle: {
      height: 70,
      width: 70,
      alignSelf: "center",
      margin: 10,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },
    textBoldStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      margin: 5,
    },
    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },
    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    inputStyle: {
      width: "80%",
      height: 50,
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
    },
    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default KampusLinkPayment;
