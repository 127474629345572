import React, { useContext } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { ThemeContext } from "../../../context/ThemeContext";
const TestTransactionSuccess = (props) => {
  const navigation = useNavigation();
  const orderId = props.route.params.orderId;
  const txnAmount = props.route.params.txnAmount;

  const message = `The test payment is successful.`;
  const { theme, changeTheme } = useContext(ThemeContext);

  return (
    <View style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Ionicons
          name={
            theme.type == "fill"
              ? "checkmark-circle-sharp"
              : "checkmark-circle-outline"
          }
          size={100}
          color={theme.colors.standardTextColor}
        />
      </View>
      <Text style={styles(theme).subHeaderStyle}>
        Amount Paid: ₹ {txnAmount}
      </Text>

      <Text style={styles(theme).subHeaderStyle}>{message}</Text>
      <Text style={styles(theme).subHeaderStyle}>orderID is: {orderId}</Text>

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          navigation.navigate("PaymentTesting");
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Back</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },

    buttonStyle: {
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 3,
      backgroundColor: theme.colors.backgroundColor,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
  });
export default TestTransactionSuccess;
