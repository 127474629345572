import React, { useContext, useState, useEffect } from "react";
import {
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Platform,
  Image,
  View,
} from "react-native";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";

import { ThemeContext } from "../../context/ThemeContext";
import { ProfileContext } from "../../context/ProfileContext";
import { UserContext } from "../../context/UserContext";
import { KampusEventContext } from "../../context/KampusEventContext";
import { SelectKard } from "../../assets/index";
import * as queries from "../../src/graphql/queries";
import { API } from "aws-amplify";

const KampusHome = () => {
  const { user } = useContext(UserContext);
  const navigation = useNavigation();
  const { theme, changeTheme } = useContext(ThemeContext);
  const { profile, setProfile } = useContext(ProfileContext);
  const { setKard, setPolygon } = useContext(KampusEventContext);
  const [incompleteProfile, setIncompleteProfile] = useState(false);
  const isFocused = useIsFocused();

  const noKardUri =
    Platform.OS === "web"
      ? require("../../assets/selectKard.png")
      : Image.resolveAssetSource(SelectKard).uri;

  const isKampusKardEligible =
    profile.kampusRegistration !== undefined
      ? profile.kampusStatus == 3 &&
        profile.kampusRegistration.volunteerCount >= 10
        ? true
        : false
      : false;

  useEffect(() => {
    if (user) {
      if (isFocused) {
        refreshProfile();
      }
    }
  }, [isFocused]);

  const refreshProfile = async () => {
    try {
      const refreshedProfile = await API.graphql({
        query: queries.getProfile,
        variables: {
          id: profile.id,
        },
      });
      setProfile(refreshedProfile.data.getProfile);
    } catch (error) {
      console.log(error);
    }
  };

  const registerKampus = () => {
    if (profile.address == null || profile.name == "") {
      setIncompleteProfile(true);
    } else {
      navigation.navigate("KampusRegistration");
    }
  };

  const enrollVolunteers = () => {
    navigation.navigate("EnrollVolunteers");
  };

  const kampusEvent = () => {
    setKard(noKardUri);
    setPolygon([]);
    navigation.navigate("SetDetailsForKampusEvent");
  };

  const kampusKard = () => {
    setKard(noKardUri);
    navigation.navigate("SelectKampusKard");
  };

  return user ? (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>KABUTAR KAMPUS</Text>
      <Text style={styles(theme).labelStyle}>
        Kabutar has many use cases for students, teachers, parents, managers and
        other stakeholders of educational institutions. Some of these use cases
        are mentioned in Use Cases and Help & FAQ sections. Educational
        institutions can use kabutar as a single stop notice board. They can
        notify admissions, examination schedules, results, various college
        events such as sports events, cultural events, seminars and webinars
        etc..,. They can spread ideas and promote their academic activities.
        They can advertise about their institution. They can convey greetings to
        their teachers, students, colleagues, friends, family members or even
        neighbours.
        {`\n\n`}
        The students, teachers, parents, managers and other stakeholders can
        know about the activities in their school / college and any school /
        college in the world.
        {`\n\n`}
        Educational institutions can also use kabutar to maintain their campus
        free of posters, banners, pamphlets, hand-outs, leaflets, flyers and
        advertisements. Such clean and tidy academic campuses leave everlasting
        pleasant memories in the minds of the students throughout their life
        apart from enhancing their academic achievement during campus days.
        {`\n\n`}
        KAbutar caMPUS – for short KAMPUS program encourages the students,
        teachers, parents, managers and other stakeholders of educational
        institutions to use kabutar app with cash discounts and appreciation
        awards.
        {`\n\n`}
        Only the educational institutions alone are eligible to apply to this
        program. Other organisations and individuals are not eligible.
      </Text>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("InformationBrochure")}
      >
        <Text style={styles(theme).buttonTextStyle}>Information Brochure</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("KampusAccount")}
      >
        <Text style={styles(theme).buttonTextStyle}>Kampus Account</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={
          profile.kampusStatus == 1 && profile.kampusRegistration == null
            ? styles(theme).buttonStyle
            : styles(theme).disabledButtonStyle
        }
        onPress={() => registerKampus()}
        disabled={
          profile.kampusStatus == 1 && profile.kampusRegistration == null
            ? false
            : true
        }
      >
        <Text style={styles(theme).buttonTextStyle}>Kampus Registration</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={
          profile.kampusStatus == 3 &&
          new Date(profile.kampusEndDate) >= new Date()
            ? styles(theme).buttonStyle
            : styles(theme).disabledButtonStyle
        }
        disabled={
          profile.kampusStatus == 3 &&
          new Date(profile.kampusEndDate) >= new Date()
            ? false
            : true
        }
        onPress={() => enrollVolunteers()}
      >
        <Text style={styles(theme).buttonTextStyle}>Enroll Volunteers</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={
          profile.kampusStatus == 3 &&
          new Date(profile.kampusEndDate) >= new Date()
            ? styles(theme).buttonStyle
            : styles(theme).disabledButtonStyle
        }
        disabled={
          profile.kampusStatus == 3 &&
          new Date(profile.kampusEndDate) >= new Date()
            ? false
            : true
        }
        onPress={() => kampusEvent()}
      >
        <Text style={styles(theme).buttonTextStyle}>New Kampus Event</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={
          profile.kampusStatus == 3 &&
          new Date(profile.kampusEndDate) >= new Date() &&
          isKampusKardEligible
            ? styles(theme).buttonStyle
            : styles(theme).disabledButtonStyle
        }
        disabled={
          profile.kampusStatus == 3 &&
          new Date(profile.kampusEndDate) >= new Date() &&
          isKampusKardEligible
            ? false
            : true
        }
        onPress={() => kampusKard()}
      >
        <Text style={styles(theme).buttonTextStyle}>Post Kampus Kard</Text>
      </TouchableOpacity>

      <AwesomeAlert
        show={incompleteProfile}
        title="INCOMPLETE PROFILE"
        message={
          "The name and/or address of the institution campus is not set in the profile. The name and address of the institution should be set in the profile. Also the name, location and boundaries of the institution should be available in google maps for easy verification. Different addresses such as office address, billing address etc.., can be saved in the profile. The first address in the profile should match the address of the institution campus."
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setIncompleteProfile(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  ) : (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>KABUTAR KAMPUS</Text>
      <Text style={styles(theme).labelStyle}>
        Kabutar has many use cases for students, teachers, parents, managers and
        other stakeholders of educational institutions. Some of these use cases
        are mentioned in Use Cases and Help & FAQ sections. Educational
        institutions can use kabutar as a single stop notice board. They can
        notify admissions, examination schedules, results, various college
        events such as sports events, cultural events, seminars and webinars
        etc..,. They can spread ideas and promote their academic activities.
        They can advertise about their institution. They can convey greetings to
        their teachers, students, colleagues, friends, family members or even
        neighbours.
        {`\n\n`}
        The students, teachers, parents, managers and other stakeholders can
        know about the activities in their school / college and any school /
        college in the world.
        {`\n\n`}
        Educational institutions can also use kabutar to maintain their campus
        free of posters, banners, pamphlets, hand-outs, leaflets, flyers and
        advertisements. Such clean and tidy academic campuses leave everlasting
        pleasant memories in the minds of the students throughout their life
        apart from enhancing their academic achievement during campus days.
        {`\n\n`}
        KAbutar caMPUS – for short KAMPUS program encourages the students,
        teachers, parents, managers and other stakeholders of educational
        institutions to use kabutar app with cash discounts and appreciation
        awards.
        {`\n\n`}
        Only the educational institutions alone are eligible to apply to this
        program. Other organisations and individuals are not eligible.
      </Text>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("InformationBrochure")}
      >
        <Text style={styles(theme).buttonTextStyle}>Information Brochure</Text>
      </TouchableOpacity>
      <View style={styles(theme).rowGroupCenterStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          You need to sign in to register. Click the button below to Sign In /
          Sign Up.
        </Text>
      </View>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("Registration")}
      >
        <Text style={styles(theme).buttonTextStyle}>Sign In </Text>
      </TouchableOpacity>
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },
    labelStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },

    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default KampusHome;
