import { Facebook, Instagram, Twitter, Youtube } from "../assets/index";

export const milestones = [
  {
    date: "1st MARCH 2024",
    text: "Launch of version 2.4.0",
  },
  {
    date: "03 FEBRUARY 2024",
    text: "Launch of version 2.3.0",
  },
  {
    date: "17 JANUARY 2024",
    text: "Launch of version 2.2.0",
  },
  {
    date: "25 DECEMBER 2023",
    text: "Launch of version 2.1.0",
  },
  {
    date: "14 NOVEMBER 2023",
    text: "Launch of version 2.0.0",
  },
  {
    date: "02 OCTOBER 2023",
    text: "COMPLETED 1 YEAR",
  },
  {
    date: "20 SEPTEMBER 2023",
    text: "Launch of version 1.8.0 ",
  },
  {
    date: "19 JULY 2023",
    text: "Launch of version 1.7.0 ",
  },
  {
    date: "02 JUNE 2023",
    text: "Launch of version 1.6.0 ",
  },
  {
    date: "26 MAY 2023",
    text: "Launch of version 1.5.0 ",
  },
  {
    date: "05 APR 2023",
    text: "Launch of version 1.4.0 ",
  },
  {
    date: "11 JAN 2023",
    text: "Launch of version 1.3.0 ",
  },
  {
    date: "17 DEC 2022",
    text: "Launch of version 1.2.0 ",
  },
  {
    date: "27 NOV 2022",
    text: "Launch of version 1.1.0 ",
  },
  {
    date: "02 OCT 2022",
    text: "Launch of version 1.0.0 ",
  },
];

export const knowMore = [
  {
    image: Facebook,
    redirectTo: "Facebook",
  },
  {
    image: Youtube,
    redirectTo: "Youtube",
  },
  {
    image: Twitter,
    redirectTo: "Twitter",
  },
  {
    image: Instagram,
    redirectTo: "Instagram",
  },
];
