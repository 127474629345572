import React, { useContext, useState, useEffect } from "react";
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { TouchableOpacity } from "react-native-gesture-handler";
import * as ImagePicker from "expo-image-picker";
import * as mime from "react-native-mime-types";
import { Formik, Field } from "formik";
import { useIsFocused, useNavigation } from "@react-navigation/native";

import * as mutations from "../../src/graphql/mutations";
import CustomInput from "../../components/CustomInput";
import { ThemeContext } from "../../context/ThemeContext";
import DeleteIcon from "../../components/DeleteIcon";
import CancelOkIcon from "../../components/CancelOkIcon";
import EditDeleteIcon from "../../components/EditDeleteIcon";
import { ProfileContext } from "../../context/ProfileContext";
import noAvatar from "../../assets/noAvatar.png";
import { UserContext } from "../../context/UserContext";

const Profile = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const { profile, setProfile, avatar, setAvatar } = useContext(ProfileContext);
  const { user } = useContext(UserContext);

  const [avatarChanged, setAvatarChanged] = useState(false);
  const [editName, setEditName] = useState(false);

  const noAvatarUri =
    Platform.OS === "web"
      ? require("../../assets/noAvatar.png")
      : Image.resolveAssetSource(noAvatar).uri;

  const navigation = useNavigation();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      if (!user) {
        navigation.navigate("Registration");
      }
    }
  }, [isFocused]);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 0.1,
    });
    if (!result.cancelled) {
      setAvatar(result.uri);
      setAvatarChanged(true);
    }
  };

  const updateAvatar = async () => {
    const avatarName = "avatar.png";
    const fileType = mime.lookup(avatar.uri);
    const access = { level: "private", contentType: fileType };
    const response = await fetch(avatar);
    const blob = await response.blob();

    try {
      await Storage.put(`${avatarName}`, blob, access);
      setAvatarChanged(false);
      if (!profile.avatar) {
        const updatedProfile = await API.graphql(
          graphqlOperation(mutations.updateProfile, {
            input: {
              id: profile.id,
              avatar: true,
            },
          })
        );
        setProfile(updatedProfile.data.updateProfile);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cancelAvatarChange = () => {
    setAvatarChanged(false);
    setAvatar(noAvatarUri);
  };

  const deleteAvatar = async () => {
    try {
      await Storage.remove("avatar.png", {
        level: "private",
      });

      const updatedProfile = await API.graphql(
        graphqlOperation(mutations.updateProfile, {
          input: {
            id: profile.id,
            avatar: false,
          },
        })
      );
      setProfile(updatedProfile.data.updateProfile, { avatarFetched: false });
      setAvatar(noAvatarUri);
    } catch (err) {
      console.log(err);
    }
  };

  const updateName = async (values) => {
    try {
      const updatedProfile = await API.graphql(
        graphqlOperation(mutations.updateProfile, {
          input: {
            id: profile.id,
            name: values.name,
          },
        })
      );
      setProfile(updatedProfile.data.updateProfile);
      setEditName(false);
    } catch (err) {
      console.log(err);
    }
  };
  const deleteName = async () => {
    try {
      const updatedProfile = await API.graphql(
        graphqlOperation(mutations.updateProfile, {
          input: {
            id: profile.id,
            name: "",
          },
        })
      );
      setProfile(updatedProfile.data.updateProfile);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteAddress = async (key) => {
    const newAddressArray = profile.address;
    console.log(profile);
    newAddressArray.splice(key, 1);
    console.log(newAddressArray);
    try {
      const updatedAddress = await API.graphql(
        graphqlOperation(mutations.updateProfile, {
          input: {
            id: profile.id,
            address: newAddressArray.length == 0 ? null : newAddressArray,
          },
        })
      );
      setProfile(updatedAddress.data.updateProfile);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>PROFILE</Text>
      <Text style={styles(theme).subHeaderStyle}>Avatar</Text>
      <TouchableOpacity
        onPress={pickImage}
        disabled={profile.avatar || avatarChanged}
      >
        <Image source={{ uri: avatar }} style={styles(theme).avatarStyle} />
      </TouchableOpacity>
      {profile.avatar ? (
        <DeleteIcon deleteCallback={() => deleteAvatar()} />
      ) : null}
      {avatarChanged ? (
        <CancelOkIcon
          cancelCallback={() => cancelAvatarChange()}
          updateCallback={() => updateAvatar()}
        />
      ) : null}
      <View style={styles(theme).dividerStyle} />
      <Text style={styles(theme).headerStyle}>Email or Phone</Text>
      <View style={styles(theme).boxStyle}>
        <Text style={styles(theme).textCenterStyle}>{profile.username}</Text>
      </View>

      <View style={styles(theme).dividerStyle} />
      <Text style={styles(theme).headerStyle}>Name</Text>
      <Formik
        initialValues={{
          name: profile.name,
        }}
        enableReinitialize
        onSubmit={updateName}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <View>
            <View style={{ alignItems: "center" }}>
              <Field
                component={CustomInput}
                name="name"
                placeholder="Name"
                editable={editName}
              />
            </View>
            {editName ? (
              <CancelOkIcon
                cancelCallback={() => {
                  setEditName(false);
                  setFieldValue("name", profile.name);
                }}
                updateCallback={() => handleSubmit()}
              />
            ) : (
              <EditDeleteIcon
                editCallback={() => setEditName(true)}
                deleteCallback={() => deleteName()}
              />
            )}
          </View>
        )}
      </Formik>
      {profile.kampusStatus == 1 && (
        <>
          <View style={styles(theme).dividerStyle} />
          <Text style={styles(theme).headerStyle}>KABUTAR KAMPUS</Text>
          <View style={styles(theme).boxStyle}>
            <Text style={styles(theme).textJustifyStyle}>
              You are yet to avail kampus concessions. Please check out Kabutar
              Kampus
            </Text>
          </View>
        </>
      )}

      <View style={styles(theme).dividerStyle} />
      <Text style={styles(theme).headerStyle}>Address(es)</Text>
      {profile.address ? (
        profile.address.map((item, key) => {
          return (
            <View key={key} style={styles(theme).boxStyle}>
              <Text style={styles(theme).textJustifyStyle}>
                Address type: {item.type} , Name: {item.name}
              </Text>
              <Text style={styles(theme).textJustifyStyle}>
                {item.line1}, {item.line2}, {item.line3}
              </Text>
              <Text style={styles(theme).textJustifyStyle}>
                State / Region: {item.state}, Country: {item.country}, PIN /
                ZIP: {item.zip}
              </Text>

              <View>
                <EditDeleteIcon
                  editCallback={() =>
                    navigation.navigate("AddNewAddress", {
                      key: key,
                    })
                  }
                  deleteCallback={() => deleteAddress(key)}
                />
              </View>
            </View>
          );
        })
      ) : (
        <Text style={styles(theme).textCenterStyle}>
          You do not have any addresses
        </Text>
      )}
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() =>
          navigation.navigate("AddNewAddress", {
            key: null,
          })
        }
      >
        <Text style={styles(theme).buttonTextStyle}>Add New Address</Text>
      </TouchableOpacity>
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },

    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    dividerStyle: {
      width: "80%",
      alignSelf: "center",
      borderBottomColor: theme.colors.standardTextColor,
      borderBottomWidth: 5,
      margin: 10,
    },
    avatarStyle: {
      height: 200,
      width: 200,
      alignSelf: "center",
      borderRadius: 100,
    },
  });

export default Profile;
