import React, { createContext, useContext, useState, useEffect } from "react";
import { Image, Platform } from "react-native";
import { API, Storage } from "aws-amplify";
import { UserContext } from "./UserContext";

import * as queries from "../src/graphql/queries";
import * as mutations from "../src/graphql/mutations";
import noAvatar from "../assets/noAvatar.png";

export const ProfileContext = createContext(null);
const initialProfile = {
  username: "",
  avatar: false,
  address: null,
  avatarFetched: false,
  name: "",
};
export default ({ children }) => {
  const noAvatarUri =
    Platform.OS === "web"
      ? require("../assets/noAvatar.png")
      : Image.resolveAssetSource(noAvatar).uri;
  const [avatar, setAvatar] = useState(noAvatarUri);
  const [profile, setProfile] = useState(initialProfile);
  const { user } = useContext(UserContext);
  const changeTrigger = user ? user.attributes.email : "b";
  useEffect(() => {
    if (user) {
      checkProfile();
    }
  }, [changeTrigger]);

  const getAvatar = async () => {
    try {
      const s3avatar = await Storage.get("avatar.png", {
        level: "private",
      });

      setAvatar(s3avatar);
      setProfile((profile) => {
        return { ...profile, avatarFetched: true };
      });
    } catch (err) {
      console.log(err);
    }
  };

  const checkProfile = async () => {
    var username =
      user.attributes.email != undefined
        ? user.attributes.email
        : user.attributes.phone_number;
    try {
      const existingProfile = await API.graphql({
        query: queries.listProfilesByUsername,
        variables: {
          username: username,
        },
      });
      if (existingProfile.data.listProfilesByUsername.items.length == 0) {
        const newProfile = await API.graphql({
          query: mutations.createProfile,
          variables: {
            input: {
              username: username,
              avatar: false,
              name: "",
              kampusStatus: 1,
            },
          },
        });
        setProfile(newProfile.data.createProfile);
      } else {
        setProfile(existingProfile.data.listProfilesByUsername.items[0]);

        if (existingProfile.data.listProfilesByUsername.items[0].avatar) {
          getAvatar();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <ProfileContext.Provider value={{ profile, avatar, setProfile, setAvatar }}>
      {children}
    </ProfileContext.Provider>
  );
};
