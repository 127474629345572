import React, { useContext, useState, useEffect } from "react";
import { Text, View, StyleSheet, ScrollView } from "react-native";
import { API } from "aws-amplify";
import { Ionicons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";
import * as queries from "../../src/graphql/queries";
import { ThemeContext } from "../../context/ThemeContext";
import { UserContext } from "../../context/UserContext";
import { DisplayKardsContext } from "../../context/DisplayKardsContext";
import { MyLocationContext } from "../../context/MyLocationContext";
import { CurrentLocationContext } from "../../context/CurrentLocationContext";

const MyLocation = () => {
  const { currentLocation, setCurrentLocation } = useContext(
    CurrentLocationContext
  );
  const {
    myLocation,
    setMyLocation,
    latDelta,
    setLatDelta,
    lonDelta,
    setLonDelta,
  } = useContext(MyLocationContext);
  const { displayKards, setDisplayKards } = useContext(DisplayKardsContext);
  const [myLocationModal, setMyLocationModal] = useState(false);
  const { theme, changeTheme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const [region, setRegion] = useState(null);
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  useEffect(() => {
    setDisplayKards([]);
    if (isFocused) {
      updateDisplayKards().then((data) => {
        setDisplayKards(data);
      });
    }
  }, [myLocation && myLocation.latitude]);

  const updateDisplayKards = async () => {
    // console.log(myLocation.longitude);
    // console.log(myLocation.latitude);
    var itemArray = [];

    try {
      var nextToken = "0";
      while (nextToken != "") {
        const fetchkards = await API.graphql({
          query: queries.searchEs,
          variables: {
            location: {
              lng: Number(myLocation.longitude),
              lat: Number(myLocation.latitude),
            },
            nextToken: nextToken,
          },
          // tested authMode is compulsory otherwise no Current user error for Unauthorized user but works for authorized user
          authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
        });
        if (fetchkards.data.searchEs.items.length != 0) {
          fetchkards.data.searchEs.items.map((item) => {
            itemArray.push(item);
          });
        }
        nextToken = fetchkards.data.searchEs.nextToken;
      }

      return itemArray;
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>MY LOCATION</Text>
        <TouchableOpacity onPress={() => setMyLocationModal(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <View>
        {/* {myLocation.latitude == currentLocation.latitude &&
        myLocation.longitude == currentLocation.longitude ? (
          <View style={styles(theme).rowGroupCenterStyle}>
            <Text style={styles(theme).textJustifyStyle}>
              If you have given location access permission, your location is set
              to current location. Otherwise, it is set to default location.
            </Text>
          </View>
        ) : ( */}
        <View>
          <View style={styles(theme).rowGroupCenterStyle}>
            <Text style={styles(theme).textJustifyStyle}>
              Your location is set to
            </Text>
          </View>
          <Text style={styles(theme).textCenterStyle}>
            Latitude - {myLocation.latitude.toString().substring(0, 7)}
          </Text>
          <Text style={styles(theme).textCenterStyle}>
            Longitude - {myLocation.longitude.toString().substring(0, 7)}
          </Text>
        </View>
        {/* )} */}
      </View>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          navigation.navigate("ChangeMyLocation");
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>View / Change </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          setMyLocation(currentLocation);
          setRegion(null);
          setLatDelta(latDelta);
          setLonDelta(lonDelta);
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Reset</Text>
      </TouchableOpacity>
      {displayKards && displayKards.length != 0 ? (
        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => {
            navigation.navigate("DisplayKards");
          }}
        >
          <Text style={styles(theme).buttonTextStyle}>
            {displayKards.length} kards in this location - click to view
          </Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => {
            navigation.navigate("UseCases");
          }}
        >
          <Text style={styles(theme).buttonTextStyle}>
            0 kards in this location - click to view use cases
          </Text>
        </TouchableOpacity>
      )}
      <AwesomeAlert
        show={myLocationModal}
        title="MY LOCATION"
        message="You can change the location to view the kards displayed in that location. You can view / change the location using view / change button. You can set the location to the current / default  location using the reset button."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setMyLocationModal(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textCenterStyle: {
      margin: 5,
      color: theme.colors.standardTextColor,
      textAlign: "center",
    },
    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default MyLocation;
