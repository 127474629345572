import React, { useContext, useState, useEffect } from "react";
import {
  ScrollView,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import { API } from "aws-amplify";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import ShortUniqueId from "short-unique-id";
import AwesomeAlert from "react-native-awesome-alerts";

import { ProfileContext } from "../../../context/ProfileContext";
import { OrderContext } from "../../../context/OrderContext";
import { ThemeContext } from "../../../context/ThemeContext";
import { UserContext } from "../../../context/UserContext";

import * as queries from "../../../src/graphql/queries";

const KampusAccount = () => {
  const { user } = useContext(UserContext);
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const { theme, changeTheme } = useContext(ThemeContext);
  const [kampusEvents, setKampusEvents] = useState([]);
  const [revalidateKampusAccount, setRevalidateKampusAccount] = useState(false);
  const { profile, setProfile } = useContext(ProfileContext);
  const uid = new ShortUniqueId({ length: 29 });
  const id = uid();
  const { setOrderId, setAmount } = useContext(OrderContext);

  const stagingTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "stagingTeam"
    );

  const devTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "devTeam"
    );

  const kampusStatus =
    profile.kampusStatus == 1
      ? "You can apply for kampus registration or enrol as kampus volunteer."
      : profile.kampusStatus == 2
      ? "You have applied for kampus registration and the application is being verified."
      : profile.kampusStatus == 3
      ? `You have been registered as kampus Institution. You can avail 20% concession on paid kards subject to a maximum of ₹ 100. `
      : profile.kampusStatus == 4
      ? "You are enrolled as kampus volunteer. You can avail 20% concession on paid kards subject to a maximum of ₹ 100. "
      : null;

  useEffect(() => {
    if (isFocused) {
      updateProfile();
    }
  }, [isFocused]);

  const updateProfile = async () => {
    try {
      const updatedProfile = await API.graphql({
        query: queries.getProfile,
        variables: {
          id: profile.id,
        },
      });

      setProfile(updatedProfile.data.getProfile);
      setKampusEvents(updatedProfile.data.getProfile.kampusEvent.items);
    } catch (error) {
      console.log(error);
    }
  };

  // const makePayment = () => {
  //   setOrderId(id);
  //   if (profile.kampusStatus == 4) {
  //     setAmount(590.0);
  //   } else {
  //     setAmount(
  //       profile.kampusRegistration.volunteerCount > 50
  //         ? (profile.kampusRegistration.volunteerCount * 11.8).toFixed(2)
  //         : 590.0
  //     );
  //   }
  //   navigation.navigate("SelectKampusPaymentMethod");
  // };

  const makePayment = () => {
    setOrderId(id);
    if (stagingTeam || devTeam) {
      setAmount(1.0);
    } else if (profile.kampusStatus == 4) {
      setAmount(590.0);
    } else if (profile.kampusStatus == 3) {
      setAmount(
        profile.kampusRegistration.volunteerCount > 50
          ? (profile.kampusRegistration.volunteerCount * 11.8).toFixed(2)
          : 590.0
      );
    }

    navigation.navigate("SelectKampusPaymentMethod");
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}> KAMPUS ACCOUNT</Text>
        <TouchableOpacity
          onPress={() => {
            updateProfile();
          }}
        >
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Status</Text>
      <View style={styles(theme).boxStyle}>
        <Text style={styles(theme).textJustifyStyle}>{kampusStatus}</Text>

        {profile.kampusRegistration &&
          profile.kampusRegistration.volunteerCount < 10 && (
            <>
              <Text style={styles(theme).textJustifyStyle}>
                Volunteers Enrolled: {profile.kampusRegistration.volunteerCount}
              </Text>
              <Text style={styles(theme).textJustifyStyle}>
                You should enroll atleast 10 volunteers to post kampus kard FREE
                OF COST.
              </Text>
            </>
          )}
        {profile.kampusRegistration &&
          profile.kampusRegistration.volunteerCount >= 10 && (
            <>
              <Text style={styles(theme).textJustifyStyle}>
                Volunteers Enrolled: {profile.kampusRegistration.volunteerCount}
              </Text>
              <Text style={styles(theme).textJustifyStyle}>
                You can post kampus kard FREE OF COST.
              </Text>
            </>
          )}
      </View>

      {profile.kampusStatus == 3 && (
        <>
          <Text style={styles(theme).subHeaderStyle}>Championship</Text>
          <View style={styles(theme).boxStyle}>
            {profile.kampusRegistration &&
              profile.kampusRegistration.volunteerCount < 1000 && (
                <Text style={styles(theme).textJustifyStyle}>
                  You have to enroll 1000 volunteers to win KAMPUS CHAMPIONSHIP
                  AWARD.
                </Text>
              )}
            {profile.kampusRegistration &&
              profile.kampusRegistration.volunteerCount >= 1000 && (
                <>
                  <Text style={styles(theme).textJustifyStyle}>
                    Congratulations!!! Your institution has been awarded KAMPUS
                    CHAMPIONSHIP AWARD on{" "}
                    {new Date(
                      profile.kampusRegistration.championshipDate
                    ).toLocaleDateString()}
                  </Text>

                  <View style={styles(theme).rowGroupSpaceEvenlyStyle}>
                    <AntDesign
                      name={theme.type == "outline" ? "staro" : "star"}
                      size={50}
                      color={theme.colors.standardTextColor}
                    />
                    <AntDesign
                      name={theme.type == "outline" ? "staro" : "star"}
                      size={60}
                      color={theme.colors.standardTextColor}
                    />
                    <AntDesign
                      name={theme.type == "outline" ? "staro" : "star"}
                      size={50}
                      color={theme.colors.standardTextColor}
                    />
                  </View>
                </>
              )}
          </View>

          <Text style={styles(theme).subHeaderStyle}>Events</Text>

          {kampusEvents.length != 0 ? (
            <>
              {kampusEvents.map((item, key) => {
                return (
                  <TouchableOpacity
                    key={key}
                    onPress={() => {
                      navigation.navigate("KampusEventDetails", {
                        item: item,
                      });
                    }}
                  >
                    <View style={styles(theme).boxStyle}>
                      <Text style={styles(theme).textJustifyStyle}>
                        Title: {item.title}
                      </Text>
                      <Text style={styles(theme).textJustifyStyle}>
                        Name:{item.name}
                      </Text>
                      <View style={styles(theme).boxTextStyle}>
                        <Text style={styles(theme).textStyle}>
                          Request Date :{" "}
                        </Text>
                        <Text style={styles(theme).textBoldStyle}>
                          {new Date(item.requestDate).toLocaleDateString()}
                        </Text>
                      </View>
                      <View style={styles(theme).boxTextStyle}>
                        <Text style={styles(theme).textStyle}>Status : </Text>
                        <Text style={styles(theme).textBoldStyle}>
                          {item.status}
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </>
          ) : (
            <ScrollView style={styles(theme).screenStyle}>
              <View style={styles(theme).boxStyle}>
                <Text style={styles(theme).textCenterStyle}>
                  You do not have any kampus events
                </Text>
              </View>
            </ScrollView>
          )}
        </>
      )}

      {(profile.kampusStatus == 3 || profile.kampusStatus == 4) &&
        new Date(profile.kampusEndDate) >= new Date() && (
          <>
            <Text style={styles(theme).subHeaderStyle}>Concessions</Text>
            <View style={styles(theme).boxStyle}>
              <View style={styles(theme).boxTextStyle}>
                <Text style={styles(theme).textStyle}>Start Date:</Text>
                <Text style={styles(theme).textBoldStyle}>
                  {new Date(profile.kampusStartDate).toLocaleDateString()}
                </Text>
              </View>
              <View style={styles(theme).boxTextStyle}>
                <Text style={styles(theme).textStyle}>Start Time:</Text>
                <Text style={styles(theme).textBoldStyle}>
                  {" "}
                  {new Date(profile.kampusStartDate).toLocaleTimeString()}
                </Text>
              </View>
              <View style={styles(theme).boxTextStyle}>
                <Text style={styles(theme).textStyle}>End Date:</Text>
                <Text style={styles(theme).textBoldStyle}>
                  {new Date(profile.kampusEndDate).toLocaleDateString()}
                </Text>
              </View>
              <View style={styles(theme).boxTextStyle}>
                <Text style={styles(theme).textStyle}>End Time:</Text>
                <Text style={styles(theme).textBoldStyle}>
                  {new Date(profile.kampusEndDate).toLocaleTimeString()}
                </Text>
              </View>
              <Text style={styles(theme).textBoldStyle}>
                The concessions can be availed now.
              </Text>
            </View>
          </>
        )}

      {(profile.kampusStatus == 3 || profile.kampusStatus == 4) &&
        new Date(profile.kampusEndDate) < new Date() && (
          <>
            <Text style={styles(theme).subHeaderStyle}>Concessions</Text>
            <View style={styles(theme).boxStyle}>
              <View style={styles(theme).boxTextStyle}>
                <Text style={styles(theme).textStyle}>Start Date:</Text>
                <Text style={styles(theme).textBoldStyle}>
                  {new Date(profile.kampusStartDate).toLocaleDateString()}
                </Text>
              </View>
              <View style={styles(theme).boxTextStyle}>
                <Text style={styles(theme).textStyle}>Start Time:</Text>
                <Text style={styles(theme).textBoldStyle}>
                  {" "}
                  {new Date(profile.kampusStartDate).toLocaleTimeString()}
                </Text>
              </View>
              <View style={styles(theme).boxTextStyle}>
                <Text style={styles(theme).textStyle}>End Date:</Text>
                <Text style={styles(theme).textBoldStyle}>
                  {new Date(profile.kampusEndDate).toLocaleDateString()}
                </Text>
              </View>
              <View style={styles(theme).boxTextStyle}>
                <Text style={styles(theme).textStyle}>End Time:</Text>
                <Text style={styles(theme).textBoldStyle}>
                  {new Date(profile.kampusEndDate).toLocaleTimeString()}
                </Text>
              </View>
              <Text style={styles(theme).textBoldStyle}>
                The concession period has expired. You can revalidate now.
              </Text>
            </View>
            <TouchableOpacity
              style={styles(theme).buttonStyle}
              onPress={() => setRevalidateKampusAccount(true)}
            >
              <Text style={styles(theme).buttonTextStyle}>Revalidate</Text>
            </TouchableOpacity>
          </>
        )}
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("KampusHome")}
      >
        <Text style={styles(theme).buttonTextStyle}>Back</Text>
      </TouchableOpacity>
      <AwesomeAlert
        show={revalidateKampusAccount}
        title="REVALIDATE KAMPUS ACCOUNT"
        message={`The revalidation charges are ₹ ${
          // profile.kampusStatus == 3 &&
          // profile.kampusRegistration.volunteerCount > 50
          //   ? (profile.kampusRegistration.volunteerCount * 11.8).toFixed(2)
          //   : 590.0
          stagingTeam || devTeam
            ? 1.0
            : profile.kampusStatus == 4
            ? 590.0
            : profile.kampusStatus == 3 &&
              profile.kampusRegistration.volunteerCount > 50
            ? (profile.kampusRegistration.volunteerCount * 11.8).toFixed(2)
            : 590.0
        } Would you like to revalidate now ?`}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        confirmText="Yes,proceed to pay"
        cancelText="No,not now"
        onCancelPressed={() => setRevalidateKampusAccount(false)}
        onConfirmPressed={() => {
          setRevalidateKampusAccount(false);
          makePayment();
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

export default KampusAccount;

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },
    textBoldStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      margin: 5,
    },
    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },
    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    boxTextStyle: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    rowGroupSpaceEvenlyStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
