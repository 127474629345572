import React, { useContext } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { API, graphqlOperation, Storage } from "aws-amplify";

import { ThemeContext } from "../../../context/ThemeContext";
import * as mutations from "../../../src/graphql/mutations";
import * as queries from "../../../src/graphql/queries";
const TestTransactionFailure = (props) => {
  const navigation = useNavigation();

  const orderId = props.route.params.orderId;

  const message = props.route.params.message;

  const { theme, changeTheme } = useContext(ThemeContext);

  const updateTransaction = async () => {
    try {
      const getRecord = await API.graphql({
        query: queries.getKard,
        variables: {
          id: orderId,
        },
      });
      const imageName = getRecord.data.getKard.imageName;
      const deleteTransaction = await API.graphql(
        graphqlOperation(mutations.deleteKard, {
          input: {
            id: orderId,
          },
        })
      );
      // console.log(deleteTransaction);
      const deleteKard = await Storage.remove(imageName);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <View style={styles(theme).screenStyle}>
      <View>
        <View style={styles(theme).headerViewStyle}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "close-circle-sharp"
                : "close-circle-outline"
            }
            size={100}
            color={theme.colors.standardTextColor}
          />
        </View>

        <Text style={styles(theme).subHeaderStyle}>{message}</Text>

        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => {
            navigation.navigate("PaymentTesting");
          }}
        >
          <Text style={styles(theme).buttonTextStyle}>Back</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },

    buttonStyle: {
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 3,
      backgroundColor: theme.colors.backgroundColor,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
  });
export default TestTransactionFailure;
