import React, { useContext, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { Field, Formik } from "formik";
import { API } from "aws-amplify";
import AwesomeAlert from "react-native-awesome-alerts";
import * as Yup from "yup";

import { ThemeContext } from "../context/ThemeContext";
import CustomInput from "../components/CustomInput";
import MultilineCustomInput from "../components/MultilineCustomInput";
import * as mutations from "../src/graphql/mutations";
import { UserContext } from "../context/UserContext";
import ShortUniqueId from "short-unique-id";

const ContactUs = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const [feedback, setFeedback] = useState(false);
  const { user } = useContext(UserContext);
  const uid = new ShortUniqueId({ length: 29 });

  const onSubmit = async (values, { resetForm }) => {
    const contactUs = await API.graphql({
      query: mutations.createFeedback,
      variables: {
        input: {
          id: uid(),
          type: "contact",
          email: values.email,
          message: values.message,
          status: "pending",
          requestDate: new Date().toISOString(),
        },
      },
      authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
    });

    resetForm();
    setFeedback(true);
  };

  const initialValues = {};
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid Email").required("Reqiured"),
    message: Yup.string().required("Required"),
  });
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>CONTACT US</Text>
      <View>
        <Formik
          initialValues={{
            email: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isValid, setFieldValue }) => (
            <View>
              <View>
                <Text style={styles(theme).labelStyle}>Email*</Text>
                <Field
                  component={CustomInput}
                  name="email"
                  placeholder="Enter Your Email"
                />
                <Text style={styles(theme).labelStyle}>Message*</Text>
                <Field
                  component={MultilineCustomInput}
                  name="message"
                  placeholder="Message"
                />
              </View>
              <TouchableOpacity
                style={
                  isValid
                    ? styles(theme).buttonStyle
                    : styles(theme).disabledButtonStyle
                }
                onPress={() => handleSubmit()}
                disabled={!isValid}
              >
                <Text style={styles(theme).buttonTextStyle}>Send!</Text>
              </TouchableOpacity>
            </View>
          )}
        </Formik>
      </View>
      <AwesomeAlert
        show={feedback}
        title="CONTACT US"
        message={
          "Thank you for contacting us. We will get back to you shortly, if required."
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok"
        onConfirmPressed={() => {
          setFeedback(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    labelStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },
    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default ContactUs;
