import React, { useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import ShortUniqueId from "short-unique-id";
import { ThemeContext } from "../../../context/ThemeContext";
import { OrderContext } from "../../../context/OrderContext";

const PaymentTesting = () => {
  const navigation = useNavigation();
  const { theme, changeTheme } = useContext(ThemeContext);
  const uid = new ShortUniqueId({ length: 29 });
  const id = uid();

  const { setOrderId, setAmount } = useContext(OrderContext);

  const makePayment = () => {
    setOrderId(id);
    setAmount(1.0);
    navigation.navigate("SelectTestPaymentMethod");
  };
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>PAYMENT TESTING</Text>

      <View style={styles(theme).rowGroupCenterStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          Click the button below to test payment integration. This will result
          in actual payment of ₹ 1
        </Text>
      </View>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          makePayment();
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Payment Testing</Text>
      </TouchableOpacity>
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: { flex: 1, backgroundColor: "white" },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 3,
      backgroundColor: theme.colors.backgroundColor,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },
    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },
  });

export default PaymentTesting;
