import {
  DefaultF,
  CrimsonF,
  SpringGreenF,
  FuriousOrangeF,
  RubyF,
  ElectricPurpleF,
  IndigoF,
  CyberYellowF,
  SailorBlueF,
  DeepSkyBlueF,
  WindsorWineF,
  PestoF,
  BrownPodF,
  NeonBlueF,
  SpaceCherryF,
  MosqueF,
  DarkOrangeF,
  RosePinkF,
  DarkGoldenRodF,
  BismarkF,
  SteelBlueF,
  BrownSugarF,
  DarkKhakiF,
  PeruF,
  NavyF,
  CherryTomatoF,
  IslandGreenF,
  ChocolateF,
  DeepPinkF,
  DuskyCitronF,
  DimGreyF,
  DarkSlateBlueF,
  BlueF,
  ForestBiomeF,
  FuchsiaF,
  IceFlowF,
  AquaF,
} from "./Themes";

export const fillThemes = [
  {
    label: "Default",
    changeTo: DefaultF,
    color: "#6200ee",
  },
  {
    label: "Crimson",
    changeTo: CrimsonF,
    color: "#AE0E36",
  },
  {
    label: "Spring Green",
    changeTo: SpringGreenF,
    color: "#00e676",
  },
  {
    label: "Furious Orange",
    changeTo: FuriousOrangeF,
    color: "#ff5722",
  },
  {
    label: "Ruby",
    changeTo: RubyF,
    color: "#d81b60",
  },
  {
    label: "Electric Purple",
    changeTo: ElectricPurpleF,
    color: "#aa00ff",
  },
  {
    label: "Indigo",
    changeTo: IndigoF,
    color: "#4B0082",
  },
  {
    label: "Cyber Yellow",
    changeTo: CyberYellowF,
    color: "#FDD20E",
  },
  {
    label: "Sailor Blue",
    changeTo: SailorBlueF,
    color: "#00203F",
  },
  {
    label: "Deep Sky Blue",
    changeTo: DeepSkyBlueF,
    color: "#00b0ff",
  },
  {
    label: "Windsor Wine",
    changeTo: WindsorWineF,
    color: "#643E46",
  },
  {
    label: "Pesto",
    changeTo: PestoF,
    color: "#827717",
  },
  {
    label: "Brown Pod",
    changeTo: BrownPodF,
    color: "#3e2723",
  },
  {
    label: "Neon Blue",
    changeTo: NeonBlueF,
    color: "#304ffe",
  },

  {
    label: "Space Cherry",
    changeTo: SpaceCherryF,
    color: "#990011",
  },

  {
    label: "Mosque",
    changeTo: MosqueF,
    color: "#006064",
  },
  {
    label: "Dark Orange",
    changeTo: DarkOrangeF,
    color: "#ff8f00",
  },
  {
    label: "Rose Pink",
    changeTo: RosePinkF,
    color: "#DF6589",
  },
  {
    label: "Dark Golden Rod",
    changeTo: DarkGoldenRodF,
    color: "#B8860B",
  },
  {
    label: "Bismark",
    changeTo: BismarkF,
    color: "#455a64",
  },

  {
    label: "Steel Blue",
    changeTo: SteelBlueF,
    color: "#4682B4",
  },
  {
    label: "Brown Sugar",
    changeTo: BrownSugarF,
    color: "#A07855",
  },
  {
    label: "Dark Khaki",
    changeTo: DarkKhakiF,
    color: "#BDB76B",
  },
  {
    label: "Peru",
    changeTo: PeruF,
    color: "#CD853F",
  },
  {
    label: "Navy",
    changeTo: NavyF,
    color: "#000080",
  },
  {
    label: "Cherry Tomato",
    changeTo: CherryTomatoF,
    color: "#ED2B33",
  },
  {
    label: "Island Green",
    changeTo: IslandGreenF,
    color: "#2BAE66",
  },

  {
    label: "Chocolate",
    changeTo: ChocolateF,
    color: "#D2691E",
  },
  {
    label: "Deep Pink",
    changeTo: DeepPinkF,
    color: "#FF1493",
  },
  {
    label: "Dusky Citron",
    changeTo: DuskyCitronF,
    color: "#E3CD81",
  },
  {
    label: "Dim Grey",
    changeTo: DimGreyF,
    color: "#616161",
  },
  {
    label: "Dark Slate Blue",
    changeTo: DarkSlateBlueF,
    color: "#483D8B",
  },
  {
    label: "Blue",
    changeTo: BlueF,
    color: "#0000FF",
  },
  {
    label: "Forest Biome",
    changeTo: ForestBiomeF,
    color: "#184A45",
  },
  {
    label: "Fuchsia",
    changeTo: FuchsiaF,
    color: "#FF00FF",
  },
  {
    label: "Ice Flow",
    changeTo: IceFlowF,
    color: "#A2A2A1",
  },
  {
    label: "Aqua",
    changeTo: AquaF,
    color: "#18ffff",
  },
];
