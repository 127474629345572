import React, { useContext, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Linking,
  ScrollView,
} from "react-native";

import { IconButton } from "react-native-paper";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import * as WebBrowser from "expo-web-browser";
import Amplify, { Auth } from "aws-amplify";
import { useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";

import { Ionicons, AntDesign } from "@expo/vector-icons";
import { ThemeContext } from "../../context/ThemeContext";
import { DeviceContext } from "../../context/DeviceContext";
import CustomInput from "../../components/CustomInput";
// import CustomPhoneInput from "../../components/CustomPhoneInput";
import awsconfig from "../../src/aws-exports";

async function urlOpener(url, redirectUrl) {
  // console.log("Entered");
  const { type, url: newUrl } = WebBrowser.openAuthSessionAsync(
    url,
    redirectUrl
  );
  if (type === "success" && Platform.OS === "ios") {
    WebBrowser.dismissBrowser();
    return Linking.openURL(newUrl);
  }
}

Amplify.configure({
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    urlOpener,
  },
});

const SignIn = (props) => {
  const { deviceType, setDeviceType } = useContext(DeviceContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const navigation = useNavigation();

  // const [signUpMode, setSignUpMode] = useState(
  //   deviceType == "MOBILE" ? "phone" : "email"
  // );
  const [signUpMode, setSignUpMode] = useState("email");

  const [pendingVerification, setPendingVerification] = useState(false);
  const [countryCode, setCountryCode] = useState("IN");
  const [username, setUsername] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object({
    username:
      signUpMode == "email"
        ? Yup.string().email("Invalid Email").required("Reqiured")
        : Yup.string().required("Required"),

    password: Yup.string()
      .min(6, "Password should be minimum 6 characters")
      .required("Required"),
  });

  async function onSubmit(values, { resetForm, setFieldError }) {
    try {
      await Auth.signIn({
        username: values.username,
        password: values.password,
      });
      resetForm();
      navigation.navigate("Home");
    } catch (err) {
      resetForm();
      console.log(err);

      if (err.message == "User is not confirmed.") {
        // console.log("yes this is user not confirmed exception");
        setUsername(values.username);
        setPendingVerification(true);
        // console.log("pending verification is true");
      } else {
        setFieldError("general", err.message);
        setError(true);
        setErrorMessage(err.message);
      }
    }
  }

  if (props.authState === "signIn") {
    return (
      <ScrollView style={styles(theme).fullScreenStyle}>
        <Text style={styles(theme).headerStyle}>SIGN IN</Text>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isValid, setFieldValue }) => (
            <View>
              <View style={styles(theme).rowGroupStyle}>
                {/* <TouchableOpacity
                  onPress={() => {
                    setSignUpMode("email");
                    setFieldValue("username", "");
                  }}
                >
                  <Ionicons
                    name={
                      signUpMode == "email"
                        ? "radio-button-on"
                        : "radio-button-off"
                    }
                    size={25}
                    color={theme.colors.standardTextColor}
                  />
                </TouchableOpacity> */}
                {/* <Text style={styles(theme).textStyle}>Email</Text> */}
                {/* <TouchableOpacity
                  onPress={() => {
                    setSignUpMode("phone");
                    setFieldValue("username", "");
                  }}
                >
                  <Ionicons
                    name={
                      signUpMode == "phone"
                        ? "radio-button-on"
                        : "radio-button-off"
                    }
                    size={25}
                    color={theme.colors.standardTextColor}
                  />
                </TouchableOpacity>
                <Text style={styles(theme).textStyle}>Phone Numbeeer</Text> */}
              </View>
              {signUpMode == "email" && (
                <>
                  <Text style={styles(theme).labelStyle}>Email*</Text>
                  <Field
                    component={CustomInput}
                    name="username"
                    placeholder="Email Address"
                    keyboardType="email-address"
                  />
                </>
              )}

              {/* {signUpMode == "phone" && (
                <>
                  <Text style={styles(theme).labelStyle}>Phone Number*</Text>
                  <Field
                    component={CustomPhoneInput}
                    name="username"
                    placeholder="Mobile Number"
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                  />
                </>
              )} */}
              <Text style={styles(theme).labelStyle}>Password*</Text>
              <Field
                component={CustomInput}
                name="password"
                placeholder="Password"
                secureTextEntry
              />
              <TouchableOpacity
                style={
                  isValid
                    ? styles(theme).buttonStyle
                    : styles(theme).disabledButtonStyle
                }
                onPress={handleSubmit}
                disabled={!isValid}
              >
                <Text style={styles(theme).buttonTextStyle}>Sign In</Text>
              </TouchableOpacity>
            </View>
          )}
        </Formik>
        <View style={styles(theme).rowGroupSpaceBetweenStyle}>
          <TouchableOpacity onPress={() => props.onStateChange("signUp", {})}>
            <Text style={styles(theme).textStyle}>Sign Up</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => props.onStateChange("forgotPassword", {})}
          >
            <Text style={styles(theme).textStyle}>Forgot Password</Text>
          </TouchableOpacity>
        </View>
        <Text style={styles(theme).textCenterStyle}>
          --- Or Sign In With ---
        </Text>
        <View style={styles(theme).rowGroupSpaceEvenlyStyle}>
          <IconButton
            icon="google-plus"
            color={"red"}
            size={50}
            onPress={() => Auth.federatedSignIn({ provider: "Google" })}
          />
          <IconButton
            icon="facebook"
            color={"#4267B2"}
            size={45}
            onPress={() => Auth.federatedSignIn({ provider: "Facebook" })}
          />
          <AntDesign
            name="amazon"
            color={"#ff9900"}
            size={45}
            onPress={() =>
              Auth.federatedSignIn({ provider: "LoginWithAmazon" })
            }
          />
        </View>
        <AwesomeAlert
          show={pendingVerification}
          title="PENDING VERIFICATION"
          message="Your email/phone number is not yet verified. Would you like to verify it now?"
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No"
          confirmText="Yes"
          onCancelPressed={() => setPendingVerification(false)}
          onConfirmPressed={() => {
            setPendingVerification(false);
            Auth.resendSignUp(username);
            props.onStateChange("confirmSignUp", {
              username: username,
            });
          }}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
          cancelButtonStyle={styles(theme).alertButtonStyle}
          confirmButtonStyle={styles(theme).alertButtonStyle}
          cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
          confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
        />
        <AwesomeAlert
          show={error}
          title="ERROR"
          message={errorMessage}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="Ok, got it"
          onConfirmPressed={() => {
            setError(false);
            setErrorMessage("");
          }}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
          confirmButtonStyle={styles(theme).alertButtonStyle}
          confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
        />
      </ScrollView>
    );
  } else {
    return null;
  }
};

const styles = (theme) =>
  StyleSheet.create({
    fullScreenStyle: {
      flex: 1,
      backgroundColor: "white",
      width: "100%",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },

    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },

    labelStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },

    rowGroupStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      margin: 10,
    },

    rowGroupSpaceEvenlyStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: 10,
    },

    rowGroupSpaceBetweenStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-between",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default SignIn;
