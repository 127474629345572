import React, { useContext, useState } from "react";
import {
  Text,
  Image,
  View,
  StyleSheet,
  Platform,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { API, graphqlOperation, Storage } from "aws-amplify";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";
import "react-native-get-random-values";
import ShortUniqueId from "short-unique-id";
import { useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";

import * as mutations from "../../../src/graphql/mutations";
import { ThemeContext } from "../../../context/ThemeContext";
import { ProfileContext } from "../../../context/ProfileContext";
import { PromoKardContext } from "../../../context/PromoKardContext";
import { SelectKard } from "../../../assets/index";

const ReviewAndConfirmPromoKard = () => {
  const {
    kard,
    setKard,
    setKardSelected,
    mapRegionForPromoKard,
    polygon,
    setPolygon,
    finalViews,
    setFinalViews,
    date,
    setDate,
  } = useContext(PromoKardContext);

  const { profile, setProfile } = useContext(ProfileContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const [checkboxSelected, setCheckboxSelected] = useState(false);
  const [reviewAndConfirm, setReviewAndConfirm] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);
  const [postError, setPostError] = useState(false);
  const navigation = useNavigation();
  const uid = new ShortUniqueId({ length: 29 });
  const id = uid();
  const noKardUri =
    Platform.OS === "web"
      ? require("../../../assets/selectKard.png")
      : Image.resolveAssetSource(SelectKard).uri;

  const postPromoKard = async () => {
    //  set image name
    const fileType =
      Platform.OS == "web"
        ? kard.substring(kard.indexOf("/") + 1, kard.indexOf(";"))
        : kard.substring(kard.lastIndexOf(".") + 1);
    const imageName = id + "." + fileType;
    const response = await fetch(kard);
    const blob = await response.blob();
    const access = { level: "public", contentType: fileType };
    var displayAreaObject = {
      type: "polygon",
      coordinates: [[]],
    };
    polygon.map((item, key) => {
      var newItem =
        Platform.OS == "web"
          ? [Number(item.lng.toFixed(5)), Number(item.lat.toFixed(5))]
          : [
              Number(item.longitude.toFixed(5)),
              Number(item.latitude.toFixed(5)),
            ];
      displayAreaObject.coordinates[0].push(newItem);
    });
    var firstItem =
      Platform.OS == "web"
        ? [Number(polygon[0].lng.toFixed(5)), Number(polygon[0].lat.toFixed(5))]
        : [
            Number(polygon[0].longitude.toFixed(5)),
            Number(polygon[0].latitude.toFixed(5)),
          ];
    displayAreaObject.coordinates[0].push(firstItem);
    const displayAreaJSONString = JSON.stringify(displayAreaObject);
    try {
      const createKard = await API.graphql(
        graphqlOperation(mutations.createKard, {
          input: {
            id: id,
            userId: profile.id,
            // userIdentityId: credentials.identityId,
            username: profile.username,
            imageName: imageName,
            displayArea: displayAreaJSONString,
            kardType: "promo",
            kardStatus: "active",
            transactionId: "nil",
            transactionDate: new Date().toISOString(),
            billingAddress: profile.address[0],
            charges: 0,
            discount: 0,
            taxes: 0,
            total: 0,
            setViews: finalViews,
            shownViews: 0,
            likes: 0,
            dislikes: 0,
            downloads: 0,
            flags: 0,
            changeKard: 0,
            changeDisplayArea: 0,
            startDate: date.toISOString(),
            // expiryDate: kardType != "free" ? 5 mins : null
          },
        })
      );
      await Storage.put(imageName, blob, access);
      setPolygon([]);
      setFinalViews(1000);
      setDate(new Date());
      setKard(noKardUri);
      setKardSelected(false);
      setPostSuccess(true);
    } catch (err) {
      setPostError(true);
      console.log(err);
    }
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>REVIEW AND CONFIRM</Text>
        <TouchableOpacity onPress={() => setReviewAndConfirm(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <Text style={styles(theme).textCenterStyle}>Step 3 of 3</Text>
      <Text style={styles(theme).subHeaderStyle}>Selected Kard</Text>
      <View>
        <Image source={{ uri: kard }} style={styles(theme).kardStyle} />
        <View>
          <Text style={styles(theme).subHeaderStyle}>Display Area</Text>
        </View>
        <MapView
          style={styles(theme).kardStyle}
          provider={PROVIDER_GOOGLE}
          initialRegion={mapRegionForPromoKard}
        >
          <MapView.Marker
            coordinate={mapRegionForPromoKard}
            pinColor={theme.colors.standardTextColor}
          />
          {Platform.OS == "web" ? (
            <MapView.Polygon
              path={polygon}
              options={{
                strokeColor: theme.colors.standardTextColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: theme.colors.standardTextColor,
                fillOpacity: 0.35,
              }}
            />
          ) : (
            <MapView.Polygon
              coordinates={polygon}
              strokeColor={theme.colors.standardTextColor}
              fillColor={theme.colors.standardTextColor}
            />
          )}
        </MapView>
        <Text style={styles(theme).subHeaderStyle}>Display Details</Text>
        <View style={styles(theme).boxStyle}>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Number of Views: </Text>
            <Text style={styles(theme).textBoldStyle}>{finalViews}</Text>
          </View>

          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Starting Date: </Text>
            <Text style={styles(theme).textBoldStyle}>
              {date.toLocaleDateString()}
            </Text>
          </View>

          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Starting Time: </Text>
            <Text style={styles(theme).textBoldStyle}>
              {date.toLocaleTimeString()}
            </Text>
          </View>
        </View>

        <View style={styles(theme).termsViewStyle}>
          <TouchableOpacity
            onPress={() => setCheckboxSelected(!checkboxSelected)}
          >
            <AntDesign
              name={
                theme.type == "fill"
                  ? !checkboxSelected
                    ? "closecircle"
                    : "checkcircle"
                  : !checkboxSelected
                  ? "closecircleo"
                  : "checkcircleo"
              }
              size={50}
              color={!checkboxSelected ? "red" : theme.colors.standardTextColor}
            />
          </TouchableOpacity>

          <Text
            style={{
              flex: 1,
              flexWrap: "wrap",
              color:
                theme.type == "fill"
                  ? !checkboxSelected
                    ? "red"
                    : theme.colors.standardTextColor
                  : !checkboxSelected
                  ? "red"
                  : theme.colors.standardTextColor,
              paddingLeft: 20,
              textAlign: "justify",
            }}
          >
            I have read the terms and conditions and I agree
          </Text>
        </View>
      </View>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("ChooseSettingsForPromoKard")}
      >
        <Text style={styles(theme).buttonTextStyle}>Back</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={
          checkboxSelected
            ? styles(theme).buttonStyle
            : styles(theme).disabledButtonStyle
        }
        onPress={() => postPromoKard()}
        disabled={!checkboxSelected}
      >
        <Text style={styles(theme).buttonTextStyle}>Post</Text>
      </TouchableOpacity>
      <AwesomeAlert
        show={reviewAndConfirm}
        title="REVIEW AND CONFIRM"
        message={
          "Congratulations!!! You are now ready to post your promo kard!! Before you post your promo kard , please review the details. You must also go through the terms and conditions and agree."
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setReviewAndConfirm(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle1}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={postSuccess}
        title="SUCCESS"
        message={
          "Congratulations!!! You have successfully posted promo kard.  "
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setPostSuccess(false);
          navigation.navigate("SelectPromoKard");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={postError}
        title="ERROR"
        message={
          "Sorry !!! There was some error while posting promo kard. Please try after sometime. If the error persists, please report the problem under feedback section. "
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setPostError(false);
          navigation.navigate("SelectPromoKard");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },

    kardStyle: {
      height: 550,
      width: 350,
      alignSelf: "center",
      borderRadius: 20,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },
    textBoldStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    boxTextStyle: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },
    termsViewStyle: {
      width: "80%",
      flexDirection: "row",
      alignSelf: "center",
      alignItems: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default ReviewAndConfirmPromoKard;
