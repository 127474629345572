export const availableBanks = [
  { code: "SBI", name: "State Bank of India" },
  { code: "PNB", name: "Punjab National Bank" },
  { code: "YES", name: "YES Bank" },
  { code: "NKMB", name: "KOTAK Bank" },
  { code: "INDS", name: "Indus Bank" },
  { code: "BOI", name: "Bank of India" },
  { code: "BOB", name: "Bank of Baroda" },
  { code: "BOM", name: "Bank of Maharashtra" },
  { code: "CANARA", name: "Canara Bank" },
  { code: "CSB", name: "Catholic Syrian Bank" },
  { code: "CBI", name: "Central Bank of India" },
  { code: "DHAN", name: "Dhanlaxmi Bank" },
  { code: "INDB", name: "Indian Bank" },
  { code: "IDBI", name: "IDBI Bank" },
  { code: "IDFC", name: "IDFC Bank" },
  { code: "JKB", name: "J & K Bank" },
  { code: "KTKB", name: "Karnataka Bank" },
  { code: "KVB", name: "Karur Vaisya Bank" },
  { code: "LVB", name: "Laxmi Vilas Bank" },
  { code: "PSB", name: "Punjab & Sind Bank" },
  { code: "SIB", name: "South Indian Bank" },
  { code: "UCO", name: "UCO Bank" },
  { code: "UNI", name: "Union Bank" },
  { code: "AUBL", name: "AU Small Finance Bank" },
  { code: "BHARAT", name: "Bharath Bank" },
  { code: "COSMOS", name: "COSMOS Bank" },
  { code: "DCB", name: "DCB Bank" },
  { code: "ESFB", name: "Eqitas Bank" },
  { code: "GPPB", name: "Gopinath Patil Bank" },
  { code: "JSB", name: "Janatha Sahakari Bank" },
  { code: "NKGS", name: "NKGS Bank" },
  { code: "RATN", name: "RBL Bank" },
  { code: "SVC", name: "SVC Bank" },
  { code: "STB", name: "Saraswat Coop Bank" },
  { code: "SSFB", name: "Suryodaya Small Finance Bank" },
];
