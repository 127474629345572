const upiHandles = [
  "@birla",
  "@airtel",
  "@airtelpaymentsbank",
  "@apb",
  "@allbank",
  "@albk",
  "@allahabadbank",
  "@andb",
  "@axisbank",
  "@axis",
  "@pingpay",
  "@okaxis",
  "@axisgo",
  "@apl",
  "@freecharge",
  "@lime",
  "abfspay",
  "@bandhan",
  "@barodampay",
  "@barodapay",
  "@boi",
  "@mahb",
  "@cnrb",
  "@csbpay",
  "@csbcash",
  "@centralbank",
  "@cbin",
  "@cboi",
  "@cub",
  "@dbs",
  "@dcb",
  "@dcbbank",
  "@denabank",
  "@equitas",
  "@purz",
  "@federal",
  "@fbl",
  "@finobank",
  "@hdfcbank",
  "@payzapp",
  "@okhdfcbank",
  "@rajgovhdfcbank",
  "@hsbc",
  "@imobile",
  "@ezeepay",
  "@eazypay",
  "@icici",
  "@okicici",
  "@pockets",
  "@icicipay",
  "@idbi",
  "@idfcbank",
  "@idfcnetc",
  "@indianbank",
  "@cmsidfc",
  "@indbank",
  "@indianbk",
  "@iob",
  "@indus",
  "@jkb",
  "@jsbp",
  "@jsb",
  "@kbl",
  "@karb",
  "@kbl052",
  "@kvb",
  "@karurvysyabank",
  "@kvbank",
  "@kaypay",
  "@kmb",
  "@kmbl",
  "@kotak",
  "@lvb",
  "@obc",
  "@paytm",
  "@pnb",
  "@psb",
  "@rbl",
  "@sib",
  "@srcb",
  "@sc",
  "@scmobile",
  "@scb",
  "@scbl",
  "@s2b",
  "@sbi",
  "@oksbi",
  "@syndicate",
  "@syndbank",
  "@synd",
  "@lvb",
  "@lvbank",
  "@tjsb",
  "@uco",
  "@unionbankofindia",
  "@unionbank",
  "@uboi",
  "@utbi ",
  "@ubi",
  "@united",
  "@vjb",
  "@vijb",
  "@vijayabank",
  "@yesbank",
  "@ybl",
  "@yesbankltd",
  "@dlb",
  "@upi",
];

export default upiHandles;
