import React, { useContext, useEffect, useState } from "react";
import {
  Text,
  View,
  ScrollView,
  Platform,
  Image,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";
import { API, Storage } from "aws-amplify";
import AwesomeAlert from "react-native-awesome-alerts";

import { KampusEventContext } from "../../../context/KampusEventContext";
import { ThemeContext } from "../../../context/ThemeContext";
import * as mutations from "../../../src/graphql/mutations";
import * as queries from "../../../src/graphql/queries";
import ChangeViewsForKampusEvent from "./ChangeViewsForKampusEvent";

const PendingKampusEventDetails = (props) => {
  const item = props.route.params.item;
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const { theme, changeTheme } = useContext(ThemeContext);
  const { mapRegionForKampusEvent, polygon, setPolygon, kard, setKard } =
    useContext(KampusEventContext);
  const [rejectModal, setRejectModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [viewsModal, setViewsModal] = useState(false);
  const [finalViews, setFinalViews] = useState(0);

  useEffect(() => {
    if (isFocused) {
      setFinalViews(item.views);
      fetchKard();
      convertDisplayAreaToPolygon();
    }
  }, [isFocused]);

  const fetchKard = async () => {
    try {
      const kardS3 = await Storage.get(item.imageName, {
        level: "public",
      });
      setKard(kardS3);
    } catch (error) {
      console.log(error);
    }
  };
  const convertDisplayAreaToPolygon = () => {
    var displayAreaJSONString = item.displayArea;
    var displayObject = JSON.parse(displayAreaJSONString);

    var array = [];
    var newItem;
    displayObject.coordinates[0].map((item, key) => {
      if (Platform.OS === "web") {
        newItem = {
          lat: item[1],
          lng: item[0],
        };
      } else {
        newItem = {
          latitude: item[1],
          longitude: item[0],
        };
      }
      array.push(newItem);
    });
    var lastItem = array.pop();
    setPolygon(array);
  };

  const rejectEvent = async () => {
    try {
      // update status in KampusEvent table
      const rejectStatus = await API.graphql({
        query: mutations.updateKampusEvent,
        variables: {
          input: {
            id: item.id,
            status: "rejected",
            message:
              "Events about how kabutar benefits academic and other communities only are permitted.",
          },
        },
      });
      // delete kard from public folder in S3
      const removeKard = await Storage.remove(item.imageName);
    } catch (err) {
      console.log(err);
    }
  };

  const approveEvent = async () => {
    try {
      // update status in KampusEvent
      const approveStatus = await API.graphql({
        query: mutations.updateKampusEvent,
        variables: {
          input: {
            id: item.id,
            status: "approved",
            views: finalViews,
            message:
              "The event kard will be displayed in the selected display area FREE OF COST.",
          },
        },
      });

      const userProfile = await API.graphql({
        query: queries.getProfile,
        variables: {
          id: item.profileKampusEventId,
        },
      });

      const createKard = await API.graphql({
        query: mutations.createKard,
        variables: {
          input: {
            id: item.id,
            userId: userProfile.data.getProfile.id,
            // userIdentityId: credentials.identityId,
            username: userProfile.data.getProfile.username,
            imageName: item.imageName,
            displayArea: item.displayArea,
            kardType: "promo",
            transactionId: "nil",
            transactionDate: new Date().toISOString(),
            billingAddress: userProfile.data.getProfile.address[0],
            charges: 0,
            discount: 0,
            taxes: 0,
            total: 0,
            setViews: finalViews,
            shownViews: 0,
            likes: 0,
            dislikes: 0,
            downloads: 0,
            flags: 0,
            changeKard: 0,
            changeDisplayArea: 0,
            startDate: item.startDate,
            kardStatus: "active",
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>KAMPUS EVENT DETAILS</Text>

      <Text style={styles(theme).subHeaderStyle}>Event Kard</Text>

      <Image
        source={{
          uri: kard,
        }}
        style={styles(theme).kardStyle}
      />

      <Text style={styles(theme).subHeaderStyle}>Display Area</Text>

      <MapView
        style={styles(theme).kardStyle}
        provider={PROVIDER_GOOGLE}
        initialRegion={mapRegionForKampusEvent}
      >
        <MapView.Marker
          coordinate={mapRegionForKampusEvent}
          pinColor={theme.colors.standardTextColor}
        />
        {Platform.OS == "web" ? (
          <MapView.Polygon
            path={polygon}
            options={{
              strokeColor: theme.colors.standardTextColor,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: theme.colors.standardTextColor,
              fillOpacity: 0.35,
            }}
          />
        ) : (
          <MapView.Polygon
            coordinates={polygon}
            strokeColor={theme.colors.standardTextColor}
            fillColor={theme.colors.standardTextColor}
          />
        )}
      </MapView>
      <Text style={styles(theme).subHeaderStyle}>Display Details</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Applied Views: </Text>
          <Text style={styles(theme).textBoldStyle}>{item.views}</Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Changed Views: </Text>
          <Text style={styles(theme).textBoldStyle}>{finalViews}</Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Starting Date: </Text>
          <Text style={styles(theme).textBoldStyle}>
            {new Date(item.startDate).toLocaleDateString()}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Starting Time: </Text>
          <Text style={styles(theme).textBoldStyle}>
            {new Date(item.startDate).toLocaleTimeString()}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Request Date: </Text>
          <Text style={styles(theme).textBoldStyle}>
            {new Date(item.requestDate).toLocaleDateString()}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Request Time: </Text>
          <Text style={styles(theme).textBoldStyle}>
            {new Date(item.requestDate).toLocaleTimeString()}
          </Text>
        </View>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Status Details</Text>
      <View style={styles(theme).boxStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          Status: {item.status}
        </Text>
        <Text style={styles(theme).textJustifyStyle}>
          Message: {item.message}
        </Text>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Event Details</Text>
      <View style={styles(theme).boxStyle}>
        <Text style={styles(theme).textJustifyStyle}>Title: {item.title}</Text>
        <Text style={styles(theme).textJustifyStyle}>Name: {item.name}</Text>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Event Description</Text>
      <View style={styles(theme).boxStyle}>
        <Text style={styles(theme).textJustifyStyle}>{item.description}</Text>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Prizes</Text>
      <View style={styles(theme).boxStyle}>
        <Text style={styles(theme).textJustifyStyle}>{item.prizes}</Text>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Event Schedule</Text>
      <View style={styles(theme).boxStyle}>
        <Text style={styles(theme).textJustifyStyle}>{item.schedule}</Text>
      </View>

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setRejectModal(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>REJECT</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setViewsModal(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>CHANGE VIEWS</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setApproveModal(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>APPROVE</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.goBack()}
      >
        <Text style={styles(theme).buttonTextStyle}>BACK</Text>
      </TouchableOpacity>
      <AwesomeAlert
        show={rejectModal}
        showProgress={false}
        title="REJECT EVENT"
        message=" Are you sure you want to reject this event?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, reject it"
        onCancelPressed={() => setRejectModal(false)}
        onConfirmPressed={() => {
          rejectEvent();
          setRejectModal(false);
          navigation.navigate("PendingKampusEvents");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={approveModal}
        showProgress={false}
        title="APPROVE EVENT"
        message=" Are you sure you want to approve this event ?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, approve it"
        onCancelPressed={() => setApproveModal(false)}
        onConfirmPressed={() => {
          approveEvent();
          setApproveModal(false);
          navigation.navigate("PendingKampusEvents");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      {viewsModal && (
        <ChangeViewsForKampusEvent
          {...{ viewsModal, setViewsModal, finalViews, setFinalViews }}
        />
      )}
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },
    kardStyle: {
      height: 550,
      width: 350,
      alignSelf: "center",
      borderRadius: 20,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },

    textBoldStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      margin: 5,
    },
    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },
    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },

    textBoldStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      margin: 5,
    },
    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    boxTextStyle: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default PendingKampusEventDetails;
