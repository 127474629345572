import React, { useState, useContext, useEffect } from "react";
import {
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
  Image,
} from "react-native";

import { API } from "aws-amplify";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";
import QRCode from "react-native-qrcode-svg";

import { Kabutar } from "../../../assets/index";
import noQrCode from "../../../assets/noQrCode.png";
import * as queries from "../../../src/graphql/queries";
import { ThemeContext } from "../../../context/ThemeContext";
import { UserContext } from "../../../context/UserContext";
import { OrderContext } from "../../../context/OrderContext";

const KardQrCodePayment = () => {
  const {
    orderId,
    setOrderId,
    amount,
    setAmount,
    qrCodeImage,
    setQrCodeImage,
    qrCodeData,
    setQrCodeData,
  } = useContext(OrderContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const [generateQrCodeError, setGenerateQrCodeError] = useState(false);
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const [paymentPending, setPaymentPending] = useState(false);

  const verifyTransactionStatus = async () => {
    try {
      const token = user.signInUserSession.idToken.jwtToken;
      const transactionStatusParams = {
        headers: {
          Authorization: token,
        },
        body: {
          orderId: orderId,
        },
      };
      const transactionStatusResponse = await API.post(
        "paymentRestApi",
        "/kard/transactionStatus",
        transactionStatusParams
      );
      if (
        transactionStatusResponse.data.body.resultInfo.resultStatus ==
        "TXN_SUCCESS"
      ) {
        setOrderId("");
        setAmount("");
        setQrCodeImage("");
        setQrCodeData(null);
        navigation.navigate("KardTransactionSuccess", {
          orderId: transactionStatusResponse.data.body.orderId,
          txnAmount: transactionStatusResponse.data.body.txnAmount,
          txnId: transactionStatusResponse.data.body.txnId,
        });
      } else if (
        transactionStatusResponse.data.body.resultInfo.resultStatus == "PENDING"
      ) {
        setPaymentPending(true);
      } else {
        setOrderId("");
        setAmount("");
        setQrCodeImage("");
        setQrCodeData(null);
        navigation.navigate("KardTransactionFailure", {
          orderId: transactionStatusResponse.data.body.orderId,
          message: "Payment was not succesfull. Please try again.",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // This is not required here.
  const verifyPaymentStatus = async () => {
    console.log(orderId);
    const getPaymentStatus = await API.graphql({
      query: queries.getPayment,
      variables: {
        id: orderId,
      },
    });
    if (getPaymentStatus.data.getPayment == null) {
      setPaymentPending(true);
    } else {
      const paymentData = JSON.parse(getPaymentStatus.data.getPayment.data);
      const orderId = paymentData.ORDERID;
      const txnAmount = paymentData.TXNAMOUNT;
      const txnId = paymentData.TXNID;
      const status = paymentData.STATUS;
      if (status == "TXN_SUCCESS") {
        navigation.navigate("KardTransactionSuccess", {
          orderId: orderId,
          txnAmount: txnAmount,
          txnId: txnId,
        });
      } else {
        navigation.navigate("KardTransactionFailure", {
          orderId: orderId,
          // txnAmount: txnAmount,
          message: "Payment was not succesfull. Please try again.",
        });
      }
    }
  };
  useEffect(() => {
    if (isFocused) {
      if (qrCodeImage == "") {
        generateQrCode();
      }
    }
  }, [isFocused]);

  const generateQrCode = async () => {
    try {
      const token = user.signInUserSession.idToken.jwtToken;
      const generateQrCodeParams = {
        headers: {
          Authorization: token,
        },
        body: {
          orderId: orderId,
          amount: amount,
        },
      };
      const generateQrCodeResponse = await API.post(
        "paymentRestApi",
        "/kard/generateQrCode",
        generateQrCodeParams
      );

      if (generateQrCodeResponse.status == "success") {
        setQrCodeImage(generateQrCodeResponse.data.image);
        setQrCodeData(generateQrCodeResponse.data.qrData);
      } else {
        setGenerateQrCodeError(true);
      }
    } catch (error) {
      setGenerateQrCodeError(true);
    }
  };
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Image source={Kabutar} style={styles(theme).logoStyle} />
      <Text style={styles(theme).headerStyle}>
        Scan QR Code to Pay ₹ {amount}
      </Text>
      {qrCodeImage != "" ? (
        <View style={{ alignSelf: "center", margin: 20 }}>
          <QRCode size={250} value={qrCodeData == null ? "test" : qrCodeData} />
        </View>
      ) : (
        <Image source={noQrCode} style={styles(theme).qrCodeStyle} />
      )}

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          verifyTransactionStatus();
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Verify Payment Status</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setChangePaymentMethod(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>Change Payment Method</Text>
      </TouchableOpacity>

      <AwesomeAlert
        show={generateQrCodeError}
        title="GENERATE QR CODE ERROR"
        message="The QR Code could not be generated. Do you want  to generate again or change payment method"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="Change payment method"
        confirmText="Ok, generate again"
        onCancelPressed={() => {
          navigation.navigate("SelectKardPaymentMethod");
        }}
        onConfirmPressed={() => {
          setGenerateQrCodeError(false);
          generateQrCode();
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={changePaymentMethod}
        title="CONFIRM CHANGE PAYMENT METHOD"
        message="Are you sure you want to change payment method ?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, change it"
        onCancelPressed={() => setChangePaymentMethod(false)}
        onConfirmPressed={() => {
          setChangePaymentMethod(false);
          navigation.navigate("SelectKardPaymentMethod");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />

      <AwesomeAlert
        show={paymentPending}
        title="PAYMENT PENDING"
        message="The payment is pending. If you have already paid, verify payment status after a few seconds. If you have not yet paid, please make payment and verify payment status. "
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setPaymentPending(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    logoStyle: {
      height: 70,
      width: 70,
      alignSelf: "center",
      margin: 10,
    },

    qrCodeStyle: {
      height: 200,
      width: 200,
      alignSelf: "center",
      justifyContent: "center",
      borderWidth: 1,
      margin: 10,
    },
    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default KardQrCodePayment;
