import React, { useEffect, useState, useContext } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { API } from "aws-amplify";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";

import * as queries from "../../../src/graphql/queries";
import { ThemeContext } from "../../../context/ThemeContext";

const PendingKampusRegistrations = () => {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const { theme, changeTheme } = useContext(ThemeContext);

  const [pendingApplications, setPendingApplications] = useState([]);

  useEffect(() => {
    if (isFocused) {
      getPendingApplications();
    }
  }, [isFocused]);

  const getPendingApplications = async () => {
    var itemArray = [];
    try {
      const applications = await API.graphql({
        query: queries.listKampusRegistrationsByStatus,
        variables: {
          status: "pending",
        },
      });
      if (applications.data.listKampusRegistrationsByStatus.items.length != 0) {
        applications.data.listKampusRegistrationsByStatus.items.map((item) => {
          itemArray.push(item);
        });
      }

      setPendingApplications(itemArray);
    } catch (err) {
      console.log(err);
    }
  };

  return pendingApplications.length != 0 ? (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>
          PENDING KAMPUS REGISTRATIONS
        </Text>

        <TouchableOpacity
          onPress={() => {
            getPendingApplications();
          }}
        >
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>

      {pendingApplications.map((item, key) => {
        // console.log(item);
        return (
          <TouchableOpacity
            key={key}
            onPress={() => {
              navigation.navigate("PendingKampusRegistrationDetails", {
                item: item,
              });
            }}
          >
            <View style={styles(theme).boxStyle}>
              <Text style={styles(theme).textStyle}>
                Name: {item.profile.name}, Address: {item.address.type},{" "}
                {item.address.name}, {item.address.line1}, {item.address.line2},{" "}
                {item.address.line3}, {item.address.region},{" "}
                {item.address.country}, {item.address.zip}, Email:{" "}
                {item.profile.username}, Request Date:{" "}
                {new Date(item.requestDate).toLocaleDateString()}
              </Text>
            </View>
          </TouchableOpacity>
        );
      })}
    </ScrollView>
  ) : (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>
          PENDING KAMPUS REGISTRATIONS
        </Text>

        <TouchableOpacity
          onPress={() => {
            getPendingApplications();
          }}
        >
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <View style={styles(theme).rowGroupCenterStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          You do not have any pending registrations to process
        </Text>
      </View>
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },
  });
export default PendingKampusRegistrations;
