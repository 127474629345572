import React, { useState, useContext } from "react";
import {
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
  Image,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";

import { Kabutar } from "../../assets/index";
import { ThemeContext } from "../../context/ThemeContext";
import { OrderContext } from "../../context/OrderContext";

const SelectKampusPaymentMethod = () => {
  const { orderId, amount } = useContext(OrderContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const navigation = useNavigation();

  const [qrCode, setQrCode] = useState(false);
  const [upiId, setUpiId] = useState(false);
  const [link, setLink] = useState(false);
  const [debitCard, setDebitCard] = useState(false);
  const [creditCard, setCreditCard] = useState(false);
  const [netBanking, setNetBanking] = useState(false);
  const [noPaymentMethodSelected, setNoPaymentMethodSelected] = useState(false);

  const proceedToPay = () => {
    if (qrCode) {
      navigation.navigate("KampusQrCodePayment");
    } else if (upiId) {
      navigation.navigate("KampusUpiIdPayment");
    } else if (link) {
      navigation.navigate("KampusLinkPayment");
    } else if (debitCard) {
      navigation.navigate("KampusDebitCardPayment");
    } else if (creditCard) {
      navigation.navigate("KampusCreditCardPayment");
    } else if (netBanking) {
      navigation.navigate("KampusNetBankingPayment");
    } else {
      setNoPaymentMethodSelected(true);
    }
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Image source={Kabutar} style={styles(theme).logoStyle} />

      <Text style={styles(theme).headerStyle}>Pay ₹ {amount}</Text>
      <Text style={styles(theme).headerStyle}>SELECT PAYMENT METHOD</Text>
      <View>
        <TouchableOpacity
          onPress={() => {
            setQrCode(!qrCode);
            setUpiId(false);
            setLink(false);
            setDebitCard(false);
            setCreditCard(false);
            setNetBanking(false);
          }}
        >
          <View style={styles(theme).rowGroupStyle}>
            <Ionicons
              name={qrCode ? "radio-button-on" : "radio-button-off"}
              size={25}
              color={theme.colors.standardTextColor}
            />

            <Text style={styles(theme).textBoldStyle}>QR Code</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setUpiId(!upiId);
            setQrCode(false);
            setLink(false);
            setDebitCard(false);
            setCreditCard(false);
            setNetBanking(false);
          }}
        >
          <View style={styles(theme).rowGroupStyle}>
            <Ionicons
              name={upiId ? "radio-button-on" : "radio-button-off"}
              size={25}
              color={theme.colors.standardTextColor}
            />
            <Text style={styles(theme).textBoldStyle}>UPI / VPA</Text>
          </View>
        </TouchableOpacity>
        {/* <TouchableOpacity
          onPress={() => {
            setLink(!link);
            setQrCode(false);
            setUpiId(false);
            setDebitCard(false);
            setCreditCard(false);
            setNetBanking(false);
          }}
        >
          <View style={styles(theme).rowGroupStyle}>
            <Ionicons
              name={link ? "radio-button-on" : "radio-button-off"}
              size={25}
              color={theme.colors.standardTextColor}
            />
            <Text style={styles(theme).textBoldStyle}>Mobile Payment</Text>
          </View>
        </TouchableOpacity> */}
        <TouchableOpacity
          onPress={() => {
            setDebitCard(!debitCard);
            setQrCode(false);
            setUpiId(false);
            setLink(false);
            setCreditCard(false);
            setNetBanking(false);
          }}
        >
          <View style={styles(theme).rowGroupStyle}>
            <Ionicons
              name={debitCard ? "radio-button-on" : "radio-button-off"}
              size={25}
              color={theme.colors.standardTextColor}
            />
            <Text style={styles(theme).textBoldStyle}>Debit Cards</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setCreditCard(!creditCard);
            setQrCode(false);
            setUpiId(false);
            setLink(false);
            setDebitCard(false);
            setNetBanking(false);
          }}
        >
          <View style={styles(theme).rowGroupStyle}>
            <Ionicons
              name={creditCard ? "radio-button-on" : "radio-button-off"}
              size={25}
              color={theme.colors.standardTextColor}
            />
            <Text style={styles(theme).textBoldStyle}>Credit Cards</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setNetBanking(!netBanking);
            setQrCode(false);
            setUpiId(false);
            setLink(false);
            setDebitCard(false);
            setCreditCard(false);
          }}
        >
          <View style={styles(theme).rowGroupStyle}>
            <Ionicons
              name={netBanking ? "radio-button-on" : "radio-button-off"}
              size={25}
              color={theme.colors.standardTextColor}
            />
            <Text style={styles(theme).textBoldStyle}>Net Banking</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => proceedToPay()}
        >
          <Text style={styles(theme).buttonTextStyle}>Proceed to Pay</Text>
        </TouchableOpacity>
      </View>
      <AwesomeAlert
        show={noPaymentMethodSelected}
        title="PAYMENT  METHOD SELECTION ERROR"
        message={
          "No payment method is selected. A payment method should be selected to proceed to pay."
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setNoPaymentMethodSelected(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    logoStyle: {
      height: 70,
      width: 70,
      alignSelf: "center",
      margin: 10,
    },

    textBoldStyle: {
      color: theme.colors.standardTextColor,
      // fontWeight: "bold",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    rowGroupStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default SelectKampusPaymentMethod;
