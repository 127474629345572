import React from "react";
import { Text, View } from "react-native";

const SearchKard = () => {
  return (
    <View>
      <Text>Search Kard</Text>
    </View>
  );
};

export default SearchKard;
