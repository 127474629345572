import React, { createContext, useState, useContext } from "react";
import { Dimensions } from "react-native";
// import { CurrentLocationContext } from "./CurrentLocationContext";

const SCREEN_WIDTH = Dimensions.get("screen").width;
const SCREEN_HEIGHT = Dimensions.get("screen").height;
const ASPECT_RATIO = SCREEN_WIDTH / SCREEN_HEIGHT;
const LATITUDE_DELTA = 0.0922;
const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;

const defaultLocation = {
  latitude: 28.612894,
  longitude: 77.229446,
};

// const getCurrentLocation = async () => {
//   try {
//     let loc = await Location.getCurrentPositionAsync();
//     // console.log(loc);
//     if (loc.coords.latitude) {
//       const obj = {
//         latitude: loc.coords.latitude,
//         longitude: loc.coords.longitude,
//       };
//       return obj;
//     } else {
//       const obj = {
//         latitude: 11.916064,
//         longitude: 79.812325,
//       };
//       return obj;
//     }
//   } catch (err) {
//     console.log("error in initial location function" + JSON.stringify(err));
//   }
// };

// const getCurrentLocation = async () => {
//   console.log("I am in CurrentLocationContext");
//   try {
//     let permissions = await Location.getBackgroundPermissionsAsync();
//     console.log(permissions);
//     if (permissions.status !== "granted") {
//       let loc = await Location.getLastKnownPositionAsync();
//       if (loc !== null) {
//         const obj = {
//           latitude: loc.coords.latitude,
//           longitude: loc.coords.longitude,
//         };
//         console.log("last known position available");
//         console.log(obj);
//         return obj;
//       } else {
//         const obj = {
//           latitude: 11.916064,
//           longitude: 79.812325,
//         };
//         console.log("last known position is null");
//         console.log(obj);
//         return obj;
//       }
//     } else {
//       let loc = await Location.getCurrentPositionAsync();
//       const obj = {
//         latitude: loc.coords.latitude,
//         longitude: loc.coords.longitude,
//       };
//       console.log("current position ");
//       console.log(obj);
//       return obj;
//     }
//   } catch (err) {
//     console.log("error in initial location function" + err);
//   }
// };

export const MyLocationContext = createContext(null);

export default ({ children }) => {
  // const [myLocation, setDisplayLocation] = useState(() =>
  //   getCurrentLocation().then((data) => setDisplayLocation(data))
  // );
  // const { currentLocation } = useContext(CurrentLocationContext);
  const [myLocation, setMyLocation] = useState(defaultLocation);
  const [latDelta, setLatDelta] = useState(LATITUDE_DELTA);
  const [lonDelta, setLonDelta] = useState(LONGITUDE_DELTA);
  return (
    <MyLocationContext.Provider
      value={{
        myLocation,
        setMyLocation,
        latDelta,
        setLatDelta,
        lonDelta,
        setLonDelta,
      }}
    >
      {children}
    </MyLocationContext.Provider>
  );
};
