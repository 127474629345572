import React, { createRef, useContext, useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  Text,
  Dimensions,
} from "react-native";
import Swiper from "react-native-deck-swiper";
import { API, Storage } from "aws-amplify";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { SafeAreaView } from "react-native-safe-area-context";
import { AntDesign, Entypo, FontAwesome } from "@expo/vector-icons";
import { DisplayKardsContext } from "../context/DisplayKardsContext";
import { ThemeContext } from "../context/ThemeContext";
import { DeviceContext } from "../context/DeviceContext";
import config from "../src/aws-exports";
import * as mutations from "../src/graphql/mutations";
import * as queries from "../src/graphql/queries";
import { UserContext } from "../context/UserContext";
import AwesomeAlert from "react-native-awesome-alerts";
import { MyLocationContext } from "../context/MyLocationContext";
import { CurrentLocationContext } from "../context/CurrentLocationContext";
import * as Location from "expo-location";
import { NewKardContext } from "../context/NewKardContext";
import { KampusKardContext } from "../context/KampusKardContext";
import { PromoKardContext } from "../context/PromoKardContext";
import { KampusEventContext } from "../context/KampusEventContext";
import { KampusRegistrationContext } from "../context/KampusRegistrationContext";

const SCREEN_WIDTH = Dimensions.get("screen").width;
const SCREEN_HEIGHT = Dimensions.get("screen").height;

const ASPECT_RATIO = SCREEN_WIDTH / SCREEN_HEIGHT;
const LATITUDE_DELTA = 0.0922;
const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;

const isLargeScreen = SCREEN_WIDTH >= 768;
const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

const DisplayKards = () => {
  const isFocused = useIsFocused();
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [flag, setFlag] = useState(false);
  const [info, setInfo] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState();
  const { user } = useContext(UserContext);
  const [swiperId, setSwiperId] = useState(1);
  const [currentKardIndex, setCurrentKardIndex] = useState(0);
  const [actionsVisible, setActionsVisible] = useState(false);
  const swiperRef = createRef();
  const navigation = useNavigation();
  const { theme, changeTheme } = useContext(ThemeContext);
  const { myLocation, setMyLocation } = useContext(MyLocationContext);
  const { deviceType, setDeviceType } = useContext(DeviceContext);
  const { displayKards, setDisplayKards } = useContext(DisplayKardsContext);
  const { currentLocation, setCurrentLocation } = useContext(
    CurrentLocationContext
  );
  const { setMapRegionForNewKard } = useContext(NewKardContext);
  const { setMapRegionForKampusKard } = useContext(KampusKardContext);
  const { setMapRegionForPromoKard } = useContext(PromoKardContext);
  const { setMapRegionForKampusEvent } = useContext(KampusEventContext);
  const { setMapRegionForKampusRegistration, latDelta, lonDelta } = useContext(
    KampusRegistrationContext
  );
  const [locationPermissions, setLocationPermissions] = useState(false);

  useEffect(() => {
    getCurrentLocation();
  }, []);
  // useEffect(() => {
  //   updateDisplayKards();
  // }, [myLocation.latitude]);
  const getCurrentLocation = async () => {
    try {
      let loc = await Location.getCurrentPositionAsync();
      // console.log(loc);
      if (loc.coords.latitude) {
        const obj = {
          latitude: loc.coords.latitude,
          longitude: loc.coords.longitude,
        };
        setCurrentLocation(obj);
        setMyLocation(obj);
        setMapRegionForNewKard({
          latitude: obj.latitude,
          longitude: obj.longitude,
          latitudeDelta: LATITUDE_DELTA,
          longitudeDelta: LONGITUDE_DELTA,
        });
        setMapRegionForKampusKard({
          latitude: obj.latitude,
          longitude: obj.longitude,
          latitudeDelta: LATITUDE_DELTA,
          longitudeDelta: LONGITUDE_DELTA,
        });
        setMapRegionForPromoKard({
          latitude: obj.latitude,
          longitude: obj.longitude,
          latitudeDelta: LATITUDE_DELTA,
          longitudeDelta: LONGITUDE_DELTA,
        });
        setMapRegionForKampusEvent({
          latitude: obj.latitude,
          longitude: obj.longitude,
          latitudeDelta: LATITUDE_DELTA,
          longitudeDelta: LONGITUDE_DELTA,
        });
        setMapRegionForKampusRegistration({
          latitude: obj.latitude,
          longitude: obj.longitude,
          latitudeDelta: latDelta,
          longitudeDelta: lonDelta,
        });
      }
    } catch (err) {
      setLocationPermissions(true);
    }
  };

  // const updateDisplayKards = async () => {
  //   var itemArray = [];

  //   try {
  //     var nextToken = "0";
  //     while (nextToken != "") {
  //       const fetchkard = await API.graphql({
  //         query: queries.searchEs,
  //         variables: {
  //           location: {
  //             lng: Number(myLocation.longitude),
  //             lat: Number(myLocation.latitude),
  //           },
  //           nextToken: nextToken,
  //         },
  //         // tested authMode is compulsory otherwise no Current user error for Unauthorized user but works for authorized user
  //         authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
  //       });
  //       if (fetchkard.data.searchEs.items.length != 0) {
  //         fetchkard.data.searchEs.items.map((item) => {
  //           itemArray.push(item);
  //         });
  //       }
  //       nextToken = fetchkard.data.searchEs.nextToken;
  //     }

  //     return itemArray;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const onSwiped = async (swipedCardIndex) => {
    if (actionsVisible) {
      setActionsVisible(false);
    }
    const id = displayKards[swipedCardIndex].imageName.split(".")[0];
    try {
      const incrementViews = await API.graphql({
        query: mutations.incrementViews,
        variables: {
          input: {
            id: id,
          },
        },

        authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onTapCard = (value) => {
    setCurrentKardIndex(value);
    setActionsVisible(!actionsVisible);
  };
  const handleLikes = async () => {
    // console.log("current card index  : " + currentKardIndex);
    const id = displayKards[currentKardIndex].imageName.split(".")[0];
    try {
      const incrementLikes = await API.graphql({
        query: mutations.incrementLikes,
        variables: {
          input: {
            id: id,
          },
        },

        authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });
      setLike(true);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDislikes = async () => {
    const id = displayKards[currentKardIndex].imageName.split(".")[0];
    try {
      const incrementDislikes = await API.graphql({
        query: mutations.incrementDislikes,
        variables: {
          input: {
            id: id,
          },
        },

        authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });
      setDislike(true);
    } catch (error) {
      console.log(error);
    }
  };
  const handleFlags = async () => {
    // console.log("current card index  : " + currentKardIndex);
    const id = displayKards[currentKardIndex].imageName.split(".")[0];
    setSwiperId(id);
    try {
      const incrementFlags = await API.graphql({
        query: mutations.incrementFlags,
        variables: {
          input: {
            id: id,
          },
        },

        authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });
      setFlag(true);
    } catch (error) {
      console.log(error);
    }
  };
  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };
  const handleDownloads = async () => {
    const result = await Storage.get(displayKards[currentKardIndex].imageName, {
      download: true,
    });
    downloadBlob(result.Body, `${displayKards[currentKardIndex].imageName}`);
    const id = displayKards[currentKardIndex].imageName.split(".")[0];
    try {
      const incrementDownloads = await API.graphql({
        query: mutations.incrementDownloads,
        variables: {
          input: {
            id: id,
          },
        },

        authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (like) {
      setTimeout(() => setLike(false), 1000);
    }
  }, [like]);

  useEffect(() => {
    if (dislike) {
      setTimeout(() => setDislike(false), 1000);
    }
  }, [dislike]);

  useEffect(() => {
    if (flag) {
      setTimeout(() => setFlag(false), 1000);
    }
  }, [flag]);

  const showKardDetails = async () => {
    try {
      const id = displayKards[currentKardIndex].imageName.split(".")[0];
      const getRecord = await API.graphql({
        query: queries.getKard,
        variables: {
          id: id,
        },
        authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });
      var message = ` Posted by: ${
        getRecord.data.getKard.billingAddress.name
      } \n Posted on: ${new Date(
        getRecord.data.getKard.createdAt
      ).toLocaleString()} \n Kard type: ${
        getRecord.data.getKard.kardType
      } \n Id: ${getRecord.data.getKard.id}`;
      setInfoMessage(message);
      setShowInfo(true);
    } catch (err) {
      console.log(err);
    }
  };

  const Kard = ({ kard }) => {
    return (
      <Image
        source={{
          uri: `https://${bucket}.s3.${region}.amazonaws.com/public/${kard.imageName}`,
        }}
        style={{
          height: deviceType == "MOBILE" ? "92%" : "92%",
          width: isLargeScreen ? 600 : SCREEN_WIDTH,
        }}
        resizeMode="stretch"
        resizeMethod="scale"
      />
    );
  };

  // const getLocationPermissions = async () => {
  //   let { status } = await Location.requestBackgroundPermissionsAsync();
  //   if (status !== "granted") {
  //     alert("Permission to access location was denied");
  //     return;
  //   }
  // };

  // useEffect(async () => {
  //   await getLocationPermissions();
  // }, []);

  return displayKards && displayKards.length != 0 ? (
    <View style={styles(theme).screenStyle}>
      <View style={styles(theme).displayStyle}>
        <Swiper
          containerStyle={StyleSheet.flatten(styles(theme).swiperStyle)}
          cardStyle={styles(theme).kardStyle}
          cardHorizontalMargin={0}
          cardVerticalMargin={0}
          cards={displayKards}
          renderCard={(cardData, cardIndex) => <Kard kard={cardData} />}
          ref={swiperRef}
          cardIndex={0}
          onSwiped={(swipedCardIndex) => onSwiped(swipedCardIndex)}
          goBackToPreviousCardOnSwipeLeft={true}
          goBackToPreviousCardOnSwipeBottom={true}
          onTapCard={onTapCard}
          showSecondCard={true}
          swipeAnimationDuration={300}
          infinite
          stackSize={displayKards.length > 2 ? 3 : displayKards.length}
          horizontalThreshold={isLargeScreen ? 600 / 4 : SCREEN_WIDTH / 4}
        />
        {actionsVisible && (
          <View style={styles(theme).bottomModal}>
            <TouchableOpacity
              style={styles(theme).kardIconStyle}
              onPress={() => handleLikes()}
            >
              <AntDesign
                name="like1"
                size={25}
                color={theme.colors.textColor}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles(theme).kardIconStyle}
              onPress={() => handleDislikes()}
            >
              <AntDesign
                name="dislike1"
                size={25}
                color={theme.colors.textColor}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles(theme).kardIconStyle}
              onPress={() => setInfo(true)}
            >
              <Entypo name="info" size={25} color={theme.colors.textColor} />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles(theme).kardIconStyle}
              onPress={() => handleFlags()}
            >
              <FontAwesome
                name="flag"
                size={25}
                color={theme.colors.textColor}
              />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles(theme).kardIconStyle}
              onPress={() => handleDownloads()}
            >
              <FontAwesome
                name="download"
                size={25}
                color={theme.colors.textColor}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
      <AwesomeAlert
        show={locationPermissions}
        title="LOCATION ACCESS DENIED"
        // message="Your display location is set to defult location. You can change display location to your current location using Change Display Location feature . Alternatively, You can set it to your current location by  giving location access permission. Would you like to give location access permission ?"
        message="You have denied permission to access your location. You can see kards in any location by changing the location using my location feature. If you want to see kards in your current location by default you can give location access permission when you use the app next time or as explained in Help and FAQ."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Okay"
        onConfirmPressed={() => {
          setLocationPermissions(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={like}
        title="LIKE"
        message="You have liked the kard."
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={true}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
      />
      <AwesomeAlert
        show={dislike}
        title="DISLIKE"
        message="You have disliked the kard."
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={true}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
      />
      <AwesomeAlert
        show={info}
        title="INFO"
        message="Would you like to see the info of this kard?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No"
        confirmText="Yes, show me"
        onCancelPressed={() => setInfo(false)}
        onConfirmPressed={() => {
          setInfo(false);
          showKardDetails();
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={showInfo}
        title="INFO"
        message={infoMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setShowInfo(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={flag}
        title="FLAG"
        message="You have flagged the kard."
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={true}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
      />
    </View>
  ) : (
    <SafeAreaView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>DISPLAY KARDS</Text>

      <View style={styles(theme).rowGroupCenterStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          There are no kards in this location.
        </Text>
        <Text style={styles(theme).textJustifyStyle}>
          Click below to change the location
        </Text>

        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => navigation.navigate("MyLocation")}
        >
          <Text style={styles(theme).buttonTextStyle}>
            Change Your Location
          </Text>
        </TouchableOpacity>
      </View>
      <AwesomeAlert
        show={locationPermissions}
        title="LOCATION ACCESS DENIED"
        // message="Your display location is set to defult location. You can change display location to your current location using Change Display Location feature . Alternatively, You can set it to your current location by  giving location access permission. Would you like to give location access permission ?"
        message="You have denied permission to access your location. You can see kards in any location by changing the location using my location feature. If you want to see kards in your current location by default you can give location access permission when you use the app next time or as explained in Help and FAQ."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Okay"
        onConfirmPressed={() => {
          setLocationPermissions(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </SafeAreaView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },
    kardIconStyle: {
      borderRadius: 100,
      borderWidth: 3,
      borderColor: theme.colors.borderColor,
      padding: 10,
      backgroundColor: theme.colors.backgroundColor,
    },
    buttonStyle: {
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 3,
      backgroundColor: theme.colors.backgroundColor,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },

    displayStyle: {
      height: "100%",
      width: isLargeScreen ? 600 : "100%",
      alignSelf: "center",
    },
    swiperStyle: {
      backgroundColor: "white",
      overflow: "hidden",
    },

    kardStyle: {
      fontSize: 16,
      overflow: "hidden",
    },

    bottomModal: {
      height: "40%",
      width: 60,
      flexDirection: "column",
      backgroundColor: "transparent",
      position: "absolute",
      bottom: 0,
      right: 0,
      margin: 20,
      marginTop: 0,
      alignItems: "flex-end",
      justifyContent: "space-around",
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default DisplayKards;
