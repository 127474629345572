import React, { useContext } from "react";
import { StyleSheet } from "react-native";
import WebView from "react-native-webview";

import { ThemeContext } from "../../context/ThemeContext";

const HtmlFormPost = (props) => {
  // console.log(props);
  // console.log(props.route.params.htmlForm);
  const { theme, changeTheme } = useContext(ThemeContext);
  const { htmlForm } = props.route.params.htmlForm;
  // console.log(htmlForm);
  return (
    <WebView
      style={styles(theme).screenStyle}
      originWhitelist={["*"]}
      source={{ html: props.route.params.htmlForm }}
    />
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
  });

export default HtmlFormPost;
