import React, { useContext } from "react";
import { StyleSheet } from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { ThemeContext } from "../../../context/ThemeContext";
import PendingComments from "./PendingComments";
import PendingProblems from "./PendingProblems";
import PendingMessages from "./PendingMessages";

const Tab = createMaterialTopTabNavigator();

const PendingFeedback = () => {
  const { theme, changeTheme } = useContext(ThemeContext);

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarLabelStyle: styles(theme).tabTextStyle,
        tabBarStyle: styles(theme).tabBgStyle,
        tabBarIndicatorStyle: styles(theme).tabIndicatorStyle,
      }}
    >
      <Tab.Screen name="Problems" component={PendingProblems} />
      <Tab.Screen name="Comments" component={PendingComments} />
      <Tab.Screen name="Messages" component={PendingMessages} />
    </Tab.Navigator>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    tabBgStyle: {
      backgroundColor: theme.colors.backgroundColor,
    },

    tabTextStyle: {
      fontSize: 12,
      fontWeight: "bold",
      color: theme.colors.textColor,
    },
    tabIndicatorStyle: {
      backgroundColor: theme.colors.textColor,
    },
  });

export default PendingFeedback;
