import React, { useState, useContext } from "react";
import {
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
  Image,
} from "react-native";

import { API } from "aws-amplify";
import axios from "axios";

import { useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";
import * as Yup from "yup";
import { Field, Formik } from "formik";

import { Kabutar } from "../../../assets/index";
import { ThemeContext } from "../../../context/ThemeContext";
import { UserContext } from "../../../context/UserContext";
import { OrderContext } from "../../../context/OrderContext";
import CustomInput from "../../../components/CustomInput";

const KampusDebitCardPayment = () => {
  const { orderId, amount } = useContext(OrderContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const navigation = useNavigation();

  const [binError, setBinError] = useState(false);
  const [binErrorMessage, setBinErrorMessage] = useState("");
  const [processTransactionError, setProcessTransactionError] = useState(false);
  const [processTransactionErrorMessage, setProcessTransactionErrorMessage] =
    useState("");
  const [initiateTransactionError, setInitiateTransactionError] =
    useState(false);
  const [initiateTransactionErrorMessage, setInitiateTransactionErrorMessage] =
    useState("");
  const [htmlForm, setHtmlForm] = useState("");
  const [debitCardError, setDebitCardError] = useState(false);
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);

  const validateDebitCardDetails = (
    debitCardExpiryYear,
    debitCardExpiryMonth
  ) => {
    if (
      new Date(debitCardExpiryYear, debitCardExpiryMonth).getTime() <
      new Date().getTime()
    ) {
      return false;
    }
    return true;
  };

  const makeDebitCardPayment = async (values, { resetForm }) => {
    const token = user.signInUserSession.idToken.jwtToken;
    const isValid = validateDebitCardDetails(
      values.debitCardExpiryYear,
      values.debitCardExpiryMonth
    );
    if (isValid) {
      const initiateTransactionParams = {
        headers: {
          Authorization: token,
        },
        body: {
          orderId: orderId,
          value: amount,
          currency: "INR",
          custId: user.attributes.sub,
          debitCardNumber: values.debitCardNumber,
          debitCardCvv: values.debitCardCvv,
          debitCardExpiryMonth: values.debitCardExpiryMonth,
          debitCardExpiryYear: values.debitCardExpiryYear,
        },
      };
      const initiateTransactionResponse = await API.post(
        "paymentRestApi",
        "/kampus/dcInitiateTransaction",
        initiateTransactionParams
      );
      // console.log(initiateTransactionResponse);
      if (initiateTransactionResponse.status == "success") {
        var content = initiateTransactionResponse.content;
        var processTransactionUrl =
          initiateTransactionResponse.processTransactionUrl;
        var htmlForm =
          '<html><body onload="document.forms[0].submit();"><form action ="' +
          processTransactionUrl +
          '" method ="POST" >';
        for (var name in content) {
          htmlForm =
            htmlForm +
            '<input type="hidden" name="' +
            name +
            '" value=' +
            content[name] +
            ">";
        }

        htmlForm = htmlForm + "</form></html>";

        setHtmlForm(htmlForm);
        navigation.navigate("HtmlFormPost", {
          htmlForm: htmlForm,
        });
      } else {
        setInitiateTransactionErrorMessage(
          initiateTransactionResponse.data.body.resultInfo.resultMsg
        );
        setInitiateTransactionError(true);
      }
    } else {
      setDebitCardError(true);
    }
  };

  const initialValues = {
    debitCardNumber: "",
    debitCardExpiryMonth: "",
    debitCardExpiryYear: "",
    debitCardCvv: "",
  };

  const validationSchema = Yup.object({
    debitCardNumber: Yup.string()
      .required("Reqiured")
      .matches(/^\d+$/, "Invalid number")
      .test(
        "len",
        "Invalid number",
        (val) => val && val.length >= 12 && val.length <= 19
      ),

    debitCardExpiryMonth: Yup.string()
      .required("Reqiured")
      .matches(/^\d+$/, "Invalid month")
      .test(
        "len",
        "Invalid month",
        (val) =>
          val && val.length === 2 && parseInt(val) < 13 && parseInt(val) > 0
      ),
    debitCardExpiryYear: Yup.string()
      .required("Reqiured")
      .matches(/^\d+$/, "Invalid year")
      .test(
        "len",
        "Invalid year",
        (val) =>
          val &&
          val.length === 4 &&
          parseInt(val) < 2100 &&
          parseInt(val) > 2021
      ),

    debitCardCvv: Yup.string()
      .required("Required")
      .matches(/^\d+$/, "Invalid CVV/CVC")
      .test(
        "len",
        "Invalid CVV/CVC",
        (val) => val && (val.length === 3 || val.length === 4)
      ),
  });

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Image source={Kabutar} style={styles(theme).logoStyle} />
      <Text style={styles(theme).headerStyle}>Pay ₹ {amount}</Text>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={makeDebitCardPayment}
      >
        {({ handleSubmit, isValid, setFieldValue }) => (
          <>
            <Text style={styles(theme).labelStyle}>Debit Card Number</Text>
            <Field
              component={CustomInput}
              name="debitCardNumber"
              placeholder="Enter Your Debit Card Number"
            />
            <View style={styles(theme).rowGroupCenterStyle}>
              <View>
                <Text style={styles(theme).labelStyle}>Expiry Month</Text>
                <Field
                  component={CustomInput}
                  name="debitCardExpiryMonth"
                  placeholder="Expiry Month       "
                />
              </View>
              <View>
                <Text style={styles(theme).labelStyle}>Expiry Year</Text>
                <Field
                  component={CustomInput}
                  name="debitCardExpiryYear"
                  placeholder="Expiry Year        "
                />
              </View>
              <View>
                <Text style={styles(theme).labelStyle}>CVV / CVC</Text>
                <Field
                  component={CustomInput}
                  name="debitCardCvv"
                  placeholder="CVV / CVC        "
                />
              </View>
            </View>

            <TouchableOpacity
              style={
                isValid
                  ? styles(theme).buttonStyle
                  : styles(theme).disabledButtonStyle
              }
              onPress={() => handleSubmit()}
              disabled={!isValid}
            >
              <Text style={styles(theme).buttonTextStyle}>Pay Securely</Text>
            </TouchableOpacity>
          </>
        )}
      </Formik>

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setChangePaymentMethod(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>Change Payment Method</Text>
      </TouchableOpacity>

      <AwesomeAlert
        show={debitCardError}
        title="DEBIT CARD ERROR"
        message={"The debit card details  are not valid. Please check again."}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setDebitCardError(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={binError}
        title=" CARD BIN ERROR"
        message={binErrorMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setBinError(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={processTransactionError}
        title="TRANSACTION PROCESSING ERROR"
        message={processTransactionErrorMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setProcessTransactionError(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={initiateTransactionError}
        title="INITIATE TRANSACTION ERROR"
        message={initiateTransactionErrorMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setInitiateTransactionError(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={changePaymentMethod}
        title="CONFIRM CHANGE PAYMENT METHOD"
        message="Are you sure you want to change payment method ?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, change it"
        // confirmButtonColor="#DD6B55"
        onCancelPressed={() => setChangePaymentMethod(false)}
        onConfirmPressed={() => {
          setChangePaymentMethod(false);
          navigation.navigate("SelectKampusPaymentMethod");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    logoStyle: {
      height: 70,
      width: 70,
      alignSelf: "center",
      margin: 10,
    },

    labelStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },

    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: 10,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },
    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default KampusDebitCardPayment;
