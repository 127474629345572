import React, { createContext, useContext, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { UserContext } from "./UserContext";
import { MyLocationContext } from "./MyLocationContext";
import * as queries from "../src/graphql/queries";

export const DisplayKardsContext = createContext(null);

export default ({ children }) => {
  const [displayKards, setDisplayKards] = useState([]);
  const { myLocation } = useContext(MyLocationContext);
  const { user } = useContext(UserContext);

  useEffect(async () => {
    if (
      myLocation &&
      myLocation.latitude != undefined &&
      myLocation.longitude != undefined
    ) {
      setDisplayKards([]);
      await fetchDisplayKards().then((data) => {
        setDisplayKards(data);
      });
    }
  }, [myLocation, myLocation.latitude, myLocation.longitude]);

  const fetchDisplayKards = async () => {
    var itemArray = [];

    try {
      var nextToken = "0";
      while (nextToken != "") {
        const fetchKards = await API.graphql({
          query: queries.searchEs,
          variables: {
            location: {
              lng: Number(myLocation.longitude),
              lat: Number(myLocation.latitude),
            },
            nextToken: nextToken,
          },
          // tested authMode is compulsory otherwise no Current user error for Unauthorized user but works for authorized user
          authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
        });
        if (fetchKards.data.searchEs.items.length != 0) {
          fetchKards.data.searchEs.items.map((item) => {
            itemArray.push(item);
          });
        }
        nextToken = fetchKards.data.searchEs.nextToken;
      }

      return itemArray;
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <DisplayKardsContext.Provider value={{ displayKards, setDisplayKards }}>
      {children}
    </DisplayKardsContext.Provider>
  );
};
