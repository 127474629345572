import "react-native-gesture-handler";
import React from "react";
import { Amplify } from "aws-amplify";

import UserContext from "./context/UserContext";
import ProfileContext from "./context/ProfileContext";
import DisplayKardsContext from "./context/DisplayKardsContext";
import ThemeContext from "./context/ThemeContext";
import DeviceContext from "./context/DeviceContext";
import CurrentLocationContext from "./context/CurrentLocationContext";
import MyLocationContext from "./context/MyLocationContext";
import NewKardContext from "./context/NewKardContext";
import KampusEventContext from "./context/KampusEventContext";
import KampusKardContext from "./context/KampusKardContext";
import KampusRegistrationContext from "./context/KampusRegistrationContext";

import PromoKardContext from "./context/PromoKardContext";
import OrderContext from "./context/OrderContext";

import MainLayout from "./screens/layout/MainLayout";
import awsconfig from "./src/aws-exports";
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

Amplify.configure({
  ...awsconfig,
  Analytics: {
    disabled: true,
  },
});

const App = () => {
  serviceWorkerRegistration.register();

  return (
    <UserContext>
      <ProfileContext>
        <CurrentLocationContext>
          <MyLocationContext>
            <DeviceContext>
              <ThemeContext>
                <DisplayKardsContext>
                  <NewKardContext>
                    <KampusRegistrationContext>
                      <KampusEventContext>
                        <KampusKardContext>
                          <PromoKardContext>
                            <OrderContext>
                              <MainLayout />
                            </OrderContext>
                          </PromoKardContext>
                        </KampusKardContext>
                      </KampusEventContext>
                    </KampusRegistrationContext>
                  </NewKardContext>
                </DisplayKardsContext>
              </ThemeContext>
            </DeviceContext>
          </MyLocationContext>
        </CurrentLocationContext>
      </ProfileContext>
    </UserContext>
  );
};
export default App;
