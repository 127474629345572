export const useCases = [
  {
    id: "p1.gif",
  },
  {
    id: "p2.png",
  },
  {
    id: "p3.png",
  },
  {
    id: "p4.png",
  },
  {
    id: "p5.png",
  },
  {
    id: "p6.png",
  },
  {
    id: "p7.png",
  },
  {
    id: "p8.png",
  },
  {
    id: "p9.png",
  },
  {
    id: "p10.png",
  },
  {
    id: "p11.png",
  },
  {
    id: "p12.png",
  },
  {
    id: "p13.png",
  },
  {
    id: "p14.png",
  },
  {
    id: "p15.png",
  },
  {
    id: "p16.png",
  },
  {
    id: "p17.png",
  },
  {
    id: "p18.png",
  },
  {
    id: "p19.png",
  },
  {
    id: "p20.png",
  },
  {
    id: "p21.png",
  },
  {
    id: "p22.png",
  },
  {
    id: "p23.png",
  },
  {
    id: "p24.png",
  },
  {
    id: "p25.png",
  },
  {
    id: "p26.png",
  },
  {
    id: "p27.png",
  },
  {
    id: "p28.png",
  },
  {
    id: "p29.png",
  },
  {
    id: "p30.png",
  },
  {
    id: "p31.png",
  },
  {
    id: "p32.png",
  },
  {
    id: "p33.png",
  },
  {
    id: "p34.png",
  },
  {
    id: "p35.png",
  },
];
