import React, { useContext } from "react";
import { View, ScrollView, StyleSheet, Image, Text } from "react-native";

import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";

import {
  Home,
  DisplayKards,
  SignIn,
  SignOut,
  Profile,
  PostNewKard,
  MyKards,
  MyLocation,
  Themes,
  KabutarKampus,
  UseCases,
  Help,
  ShareUs,
  Feedback,
  Privacy,
  Terms,
  ContactUs,
  AboutUs,
  PromoKard,
  SearchKard,
  PaymentTesting,
  PendingTransaction,
  PendingFeedback,
  PendingKampusRegistrations,
  PendingKampusEvents,
  Dashboard,
} from "../../assets/index";
import { ThemeContext } from "../../context/ThemeContext";
import { UserContext } from "../../context/UserContext";
const Settings = () => {
  const { user } = useContext(UserContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const navigation = useNavigation();
  console.log(user);
  const officeTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "officeTeam"
    );
  const kampusTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "kampusTeam"
    );
  const feedbackTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "feedbackTeam"
    );
  const promotionTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "promotionTeam"
    );
  const dashboardTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "dashboardTeam"
    );

  const revenueTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "revenueTeam"
    );

  const paymentTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "paymentTeam"
    );
  const txnPendingTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "txnPendingTeam"
    );
  const flagTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "flagTeam"
    );

  return (
    <ScrollView>
      <View style={styles(theme).container}>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("Home")}
        >
          <Image source={Home} style={styles(theme).image} />
          <Text style={styles(theme).label}>Home</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("ChooseTypeForNewKard")}
        >
          <Image source={PostNewKard} style={styles(theme).image} />
          <Text style={styles(theme).label}>Post New Kard</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("DisplayKards")}
        >
          <Image source={DisplayKards} style={styles(theme).image} />
          <Text style={styles(theme).label}>Display Kards</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("MyLocation")}
        >
          <Image source={MyLocation} style={styles(theme).image} />
          <Text style={styles(theme).label}>My Location</Text>
        </TouchableOpacity>
        {user ? (
          <>
            <TouchableOpacity
              style={styles(theme).itemStyle}
              onPress={() => navigation.navigate("SignOut")}
            >
              <Image source={SignOut} style={styles(theme).image} />
              <Text style={styles(theme).label}>Sign Out</Text>
            </TouchableOpacity>
          </>
        ) : (
          <TouchableOpacity
            style={styles(theme).itemStyle}
            onPress={() => navigation.navigate("Registration")}
          >
            <Image source={SignIn} style={styles(theme).image} />
            <Text style={styles(theme).label}>Sign In</Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("Profile")}
        >
          <Image source={Profile} style={styles(theme).image} />
          <Text style={styles(theme).label}>Profile</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("CurrentKards")}
        >
          <Image source={MyKards} style={styles(theme).image} />
          <Text style={styles(theme).label}>My Kards</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("Themes")}
        >
          <Image source={Themes} style={styles(theme).image} />
          <Text style={styles(theme).label}>Themes</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("KampusHome")}
        >
          <Image source={KabutarKampus} style={styles(theme).image} />
          <Text style={styles(theme).label}>Kabutar Kampus</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("UseCases")}
        >
          <Image source={UseCases} style={styles(theme).image} />
          <Text style={styles(theme).label}>Use Cases</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("Help")}
        >
          <Image source={Help} style={styles(theme).image} />
          <Text style={styles(theme).label}>Help & FAQ</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("ShareUs")}
        >
          <Image source={ShareUs} style={styles(theme).image} />
          <Text style={styles(theme).label}>Share Us</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("Feedback")}
        >
          <Image source={Feedback} style={styles(theme).image} />
          <Text style={styles(theme).label}>Feedback</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("Privacy")}
        >
          <Image source={Privacy} style={styles(theme).image} />
          <Text style={styles(theme).label}>Privacy</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("Terms")}
        >
          <Image source={Terms} style={styles(theme).image} />
          <Text style={styles(theme).label}>Terms & Conditions</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("ContactUs")}
        >
          <Image source={ContactUs} style={styles(theme).image} />
          <Text style={styles(theme).label}>Contact Us</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles(theme).itemStyle}
          onPress={() => navigation.navigate("AboutUs")}
        >
          <Image source={AboutUs} style={styles(theme).image} />
          <Text style={styles(theme).label}>About Us</Text>
        </TouchableOpacity>

        {(officeTeam || promotionTeam) && (
          <TouchableOpacity
            style={styles(theme).itemStyle}
            onPress={() => navigation.navigate("SelectPromoKard")}
          >
            <Image source={PromoKard} style={styles(theme).image} />
            <Text style={styles(theme).label}>Promo Kard</Text>
          </TouchableOpacity>
        )}

        {(officeTeam || flagTeam) && (
          <TouchableOpacity
            style={styles(theme).itemStyle}
            onPress={() => navigation.navigate("SearchKard")}
          >
            <Image source={SearchKard} style={styles(theme).image} />
            <Text style={styles(theme).label}>Search Kard</Text>
          </TouchableOpacity>
        )}

        {(officeTeam || paymentTeam) && (
          <TouchableOpacity
            style={styles(theme).itemStyle}
            onPress={() => navigation.navigate("PaymentTesting")}
          >
            <Image source={PaymentTesting} style={styles(theme).image} />
            <Text style={styles(theme).label}>Payment Testing</Text>
          </TouchableOpacity>
        )}

        {(officeTeam || txnPendingTeam) && (
          <TouchableOpacity
            style={styles(theme).itemStyle}
            onPress={() => navigation.navigate("PendingTransactions")}
          >
            <Image source={PendingTransaction} style={styles(theme).image} />
            <Text style={styles(theme).label}>Pending Transactions</Text>
          </TouchableOpacity>
        )}

        {(officeTeam || feedbackTeam) && (
          <TouchableOpacity
            style={styles(theme).itemStyle}
            onPress={() => navigation.navigate("PendingFeedback")}
          >
            <Image source={PendingFeedback} style={styles(theme).image} />
            <Text style={styles(theme).label}>Pending Feedback</Text>
          </TouchableOpacity>
        )}

        {(officeTeam || kampusTeam) && (
          <TouchableOpacity
            style={styles(theme).itemStyle}
            onPress={() => navigation.navigate("PendingKampusRegistrations")}
          >
            <Image
              source={PendingKampusRegistrations}
              style={styles(theme).image}
            />
            <Text style={styles(theme).label}>
              Pending Kampus Registrations
            </Text>
          </TouchableOpacity>
        )}
        {(officeTeam || kampusTeam) && (
          <TouchableOpacity
            style={styles(theme).itemStyle}
            onPress={() => navigation.navigate("PendingKampusEvents")}
          >
            <Image source={PendingKampusEvents} style={styles(theme).image} />
            <Text style={styles(theme).label}>Pending Kampus Events</Text>
          </TouchableOpacity>
        )}

        {(officeTeam || dashboardTeam || revenueTeam) && (
          <TouchableOpacity
            style={styles(theme).itemStyle}
            onPress={() => navigation.navigate("Dashboard")}
          >
            <Image source={Dashboard} style={styles(theme).image} />
            <Text style={styles(theme).label}>Dashboard</Text>
          </TouchableOpacity>
        )}
      </View>
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "white",
      paddingTop: 20,
      paddingBottom: 20,
    },

    itemStyle: {
      marginTop: 10,
      marginBottom: 10,
      flexDirection: "row",
    },
    image: {
      height: 30,
      width: 30,
      marginLeft: 15,
      borderRadius: 50,
    },
    label: {
      marginLeft: 15,
      alignSelf: "center",
      color: theme.colors.standardTextColor,
    },
  });

export default Settings;
