import React, { useContext, useState } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native-gesture-handler";
import { API } from "aws-amplify";
import ShortUniqueId from "short-unique-id";
import AwesomeAlert from "react-native-awesome-alerts";

import { ThemeContext } from "../../context/ThemeContext";
import { ProfileContext } from "../../context/ProfileContext";
import { UserContext } from "../../context/UserContext";

import * as mutations from "../../src/graphql/mutations";

const RateUs = () => {
  const { profile } = useContext(ProfileContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState(false);
  const { user } = useContext(UserContext);
  const stars = [1, 2, 3, 4, 5];
  const uid = new ShortUniqueId({ length: 29 });

  const postRating = async () => {
    const rateUs = await API.graphql({
      query: mutations.createFeedback,
      variables: {
        input: {
          id: uid(),
          type: "rating",
          email: user ? profile.username : null,
          rating: rating,
          status: "closed",
          requestDate: new Date().toISOString(),
          expiryDate: Math.round(new Date(Date.now() + 1000).getTime() / 1000),
        },
      },
      authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
    });
    setFeedback(true);
  };
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>RATE US</Text>
      <View style={styles(theme).rowGroupCenterStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          How would you rate kabutar?
        </Text>
      </View>

      <View style={styles(theme).rowGroupSpaceEvenlyStyle}>
        {stars.map((i, k) => {
          return (
            <TouchableOpacity key={k} onPress={() => setRating(k + 1)}>
              {k < rating ? (
                theme.type == "fill" ? (
                  <MaterialIcons name="stars" size={50} color="#FDCC0D" />
                ) : (
                  <FontAwesome name="star" size={50} color="#FDCC0D" />
                )
              ) : theme.type == "fill" ? (
                <MaterialIcons
                  name="stars"
                  size={50}
                  color={theme.colors.standardTextColor}
                />
              ) : (
                <FontAwesome
                  name="star-o"
                  size={50}
                  color={theme.colors.standardTextColor}
                />
              )}
            </TouchableOpacity>
          );
        })}
      </View>
      <TouchableOpacity
        style={
          rating == 0
            ? styles(theme).disabledButtonStyle
            : styles(theme).buttonStyle
        }
        disabled={rating == 0}
        onPress={() => postRating()}
      >
        <Text style={styles(theme).buttonTextStyle}>Submit</Text>
      </TouchableOpacity>
      <AwesomeAlert
        show={feedback}
        title="FEEDBACK"
        message={"Thank you for rating us."}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, thank you"
        onConfirmPressed={() => {
          setFeedback(false);
          setRating(0);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },

    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },
    rowGroupSpaceEvenlyStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default RateUs;
