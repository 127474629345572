import React, { useState, useContext, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  ScrollView,
  Platform,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as ImagePicker from "expo-image-picker";
import AwesomeAlert from "react-native-awesome-alerts";

import { ThemeContext } from "../../context/ThemeContext";
import * as mutations from "../../src/graphql/mutations";
import * as queries from "../../src/graphql/queries";
import config from "../../src/aws-exports";

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

const ChangeKard = (props) => {
  const id = props.route.params.id;
  const imageName = props.route.params.imageName;
  const [item, setItem] = useState();
  const [kard, setKard] = useState();
  const { theme, changeTheme } = useContext(ThemeContext);
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const [checkSelected, setCheckSelected] = useState(false);
  const [verifyKardDimensions, setVerifyKardDimensions] = useState(false);

  const [timer, setTimer] = useState(new Date().getTime());

  useEffect(() => {
    if (isFocused) {
      refreshKardData();
      setTimer(new Date().getTime());
    }
  }, [isFocused]);

  // useEffect(() => {
  //   refreshKardData();
  // }, []);
  const refreshKardData = async () => {
    try {
      const newKard = await API.graphql({
        query: queries.getKard,
        variables: {
          id: id,
        },
      });
      setItem(newKard.data.getKard);
      setKard(
        `https://${bucket}.s3.${region}.amazonaws.com/public/${newKard.data.getKard.imageName}?key=${timer}`
      );
    } catch (err) {
      console.log(err);
    }
  };
  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: true,
      quality: 0.1,
    });
    if (!result.cancelled) {
      const fileSize = result.base64.length * (3 / 4) - 2;
      if (result.height <= 1200 && result.width <= 900 && fileSize < 500000) {
        setKard(result.uri);
        setKardSelected(true);
      } else {
        setVerifyKardDimensions(true);
      }
    }
  };

  const changeKard = async (item) => {
    const fileType =
      Platform.OS == "web"
        ? kard.substring(kard.indexOf("/") + 1, kard.indexOf(";"))
        : kard.substring(kard.lastIndexOf(".") + 1);
    const newImageName = item.id + "." + fileType;
    const response = await fetch(kard);
    const blob = await response.blob();
    const access = { level: "public", contentType: fileType };
    try {
      await Storage.remove(item.imageName, {
        level: "public",
      });
      const updatedItem = await API.graphql(
        graphqlOperation(mutations.updateKard, {
          input: {
            id: item.id,
            imageName: newImageName,
            changeKard: item.changeKard + 1,
          },
        })
      );
      await Storage.put(newImageName, blob, access);

      navigation.navigate("CurrentKardDetails", {
        id: id,
        imageName: newImageName,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return item ? (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>CHANGE KARD</Text>
      </View>
      <TouchableOpacity onPress={pickImage}>
        <Image
          source={{
            uri: kard,
          }}
          style={styles(theme).kardStyle}
        />
      </TouchableOpacity>
      <TouchableOpacity
        style={
          checkSelected
            ? styles(theme).buttonStyle
            : styles(theme).disabledButtonStyle
        }
        onPress={() => changeKard(item)}
        disabled={!checkSelected}
      >
        <Text style={styles(theme).buttonTextStyle}>Ok</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          setCheckSelected(false);
          navigation.navigate("CurrentKardDetails", {
            id: id,
            imageName: imageName,
          });
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Cancel</Text>
      </TouchableOpacity>
      <AwesomeAlert
        show={verifyKardDimensions}
        title="VERIFY KARD DIMENSIONS"
        message="The image width should be less than or equal to 900px, height should be less than or equal
        to 1200px and size should be less than 500KB. Please use online tools and resize the image with proper dimensions(in pixels) and size."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setVerifyKardDimensions(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  ) : null;
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    kardStyle: {
      height: 550,
      width: 350,
      alignSelf: "center",
      borderRadius: 20,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },
    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default ChangeKard;
