import React, { useEffect, useState, useContext } from "react";
import {
  ScrollView,
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { API } from "aws-amplify";
import { DatePickerModal } from "react-native-paper-dates";
import DateTimePicker from "@react-native-community/datetimepicker";

import * as queries from "../../src/graphql/queries";
import { ThemeContext } from "../../context/ThemeContext";
import { UserContext } from "../../context/UserContext";
import { Ionicons } from "@expo/vector-icons";

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const isFocused = useIsFocused();

  var todayDataObject = {
    freeActive: 0,
    freeQueue: 0,
    freePause: 0,
    freeTxnPending: 0,
    freeInsert: 0,
    freeDelete: 0,
    freeRefund: 0,
    freeComplete: 0,
    freeRemoved: 0,

    basicActive: 0,
    basicQueue: 0,
    basicPause: 0,
    basicTxnPending: 0,
    basicInsert: 0,
    basicDelete: 0,
    basicRefund: 0,
    basicComplete: 0,
    basicRemoved: 0,

    premiumActive: 0,
    premiumQueue: 0,
    premiumPause: 0,
    premiumTxnPending: 0,
    premiumInsert: 0,
    premiumDelete: 0,
    premiumRefund: 0,
    premiumComplete: 0,
    premiumRemoved: 0,

    campaignActive: 0,
    campaignQueue: 0,
    campaignPause: 0,
    campaignTxnPending: 0,
    campaignInsert: 0,
    campaignDelete: 0,
    campaignRefund: 0,
    campaignComplete: 0,
    campaignRemoved: 0,

    kampusActive: 0,
    kampusQueue: 0,
    kampusPause: 0,
    kampusTxnPending: 0,
    kampusInsert: 0,
    kampusDelete: 0,
    kampusRefund: 0,
    kampusComplete: 0,
    kampusRemoved: 0,

    promoActive: 0,
    promoQueue: 0,
    promoPause: 0,
    promoTxnPending: 0,
    promoInsert: 0,
    promoDelete: 0,
    promoRefund: 0,
    promoComplete: 0,
    promoRemoved: 0,

    totalActive: 0,
    totalQueue: 0,
    totalPause: 0,
    totalTxnPending: 0,
    totalInsert: 0,
    totalDelete: 0,
    totalRefund: 0,
    totalComplete: 0,
    totalRemoved: 0,

    freeKardCharges: 0,
    freeKardDiscount: 0,
    freeKardTaxes: 0,
    freeKardNet: 0,
    freeRefundNet: 0,

    basicKardCharges: 0,
    basicKardDiscount: 0,
    basicKardTaxes: 0,
    basicKardNet: 0,
    basicRefundNet: 0,

    premiumKardCharges: 0,
    premiumKardDiscount: 0,
    premiumKardTaxes: 0,
    premiumKardNet: 0,
    premiumRefundNet: 0,

    campaignKardCharges: 0,
    campaignKardDiscount: 0,
    campaignKardTaxes: 0,
    campaignKardNet: 0,
    campaignRefundNet: 0,

    kampusKardCharges: 0,
    kampusKardDiscount: 0,
    kampusKardTaxes: 0,
    kampusKardNet: 0,
    kampusRefundNet: 0,

    promoKardCharges: 0,
    promoKardDiscount: 0,
    promoKardTaxes: 0,
    promoKardNet: 0,
    promoRefundNet: 0,

    totalKardCharges: 0,
    totalKardDiscount: 0,
    totalKardTaxes: 0,
    totalKardNet: 0,
    totalRefundNet: 0,

    instituteRenewals: 0,
    instituteRenewalCharges: 0,
    instituteRenewalTaxes: 0,
    instituteRenewalNet: 0,

    volunteerRenewals: 0,
    volunteerRenewalCharges: 0,
    volunteerRenewalTaxes: 0,
    volunteerRenewalNet: 0,

    totalRenewals: 0,
    totalRenewalCharges: 0,
    totalRenewalTaxes: 0,
    totalRenewalNet: 0,

    profilesCreated: 0,
    profilesRemoved: 0,

    kampusRegApplied: 0,
    kampusRegApproved: 0,
    kampusRegRejected: 0,

    kampusEventApplied: 0,
    kampusEventApproved: 0,
    kampusEventRejected: 0,
    kampusEventRemoved: 0,

    kampusChampions: 0,
    kampusVolunteers: 0,

    totalRating: 0,
    oneRating: 0,
    twoRating: 0,
    threeRating: 0,
    fourRating: 0,
    fiveRating: 0,

    totalContactUs: 0,
    contactUsDisposed: 0,

    totalComments: 0,
    commentsDisposed: 0,

    totalProblems: 0,
    problemsDisposed: 0,
  };

  var totalDataObject = {
    freeActive: 0,
    freeQueue: 0,
    freePause: 0,
    freeTxnPending: 0,
    freeInsert: 0,
    freeDelete: 0,
    freeRefund: 0,
    freeComplete: 0,
    freeRemoved: 0,

    basicActive: 0,
    basicQueue: 0,
    basicPause: 0,
    basicTxnPending: 0,
    basicInsert: 0,
    basicDelete: 0,
    basicRefund: 0,
    basicComplete: 0,
    basicRemoved: 0,

    premiumActive: 0,
    premiumQueue: 0,
    premiumPause: 0,
    premiumTxnPending: 0,
    premiumInsert: 0,
    premiumDelete: 0,
    premiumRefund: 0,
    premiumComplete: 0,
    premiumRemoved: 0,

    campaignActive: 0,
    campaignQueue: 0,
    campaignPause: 0,
    campaignTxnPending: 0,
    campaignInsert: 0,
    campaignDelete: 0,
    campaignRefund: 0,
    campaignComplete: 0,
    campaignRemoved: 0,

    kampusActive: 0,
    kampusQueue: 0,
    kampusPause: 0,
    kampusTxnPending: 0,
    kampusInsert: 0,
    kampusDelete: 0,
    kampusRefund: 0,
    kampusComplete: 0,
    kampusRemoved: 0,

    promoActive: 0,
    promoQueue: 0,
    promoPause: 0,
    promoTxnPending: 0,
    promoInsert: 0,
    promoDelete: 0,
    promoRefund: 0,
    promoComplete: 0,
    promoRemoved: 0,

    totalActive: 0,
    totalQueue: 0,
    totalPause: 0,
    totalTxnPending: 0,
    totalInsert: 0,
    totalDelete: 0,
    totalRefund: 0,
    totalComplete: 0,
    totalRemoved: 0,

    freeKardCharges: 0,
    freeKardDiscount: 0,
    freeKardTaxes: 0,
    freeKardNet: 0,
    freeRefundNet: 0,

    basicKardCharges: 0,
    basicKardDiscount: 0,
    basicKardTaxes: 0,
    basicKardNet: 0,
    basicRefundNet: 0,

    premiumKardCharges: 0,
    premiumKardDiscount: 0,
    premiumKardTaxes: 0,
    premiumKardNet: 0,
    premiumRefundNet: 0,

    campaignKardCharges: 0,
    campaignKardDiscount: 0,
    campaignKardTaxes: 0,
    campaignKardNet: 0,
    campaignRefundNet: 0,

    kampusKardCharges: 0,
    kampusKardDiscount: 0,
    kampusKardTaxes: 0,
    kampusKardNet: 0,
    kampusRefundNet: 0,

    promoKardCharges: 0,
    promoKardDiscount: 0,
    promoKardTaxes: 0,
    promoKardNet: 0,
    promoRefundNet: 0,

    totalKardCharges: 0,
    totalKardDiscount: 0,
    totalKardTaxes: 0,
    totalKardNet: 0,
    totalRefundNet: 0,

    instituteRenewals: 0,
    instituteRenewalCharges: 0,
    instituteRenewalTaxes: 0,
    instituteRenewalNet: 0,

    volunteerRenewals: 0,
    volunteerRenewalCharges: 0,
    volunteerRenewalTaxes: 0,
    volunteerRenewalNet: 0,

    totalRenewals: 0,
    totalRenewalCharges: 0,
    totalRenewalTaxes: 0,
    totalRenewalNet: 0,

    profilesCreated: 0,
    profilesRemoved: 0,

    kampusRegApplied: 0,
    kampusRegApproved: 0,
    kampusRegRejected: 0,

    kampusEventApplied: 0,
    kampusEventApproved: 0,
    kampusEventRejected: 0,
    kampusEventRemoved: 0,

    kampusChampions: 0,
    kampusVolunteers: 0,

    totalRating: 0,
    oneRating: 0,
    twoRating: 0,
    threeRating: 0,
    fourRating: 0,
    fiveRating: 0,

    totalContactUs: 0,
    contactUsDisposed: 0,

    totalComments: 0,
    commentsDisposed: 0,

    totalProblems: 0,
    problemsDisposed: 0,
  };

  var rangeDataObject = {
    freeActive: 0,
    freeQueue: 0,
    freePause: 0,
    freeTxnPending: 0,
    freeInsert: 0,
    freeDelete: 0,
    freeRefund: 0,
    freeComplete: 0,
    freeRemoved: 0,

    basicActive: 0,
    basicQueue: 0,
    basicPause: 0,
    basicTxnPending: 0,
    basicInsert: 0,
    basicDelete: 0,
    basicRefund: 0,
    basicComplete: 0,
    basicRemoved: 0,

    premiumActive: 0,
    premiumQueue: 0,
    premiumPause: 0,
    premiumTxnPending: 0,
    premiumInsert: 0,
    premiumDelete: 0,
    premiumRefund: 0,
    premiumComplete: 0,
    premiumRemoved: 0,

    campaignActive: 0,
    campaignQueue: 0,
    campaignPause: 0,
    campaignTxnPending: 0,
    campaignInsert: 0,
    campaignDelete: 0,
    campaignRefund: 0,
    campaignComplete: 0,
    campaignRemoved: 0,

    kampusActive: 0,
    kampusQueue: 0,
    kampusPause: 0,
    kampusTxnPending: 0,
    kampusInsert: 0,
    kampusDelete: 0,
    kampusRefund: 0,
    kampusComplete: 0,
    kampusRemoved: 0,

    promoActive: 0,
    promoQueue: 0,
    promoPause: 0,
    promoTxnPending: 0,
    promoInsert: 0,
    promoDelete: 0,
    promoRefund: 0,
    promoComplete: 0,
    promoRemoved: 0,

    totalActive: 0,
    totalQueue: 0,
    totalPause: 0,
    totalTxnPending: 0,
    totalInsert: 0,
    totalDelete: 0,
    totalRefund: 0,
    totalComplete: 0,
    totalRemoved: 0,

    freeKardCharges: 0,
    freeKardDiscount: 0,
    freeKardTaxes: 0,
    freeKardNet: 0,
    freeRefundNet: 0,

    basicKardCharges: 0,
    basicKardDiscount: 0,
    basicKardTaxes: 0,
    basicKardNet: 0,
    basicRefundNet: 0,

    premiumKardCharges: 0,
    premiumKardDiscount: 0,
    premiumKardTaxes: 0,
    premiumKardNet: 0,
    premiumRefundNet: 0,

    campaignKardCharges: 0,
    campaignKardDiscount: 0,
    campaignKardTaxes: 0,
    campaignKardNet: 0,
    campaignRefundNet: 0,

    kampusKardCharges: 0,
    kampusKardDiscount: 0,
    kampusKardTaxes: 0,
    kampusKardNet: 0,
    kampusRefundNet: 0,

    promoKardCharges: 0,
    promoKardDiscount: 0,
    promoKardTaxes: 0,
    promoKardNet: 0,
    promoRefundNet: 0,

    totalKardCharges: 0,
    totalKardDiscount: 0,
    totalKardTaxes: 0,
    totalKardNet: 0,
    totalRefundNet: 0,

    instituteRenewals: 0,
    instituteRenewalCharges: 0,
    instituteRenewalTaxes: 0,
    instituteRenewalNet: 0,

    volunteerRenewals: 0,
    volunteerRenewalCharges: 0,
    volunteerRenewalTaxes: 0,
    volunteerRenewalNet: 0,

    totalRenewals: 0,
    totalRenewalCharges: 0,
    totalRenewalTaxes: 0,
    totalRenewalNet: 0,

    profilesCreated: 0,
    profilesRemoved: 0,

    kampusRegApplied: 0,
    kampusRegApproved: 0,
    kampusRegRejected: 0,

    kampusEventApplied: 0,
    kampusEventApproved: 0,
    kampusEventRejected: 0,
    kampusEventRemoved: 0,

    kampusChampions: 0,
    kampusVolunteers: 0,

    totalRating: 0,
    oneRating: 0,
    twoRating: 0,
    threeRating: 0,
    fourRating: 0,
    fiveRating: 0,

    totalContactUs: 0,
    contactUsDisposed: 0,

    totalComments: 0,
    commentsDisposed: 0,

    totalProblems: 0,
    problemsDisposed: 0,
  };

  const [totalData, setTotalData] = useState(totalDataObject);
  const [rangeData, setRangeData] = useState(rangeDataObject);
  const [todayData, setTodayData] = useState(todayDataObject);

  const [today, setToday] = useState(
    new Date(new Date().getTime()).toISOString().slice(0, 10)
  );

  const [fromDate, setFromDate] = useState(new Date(new Date().getTime()));
  const [endDate, setEndDate] = useState(new Date(new Date().getTime()));

  const [showFromDate, setShowFromDate] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);

  const officeTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "officeTeam"
    );

  const revenueTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "revenueTeam"
    );

  useEffect(() => {
    if (isFocused) {
      fetchTotalData();
      fetchTodayData();
    }
  }, [isFocused]);

  const fetchTotalData = async () => {
    try {
      const totalDataResponse = await API.graphql({
        query: queries.getTotalTable,
        variables: {
          id: "0000-00-00",
        },
      });

      Object.keys(totalDataObject).map(function (key, index) {
        totalDataObject[key] = 0;
      });
      if (totalDataResponse.data.getTotalTable != null) {
        Object.keys(totalDataResponse.data.getTotalTable).map(function (
          key,
          index
        ) {
          if (
            key != "id" &&
            key != "createdAt" &&
            key != "updatedAt" &&
            key != "type" &&
            key != "date"
          ) {
            totalDataObject[key] =
              totalDataResponse.data.getTotalTable[key] != null
                ? totalDataResponse.data.getTotalTable[key]
                : 0;
          }
        });
      }

      setTotalData(totalDataObject);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRangeData = async () => {
    const start = new Date(fromDate).toISOString().slice(0, 10);
    const end = new Date(endDate).toISOString().slice(0, 10);

    console.log(start);
    console.log(end);
    try {
      const rangeData = await API.graphql({
        query: queries.listTotalTableByDate,
        variables: {
          type: "TotalTable",
          date: { between: [start, end] },
        },
      });
      console.log(rangeData.data.listTotalTableByDate.items);
      Object.keys(rangeDataObject).map(function (key, index) {
        rangeDataObject[key] = 0;
      });

      rangeData.data.listTotalTableByDate.items.map((i, k) => {
        Object.keys(i).map(function (key, index) {
          if (
            key != "id" &&
            key != "createdAt" &&
            key != "updatedAt" &&
            key != "type" &&
            key != "date"
          ) {
            rangeDataObject[key] =
              i[key] != null
                ? rangeDataObject[key] + i[key]
                : rangeDataObject[key];
          }
        });
      });
      setRangeData(rangeDataObject);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTodayData = async () => {
    try {
      const todayDataResponse = await API.graphql({
        query: queries.getTotalTable,
        variables: {
          id: today,
        },
      });

      Object.keys(todayDataObject).map(function (key, index) {
        todayDataObject[key] = 0;
      });
      if (todayDataResponse.data.getTotalTable != null) {
        Object.keys(todayDataResponse.data.getTotalTable).map(function (
          key,
          index
        ) {
          if (
            key != "id" &&
            key != "createdAt" &&
            key != "updatedAt" &&
            key != "type" &&
            key != "date"
          ) {
            todayDataObject[key] =
              todayDataResponse.data.getTotalTable[key] != null
                ? todayDataResponse.data.getTotalTable[key]
                : 0;
          }
        });
      }

      setTodayData(todayDataObject);
      setRangeData(todayDataObject);
    } catch (err) {
      console.log(err);
    }
  };

  const onDismissFromDate = () => {
    setShowFromDate(false);
  };
  const onConfirmFromDate = (params) => {
    setFromDate(new Date(params.date.getTime()));
    if (params.date > endDate) {
      setEndDate(new Date(params.date.getTime()));
    }

    setShowFromDate(false);
  };

  const onChangeFromDate = (event, selectedDate) => {
    setShowFromDate(false);
    const currentDate = new Date(selectedDate.getTime()) || fromDate;
    setFromDate(currentDate);
    if (currentDate > endDate) {
      setEndDate(currentDate);
    }
  };

  const onDismissEndDate = () => {
    setShowEndDate(false);
  };
  const onConfirmEndDate = (params) => {
    setEndDate(new Date(params.date.getTime()));
    setShowEndDate(false);
  };

  const onChangeEndDate = (event, selectedDate) => {
    setShowEndDate(false);
    const currentDate = new Date(selectedDate.getTime()) || endDate;
    setEndDate(currentDate);
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>DASHBOARD</Text>
        <TouchableOpacity
          onPress={() => {
            fetchTotalData();
            fetchTodayData();
          }}
        >
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>

      {/* ************************************************************** */}

      {/* ************************************************************** */}

      {/* ************************************************************** */}

      <Text style={styles(theme).subHeaderStyle}>TODAY DATA</Text>
      <Text style={styles(theme).subHeaderStyle}>Summary</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Registered Users:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.profilesCreated}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kards:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Institutions:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusRegApplied}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Volunteers:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusVolunteers}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Champions:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusChampions}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Events:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusEventApplied}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>
            Kampus Institution Renewals:
          </Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.instituteRenewals}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>
            Kampus Volunteer Renewals:
          </Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.volunteerRenewals}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Ratings:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalRating}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Reported Problems:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalProblems}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Feedback Comments:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalComments}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>ContactUs Messages:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalContactUs}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Total Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.freeInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.basicInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.premiumInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.campaignInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.promoInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalInsert}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Kampus Institutions</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Applied:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusRegApplied}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Approved:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusRegApproved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Rejected:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusRegRejected}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusRegApplied -
              todayData.kampusRegApproved -
              todayData.kampusRegRejected}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Kampus Events</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Applied:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusEventApplied}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Approved:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusEventApproved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Rejected:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusEventRejected}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Removed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusEventRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusEventApplied -
              todayData.kampusEventApproved -
              todayData.kampusEventRejected -
              todayData.kampusEventRemoved}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Rating</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalRating}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Five Star:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.fiveRating}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Four Star:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.fourRating}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Three Star:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.threeRating}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Two Star:</Text>
          <Text style={styles(theme).textBoldStyle}>{todayData.twoRating}</Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>One Star:</Text>
          <Text style={styles(theme).textBoldStyle}>{todayData.oneRating}</Text>
        </View>
      </View>
      {todayData.totalRating != 0 && (
        <>
          <Text style={styles(theme).subHeaderStyle}>Rating Percentage</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {(
                  (todayData.totalRating / todayData.totalRating) *
                  100
                ).toFixed(2)}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Five Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((todayData.fiveRating / todayData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Four Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((todayData.fourRating / todayData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Three Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {(
                  (todayData.threeRating / todayData.totalRating) *
                  100
                ).toFixed(2)}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Two Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((todayData.twoRating / todayData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>One Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((todayData.oneRating / todayData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
          </View>
        </>
      )}

      <Text style={styles(theme).subHeaderStyle}>Reported Problems</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalProblems}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Disposed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.problemsDisposed}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalProblems - todayData.problemsDisposed}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Feedback Comments</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalComments}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Disposed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.commentsDisposed}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalComments - todayData.commentsDisposed}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>ContactUs Messages</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalContactUs}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Disposed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.contactUsDisposed}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalContactUs - todayData.contactUsDisposed}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Active Kards</Text>

      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.freeActive}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.basicActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.premiumActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.campaignActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.promoActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalActive}
          </Text>
        </View>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Completed Kards</Text>

      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.freeComplete}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.basicComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.premiumComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.campaignComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.promoComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalComplete}
          </Text>
        </View>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Pause Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>{todayData.freePause}</Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.basicPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.premiumPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.campaignPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.promoPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalPause}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Deleted Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.freeDelete}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.basicDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.premiumDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.campaignDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.promoDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalDelete}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Queue Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>{todayData.freeQueue}</Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.basicQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.premiumQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.campaignQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.promoQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalQueue}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Refund Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.freeRefund}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.basicRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.premiumRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.campaignRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.promoRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalRefund}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>TxnPending Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.freeTxnPending}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.basicTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.premiumTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.campaignTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.promoTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalTxnPending}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Removed Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.freeRemoved}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.basicRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.premiumRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.campaignRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.kampusRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.promoRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {todayData.totalRemoved}
          </Text>
        </View>
      </View>

      {(officeTeam || revenueTeam) && (
        <>
          <Text style={styles(theme).subHeaderStyle}>REVENUES</Text>
          <Text style={styles(theme).subHeaderStyle}>Summary</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.totalKardCharges + todayData.totalRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.totalKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.totalKardTaxes + todayData.totalRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.totalKardNet + todayData.totalRenewalNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.totalRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹
                {todayData.totalKardNet +
                  todayData.totalRenewalNet -
                  todayData.totalRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Free Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.freeKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.freeKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.freeKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.freeKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.freeRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.freeKardNet - todayData.freeRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Basic Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.basicKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.basicKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.basicKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.basicKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.basicRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.basicKardNet - todayData.basicRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Premium Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.premiumKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.premiumKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.premiumKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.premiumKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.premiumRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.premiumKardNet - todayData.premiumRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Campaign Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.campaignKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.campaignKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.campaignKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.campaignKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.campaignRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.campaignKardNet - todayData.campaignRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Kampus Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.kampusKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.kampusKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.kampusKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.kampusKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.kampusRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.kampusKardNet - todayData.kampusRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Promo Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.promoKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.promoKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.promoKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.promoKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.promoRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.promoKardNet - todayData.promoRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Kampus Renewals</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.totalRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.totalRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.totalRenewalNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Institution Renewals</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.instituteRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.instituteRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.instituteRenewalNet}
              </Text>
            </View>
          </View>
          <Text style={styles(theme).subHeaderStyle}>Volunteer Renewals</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.volunteerRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.volunteerRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {todayData.volunteerRenewalNet}
              </Text>
            </View>
          </View>
        </>
      )}

      {/* ************************************************************** */}

      {/* ************************************************************** */}

      {/* ************************************************************** */}

      {/* ************************************************************** */}

      <Text style={styles(theme).subHeaderStyle}>CUMULATIVE DATA</Text>
      <Text style={styles(theme).subHeaderStyle}>Summary</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Registered Users:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.profilesCreated}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kards:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Institutions:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusRegApplied}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Volunteers:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusVolunteers}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Champions:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusChampions}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Events:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusEventApplied}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>
            Kampus Institution Renewals:
          </Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.instituteRenewals}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>
            Kampus Volunteer Renewals:
          </Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.volunteerRenewals}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Ratings:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalRating}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Reported Problems:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalProblems}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Feedback Comments:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalComments}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>ContactUs Messages:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalContactUs}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Total Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.freeInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.basicInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.premiumInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.campaignInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.promoInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalInsert}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Kampus Institutions</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Applied:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusRegApplied}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Approved:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusRegApproved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Rejected:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusRegRejected}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusRegApplied -
              totalData.kampusRegApproved -
              totalData.kampusRegRejected}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Kampus Events</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Applied:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusEventApplied}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Approved:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusEventApproved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Rejected:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusEventRejected}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Removed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusEventRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusEventApplied -
              totalData.kampusEventApproved -
              totalData.kampusEventRejected -
              totalData.kampusEventRemoved}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Rating</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalRating}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Five Star:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.fiveRating}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Four Star:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.fourRating}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Three Star:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.threeRating}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Two Star:</Text>
          <Text style={styles(theme).textBoldStyle}>{totalData.twoRating}</Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>One Star:</Text>
          <Text style={styles(theme).textBoldStyle}>{totalData.oneRating}</Text>
        </View>
      </View>
      {totalData.totalRating != 0 && (
        <>
          <Text style={styles(theme).subHeaderStyle}>Rating Percentage</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {(
                  (totalData.totalRating / totalData.totalRating) *
                  100
                ).toFixed(2)}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Five Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((totalData.fiveRating / totalData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Four Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((totalData.fourRating / totalData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Three Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {(
                  (totalData.threeRating / totalData.totalRating) *
                  100
                ).toFixed(2)}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Two Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((totalData.twoRating / totalData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>One Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((totalData.oneRating / totalData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
          </View>
        </>
      )}

      <Text style={styles(theme).subHeaderStyle}>Reported Problems</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalProblems}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Disposed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.problemsDisposed}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalProblems - totalData.problemsDisposed}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Feedback Comments</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalComments}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Disposed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.commentsDisposed}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalComments - totalData.commentsDisposed}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>ContactUs Messages</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalContactUs}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Disposed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.contactUsDisposed}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalContactUs - totalData.contactUsDisposed}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Active Kards</Text>

      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.freeActive}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.basicActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.premiumActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.campaignActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.promoActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalActive}
          </Text>
        </View>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Completed Kards</Text>

      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.freeComplete}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.basicComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.premiumComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.campaignComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.promoComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalComplete}
          </Text>
        </View>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Pause Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>{totalData.freePause}</Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.basicPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.premiumPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.campaignPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.promoPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalPause}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Deleted Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.freeDelete}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.basicDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.premiumDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.campaignDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.promoDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalDelete}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Queue Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>{totalData.freeQueue}</Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.basicQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.premiumQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.campaignQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.promoQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalQueue}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Refund Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.freeRefund}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.basicRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.premiumRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.campaignRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.promoRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalRefund}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>TxnPending Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.freeTxnPending}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.basicTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.premiumTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.campaignTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.promoTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalTxnPending}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Removed Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.freeRemoved}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.basicRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.premiumRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.campaignRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.kampusRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.promoRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {totalData.totalRemoved}
          </Text>
        </View>
      </View>

      {(officeTeam || revenueTeam) && (
        <>
          <Text style={styles(theme).subHeaderStyle}>REVENUES</Text>
          <Text style={styles(theme).subHeaderStyle}>Summary</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.totalKardCharges + totalData.totalRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.totalKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.totalKardTaxes + totalData.totalRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.totalKardNet + totalData.totalRenewalNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.totalRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹
                {totalData.totalKardNet +
                  totalData.totalRenewalNet -
                  totalData.totalRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Free Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.freeKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.freeKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.freeKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.freeKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.freeRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.freeKardNet - totalData.freeRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Basic Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.basicKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.basicKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.basicKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.basicKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.basicRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.basicKardNet - totalData.basicRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Premium Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.premiumKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.premiumKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.premiumKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.premiumKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.premiumRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.premiumKardNet - totalData.premiumRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Campaign Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.campaignKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.campaignKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.campaignKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.campaignKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.campaignRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.campaignKardNet - totalData.campaignRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Kampus Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.kampusKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.kampusKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.kampusKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.kampusKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.kampusRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.kampusKardNet - totalData.kampusRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Promo Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.promoKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.promoKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.promoKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.promoKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.promoRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.promoKardNet - totalData.promoRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Kampus Renewals</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.totalRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.totalRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.totalRenewalNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Institution Renewals</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.instituteRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.instituteRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.instituteRenewalNet}
              </Text>
            </View>
          </View>
          <Text style={styles(theme).subHeaderStyle}>Volunteer Renewals</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.volunteerRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.volunteerRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {totalData.volunteerRenewalNet}
              </Text>
            </View>
          </View>
        </>
      )}

      {/* ************************************************************** */}

      {/* ************************************************************** */}

      {/* ************************************************************** */}

      {/* ************************************************************** */}

      {/* ************************************************************** */}

      <Text style={styles(theme).subHeaderStyle}>RANGE DATA</Text>

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setShowFromDate(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>Start Date</Text>
      </TouchableOpacity>
      <Text style={styles(theme).textCenterStyle}>
        Date: {new Date(new Date(fromDate).getTime()).toDateString()}
      </Text>

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setShowEndDate(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>End Date</Text>
      </TouchableOpacity>
      <Text style={styles(theme).textCenterStyle}>
        Date: {new Date(new Date(endDate).getTime()).toDateString()}
      </Text>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => fetchRangeData()}
      >
        <Text style={styles(theme).buttonTextStyle}>Get Range Data</Text>
      </TouchableOpacity>

      <Text style={styles(theme).subHeaderStyle}>Summary</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Registered Users:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.profilesCreated}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kards:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Institutions:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusRegApplied}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Volunteers:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusVolunteers}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Champions:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusChampions}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus Events:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusEventApplied}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>
            Kampus Institution Renewals:
          </Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.instituteRenewals}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>
            Kampus Volunteer Renewals:
          </Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.volunteerRenewals}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Ratings:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalRating}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Reported Problems:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalProblems}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Feedback Comments:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalComments}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>ContactUs Messages:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalContactUs}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Total Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.freeInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.basicInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.premiumInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.campaignInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.promoInsert}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalInsert}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Kampus Institutions</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Applied:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusRegApplied}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Approved:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusRegApproved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Rejected:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusRegRejected}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusRegApplied -
              rangeData.kampusRegApproved -
              rangeData.kampusRegRejected}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Kampus Events</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Applied:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusEventApplied}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Approved:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusEventApproved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Rejected:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusEventRejected}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Removed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusEventRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusEventApplied -
              rangeData.kampusEventApproved -
              rangeData.kampusEventRejected -
              rangeData.kampusEventRemoved}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Rating</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalRating}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Five Star:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.fiveRating}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Four Star:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.fourRating}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Three Star:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.threeRating}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Two Star:</Text>
          <Text style={styles(theme).textBoldStyle}>{rangeData.twoRating}</Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>One Star:</Text>
          <Text style={styles(theme).textBoldStyle}>{rangeData.oneRating}</Text>
        </View>
      </View>
      {rangeData.totalRating != 0 && (
        <>
          <Text style={styles(theme).subHeaderStyle}>Rating Percentage</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {(
                  (rangeData.totalRating / rangeData.totalRating) *
                  100
                ).toFixed(2)}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Five Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((rangeData.fiveRating / rangeData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Four Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((rangeData.fourRating / rangeData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Three Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {(
                  (rangeData.threeRating / rangeData.totalRating) *
                  100
                ).toFixed(2)}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Two Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((rangeData.twoRating / rangeData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>One Star:</Text>
              <Text style={styles(theme).textBoldStyle}>
                {((rangeData.oneRating / rangeData.totalRating) * 100).toFixed(
                  2
                )}
                %
              </Text>
            </View>
          </View>
        </>
      )}

      <Text style={styles(theme).subHeaderStyle}>Reported Problems</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalProblems}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Disposed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.problemsDisposed}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalProblems - rangeData.problemsDisposed}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Feedback Comments</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalComments}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Disposed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.commentsDisposed}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalComments - rangeData.commentsDisposed}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>ContactUs Messages</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalContactUs}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Disposed:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.contactUsDisposed}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Pending:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalContactUs - rangeData.contactUsDisposed}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Active Kards</Text>

      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.freeActive}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.basicActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.premiumActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.campaignActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.promoActive}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalActive}
          </Text>
        </View>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Completed Kards</Text>

      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.freeComplete}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.basicComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.premiumComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.campaignComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.promoComplete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalComplete}
          </Text>
        </View>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Pause Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>{rangeData.freePause}</Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.basicPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.premiumPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.campaignPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.promoPause}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalPause}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Deleted Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.freeDelete}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.basicDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.premiumDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.campaignDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.promoDelete}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalDelete}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Queue Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>{rangeData.freeQueue}</Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.basicQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.premiumQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.campaignQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.promoQueue}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalQueue}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Refund Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.freeRefund}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.basicRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.premiumRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.campaignRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.promoRefund}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalRefund}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>TxnPending Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.freeTxnPending}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.basicTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.premiumTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.campaignTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.promoTxnPending}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalTxnPending}
          </Text>
        </View>
      </View>

      <Text style={styles(theme).subHeaderStyle}>Removed Kards</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Free</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.freeRemoved}
          </Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Basic</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.basicRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Premium</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.premiumRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Campaign</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.campaignRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Kampus</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.kampusRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Promo</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.promoRemoved}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total</Text>
          <Text style={styles(theme).textBoldStyle}>
            {rangeData.totalRemoved}
          </Text>
        </View>
      </View>

      {(officeTeam || revenueTeam) && (
        <>
          <Text style={styles(theme).subHeaderStyle}>REVENUES</Text>
          <Text style={styles(theme).subHeaderStyle}>Summary</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.totalKardCharges + rangeData.totalRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.totalKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.totalKardTaxes + rangeData.totalRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.totalKardNet + rangeData.totalRenewalNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.totalRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹
                {rangeData.totalKardNet +
                  rangeData.totalRenewalNet -
                  rangeData.totalRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Free Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.freeKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.freeKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.freeKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.freeKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.freeRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.freeKardNet - rangeData.freeRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Basic Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.basicKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.basicKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.basicKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.basicKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.basicRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.basicKardNet - rangeData.basicRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Premium Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.premiumKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.premiumKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.premiumKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.premiumKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.premiumRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.premiumKardNet - rangeData.premiumRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Campaign Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.campaignKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.campaignKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.campaignKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.campaignKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.campaignRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.campaignKardNet - rangeData.campaignRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Kampus Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.kampusKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.kampusKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.kampusKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.kampusKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.kampusRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.kampusKardNet - rangeData.kampusRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Promo Kards</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.promoKardCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Discounts</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.promoKardDiscount}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.promoKardTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.promoKardNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.promoRefundNet}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Net Revenue</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.promoKardNet - rangeData.promoRefundNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Kampus Renewals</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.totalRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.totalRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.totalRenewalNet}
              </Text>
            </View>
          </View>

          <Text style={styles(theme).subHeaderStyle}>Institution Renewals</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.instituteRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.instituteRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.instituteRenewalNet}
              </Text>
            </View>
          </View>
          <Text style={styles(theme).subHeaderStyle}>Volunteer Renewals</Text>
          <View style={styles(theme).boxStyle}>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Charges</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.volunteerRenewalCharges}
              </Text>
            </View>

            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Taxes</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.volunteerRenewalTaxes}
              </Text>
            </View>
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Total</Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {rangeData.volunteerRenewalNet}
              </Text>
            </View>
          </View>
        </>
      )}

      {/* ************************************************************** */}

      {/* ************************************************************** */}

      {showFromDate &&
        (Platform.OS == "web" ? (
          <DatePickerModal
            mode="single"
            onDismiss={onDismissFromDate}
            date={fromDate}
            visible={true}
            onConfirm={onConfirmFromDate}
            locale={"en"}
            validRange={{
              startDate: new Date(2022, 9, 1),
              endDate: new Date(),
            }}
          />
        ) : (
          <DateTimePicker
            testID="dateTimePicker"
            value={fromDate}
            mode={"date"}
            display="spinner"
            onChange={onChangeFromDate}
            minimumDate={new Date(2022, 9, 1)}
            maximumDate={new Date(new Date().getTime())}
          />
        ))}
      {showEndDate &&
        (Platform.OS == "web" ? (
          <DatePickerModal
            mode="single"
            onDismiss={onDismissEndDate}
            date={endDate}
            visible={true}
            onConfirm={onConfirmEndDate}
            locale={"en"}
            validRange={{
              startDate: new Date(fromDate),
              endDate: new Date(),
            }}
          />
        ) : (
          <DateTimePicker
            testID="dateTimePicker"
            value={endDate}
            mode={"date"}
            display="spinner"
            onChange={onChangeEndDate}
            minimumDate={new Date(fromDate)}
            maximumDate={new Date(new Date().getTime())}
          />
        ))}
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    // may not be needed
    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },
    textBoldStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      margin: 5,
    },
    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    boxTextStyle: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    dividerStyle: {
      borderBottomColor: theme.colors.standardTextColor,
      borderBottomWidth: 2,
    },
  });

export default Dashboard;
