import React, { useContext, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";

import { Auth } from "aws-amplify";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import AwesomeAlert from "react-native-awesome-alerts";

import { ThemeContext } from "../../context/ThemeContext";
import CustomInput from "../../components/CustomInput";

const ForgotPasswordSubmit = (props) => {
  const { theme, changeTheme } = useContext(ThemeContext);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resendCode, setResendCode] = useState(false);

  const initialValues = {
    code: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    code: Yup.string().required("Reqiured"),
    password: Yup.string()
      .min(6, "Password should be minium 6 characters")
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  async function onSubmit(values, { resetForm, setFieldError }) {
    try {
      await Auth.forgotPasswordSubmit(
        props.authData.username,
        values.code,
        values.password
      );
      resetForm();
      props.onStateChange("signIn", {});
    } catch (err) {
      resetForm();
      setFieldError("general", err.message);
      setError(true);
      setErrorMessage(err.message);
    }
  }

  const resendForgotPasswordCode = async () => {
    try {
      await Auth.forgotPassword(props.authData.username);
      setResendCode(true);
    } catch (error) {
      console.log(error);
    }
  };
  if (props.authState === "forgotPasswordSubmit") {
    return (
      <ScrollView style={styles(theme).fullScreenStyle}>
        <Text style={styles(theme).headerStyle}>SET NEW PASSWORD</Text>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isValid }) => (
            <>
              <Text style={styles(theme).labelStyle}>Confirmation Code*</Text>
              <Field
                component={CustomInput}
                name="code"
                placeholder="Confirmation Code"
              />
              <Text style={styles(theme).labelStyle}> New Password*</Text>
              <Field
                component={CustomInput}
                name="password"
                placeholder="Password"
                secureTextEntry
              />
              <Text style={styles(theme).labelStyle}>
                Confirm New Password*
              </Text>
              <Field
                component={CustomInput}
                name="confirmPassword"
                placeholder="Confirm Password"
                secureTextEntry
              />

              <TouchableOpacity
                style={
                  isValid
                    ? styles(theme).buttonStyle
                    : styles(theme).disabledButtonStyle
                }
                onPress={handleSubmit}
                disabled={!isValid}
              >
                <Text style={styles(theme).buttonTextStyle}>Send</Text>
              </TouchableOpacity>
            </>
          )}
        </Formik>
        <View style={styles(theme).rowGroupSpaceBetweenStyle}>
          <TouchableOpacity onPress={() => resendForgotPasswordCode()}>
            <Text style={styles(theme).textStyle}>Resend Code</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => props.onStateChange("signIn", {})}>
            <Text style={styles(theme).textStyle}>Back to Sign In</Text>
          </TouchableOpacity>
        </View>
        <AwesomeAlert
          show={resendCode}
          title="RESEND CODE"
          message="Verification Code has been resent successfully."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="Ok, got it"
          onConfirmPressed={() => {
            setResendCode(false);
          }}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
          cancelButtonStyle={styles(theme).alertButtonStyle}
          confirmButtonStyle={styles(theme).alertButtonStyle}
          cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
          confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
        />
        <AwesomeAlert
          show={error}
          title="ERROR"
          message={errorMessage}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="Ok, got it"
          onConfirmPressed={() => {
            setError(false);
            setErrorMessage("");
          }}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
          confirmButtonStyle={styles(theme).alertButtonStyle}
          confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
        />
      </ScrollView>
    );
  } else {
    return null;
  }
};

const styles = (theme) =>
  StyleSheet.create({
    fullScreenStyle: {
      flex: 1,
      backgroundColor: "white",
      width: "100%",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },

    labelStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },

    rowGroupSpaceBetweenStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-between",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default ForgotPasswordSubmit;
