import React, { useContext } from "react";
import { StyleSheet } from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import { ThemeContext } from "../../context/ThemeContext";

import Comments from "./Comments";
import RateUs from "./RateUs";
import ReportProblem from "./ReportProblem";

const Tab = createMaterialTopTabNavigator();

const Feedback = () => {
  const { theme, changeTheme } = useContext(ThemeContext);

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarLabelStyle: styles(theme).tabTextStyle,
        tabBarStyle: styles(theme).tabBgStyle,
        tabBarIndicatorStyle: styles(theme).tabIndicatorStyle,
      }}
    >
      <Tab.Screen name="Rate Us" component={RateUs} />
      <Tab.Screen name="Report Problem" component={ReportProblem} />
      <Tab.Screen name="Comments" component={Comments} />
    </Tab.Navigator>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    tabBgStyle: {
      backgroundColor: theme.colors.backgroundColor,
    },

    tabTextStyle: {
      fontSize: 12,
      fontWeight: "bold",
      color: theme.colors.textColor,
    },
    tabIndicatorStyle: {
      backgroundColor: theme.colors.textColor,
    },
  });

export default Feedback;
