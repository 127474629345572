import React from "react";
import { Text, View } from "react-native";

function SearchKardDetails() {
  return (
    <View>
      <Text>Search Kard Details Screen</Text>
    </View>
  );
}

export default SearchKardDetails;
