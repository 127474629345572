import React, { createContext, useState, useContext } from "react";
import { Dimensions, Image, Platform } from "react-native";

import { SelectKard } from "../assets/index";
import { CurrentLocationContext } from "./CurrentLocationContext";

const SCREEN_WIDTH = Dimensions.get("screen").width;
const SCREEN_HEIGHT = Dimensions.get("screen").height;
const ASPECT_RATIO = SCREEN_WIDTH / SCREEN_HEIGHT;
const LATITUDE_DELTA = 0.0922;
const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;

// const getCurrentLocation = async () => {
//   try {
//     let loc = await Location.getCurrentPositionAsync();
//     // console.log(loc);
//     if (loc.coords.latitude) {
//       const obj = {
//         latitude: loc.coords.latitude,
//         longitude: loc.coords.longitude,
//       };
//       return obj;
//     } else {
//       const obj = {
//         latitude: 11.916064,
//         longitude: 79.812325,
//       };
//       return obj;
//     }
//   } catch (err) {
//     console.log("error in initial location function" + JSON.stringify(err));
//   }
// };

// const getCurrentLocation = async () => {
//   console.log("I am in CurrentLocationContext");
//   try {
//     let permissions = await Location.getBackgroundPermissionsAsync();
//     console.log(permissions);
//     if (permissions.status !== "granted") {
//       let loc = await Location.getLastKnownPositionAsync();
//       if (loc !== null) {
//         const obj = {
//           latitude: loc.coords.latitude,
//           longitude: loc.coords.longitude,
//         };
//         console.log("last known position available");
//         console.log(obj);
//         return obj;
//       } else {
//         const obj = {
//           latitude: 11.916064,
//           longitude: 79.812325,
//         };
//         console.log("last known position is null");
//         console.log(obj);
//         return obj;
//       }
//     } else {
//       let loc = await Location.getCurrentPositionAsync();
//       const obj = {
//         latitude: loc.coords.latitude,
//         longitude: loc.coords.longitude,
//       };
//       console.log("current position ");
//       console.log(obj);
//       return obj;
//     }
//   } catch (err) {
//     console.log("error in initial location function" + err);
//   }
// };

export const NewKardContext = createContext(null);
export default ({ children }) => {
  const { currentLocation } = useContext(CurrentLocationContext);
  const [mapRegionForNewKard, setMapRegionForNewKard] = useState({
    latitude: currentLocation.latitude,
    longitude: currentLocation.longitude,
    latitudeDelta: LATITUDE_DELTA,
    longitudeDelta: LONGITUDE_DELTA,
  });
  const noKardUri =
    Platform.OS === "web"
      ? require("../assets/selectKard.png")
      : Image.resolveAssetSource(SelectKard).uri;
  const [kardType, setKardType] = useState("");
  const [kard, setKard] = useState(noKardUri);
  const [kardSelected, setKardSelected] = useState(false);
  const [polygon, setPolygon] = useState([]);
  const [finalViews, setFinalViews] = useState(0);
  const [addressKey, setAddressKey] = useState(null);
  const [date, setDate] = useState(new Date());
  const [checked, setChecked] = useState(false);

  return (
    <NewKardContext.Provider
      value={{
        kardType,
        setKardType,
        kard,
        setKard,
        kardSelected,
        setKardSelected,
        mapRegionForNewKard,
        setMapRegionForNewKard,
        polygon,
        setPolygon,
        finalViews,
        setFinalViews,
        addressKey,
        setAddressKey,
        date,
        setDate,
        checked,
        setChecked,
      }}
    >
      {children}
    </NewKardContext.Provider>
  );
};
