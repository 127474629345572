import React, { useContext } from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { ThemeContext } from "../../context/ThemeContext";

import { Entypo } from "@expo/vector-icons";
const MenuIcon = () => {
  const navigation = useNavigation();
  const { theme, changeTheme } = useContext(ThemeContext);

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate("Settings")}
      style={styles(theme).menuIconStyle}
    >
      <Entypo name="menu" size={25} color={theme.colors.textColor} />
    </TouchableOpacity>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    menuIconStyle: {
      paddingLeft: 15,
    },
  });

export default MenuIcon;
