export const faq1 = [
  {
    question: "1. What is kabutar?",
    answer:
      "Kabutar is an app that delivers information to your area. It is a social media platform for sharing geo location based information. It is a single stop information board aka kard board for users in any area.",
  },
  {
    question: "2. What is a kard?",
    answer:
      "A kard is an image file containing any information. In this app, the information is posted and displayed as kard.",
  },
  {
    question: "3. Why should I use kabutar?",
    answer:
      "You can post invitation cards, greeting cards, messages, letters, leaflets, pamphlets, flyers, hand-outs, posters, banners, wall posters, movie posters, news, alerts, notifications, personal appeals, announcements, advertisements, classifieds or any other information targeting users in any area. You can also view kards displayed in your area or any other area. It helps you to know what’s happening or trending or most talked about in your area or any other area. It is a digital alternative for noticeboards, bill boards, hoardings, advertisements and classifieds in newspapers and magazines and other physical forms of information. It is very useful for both personal and business use. It is zero-waste, easy to use and cost effective method.",
  },
  {
    question: "4. How can I use kabutar?",
    answer:
      "Kabutar is easy to use. It can be accessed from devices with internet connectivity such as computer, laptop, tablet and mobile phone. You can post messages as kards and set where, when and how many times they should be displayed targeting your viewers. You can also view the kards in your area or any other area.",
  },
];

export const faq2 = [
  //use cases

  {
    question: "5. What are the use cases of kabutar in general?",
    answer:
      "The use cases of kabutar in general are same as that of invitation cards, greeting cards, messages, letters, leaflets, pamphlets, flyers,  hand-outs, posters, banners, wall posters, movie posters, noticeboards, bill boards, hoardings,  news, alerts, notifications, personal appeals, announcements, advertisements and classifieds etc..,",
  },
  {
    question: "6. Can I post greeting cards through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the greeting kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "7. Can I post invitation cards through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the invitation kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "8. Can I post messages through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the message kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "9. Can I post letters through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the letter kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "10. Can I post leaflets through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the leaflet kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "11. Can I post pamphlets through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the pamphlet kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },

  {
    question: "12. Can I post flyers through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the flyer kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },

  {
    question: "13. Can I post hand-outs through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the hand-out kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "14. Can I post posters through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the poster kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "15. Can I post wall posters through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the wall poster kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "16. Can I post movie posters through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the movie poster kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "17. Can I post banners through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the movie poster kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "18. Can I post news through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the news kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "19. Can I post alerts through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the alert kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "20. Can I post notifications through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the notification kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "21. Can I post personal appeals through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the appeal kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "22. Can I post public announcements through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the public announcement kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "23. Can I post advertisements through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the advertisement kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "24. Can I post information through kabutar?",
    answer:
      "Yes. This is also one of the most important use cases.  You can post the information kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question:
      "25. Can I share good ideas or promote good things through kabutar?",
    answer:
      "Yes. This is another most important use case.  You can post your ideas / promote good things as kards and set where, when and how many times it should be displayed. Kabutar delivers the kards to the users in the destination. ",
  },
  {
    question:
      "26. I want to rent my house or sell my car. Can I use Kabutar for this?",
    answer:
      "Yes. This is yet another important use case. You can post your requirement kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question:
      "27. I started a mobile accessories shop. I want to advertise it in my area. Can I use kabutar for this?",
    answer:
      "Yes. This is yet another important use case. You can post your business kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question:
      "28. I am a social worker. I am conducting a free medical check-up camp. I want to inform this to the people in that area. Can I use kabutar for this?",
    answer:
      "Yes. This is yet another important use case. You can post your service kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "29. Can I promote my business?",
    answer:
      "Yes. This is yet another important use case. You can post your business kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question: "30. What are the other business use cases?",
    answer:
      "Other business use cases include promotion of your products, services, offers, discounts, special business events, expos, exhibitions, trade fairs, shopping festivals etc.., ",
  },
  {
    question:
      "31. What are the use cases of kabutar for academic institutions?",
    answer:
      "Academic institutions can use kabutar as a single stop notice board. They can notify admissions, examination schedules, results, various college events such as sports events, cultural events, seminars and webinars etc..,. They can spread ideas and promote their academic activities. They can advertise about their institution. We have kabutar kampus promotion program exclusively for academic institutions.  They can convey greetings to their teachers, students, colleagues, friends, family members or even neighbours. Refer Kabutar Kampus section for more details. ",
  },
  {
    question:
      "32. I am representative of an academic institution. Can I use kabutar to notify admissions, recruitments, tenders and other requirements?",
    answer:
      "Yes. You can post your admissions, recruitments, tenders and other requirements kards and set where, when and how many times it should be displayed.  Kabutar delivers the kards to the users in the destination.",
  },
  {
    question: "33. What are the use cases of kabutar for academicians?",
    answer:
      "Academicians can use kabutar to convey greetings to their students, colleagues, friends, family members or even neighbours. They can also use kabutar to spread ideas and events happening in their institutions. More importantly, they can use kabutar to promote their academic activities.",
  },
  {
    question:
      "34. I am an academician. I am conducting a seminar. I want to invite academicians from other institution(s). Can I use kabutar for this?",
    answer:
      "Yes. This is yet another important use case. You can post invitation kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question:
      "35. I am a teacher and take coaching classes and tuitions. I want to inform this to the people in that area.  Can I use kabutar for this?",
    answer:
      "Yes. This is yet another important use case. You can post your service kard and set where, when and how many times it should be displayed. Kabutar delivers the kard to the users in the destination.",
  },
  {
    question:
      "36. What are the use cases of kabutar for students, teachers, parents, managers and other stakeholders?",
    answer:
      "Students, teachers, parents, managers and other stakeholders can use kabutar to know about admissions, examination schedules, results, campus interviews, college events in their own institution as well as other institutions. They can also use kabutar to convey greetings to their friends, teachers, family members or even neighbours.",
  },
  {
    question:
      "37. I am a student studying in college A. Can I know what is happening in my college?",
    answer:
      "Yes. You can view the kards displayed in your college that are posted by other students, your teachers or your institution itself to know what is happening your college.",
  },
  {
    question:
      "38. I am a student studying in college A. Can I know what is happening in college B?",
    answer:
      "Yes. You can view the kards displayed in college B that are posted by their students, their teachers or college B itself to know what is happening there.",
  },
];

export const faq3 = [
  {
    question: "39. What are initial settings for a kard?",
    answer:
      "The initial settings determine where, when and how many times the kard is displayed. The area setting determines where the kard is displayed. The views setting determine how many times the kard is displayed. The start time and date setting determines from when the kard is displayed.",
  },

  {
    question: "40. What are different types of kards?",
    answer: `There are six different types of kards. 
      1. Free kards 
      2. Basic kards 
      3. Premium kards 
      4. Campaign kards 
      5. Kampus kards and 
      6. Promo kards.  
      Kampus kards and promo kards are only available for academic institutions under Kampus  Program.
      `,
  },

  {
    question: "41. What are the charges for different types of kards?",
    answer:
      "There are no charges for free, kampus and promo kards. However basic, premium, campaign kards are charged @ ₹ 0.1 per view. ",
  },

  {
    question: "42. What is the view range for different types of kards?",
    answer: `1.	Free kards - 1 to 100, 
      2.	Basic kards - 101 to 10000, 
      3.  Premium kards - 10001 to 100000, 
      4.  Campaign kards - 100001 to 10000000, 
      5.  Kampus kards - 10 times number of volunteers registered, 
      6.  Promo kards - 1 to 100000.
      `,
  },

  {
    question: "43. What is advance post time for different types of kards?",
    answer: `1.	Free kards - 7 days,
      2.	Basic kards - 30 days, 
      3.	Premium kards - 60 days,  
      4.	Campaign kards - 90 days, 
      5.	Kampus kards - 7 days, 
      6.	Promo kards - 90 days.
      `,
  },
  {
    question: `44. Can I delete a kard after it is posted?`,
    answer: "Yes. This feature is available for all types of kards. ",
  },
  {
    question: `45. Can I pause and activate a kard for display after it is posted?`,
    answer:
      "Yes. This feature is available for basic, premium, campaign, kampus and promo kards. It is not available for free kards. ",
  },

  {
    question: `46. Can I change kard after it is posted?`,

    answer:
      "Yes. This feature is available for premium and campaign kards. It is not available for free, basic, kampus and promo kards.",
  },
  {
    question: `47. Can I change display area after the kard is posted?`,
    answer:
      "Yes. This feature is available for campaign kards. It is not available for free, basic, premium, kampus and promo kards.",
  },
  {
    question: `48. Can I get refund after the kard is posted?`,
    answer:
      "Yes. This feature is available for basic, premium and campaign kards. It is not available for free, kampus and promo kards as they are free. ",
  },
];

export const faq4 = [
  {
    question: "49. How can I view kards?",
    answer:
      "You can view kards at our website - https://www.kabutarapp.com or in kabutar mobile / web app.",
  },
  {
    question: "50. Do I have to pay for viewing the kards?  ",
    answer: "No. It is absolutely free.",
  },
  {
    question:
      "51. Do I have to sign in / sign up /register for viewing the kards?",
    answer: "No. Unregistered users can also view the kards.",
  },
  {
    question: "52. Can I view kards in my mobile?",
    answer:
      "Yes. You have to visit our website - https://www.kabutarapp.com from your mobile browser or download kabutar app in your mobile from your browser or  our website. ",
  },

  {
    question: "53. Can I view kards on my desktop or laptop?",
    answer:
      "Yes. You have to visit our website - https://www.kabutarapp.com from your desktop or laptop browser or download kabutar web app in your desktop or laptop from your browser or our website. ",
  },
  {
    question: "54. How is the location determined?",
    answer: "The location is determined by your current geo location.",
  },
  {
    question:
      "55. I am now at location A. Can I view kards at location B without going there?",
    answer:
      "Yes. Simply change your location to B. You can view kards displayed in any area from any area by changing your location.",
  },
];

export const faq5 = [
  {
    question: "56. Okay. I am ready to view the kards. But who will post them?",
    answer:
      "Anyone can post. You can also post kards for others to view. We have given some use cases above for guidance.",
  },

  {
    question: "57. How can I post kards?",
    answer:
      "You can post kards using Post New Kard feature either on our website https://www.kabutarapp.com or from your mobile / web app. ",
  },

  {
    question:
      "58. Do I have to sign in / sign up /register for posting the kards? ",
    answer: "Yes.",
  },

  {
    question: "59. Can I post kards from my mobile?",
    answer:
      "Yes. You can post kards from Post New Kard feature either on our website https://www.kabutarapp.com or from your mobile / web app.",
  },

  {
    question: "60. Can I post kards from my desktop or laptop? ",
    answer:
      "Yes. You can post kards from Post New Kard Screen either on our website https://www.kabutarapp.com or from your mobile / web app.",
  },
  {
    question: "61. Do I have to add address to post kard?  ",
    answer: "Yes, it is compulsory. ",
  },
  {
    question:
      "62. What happens if I don't choose any settings while posting a kard? ",
    answer:
      "For all types of kards, the area setting is compulsory to post. However if number of views and start date and time settings are not set, the default settings are applied.",
  },
  {
    question:
      "63. What is the default setting for views for different types of kards? ",
    answer: `The default number of views setting is: 
      1.	Free kards - 50, 
      2.	Basic kards - 5000, 
      3.	Premium kards -50000, 
      4.	Campaign kards -500000,
      5.	Kampus kards – 50,
      6.	Promo kards – 1000.
      `,
  },
  {
    question:
      "64. What is the default setting for start date and time for different types of kards?  ",
    answer: "For all types, it is date and time of posting.",
  },
];

export const faq6 = [
  {
    question: "65. After posting, how much time it takes to display the kards?",
    answer:
      "It is displayed immediately. However, you can set the starting date and time of display.",
  },
  {
    question:
      "66. I want to display a kard from a particular date. Before how many days, should I post? ",
    answer:
      "There is no minimum time requirement. Kards are displayed immediately after they are posted.  ",
  },
  {
    question:
      "67. I want to display a free kard from a particular date and I want to post it before. When can I post?",
    answer: "You can post any time before 7 days.",
  },
  {
    question:
      "68. I want to display a basic kard from a particular date and I want to post it before.  When can I post?",
    answer: "You can post any time before 30 days.",
  },
  {
    question:
      "69. I want to display a premium kard from a particular date and I want to post it before.  When can I post?",
    answer: "You can post any time before 60 days.",
  },
  {
    question:
      "70. I want to display a campaign kard from a particular date and I want to post it before.  When can I post?",
    answer: "You can post any time before 90 days.",
  },
];

export const faq7 = [
  {
    question: "71. What is kabutar kampus program?",
    answer: `Kabutar has many use cases for students, teachers, parents, managers and other stakeholders of educational institutions. Educational institutions can use kabutar as a single stop notice board. They can notify admissions, examination schedules, results, various college events such as sports events, cultural events, seminars and webinars etc..,. They can spread ideas and promote their academic activities. They can advertise about their institution. They can convey greetings to their teachers, students, colleagues, friends, family members or even neighbours.

      The students, teachers, parents, managers and other stakeholders can know about the activities in their school / college and any school / college in the world. 
      
      Educational institutions can also use kabutar to maintain their campus free of posters, banners, pamphlets, hand-outs, leaflets, flyers and advertisements. Such clean and tidy academic campuses leave everlasting pleasant memories in the minds of the students throughout their life apart from enhancing their academic achievement during campus days. 
      KAbutar caMPUS – for short KAMPUS program encourages the students, teachers, parents, managers and other stakeholders to use kabutar app with cash discounts and appreciation awards.
      
      Only the educational institutions alone are eligible to apply to this program. Other organisations and individuals are not eligible.  
      
      Refer to kabutar kampus information brochure for more details.
      `,
  },
  {
    question:
      "72. Is kabutar kampus program exclusively for academic institutions?",
    answer: "Yes.",
  },
  {
    question:
      "73. What are the incentives for academic institutions under kabutar kampus program?",
    answer:
      "The incentives are detailed in kabutar kampus information brochure.",
  },
  {
    question:
      "74. Who can be registered as a volunteer under kabutar kampus program?",
    answer:
      "Students, teachers, staff, parents, managers and all other stakeholders of an academic institution can be registered as volunteers. Other details are given in kabutar kampus information brochure.",
  },
  {
    question:
      "75. I am a volunteer under kabutar kampus program. Do I get any discount to post basic, premium and campaign  kards?",
    answer:
      "Yes. More details are given in kabutar kampus information brochure.",
  },
];
export const faq8 = [
  {
    question: "76. Is sign up process simple?",
    answer:
      "Yes, it is very simple. You can sign up with just your email / mobile number and password. No further details are asked.",
  },
  {
    question: "77. Can I signin / signup with social logins? ",
    answer: "Yes.",
  },
  {
    question: "78. Can I signin / signup with email from all countries",
    answer: "Yes.",
  },
  {
    question: "79. Can I signin / signup with mobile number from all countries",
    answer:
      "No, in some countries (US etc..,) OTP for verification cannot be delivered using SMS. So instead you will need to signin / signup using email",
  },
  {
    question: "80. What social logins are available?",
    answer:
      "Currently, social login with Google, Facebook, Amazon are available.",
  },
  {
    question: "81. Can I login in on two devices at the same time? ",
    answer: "Yes.",
  },
];

export const faq9 = [
  {
    question: "82. Do I have to pay for viewing the kards?",
    answer: "No. It is absolutely free",
  },
  {
    question: "83. Do I have to pay for posting the free kards?",
    answer: "No. It is absolutely free",
  },
  {
    question:
      "84. Do I have to pay for posting the basic/premium /campaign kards?",
    answer:
      "Yes. The basic/premium /campaign kards are charged @ ₹ 0.1 per view.",
  },
];
export const faq10 = [
  {
    question: "85. Can I get refund ?",
    answer: "Yes.",
  },
  {
    question: "86. Is the refund process simple ?",
    answer: "Yes.",
  },
  {
    question: "87. How can I get refund ?",
    answer: `You can get refund by clicking Refund button in My Kards > Current Kards> Kard Details. `,
  },
  {
    question: "88. Explain how  the refund amount is calculated with examples?",
    answer: `The refund amount is calculated as 90% of amount paid without taxes minus the cost of already displayed views and it should be at least ₹ 100.00. The 10% of the amount paid without taxes is deducted for provisioning of IT and non IT resources. The amount paid as taxes is also not refunded.
    Example 1: You have posted a basic kard with 5000 views. You have not availed any concession. You have requested for refund after your kard has reached 3000 displayed views. The total amount paid is ₹ 590.00 (Charges for 5000 views is ₹ 500.00, Discount is ₹ 0.0 and Taxes is ₹ 90.00). The 90% of amount paid without taxes is ₹ 450.00 (90% of ₹ 500.00). The cost of already displayed views is ₹ 300.00 (3000 multiplied by ₹ 0.10).  Therefore the refund amount is ₹ 150.00 (₹ 450.00 minus ₹ 300.00).
    
    Example 2: You have posted a basic kard with 5000 views. You have not availed any concession. You have requested for refund after your kard has reached 3500 displayed views. The total amount paid is ₹ 590.00 (Charges for 5000 views is ₹ 500.00, Discount is ₹ 0.0 and Taxes is ₹ 90.00). The 90% of amount paid without taxes is ₹ 450.00 (90% of ₹ 500.00). The cost of already displayed views is ₹ 350.00 (3500 multiplied by ₹ 0.10).  Therefore the refund amount is ₹ 100.00 (₹ 450.00 minus ₹ 350.00).  
    
    Example 3: You have posted a basic kard with 5000 views. You have not availed any concession. You have requested for refund after your kard has reached 3501 displayed views. The total amount paid is ₹ 590.00 (Charges for 5000 views is ₹ 500.00, Discount is ₹ 0.0 and Taxes is ₹ 90.00). The 90% of amount paid without taxes is ₹ 450.00 (90% of ₹ 500.00). The cost of already displayed views is ₹ 350.10 (3501 multiplied by ₹ 0.10).  Therefore the refund amount is ₹ 99.90 (₹ 450.00 minus ₹ 350.00). Since the refund amount is less than ₹ 100.00, it is not eligible for refund. 
    
    Example 4: You have posted a basic kard with 5000 views. You have availed 20 % discount of ₹ 100.00. You have requested for refund after your kard has reached 2100 displayed views. The total amount paid is ₹ 472.00 (Charges for 5000 views is ₹ 500.00, Discount is ₹ 100.0 and Taxes is ₹ 72.00). The 90% of amount paid without taxes is ₹ 360.00 (90% of ₹ 400.00). The cost of already displayed views is ₹ 210.00 (2100 multiplied by ₹ 0.10).  Therefore the refund amount is ₹ 150.00 (₹ 360.00 minus ₹ 210.00). 
    
    Example 5: You have posted a basic kard with 5000 views. You have availed 20 % discount of ₹ 100.00. You have requested for refund after your kard has reached 2600 displayed views. The total amount paid is ₹ 472.00 (Charges for 5000 views is ₹ 500.00, Discount is ₹ 100.0 and Taxes is ₹ 72.00). The 90% of amount paid without taxes is ₹ 360.00 (90% of ₹ 400.00). The cost of already displayed views is ₹ 260.00 (2600 multiplied by ₹ 0.10).  Therefore the refund amount is ₹ 100.00 (₹ 360.00 minus ₹ 260.00). 
    
    Example 6: You have posted a basic kard with 5000 views. You have availed 20 % discount of ₹ 100.00. You have requested for refund after your kard has reached 2601 displayed views. The total amount paid is ₹ 472.00 (Charges for 5000 views is ₹ 500.00, Discount is ₹ 100.0 and Taxes is ₹ 72.00). The 90% of amount paid without taxes is ₹ 360.00 (90% of ₹ 400.00). The cost of already displayed views is ₹ 260.10 (2601 multiplied by ₹ 0.10).  Therefore the refund amount is ₹ 99.90 (₹ 360.00 minus ₹ 260.10). Since the refund amount is less than ₹ 100.00, it is not eligible for refund.
    `,
  },
];
export const faq11 = [
  {
    question: "89. What is the maximum size for a kard?",
    answer: "500 KB",
  },
  {
    question: "90. What are the supported formats?",
    answer: "JPEG /JPG, PNG, WEBP  and SVG formats are supported.",
  },
  {
    question: "91. What should be the pixel resolution?",
    answer:
      "The pixel resolution less than 900 in width and 1200 in height are recommended. If the image is higher/wider it is automatically compressed.",
  },
  {
    question: "92. Does the app require internet?",
    answer: "Yes.",
  },
  {
    question:
      "93. Are  the mobile / web  apps and website available worldwide? ",
    answer: "Yes.",
  },
  {
    question: "94. Does the app require any permission?  ",
    answer:
      "Not compulsorily, but if you give location permission, kards in your area are displayed when you open the app.",
  },
  {
    question: "95. What if I do not give location permission?",
    answer:
      "If you do not give location permission, kards in the default area (India Gate, New Delhi) are displayed when you open the app. However you can change the display area using My Location feature.",
  },
  {
    question: "96. When I view the kards, are they saved in my mobile?",
    answer: "No.",
  },
  {
    question: "97. Can I download and share the kards?",
    answer: "Yes, you can download and share the kards.",
  },
  {
    question: "98. Does my location change if I'm using a vpn?  ",
    answer:
      "No, your location is based on the location you set in My Location. ",
  },
];

export const faq12 = [
  {
    question:
      "99. Do I have to sign in / sign up / register to give feedback or rating or report bugs and glitches?",
    answer: "No.",
  },
  {
    question: "100. Where can I give feedback?",
    answer: "You can give feedback  in the feedback section.",
  },
  {
    question: "101. Where can I report bugs and glitches?",
    answer: "You can report bugs and glitches in the feedback section.",
  },
  {
    question: "102. Where can I rate the appl or website?",
    answer: "You can rate the app or website in the feedback section.",
  },
];

export const faq13 = [
  {
    question: "103. How many themes are available?",
    answer:
      "There are total 74 themes. 37 are outline themes and 37 are fill themes.",
  },
  {
    question: "104. Will the app slow down if I change the theme frequently?",
    answer: "No, you can change the theme as many times as you like.",
  },
  {
    question: "105. Will the app break if I change the theme frequently?",
    answer: "No, you can change the theme as many times as you like.",
  },
  {
    question: "106. Can I add my own theme?",
    answer: "Currently, no.",
  },
];

export const faq14 = [
  {
    question:
      "107. How can I download kabutar app in my mobile or desktop or laptop?",
    answer: `When you visit https://www.kabutarapp.com from your mobile or desktop or laptop browser for the first time, the app can be downloaded with the browser enabled Add to Home Screen prompt. Otherwise, click on three dots on the top right corner and install the app. You may also refer to other material in the internet about installing progressive web apps (PWAs).
      Alternatively, you can use the Install button on the Home page to download the app in any device.
      `,
  },
  {
    question: "108. Why should I download kabutar app?",
    answer:
      "If you download the app, the website url https://www.kabutarapp.com is saved on your device.  The app logo appears on your device and can be used to open the app. Otherwise, you have to remember the website url https://www.kabutarapp.com to visit the website.",
  },
];
