import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ThemeContext } from "../context/ThemeContext";

const EditDeleteIcon = ({ editCallback, deleteCallback }) => {
  const { theme, changeTheme } = useContext(ThemeContext);
  return (
    <View style={styles(theme).screenStyle}>
      {theme.type == "fill" ? (
        <View style={styles(theme).iconViewStyle}>
          <TouchableOpacity onPress={editCallback}>
            <MaterialCommunityIcons
              name="pencil-circle"
              size={50}
              color={theme.colors.backgroundColor}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={deleteCallback}>
            <MaterialCommunityIcons
              name="delete-circle"
              size={50}
              color={theme.colors.backgroundColor}
            />
          </TouchableOpacity>
        </View>
      ) : (
        <View style={styles(theme).iconViewStyle}>
          <TouchableOpacity onPress={editCallback}>
            <MaterialCommunityIcons
              name="pencil-circle-outline"
              size={50}
              color={theme.colors.textColor}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={deleteCallback}>
            <MaterialCommunityIcons
              name="delete-circle-outline"
              size={50}
              color={theme.colors.textColor}
            />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    iconViewStyle: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
  });

export default EditDeleteIcon;
