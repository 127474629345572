import React, { useContext, useState } from "react";
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { API } from "aws-amplify";
import { AntDesign } from "@expo/vector-icons";
import AwesomeAlert from "react-native-awesome-alerts";
import { useNavigation } from "@react-navigation/native";

import * as mutations from "../../../src/graphql/mutations";
import { ThemeContext } from "../../../context/ThemeContext";
import { ProfileContext } from "../../../context/ProfileContext";

import { KampusRegistrationContext } from "../../../context/KampusRegistrationContext";

const KampusRegistration = () => {
  const navigation = useNavigation();

  const { theme, changeTheme } = useContext(ThemeContext);
  const { profile, setProfile } = useContext(ProfileContext);
  const { kampusLocation, polygon, setPolygon } = useContext(
    KampusRegistrationContext
  );

  const [markInstitute, setMarkInstitute] = useState(false);
  const [checkboxSelected, setCheckboxSelected] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);
  const [postError, setPostError] = useState(false);

  const convertPolygonToDisplayAreaJSONString = () => {
    var displayAreaObject = {
      type: "polygon",
      coordinates: [[]],
    };

    polygon.map((item, key) => {
      var newItem =
        Platform.OS == "web"
          ? [Number(item.lng.toFixed(5)), Number(item.lat.toFixed(5))]
          : [
              Number(item.longitude.toFixed(5)),
              Number(item.latitude.toFixed(5)),
            ];
      displayAreaObject.coordinates[0].push(newItem);
    });

    var firstItem =
      Platform.OS == "web"
        ? [Number(polygon[0].lng.toFixed(5)), Number(polygon[0].lat.toFixed(5))]
        : [
            Number(polygon[0].longitude.toFixed(5)),
            Number(polygon[0].latitude.toFixed(5)),
          ];

    displayAreaObject.coordinates[0].push(firstItem);
    const displayAreaJSONString = JSON.stringify(displayAreaObject);
    return displayAreaJSONString;
  };

  const registerKampus = async () => {
    const displayAreaJSONString = convertPolygonToDisplayAreaJSONString();
    const locationJSONString = JSON.stringify([
      kampusLocation.latitude.toString().substring(0, 7),
      kampusLocation.longitude.toString().substring(0, 7),
    ]);

    try {
      // create kampus Registration
      const newRegistration = await API.graphql({
        query: mutations.createKampusRegistration,
        variables: {
          input: {
            id: profile.id,
            requestDate: new Date().toISOString(),
            kampusLocation: locationJSONString,
            displayArea: displayAreaJSONString,
            status: "pending",
            volunteerCount: 0,
            championshipDate: "nil",
            address: profile.address[0],
          },
        },
      });
      // update kampusStatus
      const updateKampusStatus = await API.graphql({
        query: mutations.updateProfile,
        variables: {
          input: {
            id: profile.id,
            kampusStatus: 2,
          },
        },
      });

      setProfile(updateKampusStatus.data.updateProfile);
      setPolygon([]);
      setPostSuccess(true);
    } catch (err) {
      setPostError(true);
      console.log(err);
    }
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>KAMPUS REGISTRATION</Text>

      <Text style={styles(theme).labelStyle}>
        Institution Name: {profile.name}
      </Text>

      <Text style={styles(theme).labelStyle}>
        Email / Phone: {profile.username}
      </Text>

      {profile.address && (
        <Text style={styles(theme).labelStyle}>
          Address: {profile.address[0].name}, {profile.address[0].line1},{" "}
          {profile.address[0].line2}, {profile.address[0].line3},{" "}
          {profile.address[0].region}, {profile.address[0].country} -{" "}
          {profile.address[0].zip}
        </Text>
      )}

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          {
            setMarkInstitute(true);
            navigation.navigate("MarkInstitution");
          }
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>
          Mark your instituion location on map
        </Text>
      </TouchableOpacity>

      {markInstitute ? (
        <>
          <Text style={styles(theme).textCenterStyle}>Institution marked.</Text>
          <Text style={styles(theme).textCenterStyle}>
            Latitude - {kampusLocation.latitude.toString().substring(0, 7)},
          </Text>
          <Text style={styles(theme).textCenterStyle}>
            Longitude - {kampusLocation.longitude.toString().substring(0, 7)}
          </Text>
        </>
      ) : (
        <Text style={styles(theme).textCenterStyle}>
          Institution location is not marked.
        </Text>
      )}

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          navigation.navigate("MarkBoundaries");
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>
          Mark institution boundaries on map
        </Text>
      </TouchableOpacity>

      {polygon.length < 3 ? (
        <Text style={styles(theme).textCenterStyle}>
          Institution boundaries are not marked
        </Text>
      ) : (
        <Text style={styles(theme).textCenterStyle}>
          Institution boundaries are marked with {polygon.length} sides
        </Text>
      )}

      <View style={styles(theme).termsViewStyle}>
        <TouchableOpacity
          onPress={() => setCheckboxSelected(!checkboxSelected)}
        >
          <AntDesign
            name={
              theme.type == "fill"
                ? !checkboxSelected
                  ? "closecircle"
                  : "checkcircle"
                : !checkboxSelected
                ? "closecircleo"
                : "checkcircleo"
            }
            size={50}
            color={!checkboxSelected ? "red" : theme.colors.standardTextColor}
          />
        </TouchableOpacity>

        <Text
          style={{
            flex: 1,
            flexWrap: "wrap",
            color:
              theme.type == "fill"
                ? !checkboxSelected
                  ? "red"
                  : theme.colors.standardTextColor
                : !checkboxSelected
                ? "red"
                : theme.colors.standardTextColor,
            paddingLeft: 20,
            textAlign: "justify",
          }}
        >
          <Text>
            Certified that I am the authorized representative of the educational
            institution and the information given above is correct. I have read
            the terms and conditions and hearby apply for kampus registration.
          </Text>
        </Text>
      </View>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          setCheckboxSelected(false);
          navigation.navigate("KampusHome");
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Back</Text>
      </TouchableOpacity>

      <TouchableOpacity
        disabled={!(markInstitute && polygon.length >= 3 && checkboxSelected)}
        style={
          markInstitute && polygon.length >= 3 && checkboxSelected
            ? styles(theme).buttonStyle
            : styles(theme).disabledButtonStyle
        }
        onPress={() => {
          registerKampus();
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Register</Text>
      </TouchableOpacity>

      <AwesomeAlert
        show={postSuccess}
        title="SUCCESS"
        message={
          "Kampus registration application is successfully submitted. The registration will be approved only after succesfull verification. It is suggested to ensure that the location and boundaries of the institution are available in google maps for easy verification. You will be contacted via email/phone for any clarifications"
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, thank you"
        onConfirmPressed={() => {
          setPostSuccess(false);
          navigation.navigate("KampusHome");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={postError}
        title="ERROR"
        message="There was an unexpected error. Please try again after some time."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setPostError(false);
          navigation.navigate("KampusHome");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },

    labelStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },

    termsViewStyle: {
      width: "80%",
      flexDirection: "row",
      alignSelf: "center",
      alignItems: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default KampusRegistration;
