import React, { useEffect, useState, useContext } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { API } from "aws-amplify";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import * as queries from "../../../src/graphql/queries";
import * as mutations from "../../../src/graphql/mutations";
import { ThemeContext } from "../../../context/ThemeContext";
const PendingComments = () => {
  const [pendingComments, setPendingComments] = useState([]);
  const { theme, changeTheme } = useContext(ThemeContext);
  useEffect(() => {
    getPendingComments();
  }, []);
  const getPendingComments = async () => {
    var itemArray = [];
    try {
      const comments = await API.graphql({
        query: queries.listFeedbackByTypeAndStatus,
        variables: {
          type: "comment",
          status: { eq: "pending" },
        },
      });
      if (comments.data.listFeedbackByTypeAndStatus.items.length != 0) {
        comments.data.listFeedbackByTypeAndStatus.items.map((item) => {
          itemArray.push(item);
        });
      }

      setPendingComments(itemArray);
    } catch (err) {
      console.log(err);
    }
  };
  const closeComment = async (item) => {
    try {
      const deleteComment = await API.graphql({
        query: mutations.deleteFeedback,
        variables: {
          input: {
            id: item.id,
          },
        },
      });

      getPendingComments();
    } catch (err) {
      console.log(err);
    }
  };
  return pendingComments.length != 0 ? (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>PENDING COMMENTS</Text>

        <TouchableOpacity onPress={() => getPendingComments()}>
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      {pendingComments.map((item, key) => {
        return (
          <View key={key} style={styles(theme).boxStyle}>
            <Text style={styles(theme).textJustifyStyle}>
              Email: {item.email}
            </Text>

            <Text style={styles(theme).textJustifyStyle}>
              id: {item.id} , Request Date:{" "}
              {new Date(item.requestDate).toLocaleDateString()}
            </Text>
            <Text style={styles(theme).textJustifyStyle}>
              Comment: {item.message}
            </Text>

            <View style={styles(theme).headerViewStyle}>
              <TouchableOpacity onPress={() => closeComment(item)}>
                <MaterialCommunityIcons
                  name={
                    theme.type == "fill"
                      ? "delete-circle"
                      : "delete-circle-outline"
                  }
                  size={50}
                  color={theme.colors.standardTextColor}
                />
              </TouchableOpacity>
            </View>
          </View>
        );
      })}
    </ScrollView>
  ) : (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>PENDING COMMENTS</Text>

        <TouchableOpacity onPress={() => getPendingComments()}>
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <View style={styles(theme).rowGroupCenterStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          You do not have any pending comments to process
        </Text>
      </View>
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },
  });

export default PendingComments;
