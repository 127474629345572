/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const incrementViews = /* GraphQL */ `
  mutation IncrementViews($input: incrementInput!) {
    incrementViews(input: $input) {
      id
      userId
      username
      imageName
      displayArea
      kardType
      kardStatus
      transactionId
      transactionDate
      billingAddress {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      charges
      discount
      taxes
      total
      setViews
      shownViews
      likes
      dislikes
      downloads
      flags
      changeKard
      changeDisplayArea
      startDate
      endDate
      expiryDate
      refundAmount
      refundTxnDate
      refundTxnId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const incrementLikes = /* GraphQL */ `
  mutation IncrementLikes($input: incrementInput!) {
    incrementLikes(input: $input) {
      id
      userId
      username
      imageName
      displayArea
      kardType
      kardStatus
      transactionId
      transactionDate
      billingAddress {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      charges
      discount
      taxes
      total
      setViews
      shownViews
      likes
      dislikes
      downloads
      flags
      changeKard
      changeDisplayArea
      startDate
      endDate
      expiryDate
      refundAmount
      refundTxnDate
      refundTxnId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const incrementDislikes = /* GraphQL */ `
  mutation IncrementDislikes($input: incrementInput!) {
    incrementDislikes(input: $input) {
      id
      userId
      username
      imageName
      displayArea
      kardType
      kardStatus
      transactionId
      transactionDate
      billingAddress {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      charges
      discount
      taxes
      total
      setViews
      shownViews
      likes
      dislikes
      downloads
      flags
      changeKard
      changeDisplayArea
      startDate
      endDate
      expiryDate
      refundAmount
      refundTxnDate
      refundTxnId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const incrementFlags = /* GraphQL */ `
  mutation IncrementFlags($input: incrementInput!) {
    incrementFlags(input: $input) {
      id
      userId
      username
      imageName
      displayArea
      kardType
      kardStatus
      transactionId
      transactionDate
      billingAddress {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      charges
      discount
      taxes
      total
      setViews
      shownViews
      likes
      dislikes
      downloads
      flags
      changeKard
      changeDisplayArea
      startDate
      endDate
      expiryDate
      refundAmount
      refundTxnDate
      refundTxnId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const incrementDownloads = /* GraphQL */ `
  mutation IncrementDownloads($input: incrementInput!) {
    incrementDownloads(input: $input) {
      id
      userId
      username
      imageName
      displayArea
      kardType
      kardStatus
      transactionId
      transactionDate
      billingAddress {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      charges
      discount
      taxes
      total
      setViews
      shownViews
      likes
      dislikes
      downloads
      flags
      changeKard
      changeDisplayArea
      startDate
      endDate
      expiryDate
      refundAmount
      refundTxnDate
      refundTxnId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const removeKard = /* GraphQL */ `
  mutation RemoveKard($input: removeKardInput!) {
    removeKard(input: $input) {
      status
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      username
      name
      address {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      avatar
      kampusStatus
      kampusStartDate
      kampusEndDate
      kampusRegistration {
        id
        requestDate
        kampusLocation
        displayArea
        status
        volunteerCount
        championshipDate
        expiryDate
        profile {
          id
          username
          name
          avatar
          kampusStatus
          kampusStartDate
          kampusEndDate
          createdAt
          updatedAt
          profileKampusRegistrationId
          owner
        }
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        createdAt
        updatedAt
        owner
      }
      kampusEvent {
        items {
          id
          requestDate
          imageName
          displayArea
          title
          name
          description
          prizes
          schedule
          views
          startDate
          status
          message
          createdAt
          updatedAt
          profileKampusEventId
          owner
        }
        nextToken
      }
      kampusRenewal {
        items {
          id
          kampusStatus
          transactionId
          transactionDate
          renewalCharges
          renewalTaxes
          renewalAmount
          createdAt
          updatedAt
          profileKampusRenewalId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      profileKampusRegistrationId
      owner
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      username
      name
      address {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      avatar
      kampusStatus
      kampusStartDate
      kampusEndDate
      kampusRegistration {
        id
        requestDate
        kampusLocation
        displayArea
        status
        volunteerCount
        championshipDate
        expiryDate
        profile {
          id
          username
          name
          avatar
          kampusStatus
          kampusStartDate
          kampusEndDate
          createdAt
          updatedAt
          profileKampusRegistrationId
          owner
        }
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        createdAt
        updatedAt
        owner
      }
      kampusEvent {
        items {
          id
          requestDate
          imageName
          displayArea
          title
          name
          description
          prizes
          schedule
          views
          startDate
          status
          message
          createdAt
          updatedAt
          profileKampusEventId
          owner
        }
        nextToken
      }
      kampusRenewal {
        items {
          id
          kampusStatus
          transactionId
          transactionDate
          renewalCharges
          renewalTaxes
          renewalAmount
          createdAt
          updatedAt
          profileKampusRenewalId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      profileKampusRegistrationId
      owner
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      username
      name
      address {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      avatar
      kampusStatus
      kampusStartDate
      kampusEndDate
      kampusRegistration {
        id
        requestDate
        kampusLocation
        displayArea
        status
        volunteerCount
        championshipDate
        expiryDate
        profile {
          id
          username
          name
          avatar
          kampusStatus
          kampusStartDate
          kampusEndDate
          createdAt
          updatedAt
          profileKampusRegistrationId
          owner
        }
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        createdAt
        updatedAt
        owner
      }
      kampusEvent {
        items {
          id
          requestDate
          imageName
          displayArea
          title
          name
          description
          prizes
          schedule
          views
          startDate
          status
          message
          createdAt
          updatedAt
          profileKampusEventId
          owner
        }
        nextToken
      }
      kampusRenewal {
        items {
          id
          kampusStatus
          transactionId
          transactionDate
          renewalCharges
          renewalTaxes
          renewalAmount
          createdAt
          updatedAt
          profileKampusRenewalId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      profileKampusRegistrationId
      owner
    }
  }
`;
export const createKampusRegistration = /* GraphQL */ `
  mutation CreateKampusRegistration(
    $input: CreateKampusRegistrationInput!
    $condition: ModelKampusRegistrationConditionInput
  ) {
    createKampusRegistration(input: $input, condition: $condition) {
      id
      requestDate
      kampusLocation
      displayArea
      status
      volunteerCount
      championshipDate
      expiryDate
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      address {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateKampusRegistration = /* GraphQL */ `
  mutation UpdateKampusRegistration(
    $input: UpdateKampusRegistrationInput!
    $condition: ModelKampusRegistrationConditionInput
  ) {
    updateKampusRegistration(input: $input, condition: $condition) {
      id
      requestDate
      kampusLocation
      displayArea
      status
      volunteerCount
      championshipDate
      expiryDate
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      address {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteKampusRegistration = /* GraphQL */ `
  mutation DeleteKampusRegistration(
    $input: DeleteKampusRegistrationInput!
    $condition: ModelKampusRegistrationConditionInput
  ) {
    deleteKampusRegistration(input: $input, condition: $condition) {
      id
      requestDate
      kampusLocation
      displayArea
      status
      volunteerCount
      championshipDate
      expiryDate
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      address {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createKampusEvent = /* GraphQL */ `
  mutation CreateKampusEvent(
    $input: CreateKampusEventInput!
    $condition: ModelKampusEventConditionInput
  ) {
    createKampusEvent(input: $input, condition: $condition) {
      id
      requestDate
      imageName
      displayArea
      title
      name
      description
      prizes
      schedule
      views
      startDate
      status
      message
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      createdAt
      updatedAt
      profileKampusEventId
      owner
    }
  }
`;
export const updateKampusEvent = /* GraphQL */ `
  mutation UpdateKampusEvent(
    $input: UpdateKampusEventInput!
    $condition: ModelKampusEventConditionInput
  ) {
    updateKampusEvent(input: $input, condition: $condition) {
      id
      requestDate
      imageName
      displayArea
      title
      name
      description
      prizes
      schedule
      views
      startDate
      status
      message
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      createdAt
      updatedAt
      profileKampusEventId
      owner
    }
  }
`;
export const deleteKampusEvent = /* GraphQL */ `
  mutation DeleteKampusEvent(
    $input: DeleteKampusEventInput!
    $condition: ModelKampusEventConditionInput
  ) {
    deleteKampusEvent(input: $input, condition: $condition) {
      id
      requestDate
      imageName
      displayArea
      title
      name
      description
      prizes
      schedule
      views
      startDate
      status
      message
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      createdAt
      updatedAt
      profileKampusEventId
      owner
    }
  }
`;
export const createKampusRenewal = /* GraphQL */ `
  mutation CreateKampusRenewal(
    $input: CreateKampusRenewalInput!
    $condition: ModelKampusRenewalConditionInput
  ) {
    createKampusRenewal(input: $input, condition: $condition) {
      id
      kampusStatus
      transactionId
      transactionDate
      renewalCharges
      renewalTaxes
      renewalAmount
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      createdAt
      updatedAt
      profileKampusRenewalId
      owner
    }
  }
`;
export const updateKampusRenewal = /* GraphQL */ `
  mutation UpdateKampusRenewal(
    $input: UpdateKampusRenewalInput!
    $condition: ModelKampusRenewalConditionInput
  ) {
    updateKampusRenewal(input: $input, condition: $condition) {
      id
      kampusStatus
      transactionId
      transactionDate
      renewalCharges
      renewalTaxes
      renewalAmount
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      createdAt
      updatedAt
      profileKampusRenewalId
      owner
    }
  }
`;
export const deleteKampusRenewal = /* GraphQL */ `
  mutation DeleteKampusRenewal(
    $input: DeleteKampusRenewalInput!
    $condition: ModelKampusRenewalConditionInput
  ) {
    deleteKampusRenewal(input: $input, condition: $condition) {
      id
      kampusStatus
      transactionId
      transactionDate
      renewalCharges
      renewalTaxes
      renewalAmount
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      createdAt
      updatedAt
      profileKampusRenewalId
      owner
    }
  }
`;
export const createKard = /* GraphQL */ `
  mutation CreateKard(
    $input: CreateKardInput!
    $condition: ModelKardConditionInput
  ) {
    createKard(input: $input, condition: $condition) {
      id
      userId
      username
      imageName
      displayArea
      kardType
      kardStatus
      transactionId
      transactionDate
      billingAddress {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      charges
      discount
      taxes
      total
      setViews
      shownViews
      likes
      dislikes
      downloads
      flags
      changeKard
      changeDisplayArea
      startDate
      endDate
      expiryDate
      refundAmount
      refundTxnDate
      refundTxnId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateKard = /* GraphQL */ `
  mutation UpdateKard(
    $input: UpdateKardInput!
    $condition: ModelKardConditionInput
  ) {
    updateKard(input: $input, condition: $condition) {
      id
      userId
      username
      imageName
      displayArea
      kardType
      kardStatus
      transactionId
      transactionDate
      billingAddress {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      charges
      discount
      taxes
      total
      setViews
      shownViews
      likes
      dislikes
      downloads
      flags
      changeKard
      changeDisplayArea
      startDate
      endDate
      expiryDate
      refundAmount
      refundTxnDate
      refundTxnId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteKard = /* GraphQL */ `
  mutation DeleteKard(
    $input: DeleteKardInput!
    $condition: ModelKardConditionInput
  ) {
    deleteKard(input: $input, condition: $condition) {
      id
      userId
      username
      imageName
      displayArea
      kardType
      kardStatus
      transactionId
      transactionDate
      billingAddress {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      charges
      discount
      taxes
      total
      setViews
      shownViews
      likes
      dislikes
      downloads
      flags
      changeKard
      changeDisplayArea
      startDate
      endDate
      expiryDate
      refundAmount
      refundTxnDate
      refundTxnId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      data
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      data
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      data
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
export const createTotalTable = /* GraphQL */ `
  mutation CreateTotalTable(
    $input: CreateTotalTableInput!
    $condition: ModelTotalTableConditionInput
  ) {
    createTotalTable(input: $input, condition: $condition) {
      id
      freeActive
      freeQueue
      freePause
      freeTxnPending
      freeInsert
      freeDelete
      freeRefund
      freeComplete
      freeRemoved
      basicActive
      basicQueue
      basicPause
      basicTxnPending
      basicInsert
      basicDelete
      basicRefund
      basicComplete
      basicRemoved
      premiumActive
      premiumQueue
      premiumPause
      premiumTxnPending
      premiumInsert
      premiumDelete
      premiumRefund
      premiumComplete
      premiumRemoved
      campaignActive
      campaignQueue
      campaignPause
      campaignTxnPending
      campaignInsert
      campaignDelete
      campaignRefund
      campaignComplete
      campaignRemoved
      kampusActive
      kampusQueue
      kampusPause
      kampusTxnPending
      kampusInsert
      kampusDelete
      kampusRefund
      kampusComplete
      kampusRemoved
      promoActive
      promoQueue
      promoPause
      promoTxnPending
      promoInsert
      promoDelete
      promoRefund
      promoComplete
      promoRemoved
      totalActive
      totalQueue
      totalPause
      totalTxnPending
      totalInsert
      totalDelete
      totalRefund
      totalComplete
      totalRemoved
      freeKardCharges
      freeKardDiscount
      freeKardTaxes
      freeKardNet
      freeRefundNet
      basicKardCharges
      basicKardDiscount
      basicKardTaxes
      basicKardNet
      basicRefundNet
      premiumKardCharges
      premiumKardDiscount
      premiumKardTaxes
      premiumKardNet
      premiumRefundNet
      campaignKardCharges
      campaignKardDiscount
      campaignKardTaxes
      campaignKardNet
      campaignRefundNet
      kampusKardCharges
      kampusKardDiscount
      kampusKardTaxes
      kampusKardNet
      kampusRefundNet
      promoKardCharges
      promoKardDiscount
      promoKardTaxes
      promoKardNet
      promoRefundNet
      totalKardCharges
      totalKardDiscount
      totalKardTaxes
      totalKardNet
      totalRefundNet
      instituteRenewals
      instituteRenewalCharges
      instituteRenewalTaxes
      instituteRenewalNet
      volunteerRenewals
      volunteerRenewalCharges
      volunteerRenewalTaxes
      volunteerRenewalNet
      totalRenewals
      totalRenewalCharges
      totalRenewalTaxes
      totalRenewalNet
      profilesCreated
      profilesRemoved
      kampusRegApplied
      kampusRegApproved
      kampusRegRejected
      kampusEventApplied
      kampusEventApproved
      kampusEventRejected
      kampusEventRemoved
      kampusChampions
      kampusVolunteers
      totalRating
      oneRating
      twoRating
      threeRating
      fourRating
      fiveRating
      totalContactUs
      contactUsDisposed
      totalComments
      commentsDisposed
      totalProblems
      problemsDisposed
      type
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateTotalTable = /* GraphQL */ `
  mutation UpdateTotalTable(
    $input: UpdateTotalTableInput!
    $condition: ModelTotalTableConditionInput
  ) {
    updateTotalTable(input: $input, condition: $condition) {
      id
      freeActive
      freeQueue
      freePause
      freeTxnPending
      freeInsert
      freeDelete
      freeRefund
      freeComplete
      freeRemoved
      basicActive
      basicQueue
      basicPause
      basicTxnPending
      basicInsert
      basicDelete
      basicRefund
      basicComplete
      basicRemoved
      premiumActive
      premiumQueue
      premiumPause
      premiumTxnPending
      premiumInsert
      premiumDelete
      premiumRefund
      premiumComplete
      premiumRemoved
      campaignActive
      campaignQueue
      campaignPause
      campaignTxnPending
      campaignInsert
      campaignDelete
      campaignRefund
      campaignComplete
      campaignRemoved
      kampusActive
      kampusQueue
      kampusPause
      kampusTxnPending
      kampusInsert
      kampusDelete
      kampusRefund
      kampusComplete
      kampusRemoved
      promoActive
      promoQueue
      promoPause
      promoTxnPending
      promoInsert
      promoDelete
      promoRefund
      promoComplete
      promoRemoved
      totalActive
      totalQueue
      totalPause
      totalTxnPending
      totalInsert
      totalDelete
      totalRefund
      totalComplete
      totalRemoved
      freeKardCharges
      freeKardDiscount
      freeKardTaxes
      freeKardNet
      freeRefundNet
      basicKardCharges
      basicKardDiscount
      basicKardTaxes
      basicKardNet
      basicRefundNet
      premiumKardCharges
      premiumKardDiscount
      premiumKardTaxes
      premiumKardNet
      premiumRefundNet
      campaignKardCharges
      campaignKardDiscount
      campaignKardTaxes
      campaignKardNet
      campaignRefundNet
      kampusKardCharges
      kampusKardDiscount
      kampusKardTaxes
      kampusKardNet
      kampusRefundNet
      promoKardCharges
      promoKardDiscount
      promoKardTaxes
      promoKardNet
      promoRefundNet
      totalKardCharges
      totalKardDiscount
      totalKardTaxes
      totalKardNet
      totalRefundNet
      instituteRenewals
      instituteRenewalCharges
      instituteRenewalTaxes
      instituteRenewalNet
      volunteerRenewals
      volunteerRenewalCharges
      volunteerRenewalTaxes
      volunteerRenewalNet
      totalRenewals
      totalRenewalCharges
      totalRenewalTaxes
      totalRenewalNet
      profilesCreated
      profilesRemoved
      kampusRegApplied
      kampusRegApproved
      kampusRegRejected
      kampusEventApplied
      kampusEventApproved
      kampusEventRejected
      kampusEventRemoved
      kampusChampions
      kampusVolunteers
      totalRating
      oneRating
      twoRating
      threeRating
      fourRating
      fiveRating
      totalContactUs
      contactUsDisposed
      totalComments
      commentsDisposed
      totalProblems
      problemsDisposed
      type
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteTotalTable = /* GraphQL */ `
  mutation DeleteTotalTable(
    $input: DeleteTotalTableInput!
    $condition: ModelTotalTableConditionInput
  ) {
    deleteTotalTable(input: $input, condition: $condition) {
      id
      freeActive
      freeQueue
      freePause
      freeTxnPending
      freeInsert
      freeDelete
      freeRefund
      freeComplete
      freeRemoved
      basicActive
      basicQueue
      basicPause
      basicTxnPending
      basicInsert
      basicDelete
      basicRefund
      basicComplete
      basicRemoved
      premiumActive
      premiumQueue
      premiumPause
      premiumTxnPending
      premiumInsert
      premiumDelete
      premiumRefund
      premiumComplete
      premiumRemoved
      campaignActive
      campaignQueue
      campaignPause
      campaignTxnPending
      campaignInsert
      campaignDelete
      campaignRefund
      campaignComplete
      campaignRemoved
      kampusActive
      kampusQueue
      kampusPause
      kampusTxnPending
      kampusInsert
      kampusDelete
      kampusRefund
      kampusComplete
      kampusRemoved
      promoActive
      promoQueue
      promoPause
      promoTxnPending
      promoInsert
      promoDelete
      promoRefund
      promoComplete
      promoRemoved
      totalActive
      totalQueue
      totalPause
      totalTxnPending
      totalInsert
      totalDelete
      totalRefund
      totalComplete
      totalRemoved
      freeKardCharges
      freeKardDiscount
      freeKardTaxes
      freeKardNet
      freeRefundNet
      basicKardCharges
      basicKardDiscount
      basicKardTaxes
      basicKardNet
      basicRefundNet
      premiumKardCharges
      premiumKardDiscount
      premiumKardTaxes
      premiumKardNet
      premiumRefundNet
      campaignKardCharges
      campaignKardDiscount
      campaignKardTaxes
      campaignKardNet
      campaignRefundNet
      kampusKardCharges
      kampusKardDiscount
      kampusKardTaxes
      kampusKardNet
      kampusRefundNet
      promoKardCharges
      promoKardDiscount
      promoKardTaxes
      promoKardNet
      promoRefundNet
      totalKardCharges
      totalKardDiscount
      totalKardTaxes
      totalKardNet
      totalRefundNet
      instituteRenewals
      instituteRenewalCharges
      instituteRenewalTaxes
      instituteRenewalNet
      volunteerRenewals
      volunteerRenewalCharges
      volunteerRenewalTaxes
      volunteerRenewalNet
      totalRenewals
      totalRenewalCharges
      totalRenewalTaxes
      totalRenewalNet
      profilesCreated
      profilesRemoved
      kampusRegApplied
      kampusRegApproved
      kampusRegRejected
      kampusEventApplied
      kampusEventApproved
      kampusEventRejected
      kampusEventRemoved
      kampusChampions
      kampusVolunteers
      totalRating
      oneRating
      twoRating
      threeRating
      fourRating
      fiveRating
      totalContactUs
      contactUsDisposed
      totalComments
      commentsDisposed
      totalProblems
      problemsDisposed
      type
      date
      createdAt
      updatedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      type
      email
      message
      rating
      status
      requestDate
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      type
      email
      message
      rating
      status
      requestDate
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      type
      email
      message
      rating
      status
      requestDate
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
