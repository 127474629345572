import React, { useContext } from "react";
import { Text, TextInput, StyleSheet } from "react-native";
import { ThemeContext } from "../context/ThemeContext";

const CustomNumberInput = (props) => {
  const { theme, changeTheme } = useContext(ThemeContext);

  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched },
    ...inputProps
  } = props;

  const hasError = errors[name] && touched[name];

  return (
    <>
      <TextInput
        style={[styles(theme).textInput, hasError && styles(theme).errorInput]}
        value={`${value}`}
        onChangeText={(text) => onChange(name)(text)}
        onBlur={() => {
          setFieldTouched(name);
          onBlur(name);
        }}
        keyboardType="numeric"
        {...inputProps}
      />
      {hasError && <Text style={styles(theme).errorText}>{errors[name]}</Text>}
    </>
  );
};

export default CustomNumberInput;

const styles = (theme) =>
  StyleSheet.create({
    textInput: {
      color: theme.colors.standardTextColor,
      height: 50,
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 1,
      borderRadius: 10,
      paddingLeft: 10,
    },
    errorText: {
      color: "red",
      width: "80%",
      alignSelf: "center",
    },
    errorInput: {
      borderColor: "red",
    },
  });
