import React, { useContext, useEffect, useState } from "react";
import { Text, Image, View, StyleSheet, Platform } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { API, Storage } from "aws-amplify";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";
import "react-native-get-random-values";
import ShortUniqueId from "short-unique-id";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";

import { ThemeContext } from "../../context/ThemeContext";
import * as mutations from "../../src/graphql/mutations";
import * as queries from "../../src/graphql/queries";
import { NewKardContext } from "../../context/NewKardContext";
import { ProfileContext } from "../../context/ProfileContext";
import { OrderContext } from "../../context/OrderContext";
import { UserContext } from "../../context/UserContext";

const ReviewAndConfirmNewKard = () => {
  const { user } = useContext(UserContext);
  const {
    kardType,
    kard,
    polygon,
    finalViews,
    addressKey,
    date,
    mapRegionForNewKard,
  } = useContext(NewKardContext);

  const { profile, setProfile } = useContext(ProfileContext);

  const stagingTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "stagingTeam"
    );

  const devTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "devTeam"
    );
  const charges =
    finalViews <= 100
      ? 0
      : stagingTeam || devTeam
      ? 1.1
      : parseFloat(finalViews * 0.1).toFixed(2);

  const discount =
    (profile.kampusStatus == 3 || profile.kampusStatus == 4) &&
    new Date(profile.kampusEndDate).getTime() >= new Date().getTime()
      ? charges * 0.2 > 100
        ? 100
        : (charges * 0.2).toFixed(2)
      : 0;

  const taxes = ((parseFloat(charges) - parseFloat(discount)) * 0.18).toFixed(
    2
  );
  const total = (
    parseFloat(charges) -
    parseFloat(discount) +
    parseFloat(taxes)
  ).toFixed(2);

  const { theme, changeTheme } = useContext(ThemeContext);
  const { setOrderId, setAmount } = useContext(OrderContext);
  const [checkboxSelected, setCheckboxSelected] = useState(false);
  const [reviewAndConfirm, setReviewAndConfirm] = useState(false);
  const [freeKardLimit, setFreeKardLimit] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);
  const [postError, setPostError] = useState(false);
  const [proceedToPay, setProceedToPay] = useState(false);
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const uid = new ShortUniqueId({ length: 29 });
  const id = uid();

  useEffect(() => {
    if (isFocused) {
      setCheckboxSelected(false);
    }
  }, [isFocused]);

  const postFreeKard = async () => {
    var count = 0;

    try {
      const freeKards = await API.graphql({
        query: queries.listKardsByUserIdAndKardType,
        variables: {
          userId: profile.id,
          kardType: { eq: "free" },
          filter: {
            kardStatus: { ne: "delete" },
          },
        },
      });

      // console.log(freeKards.data.listKardsByUserIdAndKardType.items);
      count = freeKards.data.listKardsByUserIdAndKardType.items.length;
      if (count >= 3) {
        setFreeKardLimit(true);
      } else {
        const fileType =
          Platform.OS == "web"
            ? kard.substring(kard.indexOf("/") + 1, kard.indexOf(";"))
            : kard.substring(kard.lastIndexOf(".") + 1);

        const response = await fetch(kard);
        const blob = await response.blob();
        const access = { level: "public", contentType: fileType };
        const imageName = id + "." + fileType;

        var displayAreaObject = {
          type: "polygon",
          coordinates: [[]],
        };
        polygon.map((item, key) => {
          var newItem =
            Platform.OS == "web"
              ? [Number(item.lng.toFixed(5)), Number(item.lat.toFixed(5))]
              : [
                  Number(item.longitude.toFixed(5)),
                  Number(item.latitude.toFixed(5)),
                ];
          displayAreaObject.coordinates[0].push(newItem);
        });
        var firstItem =
          Platform.OS == "web"
            ? [
                Number(polygon[0].lng.toFixed(5)),
                Number(polygon[0].lat.toFixed(5)),
              ]
            : [
                Number(polygon[0].longitude.toFixed(5)),
                Number(polygon[0].latitude.toFixed(5)),
              ];
        displayAreaObject.coordinates[0].push(firstItem);
        const displayAreaJSONString = JSON.stringify(displayAreaObject);

        const createKard = await API.graphql({
          query: mutations.createKard,
          variables: {
            input: {
              id: id,
              userId: profile.id,
              username: profile.username,
              imageName: imageName,
              displayArea: displayAreaJSONString,
              kardType: "free",
              transactionId: "nil",
              transactionDate: new Date().toISOString(),
              billingAddress: profile.address[addressKey],
              charges: 0,
              discount: 0,
              taxes: 0,
              total: 0,
              setViews: finalViews,
              shownViews: 0,
              likes: 0,
              dislikes: 0,
              downloads: 0,
              flags: 0,
              changeKard: 0,
              changeDisplayArea: 0,
              startDate: date.toISOString(),
              kardStatus: count == 0 ? "active" : "queue",
            },
          },
        });

        const kardS3 = await Storage.put(imageName, blob, access);
        setPostSuccess(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const postPaidKard = async () => {
    const fileType =
      Platform.OS == "web"
        ? kard.substring(kard.indexOf("/") + 1, kard.indexOf(";"))
        : kard.substring(kard.lastIndexOf(".") + 1);

    const response = await fetch(kard);
    const blob = await response.blob();
    const access = { level: "public", contentType: fileType };
    const imageName = id + "." + fileType;

    var displayAreaObject = {
      type: "polygon",
      coordinates: [[]],
    };
    polygon.map((item, key) => {
      var newItem =
        Platform.OS == "web"
          ? [Number(item.lng.toFixed(5)), Number(item.lat.toFixed(5))]
          : [
              Number(item.longitude.toFixed(5)),
              Number(item.latitude.toFixed(5)),
            ];
      displayAreaObject.coordinates[0].push(newItem);
    });
    var firstItem =
      Platform.OS == "web"
        ? [Number(polygon[0].lng.toFixed(5)), Number(polygon[0].lat.toFixed(5))]
        : [
            Number(polygon[0].longitude.toFixed(5)),
            Number(polygon[0].latitude.toFixed(5)),
          ];
    displayAreaObject.coordinates[0].push(firstItem);
    const displayAreaJSONString = JSON.stringify(displayAreaObject);
    try {
      // const credentials = await Auth.currentUserCredentials();
      const createKard = await API.graphql({
        query: mutations.createKard,
        variables: {
          input: {
            id: id,
            userId: profile.id,
            // userIdentityId: credentials.identityId,
            username: profile.username,
            imageName: imageName,
            displayArea: displayAreaJSONString,
            kardType: kardType,
            transactionId: "pending",
            transactionDate: new Date().toISOString(),
            billingAddress: profile.address[addressKey],
            charges: charges,
            discount: discount,
            taxes: taxes,
            total: total,
            setViews: finalViews,
            shownViews: 0,
            likes: 0,
            dislikes: 0,
            downloads: 0,
            flags: 0,
            changeKard: 0,
            changeDisplayArea: 0,
            startDate: date.toISOString(),
            kardStatus: "txnPending",
          },
          // expiryDate: kardType != "free" ? 5 mins : null
        },
      });
      const kardS3 = await Storage.put(imageName, blob, access);
      // makePayment;
      makePayment();
    } catch (err) {
      setPostError(true);
      console.log(err);
    }
  };

  const makePayment = () => {
    setOrderId(id);
    setAmount(total);
    navigation.navigate("SelectKardPaymentMethod");
  };
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>REVIEW AND CONFIRM</Text>
        <TouchableOpacity onPress={() => setReviewAndConfirm(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <Text style={styles(theme).textCenterStyle}>Step 5 of 5</Text>
      <Text style={styles(theme).subHeaderStyle}>Selected Kard</Text>
      <View>
        <Image source={{ uri: kard }} style={styles(theme).kardStyle} />
        <View>
          <Text style={styles(theme).subHeaderStyle}>Display Area</Text>
        </View>
        <MapView
          style={styles(theme).kardStyle}
          provider={PROVIDER_GOOGLE}
          initialRegion={mapRegionForNewKard}
        >
          <MapView.Marker
            coordinate={mapRegionForNewKard}
            pinColor={theme.colors.standardTextColor}
          />
          {Platform.OS == "web" ? (
            <MapView.Polygon
              path={polygon}
              options={{
                strokeColor: theme.colors.standardTextColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: theme.colors.standardTextColor,
                fillOpacity: 0.35,
              }}
            />
          ) : (
            <MapView.Polygon
              coordinates={polygon}
              strokeColor={theme.colors.standardTextColor}
              fillColor={theme.colors.standardTextColor}
            />
          )}
        </MapView>
        <Text style={styles(theme).subHeaderStyle}>Display Details</Text>
        <View style={styles(theme).boxStyle}>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Number of Views:</Text>
            <Text style={styles(theme).textBoldStyle}>{finalViews}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Starting Date:</Text>
            <Text style={styles(theme).textBoldStyle}>
              {date.toLocaleDateString()}
            </Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Starting Time:</Text>
            <Text style={styles(theme).textBoldStyle}>
              {date.toLocaleTimeString()}
            </Text>
          </View>
        </View>
        <Text style={styles(theme).subHeaderStyle}>Bill Details</Text>
        <View style={styles(theme).boxStyle}>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>
              Charges for {finalViews} views:
            </Text>
            <Text style={styles(theme).textBoldStyle}>₹ {charges}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Discount:</Text>
            <Text style={styles(theme).textBoldStyle}>₹ {discount}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Taxes:</Text>
            <Text style={styles(theme).textBoldStyle}>₹ {taxes}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Total:</Text>
            <Text style={styles(theme).textBoldStyle}>₹ {total}</Text>
          </View>
        </View>
        <Text style={styles(theme).subHeaderStyle}>Billing Address</Text>
        {profile.address && addressKey != null && (
          <View style={styles(theme).boxStyle}>
            <Text style={styles(theme).textJustifyStyle}>
              Name: {profile.address[addressKey].name}
            </Text>
            <Text style={styles(theme).textJustifyStyle}>
              {profile.address[addressKey].line1},{" "}
              {profile.address[addressKey].line2},{" "}
              {profile.address[addressKey].line3}
            </Text>
            <Text style={styles(theme).textJustifyStyle}>
              State / Region: {profile.address[addressKey].state}, Country:{" "}
              {profile.address[addressKey].country}, PIN / ZIP:{" "}
              {profile.address[addressKey].zip}
            </Text>
          </View>
        )}

        <View style={styles(theme).termsViewStyle}>
          <TouchableOpacity
            onPress={() => setCheckboxSelected(!checkboxSelected)}
          >
            <AntDesign
              name={
                theme.type == "fill"
                  ? !checkboxSelected
                    ? "closecircle"
                    : "checkcircle"
                  : !checkboxSelected
                  ? "closecircleo"
                  : "checkcircleo"
              }
              size={50}
              color={!checkboxSelected ? "red" : theme.colors.standardTextColor}
            />
          </TouchableOpacity>

          <Text
            style={{
              flex: 1,
              flexWrap: "wrap",
              color:
                theme.type == "fill"
                  ? !checkboxSelected
                    ? "red"
                    : theme.colors.standardTextColor
                  : !checkboxSelected
                  ? "red"
                  : theme.colors.standardTextColor,
              paddingLeft: 20,
              textAlign: "justify",
            }}
          >
            I have read the terms and conditions and I agree
          </Text>
        </View>
      </View>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          navigation.navigate("BillingDetailsForNewKard");
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Back</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={
          checkboxSelected
            ? styles(theme).buttonStyle
            : styles(theme).disabledButtonStyle
        }
        onPress={() => {
          if (kardType == "free") {
            postFreeKard();
          } else {
            setProceedToPay(true);
          }
        }}
        disabled={!checkboxSelected}
      >
        <Text style={styles(theme).buttonTextStyle}>Post</Text>
      </TouchableOpacity>
      <AwesomeAlert
        show={reviewAndConfirm}
        title="REVIEW AND CONFIRM"
        message={
          "Congratulations!!! You are now ready to post your kard!! Before you post your kard, please review the details. You must also go through the terms and conditions and agree. There can be only 3 free kards at the same time. If you already have a free kard currently being displayed(active), second and third free kards will be queued(queue) until the display of first free kard is over.  However there is no such restriction for BASIC or PREMIUM or CAMPAIGN kards."
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setReviewAndConfirm(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={freeKardLimit}
        title="FREE KARD LIMIT EXCEEDED"
        message={
          "Only 3 free kards are permitted at the same time. You are already having 3 free kards. You can post either BASIC or PREMIUM or CAMPAIGN kard  or delete one of the free kards or wait till one of the free kards completes the display."
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setFreeKardLimit(false);
          navigation.navigate("ChooseTypeForNewKard");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={postSuccess}
        title="SUCCESS"
        message={
          "Congratulations!!!! Your free kard has been posted successfully. "
        }
        useNativeDriver={true}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setPostSuccess(false);
          navigation.navigate("ChooseTypeForNewKard");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={proceedToPay}
        title="PROCEED TO PAY"
        message={`Would you like to proceed to pay ₹${total}?`}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, proceed to pay"
        onCancelPressed={() => setProceedToPay(false)}
        onConfirmPressed={() => {
          setProceedToPay(false);
          postPaidKard();
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={postError}
        title="ERROR"
        message={
          "Sorry!!! There was some error while posting the kard. Please try after sometime. If the error persists, please report the problem under feedback section. "
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setPostError(false);
          navigation.navigate("ChooseTypeForNewKard");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },

    kardStyle: {
      height: 550,
      width: 350,
      alignSelf: "center",
      borderRadius: 20,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },
    textBoldStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      margin: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    boxTextStyle: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },
    termsViewStyle: {
      width: "80%",
      flexDirection: "row",
      alignSelf: "center",
      alignItems: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default ReviewAndConfirmNewKard;
