import React, { useContext, useState } from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { API, graphqlOperation } from "aws-amplify";
import { useNavigation } from "@react-navigation/native";

import { ThemeContext } from "../../context/ThemeContext";
import CustomInput from "../../components/CustomInput";
import * as mutations from "../../src/graphql/mutations";
import { ProfileContext } from "../../context/ProfileContext";
import CustomCountryInput from "../../components/CustomCountryInput";
import CustomStateInput from "../../components/CustomStateInput";

const AddNewAddress = (props) => {
  const navigation = useNavigation();
  const { theme, changeTheme } = useContext(ThemeContext);
  const { profile, setProfile } = useContext(ProfileContext);
  const initialValues =
    profile.address && profile.address[props.route.params.key]
      ? {
          type: profile.address[props.route.params.key].type,
          name: profile.address[props.route.params.key].name,
          line1: profile.address[props.route.params.key].line1,
          line2: profile.address[props.route.params.key].line2,
          line3: profile.address[props.route.params.key].line3,
          country: profile.address[props.route.params.key].country,
          state: profile.address[props.route.params.key].state,
          zip: profile.address[props.route.params.key].zip,
        }
      : {
          type: "",
          name: "",
          line1: "",
          line2: "",
          line3: "",
          country: "",
          state: "",
          zip: "",
        };

  const updateAddress = async (values, { resetForm }) => {
    const newAddress = {
      type: values.type,
      name: values.name,
      line1: values.line1,
      line2: values.line2,
      line3: values.line3,
      country: values.country,
      state: values.state,
      zip: values.zip,
    };

    var addressArray = [];
    if (profile.address) {
      addressArray = profile.address;
    }
    if (props.route.params.key == null) {
      addressArray.push(newAddress);
    } else {
      addressArray[props.route.params.key] = newAddress;
    }

    try {
      const updatedProfile = await API.graphql(
        graphqlOperation(mutations.updateProfile, {
          input: {
            id: profile.id,
            address: addressArray,
          },
        })
      );
      setProfile(updatedProfile.data.updateProfile);
      resetForm();
      navigation.goBack();
    } catch (err) {
      console.log(err);
    }
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("Reqiured"),
    name: Yup.string().required("Reqiured"),
    line1: Yup.string().required("Reqiured"),
    country: Yup.string().required("Reqiured"),
    state: Yup.string().required("Reqiured"),
    zip: Yup.string().required("Reqiured"),
  });

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>
        {props.route.params.key != null ? "EDIT ADDRESS" : "ADD NEW ADDRESS"}
      </Text>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={updateAddress}
        enableReinitialize
      >
        {({ handleSubmit, isValid }) => (
          <View style={{ marginLeft: 20 }}>
            <Field
              component={CustomInput}
              name="type"
              placeholder="Address type (Residence, Office, Billing etc..,)"
            />
            <Field
              component={CustomInput}
              name="name"
              placeholder="Full Name"
            />
            <Field
              component={CustomInput}
              name="line1"
              placeholder="Address Line 1"
            />
            <Field
              component={CustomInput}
              name="line2"
              placeholder="Address Line 2"
            />
            <Field
              component={CustomInput}
              name="line3"
              placeholder="Address Line 3"
            />
            <Field
              component={CustomCountryInput}
              name="country"
              // value={country}
              placeholder="Select Country"
              // setCountry={setCountry}
              // setState={setState}
            />
            <Field
              component={CustomStateInput}
              name="state"
              placeholder="Select State / Region"
            />
            <Field component={CustomInput} name="zip" placeholder="ZIP / PIN" />
            <TouchableOpacity
              style={styles(theme).buttonStyle}
              onPress={() => navigation.goBack()}
            >
              <Text style={styles(theme).buttonTextStyle}>CANCEL</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={
                isValid
                  ? styles(theme).buttonStyle
                  : styles(theme).disabledButtonStyle
              }
              onPress={handleSubmit}
              disabled={!isValid}
            >
              <Text style={styles(theme).buttonTextStyle}>OK</Text>
            </TouchableOpacity>
          </View>
        )}
      </Formik>
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },
  });

export default AddNewAddress;
