import React, { useContext } from "react";
import { Text, StyleSheet } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { ThemeContext } from "../context/ThemeContext";

const Privacy = () => {
  const { theme, changeTheme } = useContext(ThemeContext);

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>PRIVACY POLICY</Text>
      <Text style={styles(theme).labelStyle}>Last updated: 02 OCT 2022</Text>
      <Text style={styles(theme).labelStyle}>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </Text>
      <Text style={styles(theme).labelStyle}>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Interpretation and Definitions
      </Text>
      <Text style={styles(theme).labelBoldStyle}>Interpretation</Text>
      <Text style={styles(theme).labelStyle}>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>Definitions</Text>
      <Text style={styles(theme).labelStyle}>
        For the purposes of this Privacy Policy:
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Account </Text>
        means a unique account created for You to access our Service or parts of
        our Service.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Affiliate </Text>
        means an entity that controls, is controlled by or is under common
        control with a party, where "control" means ownership of 50% or more of
        the shares, equity interest or other securities entitled to vote for
        election of directors or other managing authority.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Application </Text>
        means the software program provided by the Company downloaded by You on
        any electronic device, named kabutar
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Company </Text>
        (referred to as either "the Company", "We", "Us" or "Our" in this
        Agreement) refers to NIXON TECH, INDIA.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Cookies </Text>
        are small files that are placed on Your computer, mobile device or any
        other device by a website, containing the details of Your browsing
        history on that website among its many uses.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Country </Text>
        refers to India
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Device </Text>
        means any device that can access the Service such as a computer, a
        cellphone or a digital tablet.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Personal Data </Text>
        is any information that relates to an identified or identifiable
        individual.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Service </Text>
        refers to the Application or the Website or both.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Service Provider </Text>
        means any natural or legal person who processes the data on behalf of
        the Company. It refers to third-party companies or individuals employed
        by the Company to facilitate the Service, to provide the Service on
        behalf of the Company, to perform services related to the Service or to
        assist the Company in analyzing how the Service is used.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>
          Third-party Social Media Service
        </Text>
        refers to any website or any social network website through which a User
        can log in or create an account to use the Service.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Usage Data </Text>
        refers to data collected automatically, either generated by the use of
        the Service or from the Service infrastructure itself (for example, the
        duration of a page visit).
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Website </Text>
        refers to kabutar, accessible from https://www.kabutarapp.com
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>You </Text>
        means the individual accessing or using the Service, or the company, or
        other legal entity on behalf of which such individual is accessing or
        using the Service, as applicable.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Collecting and Using Your Personal Data
      </Text>
      <Text style={styles(theme).labelBoldStyle}>Types of Data Collected</Text>
      <Text style={styles(theme).labelBoldStyle}>Personal Data</Text>
      <Text style={styles(theme).labelStyle}>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </Text>
      <Text style={styles(theme).labelStyle}>Email address</Text>
      <Text style={styles(theme).labelStyle}>First name and last name</Text>
      <Text style={styles(theme).labelStyle}>Phone number</Text>
      <Text style={styles(theme).labelStyle}>
        Address, State, Province, ZIP/Postal code, City
      </Text>
      <Text style={styles(theme).labelStyle}>Usage Data</Text>
      <Text style={styles(theme).labelBoldStyle}>Usage Data</Text>
      <Text style={styles(theme).labelStyle}>
        Usage Data is collected automatically when using the Service.
      </Text>
      <Text style={styles(theme).labelStyle}>
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </Text>
      <Text style={styles(theme).labelStyle}>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </Text>
      <Text style={styles(theme).labelStyle}>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Information from Third-Party Social Media Services
      </Text>
      <Text style={styles(theme).labelStyle}>
        The Company allows You to create an account and log in to use the
        Service through the following Third-party Social Media Services:
      </Text>
      <Text style={styles(theme).labelStyle}>Google</Text>
      <Text style={styles(theme).labelStyle}>Facebook</Text>
      <Text style={styles(theme).labelStyle}>AWS</Text>
      <Text style={styles(theme).labelStyle}>
        If You decide to register through or otherwise grant us access to a
        Third-Party Social Media Service, We may collect Personal data that is
        already associated with Your Third-Party Social Media Service's account,
        such as Your name, Your email address, Your activities or Your contact
        list associated with that account.
      </Text>
      <Text style={styles(theme).labelStyle}>
        You may also have the option of sharing additional information with the
        Company through Your Third-Party Social Media Service's account. If You
        choose to provide such information and Personal Data, during
        registration or otherwise, You are giving the Company permission to use,
        share, and store it in a manner consistent with this Privacy Policy.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Information Collected while Using the Application
      </Text>
      <Text style={styles(theme).labelStyle}>
        While using Our Application, in order to provide features of Our
        Application, We may collect, with Your prior permission:
      </Text>
      <Text style={styles(theme).labelStyle}>
        Information regarding your location
      </Text>
      <Text style={styles(theme).labelStyle}>
        Pictures and other information from your Device's camera and photo
        library
      </Text>
      <Text style={styles(theme).labelStyle}>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        Company's servers and/or a Service Provider's server or it may be simply
        stored on Your device.
      </Text>
      <Text style={styles(theme).labelStyle}>
        You can enable or disable access to this information at any time,
        through Your Device settings.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Tracking Technologies and Cookies
      </Text>
      <Text style={styles(theme).labelStyle}>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Cookies or Browser Cookies. </Text>
        A cookie is a small file placed on Your Device. You can instruct Your
        browser to refuse all Cookies or to indicate when a Cookie is being
        sent. However, if You do not accept Cookies, You may not be able to use
        some parts of our Service. Unless you have adjusted Your browser setting
        so that it will refuse Cookies, our Service may use Cookies.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Flash Cookies. </Text>
        Certain features of our Service may use local stored objects (or Flash
        Cookies) to collect and store information about Your preferences or Your
        activity on our Service. Flash Cookies are not managed by the same
        browser settings as those used for Browser Cookies. For more information
        on how You can delete Flash Cookies, please read "Where can I change the
        settings for disabling, or deleting local shared objects?" available at
        https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>Web Beacons. </Text>
        Certain sections of our Service and our emails may contain small
        electronic files known as web beacons (also referred to as clear gifs,
        pixel tags, and single-pixel gifs) that permit the Company, for example,
        to count users who have visited those pages or opened an email and for
        other related website statistics (for example, recording the popularity
        of a certain section and verifying system and server integrity).
      </Text>
      <Text style={styles(theme).labelStyle}>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
        remain on Your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close Your web browser.
      </Text>
      <Text style={styles(theme).labelStyle}>
        We use both Session and Persistent Cookies for the purposes set out
        below:
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Necessary / Essential Cookies
      </Text>
      <Text style={styles(theme).labelStyle}>Type: Session Cookies</Text>
      <Text style={styles(theme).labelStyle}>Administered by: Us</Text>
      <Text style={styles(theme).labelStyle}>
        Purpose: These Cookies are essential to provide You with services
        available through the Website and to enable You to use some of its
        features. They help to authenticate users and prevent fraudulent use of
        user accounts. Without these Cookies, the services that You have asked
        for cannot be provided, and We only use these Cookies to provide You
        with those services.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Cookies Policy / Notice Acceptance Cookies
      </Text>
      <Text style={styles(theme).labelStyle}>Type: Persistent Cookies</Text>
      <Text style={styles(theme).labelStyle}>Administered by: Us</Text>
      <Text style={styles(theme).labelStyle}>
        Purpose: These Cookies identify if users have accepted the use of
        cookies on the Website.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>Functionality Cookies</Text>
      <Text style={styles(theme).labelStyle}>Type: Persistent Cookies</Text>
      <Text style={styles(theme).labelStyle}>Administered by: Us</Text>
      <Text style={styles(theme).labelStyle}>
        Purpose: These Cookies allow us to remember choices You make when You
        use the Website, such as remembering your login details or language
        preference. The purpose of these Cookies is to provide You with a more
        personal experience and to avoid You having to re-enter your preferences
        every time You use the Website.
      </Text>
      <Text style={styles(theme).labelStyle}>
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Use of Your Personal Data
      </Text>
      <Text style={styles(theme).labelStyle}>
        The Company may use Personal Data for the following purposes:
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>
          To provide and maintain our Service,
        </Text>
        including to monitor the usage of our Service.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>To manage Your Account: </Text>
        to manage Your registration as a user of the Service. The Personal Data
        You provide can give You access to different functionalities of the
        Service that are available to You as a registered user.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>
          For the performance of a contract:{" "}
        </Text>
        the development, compliance and undertaking of the purchase contract for
        the products, items or services You have purchased or of any other
        contract with Us through the Service.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>To contact You: </Text>
        To contact You by email, telephone calls, SMS, or other equivalent forms
        of electronic communication, such as a mobile application's push
        notifications regarding updates or informative communications related to
        the functionalities, products or contracted services, including the
        security updates, when necessary or reasonable for their implementation.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>To provide You </Text>
        with news, special offers and general information about other goods,
        services and events which we offer that are similar to those that you
        have already purchased or enquired about unless You have opted not to
        receive such information.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>To manage Your requests: </Text>
        To attend and manage Your requests to Us.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>For business transfers: </Text>
        We may use Your information to evaluate or conduct a merger,
        divestiture, restructuring, reorganization, dissolution, or other sale
        or transfer of some or all of Our assets, whether as a going concern or
        as part of bankruptcy, liquidation, or similar proceeding, in which
        Personal Data held by Us about our Service users is among the assets
        transferred.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>For other purposes: </Text>
        We may use Your information for other purposes, such as data analysis,
        identifying usage trends, determining the effectiveness of our
        promotional campaigns and to evaluate and improve our Service, products,
        services, marketing and your experience.
      </Text>
      <Text style={styles(theme).labelStyle}>
        We may share Your personal information in the following situations:
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>With Service Providers: </Text>
        We may share Your personal information with Service Providers to monitor
        and analyze the use of our Service, to contact You.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>For business transfers: </Text>
        We may share or transfer Your personal information in connection with,
        or during negotiations of, any merger, sale of Company assets,
        financing, or acquisition of all or a portion of Our business to another
        company.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>With Affiliates: </Text>
        We may share Your information with Our affiliates, in which case we will
        require those affiliates to honor this Privacy Policy. Affiliates
        include Our parent company and any other subsidiaries, joint venture
        partners or other companies that We control or that are under common
        control with Us.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>With business partners: </Text>
        We may share Your information with Our business partners to offer You
        certain products, services or promotions.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>With other users: </Text>
        when You share personal information or otherwise interact in the public
        areas with other users, such information may be viewed by all users and
        may be publicly distributed outside. If You interact with other users or
        register through a Third-Party Social Media Service, Your contacts on
        the Third-Party Social Media Service may see Your name, profile,
        pictures and description of Your activity. Similarly, other users will
        be able to view descriptions of Your activity, communicate with You and
        view Your profile.
      </Text>
      <Text style={styles(theme).labelStyle}>
        <Text style={{ fontWeight: "bold" }}>With Your consent: </Text>
        We may disclose Your personal information for any other purpose with
        Your consent.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Retention of Your Personal Data
      </Text>
      <Text style={styles(theme).labelStyle}>
        Retention of Your Personal Data
      </Text>
      <Text style={styles(theme).labelStyle}>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Transfer of Your Personal Data
      </Text>
      <Text style={styles(theme).labelStyle}>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </Text>
      <Text style={styles(theme).labelStyle}>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </Text>
      <Text style={styles(theme).labelStyle}>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Disclosure of Your Personal Data
      </Text>
      <Text style={styles(theme).labelBoldStyle}>Business Transactions</Text>
      <Text style={styles(theme).labelStyle}>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>Law enforcement</Text>
      <Text style={styles(theme).labelStyle}>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </Text>
      <Text style={styles(theme).labelBoldStyle}>Other legal requirements</Text>
      <Text style={styles(theme).labelStyle}>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </Text>
      <Text style={styles(theme).labelStyle}>
        Comply with a legal obligation
      </Text>
      <Text style={styles(theme).labelStyle}>
        Protect and defend the rights or property of the Company
      </Text>
      <Text style={styles(theme).labelStyle}>
        Prevent or investigate possible wrongdoing in connection with the
        Service
      </Text>
      <Text style={styles(theme).labelStyle}>
        Protect the personal safety of Users of the Service or the public
      </Text>
      <Text style={styles(theme).labelStyle}>
        Protect against legal liability
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Security of Your Personal Data
      </Text>
      <Text style={styles(theme).labelStyle}>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>Children's Privacy</Text>
      <Text style={styles(theme).labelStyle}>
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to
        remove that information from Our servers.
      </Text>
      <Text style={styles(theme).labelStyle}>
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>Links to Other Websites</Text>
      <Text style={styles(theme).labelStyle}>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </Text>
      <Text style={styles(theme).labelStyle}>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>
        Changes to this Privacy Policy
      </Text>
      <Text style={styles(theme).labelStyle}>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </Text>
      <Text style={styles(theme).labelStyle}>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the "Last updated"
        date at the top of this Privacy Policy.
      </Text>
      <Text style={styles(theme).labelStyle}>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </Text>
      <Text style={styles(theme).labelBoldStyle}>Contact Us</Text>
      <Text style={styles(theme).labelStyle}>
        If you have any questions about this Privacy Policy, You can contact us:
      </Text>
      <Text style={styles(theme).labelStyle}>
        By visiting this page on our website:
        https://www.kabutarapp.com/contactUs
      </Text>
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    labelStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },

    labelBoldStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },
  });

export default Privacy;
