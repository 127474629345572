import React, { useState, useContext, useEffect } from "react";
import { Text, View, StyleSheet, Platform, Image } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";

import { ThemeContext } from "../../context/ThemeContext";
import { NewKardContext } from "../../context/NewKardContext";
import { UserContext } from "../../context/UserContext";
import { SelectKard } from "../../assets/index";
const ChooseTypeForNewKard = () => {
  const navigation = useNavigation();
  const { user } = useContext(UserContext);

  const { theme, changeTheme } = useContext(ThemeContext);
  const {
    setKardType,
    setKard,
    setKardSelected,
    setPolygon,
    setFinalViews,
    setAddressKey,
    setDate,
  } = useContext(NewKardContext);
  const [chooseType, setChooseType] = useState(false);
  const isFocused = useIsFocused();

  const noKardUri =
    Platform.OS === "web"
      ? require("../../assets/selectKard.png")
      : Image.resolveAssetSource(SelectKard).uri;

  useEffect(() => {
    if (isFocused) {
      if (!user) {
        navigation.navigate("Registration");
      }
    }
  }, [isFocused]);

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>CHOOSE TYPE</Text>

        <TouchableOpacity onPress={() => setChooseType(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <Text style={styles(theme).textCenterStyle}>Step 1 of 5</Text>
      <View>
        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => {
            setKardType("free");
            setKard(noKardUri);
            setKardSelected(false);
            setPolygon([]);
            setFinalViews(50);
            setAddressKey(null);
            setDate(new Date());
            navigation.navigate("SelectNewKard");
          }}
        >
          <Text style={styles(theme).buttonTextStyle}>Free Kard</Text>
        </TouchableOpacity>
        <View style={styles(theme).rowGroupCenterStyle}>
          <Text style={styles(theme).textJustifyStyle}>
            charges - FREE, views range - 1 to 100, features - delete, post 7
            days in advance
          </Text>
        </View>
        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => {
            setKardType("basic");
            setKard(noKardUri);
            setKardSelected(false);
            setPolygon([]);
            setFinalViews(5000);
            setAddressKey(null);
            setDate(new Date());
            navigation.navigate("SelectNewKard");
          }}
        >
          <Text style={styles(theme).buttonTextStyle}>Basic Kard</Text>
        </TouchableOpacity>
        <View style={styles(theme).rowGroupCenterStyle}>
          <Text style={styles(theme).textJustifyStyle}>
            charges - ₹ 0.1 per view, views range - 101 to 10000, features -
            delete, pause, refund, post 30 days in advance
          </Text>
        </View>
        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => {
            setKardType("premium");
            setKard(noKardUri);
            setKardSelected(false);
            setPolygon([]);
            setFinalViews(50000);
            setAddressKey(null);
            setDate(new Date());
            navigation.navigate("SelectNewKard");
          }}
        >
          <Text style={styles(theme).buttonTextStyle}>Premium Kard</Text>
        </TouchableOpacity>
        <View style={styles(theme).rowGroupCenterStyle}>
          <Text style={styles(theme).textJustifyStyle}>
            charges - ₹ 0.1 per view, views range - 10001 to 100000, features -
            delete, pause, refund, change kard, post 60 days in advance
          </Text>
        </View>
        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => {
            setKardType("campaign");
            setKard(noKardUri);
            setKardSelected(false);
            setPolygon([]);
            setFinalViews(500000);
            setAddressKey(null);
            setDate(new Date());
            navigation.navigate("SelectNewKard");
          }}
        >
          <Text style={styles(theme).buttonTextStyle}>Campaign Kard</Text>
        </TouchableOpacity>
        <View style={styles(theme).rowGroupCenterStyle}>
          <Text style={styles(theme).textJustifyStyle}>
            charges - ₹ 0.1 per view, views range - 100001 to 10000000, features
            - delete, pause, refund, change kard, change display area, post 90
            days in advance
          </Text>
        </View>
      </View>
      <AwesomeAlert
        show={chooseType}
        title="CHOOSE TYPE"
        message="Any one can be selected. Charges, views and features are as indicated. Views are the number of times the kard is displayed. Views range is the minimum and maximum views for that type of kard. Delete, pause, refund, change kard, change display area and advance post time features are type specific."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setChooseType(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default ChooseTypeForNewKard;
