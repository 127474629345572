import React, { useState, useContext, useEffect } from "react";
import {
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
  Image,
} from "react-native";

import { API } from "aws-amplify";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";
import WebView from "react-native-webview";

import * as queries from "../../../src/graphql/queries";
import { Kabutar } from "../../../assets/index";
import { ThemeContext } from "../../../context/ThemeContext";
import { OrderContext } from "../../../context/OrderContext";

const TestUpiPollingScreen = (props) => {
  const { orderId } = useContext(OrderContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const navigation = useNavigation();
  const [paymentPending, setPaymentPending] = useState(false);
  const [timer, setTimer] = useState(480);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      setTimer(480);
    }
    let isSubscribed = true;
    setInterval(() => {
      if (isSubscribed) {
        stopClock();
      }
    }, 1000);
    return () => (isSubscribed = false);
  }, [isFocused]);

  const stopClock = () => {
    setTimer((state) => {
      setTimer(state - 1);
    });
  };
  const verifyPaymentStatus = async () => {
    const getPaymentStatus = await API.graphql({
      query: queries.getPayment,
      variables: {
        id: orderId,
      },
    });
    if (getPaymentStatus.data.getPayment == null) {
      setPaymentPending(true);
    } else {
      const paymentData = JSON.parse(getPaymentStatus.data.getPayment.data);
      const orderId = paymentData.ORDERID;
      const txnAmount = paymentData.TXNAMOUNT;
      const txnId = paymentData.TXNID;
      const status = paymentData.STATUS;
      if (status == "TXN_SUCCESS") {
        navigation.navigate("TestTransactionSuccess", {
          orderId: orderId,
          txnAmount: txnAmount,
          txnId: txnId,
        });
      } else {
        navigation.navigate("TestTransactionFailure", {
          orderId: orderId,
          message: "Payment was not succesfull. Please try again.",
        });
      }
    }
  };
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Image source={Kabutar} style={styles(theme).logoStyle} />
      <Text style={styles(theme).headerStyle}>WAITING FOR PAYMENT</Text>

      <View style={styles(theme).timerViewStyle}>
        {timer > 0 ? (
          <Text style={styles(theme).timerTextStyle}>
            {Math.floor(timer / 60)} :
            {timer % 60 < 10 ? " 0" + (timer % 60) : " " + (timer % 60)}
          </Text>
        ) : (
          <Text style={styles(theme).timerTextStyle}>0</Text>
        )}
      </View>

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          verifyPaymentStatus();
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Verify Payment Status</Text>
      </TouchableOpacity>
      <WebView
        style={styles(theme).webViewStyle}
        originWhitelist={["*"]}
        source={{ html: props.route.params.htmlForm }}
      />
      <AwesomeAlert
        show={paymentPending}
        title="PAYMENT PENDING"
        message="The payment is pending. If you have already paid, verify payment status after a few seconds. If you have not yet paid, please make payment and verify payment status. "
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setPaymentPending(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    logoStyle: {
      height: 70,
      width: 70,
      alignSelf: "center",
      margin: 10,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    timerViewStyle: {
      width: 150,
      height: 150,
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      borderWidth: 2,
      borderColor: theme.colors.borderColor,
      borderRadius: 100,
      margin: 10,
    },

    timerTextStyle: {
      color: theme.colors.textColor,
      fontSize: 25,
      fontWeight: "bold",
    },

    webViewStyle: {
      width: 1,
      height: 1,
      alignSelf: "center",
    },
    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default TestUpiPollingScreen;
