import React, { useContext } from "react";
import { StyleSheet, Text, ScrollView, TouchableOpacity } from "react-native";
import { ProfileContext } from "../../context/ProfileContext";
import { ThemeContext } from "../../context/ThemeContext";

const SelectAddressForNewKard = ({
  setAddressKey,
  addressModal,
  setAddressModal,
}) => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const { profile, setProfile } = useContext(ProfileContext);

  const selectPressedAddress = (key) => {
    setAddressKey(key);
    setAddressModal(false);
  };
  return (
    <ScrollView>
      <Text style={styles(theme).subHeaderStyle}>
        Press any address to select
      </Text>
      {profile.address
        ? profile.address.map((item, key) => {
            return (
              <TouchableOpacity
                onPress={() => selectPressedAddress(key)}
                key={key}
                style={styles(theme).boxStyle}
              >
                <Text style={styles(theme).textJustifyStyle}>
                  Address type : {item.type}
                </Text>
                <Text style={styles(theme).textJustifyStyle}>
                  Name:{item.name}
                </Text>
                <Text style={styles(theme).textJustifyStyle}>
                  {item.line1}, {item.line2}, {item.line3}
                </Text>

                <Text style={styles(theme).textJustifyStyle}>
                  State / Region : {item.region}, Country: {item.country} PIN /
                  ZIP : {item.zip}
                </Text>
              </TouchableOpacity>
            );
          })
        : null}
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    boxStyle: {
      width: "80%",
      borderWidth: 1,
      borderRadius: 10,
      margin: 10,
      padding: 10,
      alignSelf: "center",
      borderColor: theme.colors.borderColor,
    },
    textJustifyStyle: {
      margin: 5,
      color: theme.colors.standardTextColor,
      textAlign: "justify",
    },
    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },
  });

export default SelectAddressForNewKard;
