import React, { useContext } from "react";
import { StyleSheet } from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import OutlineTheme from "./OutlineTheme";
import FillTheme from "./FillTheme";
import { ThemeContext } from "../../context/ThemeContext";

const Tab = createMaterialTopTabNavigator();
const Themes = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarLabelStyle: styles(theme).tabTextStyle,
        tabBarStyle: styles(theme).tabBgStyle,
        tabBarIndicatorStyle: styles(theme).tabIndicatorStyle,
      }}
    >
      <Tab.Screen name="OutlineTheme" component={OutlineTheme} />
      <Tab.Screen name="FillTheme" component={FillTheme} />
    </Tab.Navigator>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    tabBgStyle: {
      backgroundColor: theme.colors.backgroundColor,
    },
    tabTextStyle: {
      fontSize: 12,
      fontWeight: "bold",
      color: theme.colors.textColor,
    },
    tabIndicatorStyle: {
      backgroundColor: theme.colors.textColor,
    },
  });

export default Themes;
