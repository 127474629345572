import React, { useContext, useEffect, useState } from "react";
import {
  Text,
  ScrollView,
  Image,
  View,
  StyleSheet,
  Platform,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import "react-native-get-random-values";
import { API, graphqlOperation } from "aws-amplify";
import AwesomeAlert from "react-native-awesome-alerts";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";
import { useNavigation, useIsFocused } from "@react-navigation/native";

import { ThemeContext } from "../../context/ThemeContext";
import * as mutations from "../../src/graphql/mutations";
import { UserContext } from "../../context/UserContext";
import * as queries from "../../src/graphql/queries";
import { NewKardContext } from "../../context/NewKardContext";
import config from "../../src/aws-exports";
import { ProfileContext } from "../../context/ProfileContext";
import { DisplayKardsContext } from "../../context/DisplayKardsContext";
import ShortUniqueId from "short-unique-id";
import * as Print from "expo-print";
import { shareAsync } from "expo-sharing";
import { ToWords } from "to-words";
const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

const CurrentKardDetails = (props) => {
  const id = props.route.params.id;
  const imageName = props.route.params.imageName;
  const { user } = useContext(UserContext);
  const { mapRegionForNewKard, polygon, setPolygon } =
    useContext(NewKardContext);
  const { profile } = useContext(ProfileContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const navigation = useNavigation();
  const [item, setItem] = useState();
  const [timer, setTimer] = useState(new Date().getTime());
  const [pauseModal, setPauseModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [changeKardModal, setChangeKardModal] = useState(false);
  const [changeDisplayAreaModal, setChangeDisplayAreaModal] = useState(false);
  const [refundItem, setRefundItem] = useState();
  const [refundModal, setRefundModal] = useState(false);
  const [refundRejectModal, setRefundRejectModal] = useState(false);
  const [refundSuccessModal, setRefundSuccessModal] = useState();
  const [refundFailureModal, setRefundFailureModal] = useState(false);
  const { setDisplayKards } = useContext(DisplayKardsContext);
  const isFocused = useIsFocused();
  const uid = new ShortUniqueId({ length: 29 });

  useEffect(() => {
    if (isFocused) {
      refreshKardData();
      setTimer(new Date().getTime());
    }
  }, [props, isFocused]);

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const constructPolygon = (item) => {
    var arr1 = [];
    var arr2 = [];

    arr1 = JSON.parse(item).coordinates;
    arr2 = arr1[0];
    arr2.map((item, index) => {
      var newItem = {};
      if (index != arr2.length - 1) {
        if (Platform.OS == "web") {
          newItem.lat = item[1];
          newItem.lng = item[0];
        } else {
          newItem.latitude = item[1];
          newItem.longitude = item[0];
        }
        setPolygon((prev) => [...prev, newItem]);
      }
    });
  };
  const refreshKardData = async () => {
    try {
      const newKard = await API.graphql({
        query: queries.getKard,
        variables: {
          id: id,
        },
      });
      setPolygon([]);
      constructPolygon(newKard.data.getKard.displayArea);
      setItem(newKard.data.getKard);
    } catch (err) {
      console.log(err);
    }
  };
  const pauseKard = async () => {
    try {
      await API.graphql(
        graphqlOperation(mutations.updateKard, {
          input: {
            id: id,
            kardStatus: "pause",
          },
        })
      );
      refreshKardData();
    } catch (err) {
      console.log(err);
    }
  };

  const activateKard = async () => {
    try {
      await API.graphql(
        graphqlOperation(mutations.updateKard, {
          input: {
            id: id,
            kardStatus: "active",
          },
        })
      );
      refreshKardData();
    } catch (err) {
      console.log(err);
    }
  };
  const deleteKard = async (item) => {
    try {
      const deleteItem = await API.graphql(
        graphqlOperation(mutations.updateKard, {
          input: {
            id: id,
            kardStatus: "delete",
            endDate: new Date().toISOString(),
            expiryDate: Math.round(
              new Date(Date.now() + 1000).getTime() / 1000
            ),
          },
        })
      );
      // console.log(deleteItem);
      if (item.kardType == "free") {
        var queueItems = await API.graphql({
          query: queries.listKardsByUserId,
          variables: {
            userId: profile.id,
            filter: {
              kardType: { eq: "free" },
              or: [
                { kardStatus: { eq: "active" } },
                { kardStatus: { eq: "queue" } },
              ],
            },
          },
        });
        // console.log(queueItems);
        var nextItemId = null;
        if (queueItems.data.listKardsByUserId.items.length != 0) {
          if (queueItems.data.listKardsByUserId.items.length == 1) {
            if (
              queueItems.data.listKardsByUserId.items[0].kardStatus != "active"
            ) {
              nextItemId = queueItems.data.listKardsByUserId.items[0].id;
            }
          }
          if (queueItems.data.listKardsByUserId.items.length == 2) {
            if (
              queueItems.data.listKardsByUserId.items[0].kardStatus ==
                "queue" &&
              queueItems.data.listKardsByUserId.items[1].kardStatus == "queue"
            ) {
              if (
                new Date(
                  queueItems.data.listKardsByUserId.items[0].transactionDate
                ).getTime() <
                new Date(
                  queueItems.data.listKardsByUserId.items[1].transactionDate
                ).getTime()
              ) {
                nextItemId = queueItems.data.listKardsByUserId.items[0].id;
              } else {
                nextItemId = queueItems.data.listKardsByUserId.items[1].id;
              }
            }
          }
          if (nextItemId != null) {
            // console.log("next item isnt null");
            await API.graphql(
              graphqlOperation(mutations.updateKard, {
                input: {
                  id: nextItemId,
                  kardStatus: "active",
                },
              })
            );
          }
        }
      }
      setDisplayKards([]);
      refreshKardData();
    } catch (err) {
      console.log(err);
    }
  };
  const initiateRefund = async () => {
    try {
      const refundInitiateRecord = await API.graphql({
        query: queries.getKard,
        variables: {
          id: id,
        },
      });
      setRefundItem(refundInitiateRecord.data.getKard);
      const refundAmount = (
        (refundInitiateRecord.data.getKard.charges -
          refundInitiateRecord.data.getKard.discount) *
          0.9 -
        refundInitiateRecord.data.getKard.shownViews * 0.1
      ).toFixed(2);
      const transactionId = refundInitiateRecord.data.getKard.transactionId;
      const refId = uid();
      if (refundAmount >= 100.0) {
        await makeRefund(refundAmount, transactionId, refId);
      } else {
        setRefundRejectModal(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const makeRefund = async (refundAmount, transactionId, refId) => {
    const token = user.signInUserSession.idToken.jwtToken;
    const refundParams = {
      headers: {
        Authorization: token,
      },
      body: {
        orderId: id,
        refundAmount: refundAmount,
        transactionId: transactionId,
        refId: refId,
      },
    };
    try {
      const initiateRefundResponse = await API.post(
        "paymentRestApi",
        "/kard/initiateRefund",
        refundParams
      );
      if (initiateRefundResponse.status == "success") {
        const refundCompleteRecord = await API.graphql(
          graphqlOperation(mutations.updateKard, {
            input: {
              id: id,
              kardStatus: "refund",
              refundTxnId: refId,
              refundTxnDate: new Date().toISOString(),
              refundAmount: refundAmount,
              endDate: new Date().toISOString(),
              expiryDate: Math.round(
                new Date(Date.now() + 1000).getTime() / 1000
              ),
            },
          })
        );
        setRefundItem(refundCompleteRecord.data.updateKard);
        setRefundSuccessModal(true);
      } else {
        setRefundFailureModal(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const generatePdf = () => {
    const totalAmountInWords = toWords.convert(item.total);
    const html = `
  <html>
    <body>
        <h1 style="text-align: center;">KABUTAR</h1>
        <h2 style="text-align: center;">https://www.kabutarapp.com</h2>
        <hr>
        <h3 style="text-align:center;">ONLINE PAYMENT RECEIPT</h3>
        <p>Order ID : ${item.id}</p>
        <p>Order Date : ${new Date(
          item.createdAt
        ).toLocaleDateString()} ${new Date(
      item.createdAt
    ).toLocaleTimeString()}</p>
        <p>Payment ID : ${
          item.transactionId == `nil` || item.transactionId == `pending`
            ? "<b>PAYMENT NOT MADE</b>"
            : item.transactionId
        }</p>
        <p>Payment Date : ${
          item.transactionId == `nil` || item.transactionId == `pending`
            ? "<b>PAYMENT NOT MADE</b>"
            : new Date(item.transactionDate).toLocaleDateString() +
              new Date(item.transactionDate).toLocaleTimeString()
        }
    </p>
${
  item.transactionId != `pending`
    ? `<p>Paid by : ${item.billingAddress.name}, ${item.billingAddress.line1}, ${item.billingAddress.line2}, ${item.billingAddress.line3}, ${item.billingAddress.state}, ${item.billingAddress.country}, ${item.billingAddress.zip}</p>

      <p>Payment Details :</p>
        <table>
            <tbody>
                <tr>
                    <td style="width: 600px;border: 1px solid black;padding-left: 20px;">
                        <p>Charges (${item.kardType} kard for ${item.setViews} views)</p>
                    </td>
                    <td style="width: 200px;border: 1px solid black;padding-left: 20px;">
                        <p>₹${item.charges}</p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 600px;border: 1px solid black;padding-left: 20px;">
                        <p>Discount</p>
                    </td>
                    <td style="width: 200px;border: 1px solid black;padding-left: 20px;">
                        <p>₹${item.discount}</p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 600px;border: 1px solid black;padding-left: 20px;">
                        <p>Taxes</p>
                    </td>
                    <td style="width: 200px;border: 1px solid black;padding-left: 20px;">
                        <p>₹${item.taxes}</p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 600px;border: 1px solid black;padding-left: 20px;">
                        <p>Total</p>
                    </td>
                    <td style="width: 200px;border: 1px solid black;padding-left: 20px;">
                        <p>₹${item.total}</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>Paid amount in words :</p>
        <p><b>${totalAmountInWords}</b></p>
`
    : `<p></p>`
}
      
        <p>This receipt was generated on : ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}</p>
        <h3 style="text-align:center;">THANK YOU FOR MAKING PAYMENT</h3>
    </body>
    
  </html>
  `;
    if (Platform.OS === "web") {
      const file = window.open("", "", "height=500, width=500");
      file.document.write(html);
      file.document.close();
      file.print();
    } else {
      const file = Print.printToFileAsync({
        html: html,
        base64: false,
      });
      shareAsync(file.uri);
    }
  };

  return item ? (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>KARD DETAILS</Text>

      <Text style={styles(theme).subHeaderStyle}>Kard</Text>
      <View>
        <Image
          source={{
            uri: `https://${bucket}.s3.${region}.amazonaws.com/public/${imageName}?key=${timer}`,
          }}
          style={styles(theme).kardStyle}
        />
        <View>
          <Text style={styles(theme).subHeaderStyle}>Display Area</Text>
        </View>
        <MapView
          style={styles(theme).kardStyle}
          provider={PROVIDER_GOOGLE}
          initialRegion={mapRegionForNewKard}
        >
          <MapView.Marker
            coordinate={mapRegionForNewKard}
            pinColor={theme.colors.standardTextColor}
          />
          {Platform.OS == "web" ? (
            <MapView.Polygon
              path={polygon}
              options={{
                strokeColor: theme.colors.standardTextColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: theme.colors.standardTextColor,
                fillOpacity: 0.35,
              }}
            />
          ) : (
            <MapView.Polygon
              coordinates={polygon}
              strokeColor={theme.colors.standardTextColor}
              fillColor={theme.colors.standardTextColor}
            />
          )}
        </MapView>
        <Text style={styles(theme).subHeaderStyle}>Display Details </Text>
        <View style={styles(theme).boxStyle}>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Kard Type: </Text>
            <Text style={styles(theme).textBoldStyle}>{item.kardType}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Kard Status: </Text>
            <Text style={styles(theme).textBoldStyle}>{item.kardStatus}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Views: </Text>
            <Text style={styles(theme).textBoldStyle}>{item.setViews}</Text>
          </View>

          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Displayed Views: </Text>
            <Text style={styles(theme).textBoldStyle}>{item.shownViews}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Likes: </Text>
            <Text style={styles(theme).textBoldStyle}>{item.likes}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Dislikes: </Text>
            <Text style={styles(theme).textBoldStyle}>{item.dislikes}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Downloads: </Text>
            <Text style={styles(theme).textBoldStyle}>{item.downloads}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Flags: </Text>
            <Text style={styles(theme).textBoldStyle}>{item.flags}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Starting Date: </Text>
            <Text style={styles(theme).textBoldStyle}>
              {new Date(item.startDate).toLocaleDateString()}
            </Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Starting Time: </Text>
            <Text style={styles(theme).textBoldStyle}>
              {new Date(item.startDate).toLocaleTimeString()}
            </Text>
          </View>
        </View>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Billing Details</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Charges: </Text>
          <Text style={styles(theme).textBoldStyle}>₹ {item.charges}</Text>
        </View>

        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Discount: </Text>
          <Text style={styles(theme).textBoldStyle}>₹ {item.discount}</Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Taxes: </Text>
          <Text style={styles(theme).textBoldStyle}>₹ {item.taxes}</Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total: </Text>
          <Text style={styles(theme).textBoldStyle}>₹ {item.total}</Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Payment Id: </Text>
          <Text style={styles(theme).textBoldStyle}>{item.transactionId}</Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Payment Date:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {new Date(item.transactionDate).toLocaleDateString()}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Payment Time:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {new Date(item.transactionDate).toLocaleTimeString()}
          </Text>
        </View>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Billing Address</Text>
      <View style={styles(theme).boxStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          Name: {item.billingAddress.name}, {item.billingAddress.line1},{" "}
          {item.billingAddress.line2}, {item.billingAddress.line3}, State /
          Region: {item.billingAddress.state}, Country:{" "}
          {item.billingAddress.country}, PIN / ZIP: {item.billingAddress.zip}
        </Text>
      </View>
      {(item.kardType == "basic" ||
        item.kardType == "premium" ||
        item.kardType == "campaign" ||
        item.kardType == "kampus" ||
        item.kardType == "promo") &&
        item.kardStatus == "active" && (
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => setPauseModal(true)}
          >
            <Text style={styles(theme).buttonTextStyle}>Pause</Text>
          </TouchableOpacity>
        )}
      {(item.kardType == "basic" ||
        item.kardType == "premium" ||
        item.kardType == "campaign" ||
        item.kardType == "kampus" ||
        item.kardType == "promo") &&
        item.kardStatus == "pause" && (
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => setActivateModal(true)}
          >
            <Text style={styles(theme).buttonTextStyle}>Activate</Text>
          </TouchableOpacity>
        )}
      {(item.kardType == "free" ||
        item.kardType == "basic" ||
        item.kardType == "premium" ||
        item.kardType == "campaign" ||
        item.kardType == "kampus" ||
        item.kardType == "promo") &&
        (item.kardStatus == "active" ||
          item.kardStatus == "queue" ||
          item.kardStatus == "pause") && (
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => setDeleteModal(true)}
          >
            <Text style={styles(theme).buttonTextStyle}>Delete</Text>
          </TouchableOpacity>
        )}

      {(item.kardType == "basic" ||
        item.kardType == "premium" ||
        item.kardType == "campaign") &&
        (item.kardStatus == "active" || item.kardStatus == "pause") &&
        (item.charges - item.discount) * 0.9 - item.shownViews * 0.1 >=
          100.0 && (
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => setRefundModal(true)}
          >
            <Text style={styles(theme).buttonTextStyle}>Refund</Text>
          </TouchableOpacity>
        )}
      {(item.kardType == "premium" || item.kardType == "campaign") &&
        (item.kardStatus == "active" || item.kardStatus == "pause") && (
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => setChangeKardModal(true)}
          >
            <Text style={styles(theme).buttonTextStyle}>Change Kard</Text>
          </TouchableOpacity>
        )}
      {item.kardType == "campaign" &&
        (item.kardStatus == "active" || item.kardStatus == "pause") && (
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => {
              setChangeDisplayAreaModal(true);
            }}
          >
            <Text style={styles(theme).buttonTextStyle}>
              Change Display Area
            </Text>
          </TouchableOpacity>
        )}
      {(item.kardType == "free" ||
        item.kardType == "basic" ||
        item.kardType == "premium" ||
        item.kardType == "campaign") &&
        (item.kardStatus == "active" ||
          item.kardStatus == "pause" ||
          item.kardStatus == "queue") && (
          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={generatePdf}
          >
            <Text style={styles(theme).buttonTextStyle}>
              Print Payment Receipt
            </Text>
          </TouchableOpacity>
        )}
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("CurrentKards")}
      >
        <Text style={styles(theme).buttonTextStyle}>Back</Text>
      </TouchableOpacity>
      <AwesomeAlert
        show={pauseModal}
        showProgress={false}
        title="PAUSE KARD"
        message="Are you sure you want to pause this kard?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, pause it"
        onCancelPressed={() => setPauseModal(false)}
        onConfirmPressed={() => {
          setPauseModal(false);
          pauseKard();
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={activateModal}
        showProgress={false}
        title="ACTIVATE KARD"
        message="Are you sure you want to activate this kard?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, activate it"
        onCancelPressed={() => setActivateModal(false)}
        onConfirmPressed={() => {
          setActivateModal(false);
          activateKard();
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={refundModal}
        showProgress={false}
        title="INITIATE REFUND REQUEST"
        message={`At ${new Date(
          timer
        ).toLocaleString()}, the kard was displayed ${
          item.shownViews
        } times out of set views of ${
          item.setViews
        } and the remaining eligible refund amount is ₹ ${(
          (item.charges - item.discount) * 0.9 -
          item.shownViews * 0.1
        ).toFixed(
          2
        )}. The exact refund amount will be recalculated after the refund request is made. Are you sure you want to initiate refund request?`}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, make refund"
        onCancelPressed={() => setRefundModal(false)}
        onConfirmPressed={() => {
          setRefundModal(false);
          initiateRefund();
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={deleteModal}
        showProgress={false}
        title="DELETE KARD"
        message="No refund for paid kards also. Are you sure you want to delete this kard?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, delete it"
        onCancelPressed={() => setDeleteModal(false)}
        onConfirmPressed={() => {
          setDeleteModal(false);
          deleteKard(item);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={changeKardModal}
        showProgress={false}
        title="CHANGE KARD "
        message="Are you sure you want to change this kard?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, change it"
        onCancelPressed={() => setChangeKardModal(false)}
        onConfirmPressed={() => {
          setChangeKardModal(false);
          navigation.navigate("ChangeKard", {
            id: id,
            imageName: imageName,
          });
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={changeDisplayAreaModal}
        showProgress={false}
        title="CHANGE DISPLAY AREA "
        message=" Are you sure you want to change the display area of this kard?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, change it"
        onCancelPressed={() => setChangeDisplayAreaModal(false)}
        onConfirmPressed={() => {
          setChangeDisplayAreaModal(false);
          navigation.navigate("ChangeDisplayArea", {
            id: id,
            imageName: imageName,
          });
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      {refundItem && (
        <AwesomeAlert
          show={refundRejectModal}
          showProgress={false}
          title="REFUND REQUEST REJECT"
          message={`The kard was displayed ${
            refundItem.shownViews
          } times out of set views of ${
            refundItem.setViews
          } and the eligible refund amount is only ₹ ${(
            (refundItem.charges - refundItem.discount) * 0.9 -
            refundItem.shownViews * 0.1
          ).toFixed(2)}. Amount less than ₹ 100.0 is not refunded`}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="Yes, got it"
          onConfirmPressed={() => {
            refreshKardData();
            setRefundRejectModal(false);
          }}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
          confirmButtonStyle={styles(theme).alertButtonStyle}
          confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
        />
      )}
      {refundItem && (
        <AwesomeAlert
          show={refundSuccessModal}
          showProgress={false}
          title="REFUND REQUEST SUCCESS"
          message={`The refund request is accepted. Refund request id is ${refundItem.refundTxnId}. An amount of ₹ ${refundItem.refundAmount} will be refunded within 3-5 working days.`}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="Ok, thanks"
          onConfirmPressed={() => {
            refreshKardData();
            setRefundSuccessModal(false);
          }}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
          confirmButtonStyle={styles(theme).alertButtonStyle}
          confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
        />
      )}
      {refundItem && (
        <AwesomeAlert
          show={refundFailureModal}
          showProgress={false}
          title="REFUND REQUEST FAILURE"
          message="The refund request failed. Please try again later or contact us."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="Ok, got it"
          onConfirmPressed={() => {
            setRefundFailureModal(false);
          }}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
          confirmButtonStyle={styles(theme).alertButtonStyle}
          confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
        />
      )}
    </ScrollView>
  ) : null;
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },
    kardStyle: {
      height: 550,
      width: 350,
      alignSelf: "center",
      borderRadius: 20,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },

    textBoldStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      margin: 5,
    },
    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    boxTextStyle: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default CurrentKardDetails;
