import React, { useContext, useEffect, useState } from "react";
import { View, ScrollView, StyleSheet, Text, Image } from "react-native";
import { API } from "aws-amplify";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native-gesture-handler";
import { ThemeContext } from "../../context/ThemeContext";

import * as queries from "../../src/graphql/queries";
import { UserContext } from "../../context/UserContext";
import { ProfileContext } from "../../context/ProfileContext";
import config from "../../src/aws-exports";
import { NewKardContext } from "../../context/NewKardContext";
import { DeviceContext } from "../../context/DeviceContext";
import { DisplayKardsContext } from "../../context/DisplayKardsContext";
const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

const CurrentKards = () => {
  const { user } = useContext(UserContext);
  const { setPolygon } = useContext(NewKardContext);
  const { profile } = useContext(ProfileContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const navigation = useNavigation();
  const [currentKards, setCurrentKards] = useState([]);
  const isFocused = useIsFocused();
  const { deviceType, setDeviceType } = useContext(DeviceContext);
  const { setDisplayKards } = useContext(DisplayKardsContext);

  const [timer, setTimer] = useState(new Date().getTime());

  const getCurrentKards = async () => {
    try {
      const currentKards = await API.graphql({
        query: queries.listKardsByUserId,
        variables: {
          userId: profile.id,
        },
      });
      setCurrentKards(currentKards.data.listKardsByUserId.items);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isFocused) {
      if (user) {
        getCurrentKards();
      } else {
        navigation.navigate("Registration");
      }
      setTimer(new Date().getTime());
    }
  }, [isFocused]);

  // useEffect(() => {
  //   if (user) {
  //     getCurrentKards();
  //   }
  // }, []);

  return currentKards.length != 0 ? (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>CURRENT KARDS</Text>

        <TouchableOpacity
          onPress={() => {
            getCurrentKards();
          }}
        >
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <View
        style={
          deviceType == "MOBILE"
            ? styles(theme).itemMobileViewStyle
            : styles(theme).itemViewStyle
        }
      >
        {currentKards.map((i, j) => {
          return (
            <TouchableOpacity
              key={j}
              style={styles(theme).itemStyle}
              onPress={() => {
                setPolygon([]);
                setCurrentKards([]);
                navigation.navigate("CurrentKardDetails", {
                  id: i.id,
                  imageName: i.imageName,
                });
              }}
            >
              <Image
                source={{
                  uri: `https://${bucket}.s3.${region}.amazonaws.com/public/${i.imageName}?key=${timer}`,
                }}
                style={styles(theme).itemImageStyle}
              />
              <View style={styles(theme).itemTextStyle}>
                <Text style={styles(theme).textStyle}>
                  Kard Type: {i.kardType}
                </Text>
                <Text style={styles(theme).textStyle}>
                  Kard Status: {i.kardStatus}
                </Text>
                <Text style={styles(theme).textStyle}>
                  Total Views: {i.setViews}
                </Text>
                <Text style={styles(theme).textStyle}>
                  Current Views: {i.shownViews}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("PreviousKards")}
      >
        <Text style={styles(theme).buttonTextStyle}>Previous Kards</Text>
      </TouchableOpacity>
    </ScrollView>
  ) : (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>CURRENT KARDS</Text>

        <TouchableOpacity
          style={styles(theme).refreshIconStyle}
          onPress={() => {
            getCurrentKards();
            setTimer(new Date().getTime());
          }}
        >
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <View style={styles(theme).rowGroupCenterStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          You do not have any current kards
        </Text>
      </View>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("PreviousKards")}
      >
        <Text style={styles(theme).buttonTextStyle}>Previous Kards</Text>
      </TouchableOpacity>
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: { flex: 1, backgroundColor: "white" },

    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },
    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 3,
      backgroundColor: theme.colors.backgroundColor,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },
    itemViewStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      justifyContent: "space-around",
    },
    itemMobileViewStyle: {
      flexDirection: "column",
      alignSelf: "center",
    },
    itemStyle: {
      margin: 10,
      flexDirection: "row",
      borderWidth: 2,
      borderColor: theme.colors.borderColor,
      borderRadius: 10,
    },
    itemImageStyle: {
      height: 150,
      width: 100,
    },
    itemTextStyle: {
      flexDirection: "column",
      justifyContent: "space-evenly",
      paddingRight: 10,
      paddingLeft: 10,
      alignItems: "flex-start",
    },
  });

export default CurrentKards;
