import React, { createContext, useState } from "react";
import { DefaultO } from "../data/Themes";

export const ThemeContext = createContext(null);

export default ({ children }) => {
  const [theme, changeTheme] = useState(DefaultO);
  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
