import React, { useState, useContext } from "react";
import {
  View,
  Share,
  Linking,
  Platform,
  StyleSheet,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
} from "react-native";
import {
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  HatenaShareButton,
  HatenaIcon,
  InstapaperShareButton,
  InstapaperIcon,
  LineShareButton,
  LineIcon,
  LinkedinShareButton,
  LinkedinIcon,
  LivejournalShareButton,
  LivejournalIcon,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  OKIcon,
  PinterestShareButton,
  PinterestIcon,
  PocketShareButton,
  PocketIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
  ViberShareButton,
  ViberIcon,
  VKShareButton,
  VKIcon,
  WeiboShareButton,
  WeiboIcon,
  WorkplaceShareButton,
  WorkplaceIcon,
} from "react-share";
import { ThemeContext } from "../context/ThemeContext";
import { Gmail } from "../assets/index";

const ShareUs = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const [allIcons, setAllIcons] = useState(false);
  const kabutarWebsiteUrl = "https://www.kabutarapp.com/";
  const message =
    "Kabutar is an app that delivers information to your area. It is a social media platform for sharing geo location based information. It is a single stop information board aka kard board for users in any area. Check it out here! ";

  const onShare = async () => {
    try {
      Share.share({
        message: message + kabutarWebsiteUrl,
      });
    } catch (error) {
      alert(error.message);
    }
  };

  const linkShare = async () => {
    await Linking.openURL(
      `https://mail.google.com/mail/u/0/?fs=1&to&su=An+intresting+and+useful+app!&body=${
        message + kabutarWebsiteUrl
      }&ui=2&tf=cm`
    );
  };
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>SHARE US</Text>
      {Platform.OS == "web" ? (
        <View>
          <View style={styles(theme).rowGroupCenterStyle}>
            <Text style={styles(theme).textJustifyStyle}>
              How would you like to share us?
            </Text>
          </View>

          {allIcons ? (
            <View>
              <View style={styles(theme).rowGroupSpaceEvenlyStyle}>
                <View style={styles(theme).shareIconStyle}>
                  <FacebookShareButton url={kabutarWebsiteUrl} quote={message}>
                    <FacebookIcon size={50} round />
                  </FacebookShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <WhatsappShareButton url={kabutarWebsiteUrl} title={message}>
                    <WhatsappIcon size={50} round />
                  </WhatsappShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <TwitterShareButton url={kabutarWebsiteUrl} title={message}>
                    <TwitterIcon size={50} round />
                  </TwitterShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <RedditShareButton url={kabutarWebsiteUrl} title={message}>
                    <RedditIcon size={50} round />
                  </RedditShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <TouchableOpacity onPress={() => linkShare()}>
                    <Image
                      source={Gmail}
                      style={{ height: 50, width: 50, alignSelf: "center" }}
                    />
                  </TouchableOpacity>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <EmailShareButton
                    url={kabutarWebsiteUrl}
                    subject="An intresting and useful app!"
                    body={message}
                  >
                    <EmailIcon size={50} round />
                  </EmailShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <HatenaShareButton url={kabutarWebsiteUrl} title={message}>
                    <HatenaIcon size={50} round />
                  </HatenaShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <InstapaperShareButton
                    url={kabutarWebsiteUrl}
                    title="An intresting and useful app!"
                    description={message}
                  >
                    <InstapaperIcon size={50} round />
                  </InstapaperShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <LineShareButton url={kabutarWebsiteUrl} title={message}>
                    <LineIcon size={50} round />
                  </LineShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <LinkedinShareButton
                    url={kabutarWebsiteUrl}
                    title="An intresting and useful app!"
                    summary={message}
                    source="I found the app on the internet"
                  >
                    <LinkedinIcon size={50} round />
                  </LinkedinShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <LivejournalShareButton
                    url={kabutarWebsiteUrl}
                    title="An intresting and useful app!"
                    description={message}
                  >
                    <LivejournalIcon size={50} round />
                  </LivejournalShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <MailruShareButton
                    url={kabutarWebsiteUrl}
                    title="An intresting and useful app!"
                    description={message}
                    imageUrl={kabutarWebsiteUrl}
                  >
                    <MailruIcon size={50} round />
                  </MailruShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <OKShareButton
                    url={kabutarWebsiteUrl}
                    title="An intresting and useful app!"
                    description={message}
                    image={kabutarWebsiteUrl}
                  >
                    <OKIcon size={50} round />
                  </OKShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <PinterestShareButton
                    url={kabutarWebsiteUrl}
                    description={message}
                  >
                    <PinterestIcon size={50} round />
                  </PinterestShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <PocketShareButton url={kabutarWebsiteUrl} title={message}>
                    <PocketIcon size={50} round />
                  </PocketShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <TelegramShareButton url={kabutarWebsiteUrl} title={message}>
                    <TelegramIcon size={50} round />
                  </TelegramShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <TumblrShareButton
                    url={kabutarWebsiteUrl}
                    title="An intresting and useful app!"
                    caption={message}
                  >
                    <TumblrIcon size={50} round />
                  </TumblrShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <ViberShareButton url={kabutarWebsiteUrl} title={message}>
                    <ViberIcon size={50} round />
                  </ViberShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <VKShareButton
                    url={kabutarWebsiteUrl}
                    title={message}
                    image={kabutarWebsiteUrl}
                  >
                    <VKIcon size={50} round />
                  </VKShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <WeiboShareButton
                    url={kabutarWebsiteUrl}
                    title={message}
                    image={kabutarWebsiteUrl}
                  >
                    <WeiboIcon size={50} round />
                  </WeiboShareButton>
                </View>
                <View style={styles(theme).shareIconStyle}>
                  <WorkplaceShareButton url={kabutarWebsiteUrl} quote={message}>
                    <WorkplaceIcon size={50} round />
                  </WorkplaceShareButton>
                </View>
              </View>
              <TouchableOpacity
                style={styles(theme).buttonStyle}
                onPress={() => setAllIcons(!allIcons)}
              >
                <Text style={styles(theme).buttonTextStyle}>Show Less</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View>
              <View style={styles(theme).rowGroupSpaceEvenlyStyle}>
                <FacebookShareButton url={kabutarWebsiteUrl} quote={message}>
                  <FacebookIcon size={50} round />
                </FacebookShareButton>
                <WhatsappShareButton url={kabutarWebsiteUrl} title={message}>
                  <WhatsappIcon size={50} round />
                </WhatsappShareButton>
                <TwitterShareButton url={kabutarWebsiteUrl} title={message}>
                  <TwitterIcon size={50} round />
                </TwitterShareButton>
                <RedditShareButton url={kabutarWebsiteUrl} title={message}>
                  <RedditIcon size={50} round />
                </RedditShareButton>
                <TouchableOpacity onPress={() => linkShare()}>
                  <Image
                    source={Gmail}
                    style={{ height: 50, width: 50, alignSelf: "center" }}
                  />
                </TouchableOpacity>
              </View>
              <TouchableOpacity
                style={styles(theme).buttonStyle}
                onPress={() => setAllIcons(!allIcons)}
              >
                <Text style={styles(theme).buttonTextStyle}>Show More</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      ) : (
        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => onShare()}
        >
          <Text style={styles(theme).buttonTextStyle}>Share Us</Text>
        </TouchableOpacity>
      )}
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    rowGroupSpaceEvenlyStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: 10,
    },

    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },
    shareIconStyle: {
      marginBottom: 30,
      flexBasis: "20%",
    },
  });

export default ShareUs;
