import React, { useContext, useState } from "react";
import { Text, View, StyleSheet } from "react-native";
import { Button } from "react-native-paper";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { ThemeContext } from "../../../context/ThemeContext";
import { PromoKardContext } from "../../../context/PromoKardContext";
import CustomNumberInput from "../../../components/CustomNumberInput";

const SetViewsForPromoKard = ({ setViewsModal }) => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const [tempViews, setTempViews] = useState(finalViews);
  const { finalViews, setFinalViews } = useContext(PromoKardContext);
  const viewsRange = "1 to 10000000";
  const initialValues = {
    views: finalViews,
  };
  const validationSchema = Yup.object({
    views: Yup.number().required("Reqiured").min(1).max(10000000),
  });

  const onSubmit = (values) => {
    setFinalViews(values.views);
    setViewsModal(false);
  };

  return (
    <View style={styles(theme).viewModalScreenStyle}>
      <View style={styles(theme).viewModalBoxStyle}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isValid, values }) => (
            <View>
              <Text style={styles(theme).viewModalTextStyle}>
                Set Views : {values.views}
              </Text>
              <Text style={styles(theme).viewModalTextStyle}>
                Kard Type : promo
              </Text>
              <Text style={styles(theme).viewModalTextStyle}>
                Views Range : {viewsRange}
              </Text>

              <Field
                component={CustomNumberInput}
                name="views"
                placeholder="Views"
                keyboardType="numeric"
              />
              <View style={styles(theme).viewModalButtonStyle}>
                <Button
                  color={theme.colors.standardTextColor}
                  onPress={() => {
                    setViewsModal(false);
                    setTempViews(finalViews);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color={theme.colors.standardTextColor}
                  onPress={handleSubmit}
                  disabled={!isValid}
                >
                  Ok
                </Button>
              </View>
            </View>
          )}
        </Formik>
      </View>
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    viewModalScreenStyle: {
      flex: 1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
    },
    viewModalBoxStyle: {
      width: "80%",
      backgroundColor: "white",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
    },

    viewModalTextStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      fontWeight: "bold",
    },
    viewModalButtonStyle: {
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: 20,
    },
  });
export default SetViewsForPromoKard;
