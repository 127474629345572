import React, { useState, useContext } from "react";
import {
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
  TextInput,
  Image,
} from "react-native";

import { API } from "aws-amplify";
import axios from "axios";
import { useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";
import upiHandles from "./UpiHandles";

import {
  GPay,
  PhonePe,
  Paytm,
  AmazonPay,
  WhatsappPay,
  Bhim,
  Kabutar,
} from "../../../assets/index";
import { ThemeContext } from "../../../context/ThemeContext";
import { UserContext } from "../../../context/UserContext";
import { OrderContext } from "../../../context/OrderContext";

const KardUpiIdPayment = () => {
  const { orderId, amount } = useContext(OrderContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const navigation = useNavigation();
  const [upiId, setUpiId] = useState("");
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const [upiIdError, setUpiIdError] = useState(false);

  const [initiateTransactionError, setInitiateTransactionError] =
    useState(false);
  const [initiateTransactionErrorMessage, setInitiateTransactionErrorMessage] =
    useState("");

  const verifyUpiHandle = () => {
    var isValid = false;
    upiHandles.map((i, k) => {
      if (upiId.includes(i)) {
        isValid = true;
      }
    });
    return isValid;
  };

  const makeUpiIdPayment = async () => {
    const token = user.signInUserSession.idToken.jwtToken;
    const isValid = verifyUpiHandle();

    if (isValid) {
      const initiateTransactionParams = {
        headers: {
          Authorization: token,
        },
        body: {
          orderId: orderId,
          value: amount,
          currency: "INR",
          custId: user.attributes.sub,
          vpa: upiId,
        },
      };

      const initiateTransactionResponse = await API.post(
        "paymentRestApi",
        "/kard/upiInitiateTransaction",
        initiateTransactionParams
      );
      if (initiateTransactionResponse.status == "success") {
        var content = initiateTransactionResponse.content;
        var processTransactionUrl =
          initiateTransactionResponse.processTransactionUrl;
        var htmlForm =
          '<html><body onload="document.forms[0].submit();"><form action ="' +
          processTransactionUrl +
          '" method ="POST" >';

        for (var name in content) {
          htmlForm =
            htmlForm +
            '<input type="hidden" name="' +
            name +
            '" value=' +
            content[name] +
            ">";
        }

        htmlForm = htmlForm + "</form></html>";

        navigation.navigate("KardUpiPollingScreen", {
          htmlForm: htmlForm,
        });
      } else {
        setInitiateTransactionErrorMessage(
          initiateTransactionResponse.data.body.resultInfo.resultMsg
        );
        setInitiateTransactionError(true);
      }
    } else {
      setUpiIdError(true);
    }
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Image source={Kabutar} style={styles(theme).logoStyle} />
      <Text style={styles(theme).headerStyle}>Pay ₹ {amount}</Text>

      <View style={styles(theme).rowGroupCenterStyle}>
        <Image source={GPay} style={styles(theme).upiIconStyle} />
        <Text style={styles(theme).textStyle}>|</Text>

        <Image source={PhonePe} style={styles(theme).upiIconStyle} />
        <Text style={styles(theme).textStyle}>|</Text>

        <Image source={Paytm} style={styles(theme).upiIconStyle} />
        <Text style={styles(theme).textStyle}>|</Text>

        <Image source={AmazonPay} style={styles(theme).upiIconStyle} />
        <Text style={styles(theme).textStyle}>|</Text>

        <Image source={WhatsappPay} style={styles(theme).upiIconStyle} />
        <Text style={styles(theme).textStyle}>|</Text>

        <Image source={Bhim} style={styles(theme).upiIconStyle} />
        <Text style={styles(theme).textStyle}>| any UPI</Text>
      </View>

      <Text style={styles(theme).textCenterStyle}>Enter VPA / UPI ID</Text>

      <TextInput
        onChangeText={(e) => setUpiId(e)}
        name="upiId"
        style={styles(theme).inputStyle}
      />
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => makeUpiIdPayment()}
      >
        <Text style={styles(theme).buttonTextStyle}>Pay Securely</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setChangePaymentMethod(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>Change Payment Method</Text>
      </TouchableOpacity>

      <AwesomeAlert
        show={changePaymentMethod}
        title="CONFIRM CHANGE PAYMENT METHOD"
        message="Are you sure you want to change payment method ?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, change it"
        // confirmButtonColor="#DD6B55"
        onCancelPressed={() => setChangePaymentMethod(false)}
        onConfirmPressed={() => {
          setUpiId("");
          setChangePaymentMethod(false);
          navigation.navigate("SelectKardPaymentMethod");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={upiIdError}
        title="UPI ID ERROR"
        message={"The UPI ID is not valid. Please check again."}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setUpiIdError(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={initiateTransactionError}
        title="UPI ID VALIDATION ERROR"
        message={initiateTransactionErrorMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setInitiateTransactionError(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    logoStyle: {
      height: 70,
      width: 70,
      alignSelf: "center",
      margin: 10,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },

    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },

    upiIconStyle: {
      height: 25,
      width: 25,
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    inputStyle: {
      width: "80%",
      height: 50,
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
    },
    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default KardUpiIdPayment;
