import React, { useContext, useEffect, useState } from "react";
import {
  Text,
  ScrollView,
  Image,
  View,
  StyleSheet,
  Platform,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import "react-native-get-random-values";
import { API, Storage } from "aws-amplify";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";

import { ThemeContext } from "../../context/ThemeContext";
import { NewKardContext } from "../../context/NewKardContext";
import config from "../../src/aws-exports";
import * as mutations from "../../src/graphql/mutations";
import { ProfileContext } from "../../context/ProfileContext";

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

const PreviousKardDetails = (props) => {
  const navigation = useNavigation();
  const item = props.route.params.item;
  const { profile } = useContext(ProfileContext);
  const { mapRegionForNewKard, polygon, setPolygon } =
    useContext(NewKardContext);
  const isFocused = useIsFocused();
  const { theme, changeTheme } = useContext(ThemeContext);

  const [removeModal, setRemoveModal] = useState(false);

  useEffect(() => {
    if (isFocused) {
      constructPolygon();
    }
  }, [props, isFocused]);

  const removePreviousKard = async () => {
    const removeKard = await API.graphql({
      query: mutations.removeKard,
      variables: {
        input: {
          id: profile.id,
          imageName: item.imageName,
        },
      },
    });
    // console.log(removeKard);
  };

  const constructPolygon = () => {
    var arr1 = [];
    var arr2 = [];
    arr1 = JSON.parse(item.displayArea).coordinates;
    arr2 = arr1[0];
    arr2.map((item, index) => {
      var newItem = {};
      if (index != arr2.length - 1) {
        if (Platform.OS == "web") {
          newItem.lat = item[1];
          newItem.lng = item[0];
        } else {
          newItem.latitude = item[1];
          newItem.longitude = item[0];
        }
        setPolygon((prev) => [...prev, newItem]);
      }
    });
  };
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>PREVIOUS KARD DETAILS</Text>

      <Text style={styles(theme).subHeaderStyle}>Kard</Text>
      <View>
        <Image
          source={{
            uri: `https://${bucket}.s3.${region}.amazonaws.com/temporary-data/${profile.id}/images/${item.imageName}`,
            // uri: kard,
          }}
          style={styles(theme).kardStyle}
        />
        <View>
          <Text style={styles(theme).subHeaderStyle}>Display Area</Text>
        </View>
        <MapView
          style={styles(theme).kardStyle}
          provider={PROVIDER_GOOGLE}
          initialRegion={mapRegionForNewKard}
        >
          <MapView.Marker
            coordinate={mapRegionForNewKard}
            pinColor={theme.colors.standardTextColor}
          />
          {Platform.OS == "web" ? (
            <MapView.Polygon
              path={polygon}
              options={{
                strokeColor: theme.colors.standardTextColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: theme.colors.standardTextColor,
                fillOpacity: 0.35,
              }}
            />
          ) : (
            <MapView.Polygon
              coordinates={polygon}
              strokeColor={theme.colors.standardTextColor}
              fillColor={theme.colors.standardTextColor}
            />
          )}
        </MapView>
        <Text style={styles(theme).subHeaderStyle}>Display Details </Text>
        <View style={styles(theme).boxStyle}>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Kard Type : </Text>
            <Text style={styles(theme).textBoldStyle}>{item.kardType}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Kard Status : </Text>
            <Text style={styles(theme).textBoldStyle}>{item.kardStatus}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Views : </Text>
            <Text style={styles(theme).textBoldStyle}>{item.setViews}</Text>
          </View>

          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Displayed Views : </Text>
            <Text style={styles(theme).textBoldStyle}>{item.shownViews}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Likes : </Text>
            <Text style={styles(theme).textBoldStyle}>{item.likes}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Dislikes : </Text>
            <Text style={styles(theme).textBoldStyle}>{item.dislikes}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Downloads : </Text>
            <Text style={styles(theme).textBoldStyle}>{item.downloads}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Flags : </Text>
            <Text style={styles(theme).textBoldStyle}>{item.flags}</Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Starting Date : </Text>
            <Text style={styles(theme).textBoldStyle}>
              {new Date(item.startDate).toLocaleDateString()}
            </Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Starting Time : </Text>
            <Text style={styles(theme).textBoldStyle}>
              {new Date(item.startDate).toLocaleTimeString()}
            </Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>End Date : </Text>
            <Text style={styles(theme).textBoldStyle}>
              {new Date(item.endDate).toLocaleDateString()}{" "}
            </Text>
          </View>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>End Time : </Text>
            <Text style={styles(theme).textBoldStyle}>
              {new Date(item.endDate).toLocaleTimeString()}
            </Text>
          </View>
        </View>

        <Text style={styles(theme).subHeaderStyle}>Billing Details</Text>
        <View style={styles(theme).boxStyle}>
          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Charges : </Text>
            <Text style={styles(theme).textBoldStyle}>₹ {item.charges}</Text>
          </View>

          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Discount : </Text>
            <Text style={styles(theme).textBoldStyle}>₹ {item.discount}</Text>
          </View>

          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Taxes : </Text>
            <Text style={styles(theme).textBoldStyle}>₹ {item.taxes}</Text>
          </View>

          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Total : </Text>
            <Text style={styles(theme).textBoldStyle}>₹ {item.total}</Text>
          </View>

          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}>Payment Id : </Text>
            <Text style={styles(theme).textBoldStyle}>
              {item.transactionId}
            </Text>
          </View>

          <View style={styles(theme).boxTextStyle}>
            <Text style={styles(theme).textStyle}> Payment Date : </Text>
            <Text style={styles(theme).textBoldStyle}>
              {new Date(item.transactionDate).toLocaleDateString()}
            </Text>
          </View>

          {item.kardStatus == "refund" && (
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund Amount : </Text>
              <Text style={styles(theme).textBoldStyle}>
                ₹ {item.refundAmount}
              </Text>
            </View>
          )}
          {item.kardStatus == "refund" && (
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund Id : </Text>
              <Text style={styles(theme).textBoldStyle}>
                {item.refundTxnId}
              </Text>
            </View>
          )}
          {item.kardStatus == "refund" && (
            <View style={styles(theme).boxTextStyle}>
              <Text style={styles(theme).textStyle}>Refund Date : </Text>
              <Text style={styles(theme).textBoldStyle}>
                {new Date(item.refundTxnDate).toLocaleDateString()}
              </Text>
            </View>
          )}
        </View>
        <Text style={styles(theme).subHeaderStyle}>Billing Address</Text>
        <View style={styles(theme).boxStyle}>
          <Text style={styles(theme).textJustifyStyle}>
            Name: {item.billingAddress.name},{item.billingAddress.line1},{" "}
            {item.billingAddress.line2},{item.billingAddress.line3}, State /
            Region : {item.billingAddress.state}, Country:{" "}
            {item.billingAddress.country}, PIN / ZIP : {item.billingAddress.zip}
          </Text>
        </View>
      </View>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setRemoveModal(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>Remove</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("PreviousKards")}
      >
        <Text style={styles(theme).buttonTextStyle}>Back</Text>
      </TouchableOpacity>
      <AwesomeAlert
        show={removeModal}
        showProgress={false}
        title="REMOVE PREVIOUS KARD"
        message=" Previous kards are automatically removed after 90 days. Do you want to remove  this previous kard now?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, remove it"
        onCancelPressed={() => setRemoveModal(false)}
        onConfirmPressed={() => {
          setRemoveModal(false);
          removePreviousKard();
          navigation.navigate("PreviousKards");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },
    kardStyle: {
      height: 550,
      width: 350,
      alignSelf: "center",
      borderRadius: 20,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },

    textBoldStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      margin: 5,
    },
    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    boxTextStyle: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default PreviousKardDetails;
