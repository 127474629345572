import React, { useContext } from "react";
import { TouchableOpacity, Image, StyleSheet, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { ThemeContext } from "../../context/ThemeContext";
import icon from "../../assets/icon.png";
const HeaderTitle = () => {
  const navigation = useNavigation();
  const { theme, changeTheme } = useContext(ThemeContext);

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate("Home")}
      style={styles(theme).headerViewStyle}
    >
      <Image source={icon} style={styles(theme).image} />
      <Text style={styles(theme).label}>kabutar</Text>
    </TouchableOpacity>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    headerViewStyle: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      height: 50,
      width: 50,
      marginLeft: 15,
      borderRadius: 10,
    },
    label: {
      fontSize: 25,
      marginLeft: 15,
      fontWeight: "bold",
      alignSelf: "center",
      color: theme.colors.textColor,
    },
  });

export default HeaderTitle;
