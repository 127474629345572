import React, { useState, useContext } from "react";
import {
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
  Image,
} from "react-native";

import { API } from "aws-amplify";
import { useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";
import { Picker } from "@react-native-picker/picker";

import { Kabutar } from "../../../assets/index";
import { ThemeContext } from "../../../context/ThemeContext";
import { UserContext } from "../../../context/UserContext";
import { OrderContext } from "../../../context/OrderContext";
import { availableBanks } from "./AvailableBanks";

import {
  SbiBank,
  IciciBank,
  HdfcBank,
  AxisBank,
  PnbBank,
  YesBank,
  KotakBank,
  IndusBank,
  BarodaBank,
  BoiBank,
} from "../../../assets/index";
const KampusNetBankingPayment = () => {
  const { orderId, amount } = useContext(OrderContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const navigation = useNavigation();

  const [bankCode, setBankCode] = useState("");
  const [bankCodeError, setBankCodeError] = useState(false);
  const [initiateTransactionError, setInitiateTransactionError] =
    useState(false);
  const [initiateTransactionErrorMessage, setInitiateTransactionErrorMessage] =
    useState("");
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const [htmlForm, setHtmlForm] = useState("");
  const [bankNotAvailable, setBankNotAvailable] = useState(false);
  var availableBankNames = availableBanks.map((i) => {
    return " " + i["name"];
  });
  const banksNotAvailableMessage =
    "The payment gateway integration with this bank for net banking is under process. At present net banking is available for" +
    availableBankNames +
    ". You can also use other payment methods.";
  const isThisBankAvailable = availableBanks.some((i) => i.code == bankCode);

  const makeNetBankingPayment = async () => {
    const token = user.signInUserSession.idToken.jwtToken;
    if (bankCode != "") {
      if (isThisBankAvailable) {
        const initiateTransactionParams = {
          headers: {
            Authorization: token,
          },
          body: {
            orderId: orderId,
            value: amount,
            currency: "INR",
            custId: user.attributes.sub,
            channelCode: bankCode,
          },
        };
        const initiateTransactionResponse = await API.post(
          "paymentRestApi",
          "/kampus/nbInitiateTransaction",
          initiateTransactionParams
        );
        if (initiateTransactionResponse.status == "success") {
          // var processTransactionUrl = `https://securegw-stage.paytm.in/theia/api/v1/processTransaction?mid=${mid}&orderId=${orderId}`;
          var content = initiateTransactionResponse.content;
          var processTransactionUrl =
            initiateTransactionResponse.processTransactionUrl;
          var htmlForm =
            '<html><body onload="document.forms[0].submit();"><form action ="' +
            processTransactionUrl +
            '" method ="POST" >';
          for (var name in content) {
            htmlForm =
              htmlForm +
              '<input type="hidden" name="' +
              name +
              '" value=' +
              content[name] +
              ">";
          }

          htmlForm = htmlForm + "</form></html>";

          setHtmlForm(htmlForm);
          navigation.navigate("HtmlFormPost", {
            htmlForm: htmlForm,
          });
        } else {
          setInitiateTransactionErrorMessage(
            initiateTransactionResponse.data.body.resultInfo.resultMsg
          );
          setInitiateTransactionError(true);
        }
      } else {
        setBankNotAvailable(true);
      }
    } else {
      setBankCodeError(true);
    }
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Image source={Kabutar} style={styles(theme).logoStyle} />
      <Text style={styles(theme).headerStyle}>PAY ₹ {amount}</Text>
      <Text style={styles(theme).textCenterStyle}>---- POPULAR BANKS ----</Text>
      <View style={styles(theme).rowGroupSpaceEvenlyStyle}>
        <TouchableOpacity onPress={() => setBankCode("SBI")}>
          <Image source={SbiBank} style={styles(theme).bankLogoStyle} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setBankCode("ICICI")}>
          <Image source={IciciBank} style={styles(theme).bankLogoStyle} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setBankCode("HDFC")}>
          <Image source={HdfcBank} style={styles(theme).bankLogoStyle} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setBankCode("AXIS")}>
          <Image source={AxisBank} style={styles(theme).bankLogoStyle} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setBankCode("PNB")}>
          <Image source={PnbBank} style={styles(theme).bankLogoStyle} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setBankCode("YES")}>
          <Image source={YesBank} style={styles(theme).bankLogoStyle} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setBankCode("NKMB")}>
          <Image source={KotakBank} style={styles(theme).bankLogoStyle} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setBankCode("INDS")}>
          <Image source={IndusBank} style={styles(theme).bankLogoStyle} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setBankCode("BOB")}>
          <Image source={BarodaBank} style={styles(theme).bankLogoStyle} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setBankCode("BOI")}>
          <Image source={BoiBank} style={styles(theme).bankLogoStyle} />
        </TouchableOpacity>
      </View>
      <Text style={styles(theme).textCenterStyle}>---- OTHER BANKS ----</Text>
      <Picker
        style={styles(theme).pickerStyle}
        onValueChange={(itemValue, itemIndex) => setBankCode(itemValue)}
      >
        <Picker.Item
          key="unselectable"
          label="Select any other bank"
          value=""
        />
        <Picker.Item label="AIRTEL PAYMENTS BANK" value="AIRP" />
        <Picker.Item label="ALLAHABAD BANK" value="ALH" />
        <Picker.Item label="ANDHRA BANK" value="ANDB" />
        <Picker.Item label="AU SMALL FINANCE BANK" value="AUBL" />
        <Picker.Item label="BANDHAN BANK LTD" value="BBL" />
        <Picker.Item label="BHARAT BANK" value="BHARAT" />
        <Picker.Item label="BANK OF BAHRAIN AND KUWAIT" value="BBK" />
        <Picker.Item label="BANK OF MAHARASHTRA" value="BOM" />
        <Picker.Item label="CENTRAL BANK OF INDIA" value="CBI" />
        <Picker.Item label="CATHOLIC SYRIAN BANK" value="CSB" />
        <Picker.Item label="CANARA BANK" value="CANARA" />
        <Picker.Item label="CITY UNION BANK" value="CITIUB" />
        <Picker.Item label="CORPORATION BANK" value="CORP" />
        <Picker.Item label="COSMOS BANK" value="COSMOS" />
        <Picker.Item label="DCB BANK LIMITED" value="DCB" />
        <Picker.Item label="DHANLAXMI BANK" value="DHAN" />
        <Picker.Item label="DENA BANK" value="DENA" />
        <Picker.Item label="DEUTSCHE BANK" value="DEUTS" />
        <Picker.Item label="EQUITAS BANK" value="ESFB" />
        <Picker.Item label="FEDERAL BANK" value="FDEB" />
        <Picker.Item
          label="GOPINATH PATIL PARSIK JANATA SAHAKARI BANK LTD"
          value="GPPB"
        />
        <Picker.Item label="IDBI BANK" value="IDBI" />
        <Picker.Item label="IDFC BANK" value="IDFC" />
        <Picker.Item label="INDIAN BANK" value="INDB" />
        <Picker.Item label="INDIAN OVERSEAS BANK" value="IOB" />
        <Picker.Item label="JANATA SAHAKARI BANK LTD PUNE" value="JSB" />
        <Picker.Item label="JAMMU AND KASHMIR BANK" value="JKB" />
        <Picker.Item label="KARNATAKA BANK" value="KTKB" />
        <Picker.Item label="KARUR VYSYA BANK" value="KVB" />
        <Picker.Item label="LAKSHMI VILAS BANK" value="LVB" />
        <Picker.Item label="NKGSB BANK" value="NKGS" />
        <Picker.Item label="PUNJAB AND SIND BANK" value="PSB" />
        <Picker.Item label="PAYTM PAYMENTS BANK LTD" value="PYTM" />
        <Picker.Item label="RBL BANK" value="RATN" />
        <Picker.Item label="ROYAL BANK OF SCOTLAND" value="RBS" />
        <Picker.Item label="STANDARD CHARTERED BANK" value="SCB" />
        <Picker.Item label="SVC COOPERATIVE BANK LTD" value="SVC" />
        <Picker.Item label="SARASWAT CO-OPERATIVE BANK LTD" value="STB" />
        <Picker.Item label="SOUTH INDIAN BANK" value="SIB" />
        <Picker.Item label="SURYODAY SMALL FINANCE BANK" value="SSFB" />
        <Picker.Item label="TAMILNAD MERCANTILE BANK" value="TNMB" />
        <Picker.Item label="UCO BANK" value="UCO" />
        <Picker.Item label="UJJIVAN SMALL FINANCE BANK" value="USFB" />
        <Picker.Item label="UNION BANK OF INDIA RETAIL" value="UNI" />
        <Picker.Item label="UNITED BANK OF INDIA" value="UBI" />
        <Picker.Item label="VIJAYA BANK" value="VJYA" />
      </Picker>

      <Text style={styles(theme).textCenterStyle}>
        Selected Bank Code: {bankCode}
      </Text>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => makeNetBankingPayment()}
      >
        <Text style={styles(theme).buttonTextStyle}>Pay Securely</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setChangePaymentMethod(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>Change Payment Method</Text>
      </TouchableOpacity>

      <AwesomeAlert
        show={initiateTransactionError}
        title="INITIATE TRANSACTION ERROR"
        message={initiateTransactionErrorMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setInitiateTransactionError(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={bankNotAvailable}
        title="PAYMENT GATEWAY MESSAGE"
        message={banksNotAvailableMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setBankNotAvailable(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={bankCodeError}
        title="BANK IS NOT SELECTED"
        message={"Please select a bank for payment through netbanking."}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setBankCodeError(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={changePaymentMethod}
        title="CONFIRM CHANGE PAYMENT METHOD"
        message="Are you sure you want to change payment method ?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, change it"
        // confirmButtonColor="#DD6B55"
        onCancelPressed={() => setChangePaymentMethod(false)}
        onConfirmPressed={() => {
          setBankCode("");
          setChangePaymentMethod(false);
          navigation.navigate("SelectKampusPaymentMethod");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    logoStyle: {
      height: 70,
      width: 70,
      alignSelf: "center",
      margin: 10,
    },

    bankLogoStyle: {
      width: 50,
      height: 50,
      borderWidth: 1,
      borderRadius: 50,
      borderColor: theme.colors.standardTextColor,
      margin: 10,
    },

    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    rowGroupSpaceEvenlyStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: 10,
    },

    pickerStyle: {
      width: "80%",
      height: 50,
      color: theme.colors.standardTextColor,
      backgroundColor: "white",
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default KampusNetBankingPayment;
