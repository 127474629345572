import React, { useContext } from "react";
import { Text, StyleSheet } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { ThemeContext } from "../context/ThemeContext";
import { countryState } from "../data/CountryState";

const CustomStateInput = (props) => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched, setFieldValue, values },
    ...inputProps
  } = props;
  const hasError = errors[name] && touched[name];
  const regions =
    values.country == ""
      ? []
      : countryState.find((data) => data.countryName === values.country)
          .regions;
  return (
    <>
      <Picker
        style={[styles(theme).textInput, hasError && styles(theme).errorInput]}
        selectedValue={value}
        onValueChange={(itemValue, itemIndex) => {
          onChange(name)(itemValue);
        }}
        onBlur={() => {
          setFieldTouched(name);
          onBlur(name);
        }}
        {...inputProps}
      >
        <Picker.Item
          key="unselectable"
          label="Select State / Region"
          value=""
        />
        {regions.map((item, index) => (
          <Picker.Item key={index} label={item.name} value={item.name} />
        ))}
      </Picker>
      {hasError && <Text style={styles(theme).errorText}>{errors[name]}</Text>}
    </>
  );
};

export default CustomStateInput;

const styles = (theme) =>
  StyleSheet.create({
    textInput: {
      color: theme.colors.standardTextColor,
      height: 50,
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 1,
      borderRadius: 10,
      paddingLeft: 10,
    },
    errorText: {
      color: "red",
      width: "80%",
      alignSelf: "center",
    },
    errorInput: {
      borderColor: "red",
    },
  });
