import React, { useState, useContext } from "react";
import { Text, View, StyleSheet, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";
import { ThemeContext } from "../../../context/ThemeContext";
import { Button } from "react-native-paper";
import { KampusRegistrationContext } from "../../../context/KampusRegistrationContext";
import AwesomeAlert from "react-native-awesome-alerts";
import { Ionicons } from "@expo/vector-icons";

const MarkInstitution = () => {
  const navigation = useNavigation();
  const { theme, changeTheme } = useContext(ThemeContext);

  const {
    kampusLocation,
    setKampusLocation,
    latDelta,
    setLatDelta,
    lonDelta,
    setLonDelta,
  } = useContext(KampusRegistrationContext);

  const [region, setRegion] = useState(null);
  const [markInstitution, setMarkInstitution] = useState(false);
  const onRegionChange = (region) => {
    setRegion({ region });
    setLatDelta(region.latitudeDelta);
    setLonDelta(region.longitudeDelta);
  };

  return kampusLocation.latitude ? (
    <View style={styles(theme).screenStyle}>
      <MapView
        style={styles(theme).mapStyle}
        showsUserLocation={true}
        provider={PROVIDER_GOOGLE}
        showsMyLocationButton={false}
        initialRegion={{
          latitude: kampusLocation.latitude,
          longitude: kampusLocation.longitude,
          latitudeDelta: latDelta,
          longitudeDelta: lonDelta,
        }}
        onPress={(event) =>
          setKampusLocation({
            latitude:
              Platform.OS === "web"
                ? event.latLng.lat()
                : event.nativeEvent.coordinate.latitude,
            longitude:
              Platform.OS === "web"
                ? event.latLng.lng()
                : event.nativeEvent.coordinate.longitude,
          })
        }
        onRegionChangeComplete={onRegionChange}
      >
        <MapView.Marker
          coordinate={kampusLocation}
          pinColor={theme.colors.standardTextColor}
        />
      </MapView>
      <View style={styles(theme).mapTextViewStyle}>
        <Text style={styles(theme).mapTextStyle}>
          Latitude : {kampusLocation.latitude.toString().substring(0, 10)}
        </Text>
        <Text style={styles(theme).mapTextStyle}>
          Longitude :{kampusLocation.longitude.toString().substring(0, 10)}
        </Text>
      </View>
      <View style={styles(theme).mapIconViewStyle}>
        <Button onPress={() => setMarkInstitution(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </Button>
      </View>
      <View style={styles(theme).mapButtonViewStyle}>
        <Button
          onPress={() => {
            setRegion(region);
            navigation.navigate("KampusRegistration");
          }}
          style={styles(theme).mapButtonStyle}
        >
          <Text style={{ color: theme.colors.textColor }}>OK</Text>
        </Button>
      </View>
      <AwesomeAlert
        show={markInstitution}
        title="MARK INSTITUTION"
        message={
          "You can mark your institution by clicking on the map. The location and boundaries of the institution should also be available in google maps for easy verification. "
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, thank you"
        onConfirmPressed={() => {
          setMarkInstitution(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </View>
  ) : (
    <View>
      <Text>Still loading</Text>
    </View>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    mapStyle: {
      flex: 1,
      height: "100%",
      width: "100%",
    },
    mapTextViewStyle: {
      position: "absolute",
      top: 50,
      left: 10,
    },
    mapTextStyle: {
      color: theme.colors.standardTextColor,

      fontWeight: "bold",
      marginTop: 10,
    },
    mapIconViewStyle: {
      position: "absolute",
      top: 50,
      right: 0,
    },
    mapButtonViewStyle: {
      position: "absolute",
      bottom: 30,
      left: 0,
      right: 0,
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
    mapButtonStyle: {
      borderRadius: 20,
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderColor: theme.colors.borderColor,
    },
    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      borderColor: theme.colors.borderColor,
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
    },
    overlayStyle: {
      height: "100%",
      width: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      borderRadius: 20,
      justifyContent: "space-evenly",
    },

    alertButtonStyle: {
      borderRadius: 20,
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default MarkInstitution;
