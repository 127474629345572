import React, { useContext, useState } from "react";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";

import { ThemeContext } from "../../../context/ThemeContext";
import CustomInput from "../../../components/CustomInput";
import MultilineCustomInput from "../../../components/MultilineCustomInput";
import { KampusEventContext } from "../../../context/KampusEventContext";

const SetDetailsForKampusEvent = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const {
    title,
    setTitle,
    name,
    setName,
    description,
    setDescription,
    prizes,
    setPrizes,
    schedule,
    setSchedule,
  } = useContext(KampusEventContext);
  const navigation = useNavigation();
  const [eventInfo, setEventInfo] = useState(false);
  const initialValues = {
    title: title,
    name: name,
    description: description,
    prizes: prizes,
    schedule: schedule,
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Reqiured"),
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    prizes: Yup.string().required("Required"),
    schedule: Yup.string().required("Required"),
  });
  const onSubmit = (values) => {
    setTitle(values.title);
    setName(values.name);
    setDescription(values.description);
    setPrizes(values.prizes);
    setSchedule(values.schedule);
    navigation.navigate("SelectKardForKampusEvent");
  };
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>KAMPUS EVENT DETAILS</Text>
        <TouchableOpacity onPress={() => setEventInfo(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <Text style={styles(theme).textCenterStyle}>Step 1 of 4</Text>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <View>
            <Text style={styles(theme).labelStyle}>Event Title</Text>
            <Field
              component={CustomInput}
              name="title"
              placeholder="Please enter the title of the event."
            />
            <Text style={styles(theme).labelStyle}>Event Name</Text>
            <Field
              component={CustomInput}
              name="name"
              placeholder="Please enter the name of the event."
            />
            <Text style={styles(theme).labelStyle}>Event Description</Text>
            <Field
              component={MultilineCustomInput}
              name="description"
              placeholder="Please give more information or describe the event"
            />
            <Text style={styles(theme).labelStyle}>Prizes</Text>
            <Field
              component={MultilineCustomInput}
              name="prizes"
              placeholder="Please enter details of the prizes"
            />
            <Text style={styles(theme).labelStyle}>Schedule</Text>
            <Field
              component={MultilineCustomInput}
              name="schedule"
              placeholder="Please give the schedule of the event"
            />

            <View style={styles(theme).rowGroupSpaceEvenlyStyle}>
              <TouchableOpacity
                onPress={() => navigation.navigate("KampusHome")}
              >
                <AntDesign
                  name={theme.type == "fill" ? "leftcircle" : "leftcircleo"}
                  size={50}
                  color={theme.colors.standardTextColor}
                />
              </TouchableOpacity>

              <TouchableOpacity onPress={handleSubmit}>
                <AntDesign
                  name={theme.type == "fill" ? "rightcircle" : "rightcircleo"}
                  size={50}
                  color={theme.colors.standardTextColor}
                />
              </TouchableOpacity>
            </View>
          </View>
        )}
      </Formik>
      <AwesomeAlert
        show={eventInfo}
        title="KAMPUS EVENT DETAILS"
        message={
          "Please provide event title, name, description,prizes and schedule"
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setEventInfo(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },
    labelStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },

    rowGroupSpaceEvenlyStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default SetDetailsForKampusEvent;
