import React, { useContext, useEffect, useState } from "react";
import { Text, Image, View, StyleSheet, ScrollView } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import { Storage } from "aws-amplify";

import { ProfileContext } from "../../context/ProfileContext";
import { ThemeContext } from "../../context/ThemeContext";
import config from "../../src/aws-exports";
import { NewKardContext } from "../../context/NewKardContext";
import { DeviceContext } from "../../context/DeviceContext";

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;
const PreviousKards = () => {
  const { profile } = useContext(ProfileContext);
  const { setPolygon } = useContext(NewKardContext);
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const [previousKards, setPreviousKards] = useState([]);
  const { theme, changeTheme } = useContext(ThemeContext);
  const { deviceType, setDeviceType } = useContext(DeviceContext);

  useEffect(() => {
    if (isFocused) {
      getPreviousKards();
    }
  }, [isFocused]);

  const getPreviousKards = async () => {
    setPreviousKards([]);
    const listConfig = {
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    };

    const result = await Storage.list(
      "temporary-data/" + profile.id + "/records/",
      listConfig
    );
    result.map(async (item, k) => {
      const key = item.key;
      const getConfig = {
        customPrefix: {
          public: "",
          protected: "",
          private: "",
        },
        download: true,
        cacheControl: "no-cache",
      };

      const result = await Storage.get(key, getConfig);
      const data = await new Response(result.Body).text();
      const array = data.split(";");
      const addressObject = {
        type: JSON.parse(array[9]).type,
        name: JSON.parse(array[9]).name,
        line1: JSON.parse(array[9]).line1,
        line2: JSON.parse(array[9]).line2,
        line3: JSON.parse(array[9]).line3,
        state: JSON.parse(array[9]).state,
        country: JSON.parse(array[9]).country,
        zip: JSON.parse(array[9]).zip,
      };
      const object = {
        id: array[0],
        userId: array[1],
        username: array[2],
        imageName: array[3],
        displayArea: array[4],
        kardType: array[5],
        kardStatus: array[6],
        transactionId: array[7],
        transactionDate: array[8],
        billingAddress: addressObject,
        charges: array[10],
        discount: array[11],
        taxes: array[12],
        total: array[13],
        setViews: array[14],
        shownViews: array[15],
        likes: array[16],
        dislikes: array[17],
        downloads: array[18],
        flags: array[19],
        changeKard: array[20],
        changeDisplayArea: array[21],
        startDate: array[22],
        endDate: array[23],
        expiryDate: array[24],
        refundAmount: array[25],
        refundTxnDate: array[26],
        refundTxnId: array[27],
      };
      setPreviousKards((prev) => [...prev, object]);
    });
  };

  return previousKards.length != 0 ? (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>PREVIOUS KARDS</Text>

        <TouchableOpacity
          style={styles(theme).refreshIconStyle}
          onPress={() => getPreviousKards()}
        >
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <View
        style={
          deviceType == "MOBILE"
            ? styles(theme).itemMobileViewStyle
            : styles(theme).itemViewStyle
        }
      >
        {previousKards.map((i, j) => {
          return (
            <TouchableOpacity
              key={j}
              style={styles(theme).itemStyle}
              onPress={() => {
                setPolygon([]);
                setPreviousKards([]);
                navigation.navigate("PreviousKardDetails", {
                  item: i,
                });
              }}
            >
              <Image
                source={{
                  uri: `https://${bucket}.s3.${region}.amazonaws.com/temporary-data/${profile.id}/images/${i.imageName}`,
                }}
                style={styles(theme).itemImageStyle}
              />
              <View style={styles(theme).itemTextStyle}>
                <Text style={styles(theme).textStyle}>
                  Kard Type: {i.kardType}
                </Text>
                <Text style={styles(theme).textStyle}>
                  Kard Status: {i.kardStatus}
                </Text>
                <Text style={styles(theme).textStyle}>
                  Total Views: {i.setViews}
                </Text>
                <Text style={styles(theme).textStyle}>
                  Current Views: {i.shownViews}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("CurrentKards")}
      >
        <Text style={styles(theme).buttonTextStyle}>Current Kards</Text>
      </TouchableOpacity>
    </ScrollView>
  ) : (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>PREVIOUS KARDS</Text>

        <TouchableOpacity
          style={styles(theme).refreshIconStyle}
          onPress={() => getPreviousKards()}
        >
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <View style={styles(theme).rowGroupCenterStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          You do not have any previous kards.
        </Text>
      </View>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("CurrentKards")}
      >
        <Text style={styles(theme).buttonTextStyle}>Current Kards</Text>
      </TouchableOpacity>
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: { flex: 1, backgroundColor: "white" },

    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },
    buttonStyle: {
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 3,
      backgroundColor: theme.colors.backgroundColor,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },
    itemViewStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      justifyContent: "space-around",
    },
    itemMobileViewStyle: {
      flexDirection: "column",
      alignSelf: "center",
    },
    itemStyle: {
      margin: 10,
      flexDirection: "row",
      borderWidth: 2,
      borderColor: theme.colors.borderColor,
      borderRadius: 10,
    },
    itemImageStyle: {
      height: 150,
      width: 100,
    },
    itemTextStyle: {
      flexDirection: "column",
      justifyContent: "space-evenly",
      paddingRight: 10,
      paddingLeft: 10,
      alignItems: "flex-start",
    },
  });
export default PreviousKards;
