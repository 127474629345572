import React, { createContext, useState, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";

export const UserContext = createContext(null);

export default ({ children }) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          // console.log("Thank you for SigningIn");
          getUser().then((userData) => {
            setUser(userData);
          });
          break;
        case "signOut":
          // console.log("Please visit again");
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure");
          break;
      }
    });

    getUser().then((userData) => setUser(userData));

    // cancel subscription to useEffect
    // return () => {
    //   console.log("iam in usercontext useeffect cleanup");
    //   setUser(null);
    // };
  }, []);

  const getUser = async () => {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => {
        console.log("You have not yet SignedIn. Can you Please ?");
      });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
