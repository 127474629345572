import React, { useContext, useState, useEffect } from "react";
import { Text, View, StyleSheet, Platform, Dimensions } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { Button } from "react-native-paper";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import intersecting_polygon_detector from "intersecting-polygon-detector";
import AwesomeAlert from "react-native-awesome-alerts";
import { API, graphqlOperation } from "aws-amplify";

import { ThemeContext } from "../../context/ThemeContext";
import * as mutations from "../../src/graphql/mutations";
import * as queries from "../../src/graphql/queries";

import { NewKardContext } from "../../context/NewKardContext";
const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

const ChangeDisplayArea = (props) => {
  const id = props.route.params.id;
  const imageName = props.route.params.imageName;
  const { mapRegionForNewKard, setMapRegionForNewKard, polygon, setPolygon } =
    useContext(NewKardContext);
  const ASPECT_RATIO = SCREEN_WIDTH / SCREEN_HEIGHT;
  const { theme, changeTheme } = useContext(ThemeContext);
  const [item, setItem] = useState();
  const [infoModal, setInfoModal] = useState(false);
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const [preData, setPreData] = useState([...polygon]);
  const [polygonErrorModal, setPolygonErrorModal] = useState(false);

  useEffect(() => {
    if (isFocused) {
      refreshKardData();
    }
  }, [isFocused]);

  const refreshKardData = async () => {
    try {
      const newKard = await API.graphql({
        query: queries.getKard,
        variables: {
          id: id,
        },
      });
      setPolygon([]);
      constructPolygon(newKard.data.getKard.displayArea);
      setItem(newKard.data.getKard);
      // setKard(
      // `https://${bucket}.s3.${region}.amazonaws.com/public/${newKard.data.getKard.imageName}?key=${timer}`
      // );
    } catch (err) {
      console.log(err);
    }
  };
  const constructPolygon = (item) => {
    var arr1 = [];
    var arr2 = [];
    arr1 = JSON.parse(item).coordinates;
    arr2 = arr1[0];
    arr2.map((item, index) => {
      var newItem = {};
      if (index != arr2.length - 1) {
        if (Platform.OS == "web") {
          newItem.lat = item[1];
          newItem.lng = item[0];
        } else {
          newItem.latitude = item[1];
          newItem.longitude = item[0];
        }
        setPreData((prev) => [...prev, newItem]);
      }
    });
  };
  const onRegionChange = (region) => {
    var latitudeDelta =
      Platform.OS === "web"
        ? Math.pow(2, Math.log2(360) - region.zoom)
        : region.latitudeDelta;
    var longitudeDelta =
      Platform.OS === "web"
        ? latitudeDelta * ASPECT_RATIO
        : region.longitudeDelta;
    setMapRegionForNewKard({
      latitude: region.latitude,
      longitude: region.longitude,
      latitudeDelta: latitudeDelta,
      longitudeDelta: longitudeDelta,
    });
  };

  const drawPolygon = (event) => {
    if (preData.length < 10) {
      var thisItem = {};

      if (Platform.OS == "web") {
        (thisItem.lat = parseFloat(event.latLng.lat())),
          (thisItem.lng = parseFloat(event.latLng.lng()));
      } else {
        (thisItem.latitude = parseFloat(event.nativeEvent.coordinate.latitude)),
          (thisItem.longitude = parseFloat(
            event.nativeEvent.coordinate.longitude
          ));
      }
      if (preData.length < 3) {
        setPreData((prev) => [...prev, thisItem]);
      } else {
        var testData = [];
        preData.map((i, k) => {
          const existingItem =
            Platform.OS === "web" ? [i.lat, i.lng] : [i.latitude, i.longitude];
          testData.push(existingItem);
        });
        const lastItem =
          Platform.OS === "web"
            ? [thisItem.lat, thisItem.lng]
            : [thisItem.latitude, thisItem.longitude];
        testData.push(lastItem);
        const repeatFirstItem =
          Platform.OS === "web"
            ? [preData[0].lat, preData[0].lng]
            : [preData[0].latitude, preData[0].longitude];
        testData.push(repeatFirstItem);
        // console.log(testData);
        if (!intersecting_polygon_detector(testData)) {
          setPreData((prev) => [...prev, thisItem]);
        }
      }
    }
  };

  const changeDisplayArea = async (polygon) => {
    setPolygon(polygon);
    var displayAreaObject = {
      type: "polygon",
      coordinates: [[]],
    };
    polygon.map((item, key) => {
      var newItem =
        Platform.OS == "web"
          ? [Number(item.lng.toFixed(5)), Number(item.lat.toFixed(5))]
          : [
              Number(item.longitude.toFixed(5)),
              Number(item.latitude.toFixed(5)),
            ];
      displayAreaObject.coordinates[0].push(newItem);
    });
    var firstItem =
      Platform.OS == "web"
        ? [Number(polygon[0].lng.toFixed(5)), Number(polygon[0].lat.toFixed(5))]
        : [
            Number(polygon[0].longitude.toFixed(5)),
            Number(polygon[0].latitude.toFixed(5)),
          ];
    displayAreaObject.coordinates[0].push(firstItem);
    const displayAreaJSONString = JSON.stringify(displayAreaObject);
    try {
      await API.graphql(
        graphqlOperation(mutations.updateKard, {
          input: {
            id: id,
            displayArea: displayAreaJSONString,
            changeDisplayArea: item.changeDisplayArea + 1,
          },
        })
      );
      setPolygon([]);
      navigation.navigate("CurrentKardDetails", {
        id: id,
        imageName: imageName,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <View style={styles(theme).screenStyle}>
      <MapView
        style={styles(theme).mapStyle}
        provider={PROVIDER_GOOGLE}
        initialRegion={mapRegionForNewKard}
        onPress={(event) => drawPolygon(event)}
        onRegionChangeComplete={onRegionChange}
      >
        <MapView.Marker coordinate={mapRegionForNewKard} />

        {preData.length != 0 &&
          preData.map((item, key) => {
            return (
              <MapView.Marker
                key={key}
                coordinate={{
                  latitude: Platform.OS === "web" ? item.lat : item.latitude,
                  longitude: Platform.OS === "web" ? item.lng : item.longitude,
                }}
                pinColor="green"
              />
            );
          })}
        {Platform.OS === "web" ? (
          <MapView.Polygon
            path={preData}
            options={{
              strokeColor: theme.colors.standardTextColor,
            }}
          />
        ) : preData.length != 0 ? (
          <MapView.Polygon
            coordinates={preData}
            strokeColor={theme.colors.standardTextColor}
            fillColor={theme.colors.standardTextColor}
          />
        ) : null}
      </MapView>
      <View style={styles(theme).mapIconViewStyle}>
        <Button onPress={() => setInfoModal(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </Button>
      </View>

      <View style={styles(theme).mapButtonViewStyle}>
        <Button
          onPress={() => {
            setPreData([]);
          }}
          style={styles(theme).mapButtonStyle}
        >
          <Text style={{ color: theme.colors.textColor }}>CANCEL</Text>
        </Button>
        <Button
          onPress={() => {
            setPolygon([...preData]);
            preData.length < 3
              ? setPolygonErrorModal(true)
              : setPolygon((polygon) => {
                  changeDisplayArea(polygon);
                });
          }}
          style={styles(theme).mapButtonStyle}
        >
          <Text style={{ color: theme.colors.textColor }}>OK</Text>
        </Button>
      </View>
      <AwesomeAlert
        show={infoModal}
        title="CHANGE DISPLAY AREA"
        message={
          "You can change the display area which is  represented on the map by a simple polygon with 3 to 10 corners. Click or tap or press to specify a corner. Self interseccting polygons are not permitted."
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setInfoModal(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={polygonErrorModal}
        showProgress={false}
        title="INCORRECT POLYGON"
        message="Atleast 3 points should be selected to set the dissplay area"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setPolygonErrorModal(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).messageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    mapStyle: {
      flex: 1,
      height: "100%",
      width: "100%",
    },

    mapIconViewStyle: {
      top: 50,
      right: 0,
      position: "absolute",
    },
    mapButtonViewStyle: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      left: 0,
      right: 0,
      bottom: 30,
      position: "absolute",
    },
    mapButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default ChangeDisplayArea;
