import React, { useEffect, useState, useContext } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { API } from "aws-amplify";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";

import * as queries from "../../../src/graphql/queries";
import { ThemeContext } from "../../../context/ThemeContext";

const PendingKampusEvents = () => {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const { theme, changeTheme } = useContext(ThemeContext);

  const [pendingEvents, setPendingEvents] = useState([]);

  useEffect(() => {
    if (isFocused) {
      getPendingEvents();
    }
  }, [isFocused]);

  const getPendingEvents = async () => {
    var itemArray = [];
    try {
      const events = await API.graphql({
        query: queries.listKampusEventsByStatus,
        variables: {
          status: "pending",
        },
      });
      if (events.data.listKampusEventsByStatus.items.length != 0) {
        events.data.listKampusEventsByStatus.items.map((item) => {
          itemArray.push(item);
        });
      }

      setPendingEvents(itemArray);
    } catch (err) {
      console.log(err);
    }
  };

  return pendingEvents.length != 0 ? (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>PENDING KAMPUS EVENTS</Text>
        <TouchableOpacity
          onPress={() => {
            getPendingEvents();
          }}
        >
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      {pendingEvents.map((item, key) => {
        return (
          <TouchableOpacity
            key={key}
            onPress={() => {
              navigation.navigate("PendingKampusEventDetails", {
                item: item,
              });
            }}
          >
            <View style={styles(theme).boxStyle}>
              <Text style={styles(theme).textStyle}>
                Title: {item.title}, Name: {item.name}, Request Date:
                {new Date(item.requestDate).toLocaleDateString()} , Status:{" "}
                {item.status}
              </Text>
            </View>
          </TouchableOpacity>
        );
      })}
    </ScrollView>
  ) : (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>PENDING KAMPUS EVENTS</Text>

        <TouchableOpacity
          onPress={() => {
            getPendingEvents();
          }}
        >
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <View style={styles(theme).rowGroupCenterStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          You do not have any pending events to process
        </Text>
      </View>
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },
  });
export default PendingKampusEvents;
