/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchEs = /* GraphQL */ `
  query SearchEs($location: EsInput!, $nextToken: String) {
    searchEs(location: $location, nextToken: $nextToken) {
      items {
        imageName
        startDate
      }
      nextToken
      total
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      username
      name
      address {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      avatar
      kampusStatus
      kampusStartDate
      kampusEndDate
      kampusRegistration {
        id
        requestDate
        kampusLocation
        displayArea
        status
        volunteerCount
        championshipDate
        expiryDate
        profile {
          id
          username
          name
          avatar
          kampusStatus
          kampusStartDate
          kampusEndDate
          createdAt
          updatedAt
          profileKampusRegistrationId
          owner
        }
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        createdAt
        updatedAt
        owner
      }
      kampusEvent {
        items {
          id
          requestDate
          imageName
          displayArea
          title
          name
          description
          prizes
          schedule
          views
          startDate
          status
          message
          createdAt
          updatedAt
          profileKampusEventId
          owner
        }
        nextToken
      }
      kampusRenewal {
        items {
          id
          kampusStatus
          transactionId
          transactionDate
          renewalCharges
          renewalTaxes
          renewalAmount
          createdAt
          updatedAt
          profileKampusRenewalId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      profileKampusRegistrationId
      owner
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $id: ID
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProfiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      nextToken
    }
  }
`;
export const listProfilesByUsername = /* GraphQL */ `
  query ListProfilesByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      nextToken
    }
  }
`;
export const getKampusRegistration = /* GraphQL */ `
  query GetKampusRegistration($id: ID!) {
    getKampusRegistration(id: $id) {
      id
      requestDate
      kampusLocation
      displayArea
      status
      volunteerCount
      championshipDate
      expiryDate
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      address {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listKampusRegistrations = /* GraphQL */ `
  query ListKampusRegistrations(
    $id: ID
    $filter: ModelKampusRegistrationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKampusRegistrations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        requestDate
        kampusLocation
        displayArea
        status
        volunteerCount
        championshipDate
        expiryDate
        profile {
          id
          username
          name
          avatar
          kampusStatus
          kampusStartDate
          kampusEndDate
          createdAt
          updatedAt
          profileKampusRegistrationId
          owner
        }
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listKampusRegistrationsByStatus = /* GraphQL */ `
  query ListKampusRegistrationsByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelKampusRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKampusRegistrationsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestDate
        kampusLocation
        displayArea
        status
        volunteerCount
        championshipDate
        expiryDate
        profile {
          id
          username
          name
          avatar
          kampusStatus
          kampusStartDate
          kampusEndDate
          createdAt
          updatedAt
          profileKampusRegistrationId
          owner
        }
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getKampusEvent = /* GraphQL */ `
  query GetKampusEvent($id: ID!) {
    getKampusEvent(id: $id) {
      id
      requestDate
      imageName
      displayArea
      title
      name
      description
      prizes
      schedule
      views
      startDate
      status
      message
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      createdAt
      updatedAt
      profileKampusEventId
      owner
    }
  }
`;
export const listKampusEvents = /* GraphQL */ `
  query ListKampusEvents(
    $id: ID
    $filter: ModelKampusEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKampusEvents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        requestDate
        imageName
        displayArea
        title
        name
        description
        prizes
        schedule
        views
        startDate
        status
        message
        profile {
          id
          username
          name
          avatar
          kampusStatus
          kampusStartDate
          kampusEndDate
          createdAt
          updatedAt
          profileKampusRegistrationId
          owner
        }
        createdAt
        updatedAt
        profileKampusEventId
        owner
      }
      nextToken
    }
  }
`;
export const listKampusEventsByStatus = /* GraphQL */ `
  query ListKampusEventsByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelKampusEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKampusEventsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestDate
        imageName
        displayArea
        title
        name
        description
        prizes
        schedule
        views
        startDate
        status
        message
        profile {
          id
          username
          name
          avatar
          kampusStatus
          kampusStartDate
          kampusEndDate
          createdAt
          updatedAt
          profileKampusRegistrationId
          owner
        }
        createdAt
        updatedAt
        profileKampusEventId
        owner
      }
      nextToken
    }
  }
`;
export const getKampusRenewal = /* GraphQL */ `
  query GetKampusRenewal($id: ID!) {
    getKampusRenewal(id: $id) {
      id
      kampusStatus
      transactionId
      transactionDate
      renewalCharges
      renewalTaxes
      renewalAmount
      profile {
        id
        username
        name
        address {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        avatar
        kampusStatus
        kampusStartDate
        kampusEndDate
        kampusRegistration {
          id
          requestDate
          kampusLocation
          displayArea
          status
          volunteerCount
          championshipDate
          expiryDate
          createdAt
          updatedAt
          owner
        }
        kampusEvent {
          nextToken
        }
        kampusRenewal {
          nextToken
        }
        createdAt
        updatedAt
        profileKampusRegistrationId
        owner
      }
      createdAt
      updatedAt
      profileKampusRenewalId
      owner
    }
  }
`;
export const listKampusRenewals = /* GraphQL */ `
  query ListKampusRenewals(
    $id: ID
    $filter: ModelKampusRenewalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKampusRenewals(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        kampusStatus
        transactionId
        transactionDate
        renewalCharges
        renewalTaxes
        renewalAmount
        profile {
          id
          username
          name
          avatar
          kampusStatus
          kampusStartDate
          kampusEndDate
          createdAt
          updatedAt
          profileKampusRegistrationId
          owner
        }
        createdAt
        updatedAt
        profileKampusRenewalId
        owner
      }
      nextToken
    }
  }
`;
export const getKard = /* GraphQL */ `
  query GetKard($id: ID!) {
    getKard(id: $id) {
      id
      userId
      username
      imageName
      displayArea
      kardType
      kardStatus
      transactionId
      transactionDate
      billingAddress {
        name
        line1
        line2
        line3
        zip
        type
        state
        country
      }
      charges
      discount
      taxes
      total
      setViews
      shownViews
      likes
      dislikes
      downloads
      flags
      changeKard
      changeDisplayArea
      startDate
      endDate
      expiryDate
      refundAmount
      refundTxnDate
      refundTxnId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listKards = /* GraphQL */ `
  query ListKards(
    $id: ID
    $filter: ModelKardFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKards(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        username
        imageName
        displayArea
        kardType
        kardStatus
        transactionId
        transactionDate
        billingAddress {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        charges
        discount
        taxes
        total
        setViews
        shownViews
        likes
        dislikes
        downloads
        flags
        changeKard
        changeDisplayArea
        startDate
        endDate
        expiryDate
        refundAmount
        refundTxnDate
        refundTxnId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listKardsByUserId = /* GraphQL */ `
  query ListKardsByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelKardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKardsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        username
        imageName
        displayArea
        kardType
        kardStatus
        transactionId
        transactionDate
        billingAddress {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        charges
        discount
        taxes
        total
        setViews
        shownViews
        likes
        dislikes
        downloads
        flags
        changeKard
        changeDisplayArea
        startDate
        endDate
        expiryDate
        refundAmount
        refundTxnDate
        refundTxnId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listKardsByUserIdAndKardType = /* GraphQL */ `
  query ListKardsByUserIdAndKardType(
    $userId: String!
    $kardType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKardsByUserIdAndKardType(
      userId: $userId
      kardType: $kardType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        username
        imageName
        displayArea
        kardType
        kardStatus
        transactionId
        transactionDate
        billingAddress {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        charges
        discount
        taxes
        total
        setViews
        shownViews
        likes
        dislikes
        downloads
        flags
        changeKard
        changeDisplayArea
        startDate
        endDate
        expiryDate
        refundAmount
        refundTxnDate
        refundTxnId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listKardsByKardStatus = /* GraphQL */ `
  query ListKardsByKardStatus(
    $kardStatus: String!
    $sortDirection: ModelSortDirection
    $filter: ModelKardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKardsByKardStatus(
      kardStatus: $kardStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        username
        imageName
        displayArea
        kardType
        kardStatus
        transactionId
        transactionDate
        billingAddress {
          name
          line1
          line2
          line3
          zip
          type
          state
          country
        }
        charges
        discount
        taxes
        total
        setViews
        shownViews
        likes
        dislikes
        downloads
        flags
        changeKard
        changeDisplayArea
        startDate
        endDate
        expiryDate
        refundAmount
        refundTxnDate
        refundTxnId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      data
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $id: ID
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPayments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        data
        expiryDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTotalTable = /* GraphQL */ `
  query GetTotalTable($id: ID!) {
    getTotalTable(id: $id) {
      id
      freeActive
      freeQueue
      freePause
      freeTxnPending
      freeInsert
      freeDelete
      freeRefund
      freeComplete
      freeRemoved
      basicActive
      basicQueue
      basicPause
      basicTxnPending
      basicInsert
      basicDelete
      basicRefund
      basicComplete
      basicRemoved
      premiumActive
      premiumQueue
      premiumPause
      premiumTxnPending
      premiumInsert
      premiumDelete
      premiumRefund
      premiumComplete
      premiumRemoved
      campaignActive
      campaignQueue
      campaignPause
      campaignTxnPending
      campaignInsert
      campaignDelete
      campaignRefund
      campaignComplete
      campaignRemoved
      kampusActive
      kampusQueue
      kampusPause
      kampusTxnPending
      kampusInsert
      kampusDelete
      kampusRefund
      kampusComplete
      kampusRemoved
      promoActive
      promoQueue
      promoPause
      promoTxnPending
      promoInsert
      promoDelete
      promoRefund
      promoComplete
      promoRemoved
      totalActive
      totalQueue
      totalPause
      totalTxnPending
      totalInsert
      totalDelete
      totalRefund
      totalComplete
      totalRemoved
      freeKardCharges
      freeKardDiscount
      freeKardTaxes
      freeKardNet
      freeRefundNet
      basicKardCharges
      basicKardDiscount
      basicKardTaxes
      basicKardNet
      basicRefundNet
      premiumKardCharges
      premiumKardDiscount
      premiumKardTaxes
      premiumKardNet
      premiumRefundNet
      campaignKardCharges
      campaignKardDiscount
      campaignKardTaxes
      campaignKardNet
      campaignRefundNet
      kampusKardCharges
      kampusKardDiscount
      kampusKardTaxes
      kampusKardNet
      kampusRefundNet
      promoKardCharges
      promoKardDiscount
      promoKardTaxes
      promoKardNet
      promoRefundNet
      totalKardCharges
      totalKardDiscount
      totalKardTaxes
      totalKardNet
      totalRefundNet
      instituteRenewals
      instituteRenewalCharges
      instituteRenewalTaxes
      instituteRenewalNet
      volunteerRenewals
      volunteerRenewalCharges
      volunteerRenewalTaxes
      volunteerRenewalNet
      totalRenewals
      totalRenewalCharges
      totalRenewalTaxes
      totalRenewalNet
      profilesCreated
      profilesRemoved
      kampusRegApplied
      kampusRegApproved
      kampusRegRejected
      kampusEventApplied
      kampusEventApproved
      kampusEventRejected
      kampusEventRemoved
      kampusChampions
      kampusVolunteers
      totalRating
      oneRating
      twoRating
      threeRating
      fourRating
      fiveRating
      totalContactUs
      contactUsDisposed
      totalComments
      commentsDisposed
      totalProblems
      problemsDisposed
      type
      date
      createdAt
      updatedAt
    }
  }
`;
export const listTotalTables = /* GraphQL */ `
  query ListTotalTables(
    $id: ID
    $filter: ModelTotalTableFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTotalTables(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        freeActive
        freeQueue
        freePause
        freeTxnPending
        freeInsert
        freeDelete
        freeRefund
        freeComplete
        freeRemoved
        basicActive
        basicQueue
        basicPause
        basicTxnPending
        basicInsert
        basicDelete
        basicRefund
        basicComplete
        basicRemoved
        premiumActive
        premiumQueue
        premiumPause
        premiumTxnPending
        premiumInsert
        premiumDelete
        premiumRefund
        premiumComplete
        premiumRemoved
        campaignActive
        campaignQueue
        campaignPause
        campaignTxnPending
        campaignInsert
        campaignDelete
        campaignRefund
        campaignComplete
        campaignRemoved
        kampusActive
        kampusQueue
        kampusPause
        kampusTxnPending
        kampusInsert
        kampusDelete
        kampusRefund
        kampusComplete
        kampusRemoved
        promoActive
        promoQueue
        promoPause
        promoTxnPending
        promoInsert
        promoDelete
        promoRefund
        promoComplete
        promoRemoved
        totalActive
        totalQueue
        totalPause
        totalTxnPending
        totalInsert
        totalDelete
        totalRefund
        totalComplete
        totalRemoved
        freeKardCharges
        freeKardDiscount
        freeKardTaxes
        freeKardNet
        freeRefundNet
        basicKardCharges
        basicKardDiscount
        basicKardTaxes
        basicKardNet
        basicRefundNet
        premiumKardCharges
        premiumKardDiscount
        premiumKardTaxes
        premiumKardNet
        premiumRefundNet
        campaignKardCharges
        campaignKardDiscount
        campaignKardTaxes
        campaignKardNet
        campaignRefundNet
        kampusKardCharges
        kampusKardDiscount
        kampusKardTaxes
        kampusKardNet
        kampusRefundNet
        promoKardCharges
        promoKardDiscount
        promoKardTaxes
        promoKardNet
        promoRefundNet
        totalKardCharges
        totalKardDiscount
        totalKardTaxes
        totalKardNet
        totalRefundNet
        instituteRenewals
        instituteRenewalCharges
        instituteRenewalTaxes
        instituteRenewalNet
        volunteerRenewals
        volunteerRenewalCharges
        volunteerRenewalTaxes
        volunteerRenewalNet
        totalRenewals
        totalRenewalCharges
        totalRenewalTaxes
        totalRenewalNet
        profilesCreated
        profilesRemoved
        kampusRegApplied
        kampusRegApproved
        kampusRegRejected
        kampusEventApplied
        kampusEventApproved
        kampusEventRejected
        kampusEventRemoved
        kampusChampions
        kampusVolunteers
        totalRating
        oneRating
        twoRating
        threeRating
        fourRating
        fiveRating
        totalContactUs
        contactUsDisposed
        totalComments
        commentsDisposed
        totalProblems
        problemsDisposed
        type
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTotalTableByDate = /* GraphQL */ `
  query ListTotalTableByDate(
    $type: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTotalTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTotalTableByDate(
      type: $type
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freeActive
        freeQueue
        freePause
        freeTxnPending
        freeInsert
        freeDelete
        freeRefund
        freeComplete
        freeRemoved
        basicActive
        basicQueue
        basicPause
        basicTxnPending
        basicInsert
        basicDelete
        basicRefund
        basicComplete
        basicRemoved
        premiumActive
        premiumQueue
        premiumPause
        premiumTxnPending
        premiumInsert
        premiumDelete
        premiumRefund
        premiumComplete
        premiumRemoved
        campaignActive
        campaignQueue
        campaignPause
        campaignTxnPending
        campaignInsert
        campaignDelete
        campaignRefund
        campaignComplete
        campaignRemoved
        kampusActive
        kampusQueue
        kampusPause
        kampusTxnPending
        kampusInsert
        kampusDelete
        kampusRefund
        kampusComplete
        kampusRemoved
        promoActive
        promoQueue
        promoPause
        promoTxnPending
        promoInsert
        promoDelete
        promoRefund
        promoComplete
        promoRemoved
        totalActive
        totalQueue
        totalPause
        totalTxnPending
        totalInsert
        totalDelete
        totalRefund
        totalComplete
        totalRemoved
        freeKardCharges
        freeKardDiscount
        freeKardTaxes
        freeKardNet
        freeRefundNet
        basicKardCharges
        basicKardDiscount
        basicKardTaxes
        basicKardNet
        basicRefundNet
        premiumKardCharges
        premiumKardDiscount
        premiumKardTaxes
        premiumKardNet
        premiumRefundNet
        campaignKardCharges
        campaignKardDiscount
        campaignKardTaxes
        campaignKardNet
        campaignRefundNet
        kampusKardCharges
        kampusKardDiscount
        kampusKardTaxes
        kampusKardNet
        kampusRefundNet
        promoKardCharges
        promoKardDiscount
        promoKardTaxes
        promoKardNet
        promoRefundNet
        totalKardCharges
        totalKardDiscount
        totalKardTaxes
        totalKardNet
        totalRefundNet
        instituteRenewals
        instituteRenewalCharges
        instituteRenewalTaxes
        instituteRenewalNet
        volunteerRenewals
        volunteerRenewalCharges
        volunteerRenewalTaxes
        volunteerRenewalNet
        totalRenewals
        totalRenewalCharges
        totalRenewalTaxes
        totalRenewalNet
        profilesCreated
        profilesRemoved
        kampusRegApplied
        kampusRegApproved
        kampusRegRejected
        kampusEventApplied
        kampusEventApproved
        kampusEventRejected
        kampusEventRemoved
        kampusChampions
        kampusVolunteers
        totalRating
        oneRating
        twoRating
        threeRating
        fourRating
        fiveRating
        totalContactUs
        contactUsDisposed
        totalComments
        commentsDisposed
        totalProblems
        problemsDisposed
        type
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      type
      email
      message
      rating
      status
      requestDate
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $id: ID
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFeedbacks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        email
        message
        rating
        status
        requestDate
        expiryDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFeedbackByTypeAndStatus = /* GraphQL */ `
  query ListFeedbackByTypeAndStatus(
    $type: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbackByTypeAndStatus(
      type: $type
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        email
        message
        rating
        status
        requestDate
        expiryDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
