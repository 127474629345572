import React, { useState, useContext } from "react";
import { Text, View, StyleSheet, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";
import AwesomeAlert from "react-native-awesome-alerts";
import { Ionicons } from "@expo/vector-icons";
import { Button } from "react-native-paper";

import { ThemeContext } from "../../context/ThemeContext";
import { MyLocationContext } from "../../context/MyLocationContext";

const ChangeMyLocation = () => {
  const navigation = useNavigation();
  const { theme, changeTheme } = useContext(ThemeContext);

  const {
    myLocation,
    setMyLocation,
    latDelta,
    setLatDelta,
    lonDelta,
    setLonDelta,
  } = useContext(MyLocationContext);
  const initialRegion = {
    latitude: myLocation.latitude,
    longitude: myLocation.longitude,
    latitudeDelta: latDelta,
    longitudeDelta: lonDelta,
  };
  const [region, setRegion] = useState(null);
  const [changeLocation, setChangeLocation] = useState(false);
  const onRegionChange = (region) => {
    setRegion({ region });
    setLatDelta(region.latitudeDelta);
    setLonDelta(region.longitudeDelta);
  };

  return myLocation.latitude ? (
    <View style={styles(theme).screenStyle}>
      <MapView
        style={styles(theme).mapStyle}
        showsUserLocation={true}
        provider={PROVIDER_GOOGLE}
        showsMyLocationButton={false}
        initialRegion={initialRegion}
        onPress={(event) =>
          setMyLocation({
            latitude:
              Platform.OS === "web"
                ? event.latLng.lat()
                : event.nativeEvent.coordinate.latitude,
            longitude:
              Platform.OS === "web"
                ? event.latLng.lng()
                : event.nativeEvent.coordinate.longitude,
          })
        }
        onRegionChangeComplete={onRegionChange}
      >
        <MapView.Marker
          coordinate={myLocation}
          pinColor={theme.colors.standardTextColor}
        />
      </MapView>
      <View style={styles(theme).mapTextViewStyle}>
        <Text style={styles(theme).mapTextStyle}>
          Latitude : {myLocation.latitude.toString().substring(0, 10)}
        </Text>
        <Text style={styles(theme).mapTextStyle}>
          Longitude :{myLocation.longitude.toString().substring(0, 10)}
        </Text>
      </View>
      <View style={styles(theme).mapIconViewStyle}>
        <Button onPress={() => setChangeLocation(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </Button>
      </View>
      <View style={styles(theme).mapButtonViewStyle}>
        <Button
          onPress={() => {
            setRegion(region);
            navigation.navigate("MyLocation");
          }}
          style={styles(theme).mapButtonStyle}
        >
          <Text style={{ color: theme.colors.textColor }}>OK</Text>
        </Button>
      </View>
      <AwesomeAlert
        show={changeLocation}
        title="CHANGE YOUR LOCATION"
        message={"You can change your location by clicking on the map."}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, thank you"
        onConfirmPressed={() => {
          setChangeLocation(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </View>
  ) : (
    <View>
      <Text>Still loading</Text>
    </View>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    mapStyle: {
      flex: 1,
      width: "100%",
      height: "100%",
    },

    mapTextViewStyle: {
      top: 50,
      left: 10,
      position: "absolute",
      // alignSelf: "center",
      // justifyContent: "center"
    },
    mapTextStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      marginTop: 10,
    },

    mapIconViewStyle: {
      top: 50,
      right: 0,
      position: "absolute",
    },
    mapButtonViewStyle: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      left: 0,
      right: 0,
      bottom: 30,
      position: "absolute",
    },
    mapButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default ChangeMyLocation;
