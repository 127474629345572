import {
  DefaultO,
  CrimsonO,
  SpringGreenO,
  FuriousOrangeO,
  RubyO,
  ElectricPurpleO,
  IndigoO,
  CyberYellowO,
  SailorBlueO,
  DeepSkyBlueO,
  WindsorWineO,
  PestoO,
  BrownPodO,
  NeonBlueO,
  SpaceCherryO,
  MosqueO,
  DarkOrangeO,
  RosePinkO,
  DarkGoldenRodO,
  BismarkO,
  SteelBlueO,
  BrownSugarO,
  DarkKhakiO,
  PeruO,
  NavyO,
  CherryTomatoO,
  IslandGreenO,
  ChocolateO,
  DeepPinkO,
  DuskyCitronO,
  DimGreyO,
  DarkSlateBlueO,
  BlueO,
  ForestBiomeO,
  FuchsiaO,
  IceFlowO,
  AquaO,
} from "./Themes";

export const outlineThemes = [
  {
    label: "Default",
    changeTo: DefaultO,
    color: "#6200ee",
  },
  {
    label: "Crimson",
    changeTo: CrimsonO,
    color: "#AE0E36",
  },
  {
    label: "Spring Green",
    changeTo: SpringGreenO,
    color: "#00e676",
  },
  {
    label: "Furious Orange",
    changeTo: FuriousOrangeO,
    color: "#ff5722",
  },
  {
    label: "Ruby",
    changeTo: RubyO,
    color: "#d81b60",
  },
  {
    label: "Electric Purple",
    changeTo: ElectricPurpleO,
    color: "#aa00ff",
  },
  {
    label: "Indigo",
    changeTo: IndigoO,
    color: "#4B0082",
  },
  {
    label: "Cyber Yellow",
    changeTo: CyberYellowO,
    color: "#FDD20E",
  },
  {
    label: "Sailor Blue",
    changeTo: SailorBlueO,
    color: "#00203F",
  },
  {
    label: "Deep Sky Blue",
    changeTo: DeepSkyBlueO,
    color: "#00b0ff",
  },
  {
    label: "Windsor Wine",
    changeTo: WindsorWineO,
    color: "#643E46",
  },
  {
    label: "Pesto",
    changeTo: PestoO,
    color: "#827717",
  },
  {
    label: "Brown Pod",
    changeTo: BrownPodO,
    color: "#3e2723",
  },
  {
    label: "Neon Blue",
    changeTo: NeonBlueO,
    color: "#304ffe",
  },

  {
    label: "Space Cherry",
    changeTo: SpaceCherryO,
    color: "#990011",
  },

  {
    label: "Mosque",
    changeTo: MosqueO,
    color: "#006064",
  },
  {
    label: "Dark Orange",
    changeTo: DarkOrangeO,
    color: "#ff8f00",
  },
  {
    label: "Rose Pink",
    changeTo: RosePinkO,
    color: "#DF6589",
  },
  {
    label: "Dark Golden Rod",
    changeTo: DarkGoldenRodO,
    color: "#B8860B",
  },
  {
    label: "Bismark",
    changeTo: BismarkO,
    color: "#455a64",
  },

  {
    label: "Steel Blue",
    changeTo: SteelBlueO,
    color: "#4682B4",
  },
  {
    label: "Brown Sugar",
    changeTo: BrownSugarO,
    color: "#A07855",
  },
  {
    label: "Dark Khaki",
    changeTo: DarkKhakiO,
    color: "#BDB76B",
  },
  {
    label: "Peru",
    changeTo: PeruO,
    color: "#CD853F",
  },
  {
    label: "Navy",
    changeTo: NavyO,
    color: "#000080",
  },
  {
    label: "Cherry Tomato",
    changeTo: CherryTomatoO,
    color: "#ED2B33",
  },
  {
    label: "Island Green",
    changeTo: IslandGreenO,
    color: "#2BAE66",
  },

  {
    label: "Chocolate",
    changeTo: ChocolateO,
    color: "#D2691E",
  },
  {
    label: "Deep Pink",
    changeTo: DeepPinkO,
    color: "#FF1493",
  },
  {
    label: "Dusky Citron",
    changeTo: DuskyCitronO,
    color: "#E3CD81",
  },
  {
    label: "Dim Grey",
    changeTo: DimGreyO,
    color: "#616161",
  },
  {
    label: "Dark Slate Blue",
    changeTo: DarkSlateBlueO,
    color: "#483D8B",
  },
  {
    label: "Blue",
    changeTo: BlueO,
    color: "#0000FF",
  },
  {
    label: "Forest Biome",
    changeTo: ForestBiomeO,
    color: "#184A45",
  },
  {
    label: "Fuchsia",
    changeTo: FuchsiaO,
    color: "#FF00FF",
  },
  {
    label: "Ice Flow",
    changeTo: IceFlowO,
    color: "#A2A2A1",
  },
  {
    label: "Aqua",
    changeTo: AquaO,
    color: "#18ffff",
  },
];
