import React, { createRef, useContext, useState, useEffect } from "react";
import {
  Dimensions,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { AntDesign, FontAwesome, Entypo } from "@expo/vector-icons";

import { useCases } from "../data/UseCases";
import { ThemeContext } from "../context/ThemeContext";
import AwesomeAlert from "react-native-awesome-alerts";
import Swiper from "react-native-deck-swiper";
import { DeviceContext } from "../context/DeviceContext";

const SCREEN_WIDTH = Dimensions.get("screen").width;
const SCREEN_HEIGHT = Dimensions.get("screen").height;
const isLargeScreen = SCREEN_WIDTH >= 768;
const UseCases = () => {
  const [actionsVisible, setActionsVisible] = useState(false);
  const { theme, changeTheme } = useContext(ThemeContext);
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [flag, setFlag] = useState(false);
  const [download, setDownload] = useState(false);
  const [info, setInfo] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState();
  const { deviceType, setDeviceType } = useContext(DeviceContext);
  const swiperRef = createRef();

  const Kard = ({ kard }) => {
    return (
      <Image
        source={require(`../assets/useCases/${kard.id}`)}
        style={{
          height: deviceType == "MOBILE" ? "92%" : "92%",
          width: isLargeScreen ? 600 : SCREEN_WIDTH,
        }}
        resizeMode={"stretch"}
      />
    );
  };

  const onSwiped = async (swipedCardIndex) => {
    if (actionsVisible) {
      setActionsVisible(false);
    }
  };

  const onTapCard = (value) => {
    setActionsVisible(!actionsVisible);
  };

  useEffect(() => {
    if (like) {
      setTimeout(() => setLike(false), 1000);
    }
  }, [like]);

  useEffect(() => {
    if (dislike) {
      setTimeout(() => setDislike(false), 1000);
    }
  }, [dislike]);

  useEffect(() => {
    if (flag) {
      setTimeout(() => setFlag(false), 1000);
    }
  }, [flag]);
  useEffect(() => {
    if (download) {
      setTimeout(() => setDownload(false), 1000);
    }
  }, [download]);

  const showKardDetails = async () => {
    var message = `The following information will be displayed \n Posted by: *NAME*  \n Posted on: *DATE* \n Kard type: *KARD TYPE* \n Id: *KARD ID*`;
    setInfoMessage(message);
    setShowInfoMessage(true);
  };
  return (
    <View style={styles(theme).screenStyle}>
      <View style={styles(theme).displayStyle}>
        <Swiper
          containerStyle={StyleSheet.flatten(styles(theme).swiperStyle)}
          cardStyle={styles(theme).kardStyle}
          cardHorizontalMargin={5}
          cardVerticalMargin={5}
          cards={useCases}
          renderCard={(cardData, cardIndex) => <Kard kard={cardData} />}
          ref={swiperRef}
          cardIndex={0}
          onSwiped={(swipedCardIndex) => onSwiped(swipedCardIndex)}
          goBackToPreviousCardOnSwipeLeft={true}
          goBackToPreviousCardOnSwipeBottom={true}
          onTapCard={onTapCard}
          showSecondCard={true}
          swipeAnimationDuration={300}
          infinite
          stackSize={useCases.length > 2 ? 3 : useCases.length}
          horizontalThreshold={isLargeScreen ? 600 / 4 : SCREEN_WIDTH / 4}
        />

        {actionsVisible && (
          <View style={styles(theme).bottomModal}>
            <TouchableOpacity
              style={styles(theme).kardIconStyle}
              onPress={() => setLike(true)}
            >
              <AntDesign
                name="like1"
                size={25}
                color={theme.colors.textColor}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles(theme).kardIconStyle}
              onPress={() => setDislike(true)}
            >
              <AntDesign
                name="dislike1"
                size={25}
                color={theme.colors.textColor}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles(theme).kardIconStyle}
              onPress={() => setInfo(true)}
            >
              <Entypo name="info" size={25} color={theme.colors.textColor} />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles(theme).kardIconStyle}
              onPress={() => setFlag(true)}
            >
              <FontAwesome
                name="flag"
                size={25}
                color={theme.colors.textColor}
              />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles(theme).kardIconStyle}
              onPress={() => setDownload(true)}
            >
              <FontAwesome
                name="download"
                size={25}
                color={theme.colors.textColor}
              />
            </TouchableOpacity>
          </View>
        )}
        <AwesomeAlert
          show={like}
          title="LIKE"
          message="You have liked the kard"
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={true}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
        />
        <AwesomeAlert
          show={dislike}
          title="DISLIKE"
          message="You have disliked the kard."
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={true}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
        />
        <AwesomeAlert
          show={info}
          title="INFO"
          message="Would you like to see the info of this kard?"
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No"
          confirmText="Yes, show me"
          onCancelPressed={() => setInfo(false)}
          onConfirmPressed={() => {
            setInfo(false);
            showKardDetails();
          }}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
          cancelButtonStyle={styles(theme).alertButtonStyle}
          confirmButtonStyle={styles(theme).alertButtonStyle}
          cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
          confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
        />
        <AwesomeAlert
          show={showInfoMessage}
          title="INFO"
          message={infoMessage}
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText="Ok, got it"
          onConfirmPressed={() => {
            setShowInfoMessage(false);
          }}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
          confirmButtonStyle={styles(theme).alertButtonStyle}
          confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
        />
        <AwesomeAlert
          show={flag}
          title="FLAG"
          message="You have flagged the kard."
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={true}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
        />
        <AwesomeAlert
          show={download}
          title="DOWNLOAD"
          message="You can download the kard."
          closeOnTouchOutside={false}
          closeOnHardwareBackPress={false}
          alertContainerStyle={styles(theme).alertContainerStyle}
          contentContainerStyle={styles(theme).contentContainerStyle}
          overlayStyle={styles(theme).overlayStyle}
          titleStyle={styles(theme).titleStyle}
          messageStyle={styles(theme).alertMessageStyle}
          actionContainerStyle={styles(theme).actionContainerStyle}
        />
      </View>
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },
    kardIconStyle: {
      borderRadius: 100,
      borderWidth: 3,
      borderColor: theme.colors.borderColor,
      padding: 10,
      backgroundColor: theme.colors.backgroundColor,
    },
    buttonStyle: {
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 3,
      backgroundColor: theme.colors.backgroundColor,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },

    displayStyle: {
      height: "100%",
      width: isLargeScreen ? 600 : "100%",
      alignSelf: "center",
    },
    swiperStyle: {
      backgroundColor: "white",
      overflow: "hidden",
    },

    kardStyle: {
      overflow: "hidden",
    },

    bottomModal: {
      height: "40%",
      width: 60,
      flexDirection: "column",
      backgroundColor: "transparent",
      position: "absolute",
      bottom: 0,
      right: 0,
      margin: 20,
      marginTop: 0,
      alignItems: "flex-end",
      justifyContent: "space-around",
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default UseCases;
