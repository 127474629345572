import React, { useContext } from "react";
import { Text, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { ThemeContext } from "../../context/ThemeContext";

const InformationBrochure = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const navigation = useNavigation();
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>INFORMATION BROCHURE</Text>

      <Text style={styles(theme).labelStyle}>
        Kabutar has many use cases for students, teachers, parents, managers and
        other stakeholders of educational institutions. Educational institutions
        can use kabutar as a single stop notice board. They can notify
        admissions, examination schedules, results, various college events such
        as sports events, cultural events, seminars and webinars etc..,. They
        can spread ideas and promote their academic activities. They can
        advertise about their institution. They can convey greetings to their
        teachers, students, colleagues, friends, family members or even
        neighbours.
      </Text>

      <Text style={styles(theme).labelStyle}>
        The students and teachers can know about the activities in their school
        / college and any school / college in the world.
      </Text>
      <Text style={styles(theme).labelStyle}>
        Educational institutions can also use kabutar to maintain their campus
        free of posters, banners, pamphlets, hand-outs, leaflets, flyers and
        advertisements. Such clean and tidy academic campuses leave everlasting
        pleasant memories in the minds of the students throughout their life
        apart from enhancing their academic achievement during campus days.
      </Text>
      <Text style={styles(theme).labelStyle}>
        KAbutar caMPUS – for short KAMPUS program encourages the students,
        teachers, parents, managers and other stakeholders of educational
        institutions to use kabutar app with cash discounts and appreciation
        awards.
      </Text>
      <Text style={styles(theme).labelStyle}>
        Only the educational institutions alone are eligible to apply to this
        program. Other organisations and individuals are not eligible.
      </Text>

      <Text style={styles(theme).labelBoldStyle}>
        The following discounts and incentives are given to the institutions:
      </Text>

      <Text style={styles(theme).labelStyle}>
        1. FREE REGISTRATION: An educational institution can register for kampus
        program free cost.
      </Text>

      <Text style={styles(theme).labelStyle}>
        2. ENROLL UNLIMITED NUMBER OF VOLUNTEERS: The registered institution can
        enrol unlimited number of individuals as volunteers and these volunteers
        can avail discounts and incentives.
      </Text>

      <Text style={styles(theme).labelStyle}>
        3. POST UNLIMITED KAMPUS KARDS FREELY: The registered institution can
        post unlimited number of kampus kards free of cost for display in its
        institution campus. This can be availed soon after enrolling 10
        volunteers.
      </Text>

      <Text style={styles(theme).labelStyle}>
        4. 20% DISCOUNT SUBJECT TO A MAXIMUM OF ₹ 100: The institution can avail
        20% discount subject to a maximum ₹ 100 of on every paid kard for ONE
        year. The ONE year is recalculated every time when an individual is
        enrolled as volunteer. As such the institution can avail this discount
        indefinitely subject to enrolling volunteers within one year from last
        enrolment. This can be availed soon after kampus registration of the
        institution is approved.
      </Text>

      <Text style={styles(theme).labelStyle}>
        5. KAMPUS CHAMPIONSHIP AWARD: This award is given to the registered
        institution for enrolling 1000 volunteers.
      </Text>

      <Text style={styles(theme).labelStyle}>
        6. PROMOTE KAMPUS EVENTS / COMPETITIONS: The registered institution can
        conduct various events / competitions / programs on how kabutar is
        benefitting academic and other communities. The kabutar app supports and
        sponsors all such activities through promo kards with required views and
        display area FREE OF COST. This can be availed soon after successful
        registration.
      </Text>

      <Text style={styles(theme).labelBoldStyle}>
        The following discounts and incentives are given to the enrolled
        individuals by the institutions as volunteers:
      </Text>

      <Text style={styles(theme).labelStyle}>
        1. FREE REGISTRATION: Students, teachers, parents, managers and other
        stakeholders can register as kampus volunteers through their educational
        institution free cost.
      </Text>

      <Text style={styles(theme).labelStyle}>
        2. 20% DISCOUNT SUBJECT TO A MAXIMUM OF ₹ 100: The volunteers can avail
        20% discount subject to a maximum ₹ 100 of on every paid kard for THREE
        years.
      </Text>

      <Text style={styles(theme).labelStyle}>
        3. PARTICIPATE IN KAMPUS EVENTS / COMPETITIONS AND WIN PRIZES: The
        registered institution can conduct various events / competitions /
        programs on how kabutar is benefitting academic and other communities.
        The kabutar app supports and sponsors all such activities through promo
        kards with required views and display area FREE OF COST. The individuals
        can participate in such activities and win prizes.
      </Text>

      <Text style={styles(theme).labelBoldStyle}>
        PREREQUISITES FOR KAMPUS REGISTRATION
      </Text>

      <Text style={styles(theme).labelStyle}>
        1. The institution should be a registered user of kabutar app. The
        registration is free and the process is very simple. Official email or
        mobile number of the institution should be used for registration.
        Individual or personal emails or phone numbers should not be used.
      </Text>

      <Text style={styles(theme).labelStyle}>
        2. The name of the institution should be correctly entered in the
        profile section of Kabutar app.
      </Text>

      <Text style={styles(theme).labelStyle}>
        3. The first address of the institution in the profile should match with
        the institution campus.
      </Text>

      <Text style={styles(theme).labelBoldStyle}>
        AT THE TIME OF REGISTRATION
      </Text>

      <Text style={styles(theme).labelStyle}>
        1. At the time of registering for kampus, it should be ensured that the
        location and boundaries of the campus are set correctly.
      </Text>

      <Text style={styles(theme).labelStyle}>
        2. At the time of registering for kampus, it should also be ensured that
        the name and location of the institution are available in google maps
        for easy verification.
      </Text>

      <Text style={styles(theme).labelBoldStyle}>VERIFICATION PROCESS</Text>

      <Text style={styles(theme).labelStyle}>
        1. The location and campus boundaries marked while submitting the
        application are verified vis-a-vis the business profile of the
        institution in the google maps.
      </Text>

      <Text style={styles(theme).labelStyle}>
        2. If the institution name and location are not available in the google
        maps, the location and campus boundaries marked while submitting the
        application are evaluated manually through third party enquiries, if
        found necessary.
      </Text>

      <Text style={styles(theme).labelStyle}>
        3. If the location and campus boundaries marked while submitting the
        application appear to be genuine and true, the application will be
        approved.
      </Text>

      <Text style={styles(theme).labelStyle}>
        4. If the location and campus boundaries marked while submitting the
        application do not appear to be genuine and true, the application will
        be rejected.
      </Text>

      <Text style={styles(theme).labelStyle}>
        5. In case the application for Kampus is rejected, "Kampus Registration"
        button will be re enabled.
      </Text>

      <Text style={styles(theme).labelStyle}>
        6. A fresh application can be submitted with correct details once
        clean-up process is completed. This may take at most 48 hours.
      </Text>

      <Text style={styles(theme).labelBoldStyle}>ENROLLING VOLUNTEERS</Text>

      <Text style={styles(theme).labelStyle}>
        1. After successful kampus registration, volunteers can be enrolled.
      </Text>

      <Text style={styles(theme).labelStyle}>
        2. Volunteers can be enrolled by uploading an excel file (excel workbook
        saved with .xlsx extension) containing their registered emails or mobile
        numbers. The mobile numbers should be + followed by international
        dialling code followed by mobile number. Example : +916784378543. The
        email or mobile number can be in any cell in any sheet in the excel
        file/workbook. The cells should be in text format.
      </Text>

      <Text style={styles(theme).labelStyle}>
        3. At a time, only 10 volunteers can be enrolled. If there are more
        than10 volunteers, they can be enrolled in different batches.
      </Text>

      <Text style={styles(theme).labelBoldStyle}>
        PREREQISITES FOR ENROLLING VOLUNTEERS
      </Text>

      <Text style={styles(theme).labelStyle}>
        1. The individuals enrolled by the institution as volunteers should be
        registered users of kabutar app. The registration is free and the
        process is very simple.
      </Text>

      <Text style={styles(theme).labelStyle}>
        2. The enrolled individuals should be students, teachers, parents,
        managers and other stakeholders of the institution.
      </Text>

      <Text style={styles(theme).labelStyle}>
        3. The institution should not enroll anyone who is not their
        stakeholders or who is not connected with their institution as
        volunteer.
      </Text>

      <Text style={styles(theme).labelBoldStyle}> KAMPUS STATUS </Text>

      <Text style={styles(theme).labelStyle}>
        1. Status can be any one of the following:
      </Text>
      <Text style={styles(theme).labelStyle}>
        {"\t"}1. You can register as kampus institution or kampus volunteer.
      </Text>
      <Text style={styles(theme).labelStyle}>
        {"\t"}2. You have applied for kampus registration and the application is
        being verified.
      </Text>
      <Text style={styles(theme).labelStyle}>
        {"\t"}3. You have been registered as kampus institution. You can avail
        20% concession on paid kards subject to a maximum of ₹ 100.
      </Text>
      <Text style={styles(theme).labelStyle}>
        {"\t"}4. You are enrolled as kampus volunteer. You can avail 20%
        concession on paid kards subject to a maximum of ₹ 100.
      </Text>

      <Text style={styles(theme).labelStyle}>
        2. When an application for kampus registration is rejected, the status
        is reset to 1 above.
      </Text>

      <Text style={styles(theme).labelStyle}>
        3. If kampus registration of an institution has expired, it can be
        revalidated on payment of ₹10 per volunteer enrolled earlier or ₹500
        whichever is higher plus taxes. After revalidation, the institution can
        avail all the benefits.
      </Text>

      <Text style={styles(theme).labelStyle}>
        4. If kampus enrolment of an individual has expired, it can be
        revalidated on payment of ₹500 plus taxes for another THREE years.
      </Text>

      <Text style={styles(theme).labelStyle}>
        5. For volunteer revalidation, the individual need not continue to be
        student or teacher or staff of the institution that has earlier
        enrolled. They are considered as stakeholders as alumni.
      </Text>

      <Text style={styles(theme).labelBoldStyle}>OTHER GUIDELINES</Text>

      <Text style={styles(theme).labelStyle}>
        1. The maximum number of views for a kampus kard posted by the
        institution is 10 times the number of enrolled individuals as volunteers
        by the institution. If more number of views are required, it is
        suggested to enrol more individuals as volunteers.
      </Text>

      <Text style={styles(theme).labelStyle}>
        2. It is recommended not to post same kard multiple times simultaneously
        as it would be shown to the viewer multiple times. Alternatively, the
        same kard can be posted again after the first one has completed the
        display.
      </Text>

      <Text style={styles(theme).labelStyle}>
        3. It is recommended to enrol as many individuals as volunteers.
      </Text>

      <Text style={styles(theme).labelStyle}>
        4. It is recommended to conduct as many kampus events/ competitions.
      </Text>
      <Text style={styles(theme).labelStyle}>
        5. Approved kampus registration or enrolment of volunteer can be
        cancelled in future, if it is found to be false.
      </Text>

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("KampusHome")}
      >
        <Text style={styles(theme).buttonTextStyle}>Back</Text>
      </TouchableOpacity>
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    labelStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },

    labelBoldStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },
    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
  });

export default InformationBrochure;
