import React, { useState, useContext } from "react";
import { Text, View, StyleSheet, Platform } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import AwesomeAlert from "react-native-awesome-alerts";
import DateTimePicker from "@react-native-community/datetimepicker";
import { TimePickerModal } from "react-native-paper-dates";
import { DatePickerModal } from "react-native-paper-dates";

import SetViewsForKampusKard from "./SetViewsForKampusKard";
import { ThemeContext } from "../../../context/ThemeContext";
import { KampusKardContext } from "../../../context/KampusKardContext";

const ChooseSettingsForKampusKard = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const [chooseSettings, setChooseSettings] = useState(false);
  const [viewsModal, setViewsModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showTime, setShowTime] = useState(false);

  const navigation = useNavigation();

  const { polygon, setPolygon, finalViews, setFinalViews, date, setDate } =
    useContext(KampusKardContext);

  const onChange = (event, selectedDate) => {
    setShowDate(false);
    setShowTime(false);
    const currentDate = selectedDate || date;
    setDate(currentDate);
  };

  const onDismiss = () => {
    setShowDate(false);
    setShowTime(false);
  };
  const onConfirmDate = (params) => {
    setDate(params.date);
    setShowDate(false);
  };
  const onConfirmTime = ({ hours, minutes }) => {
    date.setHours(hours);
    date.setMinutes(minutes);
    setDate(date);
    setShowTime(false);
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>CHOOSE SETTINGS</Text>
        <TouchableOpacity onPress={() => setChooseSettings(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <Text style={styles(theme).textCenterStyle}>Step 2 of 3</Text>
      <View>
        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => setViewsModal(true)}
        >
          <Text style={styles(theme).buttonTextStyle}>Set Views</Text>
        </TouchableOpacity>
        <Text style={styles(theme).textCenterStyle}>Views : {finalViews}</Text>
        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => setShowDate(true)}
        >
          <Text style={styles(theme).buttonTextStyle}>Set Date</Text>
        </TouchableOpacity>
        <Text style={styles(theme).textCenterStyle}>
          Date : {date.toDateString()}
        </Text>
        <TouchableOpacity
          style={styles(theme).buttonStyle}
          onPress={() => setShowTime(true)}
        >
          <Text style={styles(theme).buttonTextStyle}>Set Time</Text>
        </TouchableOpacity>
        <Text style={styles(theme).textCenterStyle}>
          Time:
          {date.toLocaleTimeString()}
        </Text>
        {showDate &&
          (Platform.OS == "web" ? (
            <DatePickerModal
              mode="single"
              onDismiss={onDismiss}
              date={date}
              visible={true}
              onConfirm={onConfirmDate}
              locale={"en"}
              validRange={{
                startDate: new Date(),
                endDate: new Date(
                  new Date().getTime() + 7 * 24 * 60 * 60 * 1000
                ),
              }}
            />
          ) : (
            <DateTimePicker
              testID="dateTimePicker"
              minimumDate={new Date()}
              maximumDate={
                new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
              }
              value={date}
              mode={"date"}
              display="spinner"
              onChange={onChange}
            />
          ))}
        {showTime &&
          (Platform.OS == "web" ? (
            <TimePickerModal
              visible={true}
              onDismiss={onDismiss}
              onConfirm={onConfirmTime}
              label="Select time"
              cancelLabel="Cancel"
              confirmLabel="Ok"
              hours={date.getHours()}
              minutes={date.getMinutes()}
              animationType="fade"
              locale={"en"}
            />
          ) : (
            <DateTimePicker
              testID="dateTimePicker"
              value={date}
              mode={"time"}
              display="spinner"
              onChange={onChange}
            />
          ))}
      </View>

      <View style={styles(theme).rowGroupSpaceEvenlyStyle}>
        <TouchableOpacity
          onPress={() => navigation.navigate("SelectKampusKard")}
        >
          <AntDesign
            name={theme.type == "fill" ? "leftcircle" : "leftcircleo"}
            size={50}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => navigation.navigate("ReviewAndConfirmKampusKard")}
        >
          <AntDesign
            name={theme.type == "fill" ? "rightcircle" : "rightcircleo"}
            size={50}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <AwesomeAlert
        show={chooseSettings}
        title="CHOOSE SETTINGS"
        message={
          "You can set  views, date and time.  Views is the number of times the kard is displayed. Date and time are the starting date and time from when the kard is displayed. Default values for views are 50. Default values for date and time are current date and time"
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setChooseSettings(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />

      {viewsModal && (
        <SetViewsForKampusKard {...{ viewsModal, setViewsModal }} />
      )}
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    rowGroupSpaceEvenlyStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default ChooseSettingsForKampusKard;
