import React, { createContext, useState } from "react";
import { Dimensions } from "react-native";
import * as Device from "expo-device";

const SCREEN_WIDTH = Dimensions.get("screen").width;
const isLargeScreen = SCREEN_WIDTH >= 768;

export const DeviceContext = createContext(null);

export default ({ children }) => {
  const [deviceType, setDeviceType] = useState(async () => {
    const device = await Device.getDeviceTypeAsync();
    if (device == 1) {
      setDeviceType("MOBILE");
    } else {
      setDeviceType(isLargeScreen ? "NOT_MOBILE" : "MOBILE");
    }
  });

  return (
    <DeviceContext.Provider
      value={{
        deviceType,
        setDeviceType,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
