import React, { useContext, useState, useEffect } from "react";
import { Text, View, StyleSheet, Platform, Dimensions } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { Button } from "react-native-paper";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";
import { useNavigation } from "@react-navigation/native";
import intersecting_polygon_detector from "intersecting-polygon-detector";
import AwesomeAlert from "react-native-awesome-alerts";

import { ThemeContext } from "../../../context/ThemeContext";
import { KampusEventContext } from "../../../context/KampusEventContext";

const SCREEN_HEIGHT = Dimensions.get("window").height;
const SCREEN_WIDTH = Dimensions.get("window").width;

const SetAreaForKampusEvent = () => {
  const {
    mapRegionForKampusEvent,
    setMapRegionForKampusEvent,
    polygon,
    setPolygon,
  } = useContext(KampusEventContext);
  const ASPECT_RATIO = SCREEN_WIDTH / SCREEN_HEIGHT;
  const { theme, changeTheme } = useContext(ThemeContext);

  const [displayArea, setDisplayArea] = useState(false);
  const navigation = useNavigation();

  const [preData, setPreData] = useState([...polygon]);

  useEffect(() => {
    setPreData([...polygon]);
  }, [polygon.length]);

  const onRegionChange = (region) => {
    var latitudeDelta =
      Platform.OS === "web"
        ? Math.pow(2, Math.log2(360) - region.zoom)
        : region.latitudeDelta;
    var longitudeDelta =
      Platform.OS === "web"
        ? latitudeDelta * ASPECT_RATIO
        : region.longitudeDelta;
    setMapRegionForKampusEvent({
      latitude: region.latitude,
      longitude: region.longitude,
      latitudeDelta: latitudeDelta,
      longitudeDelta: longitudeDelta,
    });
  };

  const drawPolygon = (event) => {
    if (preData.length < 10) {
      var thisItem = {};

      if (Platform.OS == "web") {
        (thisItem.lat = parseFloat(event.latLng.lat())),
          (thisItem.lng = parseFloat(event.latLng.lng()));
      } else {
        (thisItem.latitude = parseFloat(event.nativeEvent.coordinate.latitude)),
          (thisItem.longitude = parseFloat(
            event.nativeEvent.coordinate.longitude
          ));
      }
      if (preData.length < 3) {
        setPreData((prev) => [...prev, thisItem]);
      } else {
        var testData = [];
        preData.map((i, k) => {
          const existingItem =
            Platform.OS === "web" ? [i.lat, i.lng] : [i.latitude, i.longitude];
          testData.push(existingItem);
        });
        const lastItem =
          Platform.OS === "web"
            ? [thisItem.lat, thisItem.lng]
            : [thisItem.latitude, thisItem.longitude];
        testData.push(lastItem);
        const repeatFirstItem =
          Platform.OS === "web"
            ? [preData[0].lat, preData[0].lng]
            : [preData[0].latitude, preData[0].longitude];
        testData.push(repeatFirstItem);
        if (!intersecting_polygon_detector(testData)) {
          setPreData((prev) => [...prev, thisItem]);
        }
      }
    }
  };
  return (
    <View style={styles(theme).screenStyle}>
      <MapView
        style={styles(theme).mapStyle}
        provider={PROVIDER_GOOGLE}
        initialRegion={mapRegionForKampusEvent}
        onPress={(event) => drawPolygon(event)}
        onRegionChangeComplete={onRegionChange}
      >
        <MapView.Marker coordinate={mapRegionForKampusEvent} />

        {preData.length != 0 &&
          preData.map((item, key) => {
            return (
              <MapView.Marker
                key={key}
                coordinate={{
                  latitude: Platform.OS === "web" ? item.lat : item.latitude,
                  longitude: Platform.OS === "web" ? item.lng : item.longitude,
                }}
                pinColor="green"
              />
            );
          })}
        {Platform.OS === "web" ? (
          <MapView.Polygon
            path={preData}
            options={{
              strokeColor: theme.colors.standardTextColor,
            }}
          />
        ) : preData.length != 0 ? (
          <MapView.Polygon
            coordinates={preData}
            strokeColor={theme.colors.standardTextColor}
            fillColor={theme.colors.standardTextColor}
          />
        ) : null}
      </MapView>
      <View style={styles(theme).mapIconViewStyle}>
        <Button onPress={() => setDisplayArea(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </Button>
      </View>

      <View style={styles(theme).mapButtonViewStyle}>
        <Button
          onPress={() => {
            setPreData([]);
          }}
          style={styles(theme).mapButtonStyle}
        >
          <Text style={{ color: theme.colors.textColor }}>CANCEL</Text>
        </Button>
        <Button
          onPress={() => {
            setPolygon([...preData]);
            navigation.navigate("ChooseSettingsForKampusEvent");
          }}
          style={styles(theme).mapButtonStyle}
        >
          <Text style={{ color: theme.colors.textColor }}>OK</Text>
        </Button>
      </View>
      <AwesomeAlert
        show={displayArea}
        title="SET DISPLAY AREA"
        message={
          "Display area is the geographical area where the kard is  displayed and is represented on the map by a simple polygon with 3 to 10 corners. Click or tap or press to specify a corner. Self interseccting polygons are not permitted."
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setDisplayArea(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    mapStyle: {
      flex: 1,
      width: "100%",
      height: "100%",
    },
    mapIconViewStyle: {
      top: 50,
      right: 0,
      position: "absolute",
    },
    mapButtonViewStyle: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      left: 0,
      right: 0,
      bottom: 30,
      position: "absolute",
    },
    mapButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default SetAreaForKampusEvent;
