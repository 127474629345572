import React, { useState, useContext, useEffect } from "react";
import { Text, View, StyleSheet, ScrollView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import AwesomeAlert from "react-native-awesome-alerts";

import { UserContext } from "../../context/UserContext";
import { ThemeContext } from "../../context/ThemeContext";
import { ProfileContext } from "../../context/ProfileContext";
import { NewKardContext } from "../../context/NewKardContext";
import SelectAddressForNewKard from "./SelectAddressForNewKard";

const BillingDetailsForNewKard = () => {
  const { user } = useContext(UserContext);
  const navigation = useNavigation();
  const { finalViews, addressKey, setAddressKey } = useContext(NewKardContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const { profile, setProfile } = useContext(ProfileContext);
  const [billingInfo, setBillingInfo] = useState(false);
  const [noAddress, setNoAddress] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      setAddressModal(false);
    }
  }, [isFocused]);

  const stagingTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "stagingTeam"
    );

  const devTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "devTeam"
    );

  const charges =
    finalViews <= 100
      ? 0
      : stagingTeam || devTeam
      ? 1.1
      : parseFloat(finalViews * 0.1).toFixed(2);

  const discount =
    (profile.kampusStatus == 3 || profile.kampusStatus == 4) &&
    new Date(profile.kampusEndDate).getTime() >= new Date().getTime()
      ? charges * 0.2 > 100
        ? 100
        : (charges * 0.2).toFixed(2)
      : 0;

  const taxes = ((parseFloat(charges) - parseFloat(discount)) * 0.18).toFixed(
    2
  );
  const total = (
    parseFloat(charges) -
    parseFloat(discount) +
    parseFloat(taxes)
  ).toFixed(2);

  const checkBillingDetails = () => {
    if (addressKey != null) {
      navigation.navigate("ReviewAndConfirmNewKard");
    } else {
      setNoAddress(true);
    }
  };
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>BILLING INFO</Text>
        <TouchableOpacity onPress={() => setBillingInfo(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <Text style={styles(theme).textCenterStyle}>Step 4 of 5</Text>
      <Text style={styles(theme).subHeaderStyle}>Bill Details</Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>
            Charges for {finalViews} views:
          </Text>
          <Text style={styles(theme).textBoldStyle}>₹ {charges}</Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Discount:</Text>
          <Text style={styles(theme).textBoldStyle}>₹ {discount}</Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Taxes:</Text>
          <Text style={styles(theme).textBoldStyle}>₹ {taxes}</Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Total:</Text>
          <Text style={styles(theme).textBoldStyle}>₹ {total}</Text>
        </View>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Billing Address</Text>

      {profile.address ? (
        <View>
          {addressKey != null ? (
            <View>
              <View style={styles(theme).boxStyle}>
                <Text style={styles(theme).textJustifyStyle}>
                  Name: {profile.address[addressKey].name}
                </Text>
                <Text style={styles(theme).textJustifyStyle}>
                  {profile.address[addressKey].line1},{" "}
                  {profile.address[addressKey].line2},{" "}
                  {profile.address[addressKey].line3}
                </Text>
                <Text style={styles(theme).textJustifyStyle}>
                  State / Region: {profile.address[addressKey].state}, Country:{" "}
                  {profile.address[addressKey].country}, PIN / ZIP:{" "}
                  {profile.address[addressKey].zip}
                </Text>

                <View style={styles(theme).headerViewStyle}>
                  <TouchableOpacity onPress={() => setAddressKey(null)}>
                    <MaterialCommunityIcons
                      name={
                        theme.type == "fill"
                          ? "close-circle"
                          : "close-circle-outline"
                      }
                      size={50}
                      color={theme.colors.standardTextColor}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <TouchableOpacity
                style={styles(theme).buttonStyle}
                setAddressKey={() => setAddressKey()}
                onPress={() => setAddressModal(true)}
              >
                <Text style={styles(theme).buttonTextStyle}>
                  Select Address
                </Text>
              </TouchableOpacity>
              <Text style={styles(theme).textCenterStyle}>OR</Text>
              <TouchableOpacity
                style={styles(theme).buttonStyle}
                onPress={() =>
                  navigation.navigate("AddNewAddress", {
                    key: null,
                  })
                }
              >
                <Text style={styles(theme).buttonTextStyle}>
                  Add New Address
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      ) : (
        <View>
          <Text style={styles(theme).textCenterStyle}>
            You do not have any addresses
          </Text>

          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() =>
              navigation.navigate("AddNewAddress", {
                key: null,
              })
            }
          >
            <Text style={styles(theme).buttonTextStyle}>Add New Address</Text>
          </TouchableOpacity>
        </View>
      )}

      <View style={styles(theme).rowGroupSpaceEvenlyStyle}>
        <TouchableOpacity
          onPress={() => navigation.navigate("ChooseSettingsForNewKard")}
        >
          <AntDesign
            name={theme.type == "fill" ? "leftcircle" : "leftcircleo"}
            size={50}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => checkBillingDetails()}>
          <AntDesign
            name={theme.type == "fill" ? "rightcircle" : "rightcircleo"}
            size={50}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <AwesomeAlert
        show={billingInfo}
        title="BILLING INFO"
        message="You can select any address already stored in the profile as billing address by pressing the select address button. You can also add new address to the profile using add new address button."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setBillingInfo(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={noAddress}
        title="NO ADDRESS SELECTED"
        message="Address is not selected. Address must be selected to proceed to the next step."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setNoAddress(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      {addressModal && (
        <SelectAddressForNewKard
          {...{ setAddressKey, addressModal, setAddressModal }}
        />
      )}
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },
    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },
    textBoldStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      margin: 5,
    },
    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    boxTextStyle: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    rowGroupSpaceEvenlyStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default BillingDetailsForNewKard;
