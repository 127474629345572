import React, { createContext, useState } from "react";

const defaultLocation = {
  latitude: 28.612894,
  longitude: 77.229446,
};

// const getCurrentLocation = async () => {
//   console.log("I am in CurrentLocationContext");
//   try {
//     let permissions = await Location.getBackgroundPermissionsAsync();
//     console.log(permissions);
//     if (permissions.status !== "granted") {
//       let loc = await Location.getLastKnownPositionAsync();
//       if (loc !== null) {
//         const obj = {
//           latitude: loc.coords.latitude,
//           longitude: loc.coords.longitude,
//         };
//         console.log("last known position available");
//         console.log(obj);
//         return obj;
//       } else {
//         const obj = {
//           latitude: 28.6448,
//           longitude: 77.216721,
//         };
//         console.log("last known position is null");
//         console.log(obj);
//         return obj;
//       }
//     } else {
//       let loc = await Location.getCurrentPositionAsync();
//       const obj = {
//         latitude: loc.coords.latitude,
//         longitude: loc.coords.longitude,
//       };
//       console.log("current position ");
//       console.log(obj);
//       return obj;
//     }
//   } catch (err) {
//     console.log("error in initial location function" + err);
//   }
// };

// const getCurrentLocation = async () => {
//   try {
//     let loc = await Location.getCurrentPositionAsync();
//     // console.log(loc);
//     if (loc.coords.latitude) {
//       const obj = {
//         latitude: loc.coords.latitude,
//         longitude: loc.coords.longitude,
//       };
//       return obj;
//     } else {
//       const obj = {
//         latitude: 11.916064,
//         longitude: 79.812325,
//       };
//       return obj;
//     }
//   } catch (err) {
//     console.log("error in initial location function" + JSON.stringify(err));
//   }
// };

export const CurrentLocationContext = createContext(null);

export default ({ children }) => {
  // const [currentLocation, setCurrentLocation] = useState(() =>
  //   getCurrentLocation().then((data) => setCurrentLocation(data))
  // );

  const [currentLocation, setCurrentLocation] = useState(defaultLocation);
  return (
    <CurrentLocationContext.Provider
      value={{
        currentLocation,
        setCurrentLocation,
      }}
    >
      {children}
    </CurrentLocationContext.Provider>
  );
};
