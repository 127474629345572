import React, { createContext, useState } from "react";

export const OrderContext = createContext(null);

export default ({ children }) => {
  const [orderId, setOrderId] = useState("");
  const [amount, setAmount] = useState("");
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [qrCodeData, setQrCodeData] = useState(null);
  return (
    <OrderContext.Provider
      value={{
        orderId,
        setOrderId,
        amount,
        setAmount,
        qrCodeImage,
        setQrCodeImage,
        qrCodeData,
        setQrCodeData,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
