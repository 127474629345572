import React, { useContext, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import * as DocumentPicker from "expo-document-picker";
import { AntDesign } from "@expo/vector-icons";
import API from "@aws-amplify/api";
import * as XLSX from "xlsx";
import { useNavigation } from "@react-navigation/native";

import { ProfileContext } from "../../../context/ProfileContext";
import { ThemeContext } from "../../../context/ThemeContext";
import * as mutations from "../../../src/graphql/mutations";
import * as queries from "../../../src/graphql/queries";

const EnrollVolunteers = () => {
  const navigation = useNavigation();
  const { profile, setProfile } = useContext(ProfileContext);
  const { theme, changeTheme } = useContext(ThemeContext);
  const [moreUsers, setMoreUsers] = useState(false);
  const [enrollStatus, setEnrollStatus] = useState(false);
  const [noUsers, setNoUsers] = useState(false);
  const [submittedUsernames, setSubmittedUsernames] = useState([]);
  const [enrollMessage, setEnrollMessage] = useState("");
  const [checkboxSelected, setCheckboxSelected] = useState(false);

  const getUsernamesFromExcelFile = async () => {
    const doc = await DocumentPicker.getDocumentAsync({
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    if (doc.type == "success") {
      var regEmail = /\S+@\S+\.\S+/;
      var regPhone = /\+[0-9]{8,16}/;
      let array = [];
      fetch(doc.uri)
        .then((res) => {
          return res.arrayBuffer();
        })
        .then((res) => {
          var workbook = XLSX.read(new Uint8Array(res), {
            type: "array",
          });
          for (let i = 0; i < workbook.Strings.length; i++) {
            if (
              regEmail.test(workbook.Strings[i].t) ||
              regPhone.test(workbook.Strings[i].t)
            ) {
              array.push(workbook.Strings[i].t);
            }
          }

          if (array.length > 10) {
            setMoreUsers(true);
          } else if (array.length == 0) {
            setNoUsers(true);
          } else {
            setSubmittedUsernames(array);
          }
        });
    }
  };

  const enrollVolunteers = async () => {
    var unRegisteredUsernamesArray = [];
    var institutionUsernamesArray = [];
    var enrolledUsernamesArray = [];
    var readyToEnrollUsersArray = [];

    //  separate submittedUsernames to readyToEnrollUsers, InstitutionEmails, alreadyEnrolledEmails and unrregisteredEmails

    for (var i = 0; i < submittedUsernames.length; i++) {
      const listUserProfiles = await API.graphql({
        query: queries.listProfilesByUsername,
        variables: {
          username: submittedUsernames[i],
        },
      });

      // check kampus status in UserProfile

      if (listUserProfiles.data.listProfilesByUsername.items.length != 0) {
        var kampusStatus =
          listUserProfiles.data.listProfilesByUsername.items[0].kampusStatus;
        if (kampusStatus == 1) {
          var item = {
            id: listUserProfiles.data.listProfilesByUsername.items[0].id,
            username: submittedUsernames[i],
          };
          readyToEnrollUsersArray.push(item);
        } else if (kampusStatus == 2 || kampusStatus == 3) {
          institutionUsernamesArray.push(submittedUsernames[i]);
        } else {
          enrolledUsernamesArray.push(submittedUsernames[i]);
        }
      } else {
        unRegisteredUsernamesArray.push(submittedUsernames[i]);
      }
    }

    var readyToEnrollUsernamesString = "";
    if (readyToEnrollUsersArray.length != 0) {
      // enroll volunteers by updating their profile

      readyToEnrollUsersArray.map(async (item, key) => {
        readyToEnrollUsernamesString =
          readyToEnrollUsernamesString + item.username + " , ";

        const enrollAsVolunteer = await API.graphql({
          query: mutations.updateProfile,
          variables: {
            input: {
              id: item.id,
              kampusStatus: 4,
              kampusStartDate: new Date().toISOString(),
              kampusEndDate: new Date(
                new Date().setFullYear(new Date().getFullYear() + 3)
              ).toISOString(),
            },
          },
        });
      });
      if (
        profile.kampusRegistration.volunteerCount < 1000 &&
        profile.kampusRegistration.volunteerCount +
          readyToEnrollUsersArray.length >=
          1000
      ) {
        // update volunteer count and championshipdate
        const updateVolunteerCount = await API.graphql({
          query: mutations.updateKampusRegistration,
          variables: {
            input: {
              id: profile.id,
              volunteerCount:
                profile.kampusRegistration.volunteerCount +
                readyToEnrollUsersArray.length,
              championshipDate: new Date().toISOString(),
            },
          },
        });
      } else {
        // update volunteer count only
        const updateVolunteerCount = await API.graphql({
          query: mutations.updateKampusRegistration,
          variables: {
            input: {
              id: profile.id,
              volunteerCount:
                profile.kampusRegistration.volunteerCount +
                readyToEnrollUsersArray.length,
            },
          },
        });
      }

      // extend End Date
      const extendExpiryDate = await API.graphql({
        query: mutations.updateProfile,
        variables: {
          input: {
            id: profile.id,
            kampusEndDate: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            ).toISOString(),
          },
        },
      });

      setProfile(extendExpiryDate.data.updateProfile);
      setProfile((state) => ({
        ...state,
      }));
    }

    var msg = `You have submitted ${submittedUsernames.length} for enrollment. Out of them ${readyToEnrollUsersArray.length} have been succesfully enrolled. The remaining are either unregistered users  or have already enrolled or institutional users. Email / Phone number(s) of sucessfully enrolled volunteer(s):  ${readyToEnrollUsernamesString}`;
    setEnrollMessage(msg);
    setEnrollStatus(true);
    // determine how 1) how many are unregistered 2) many are enrolled, 3) how many are rejected
    // show up a modal accordingly
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>ENROLL VOLUNTEERS</Text>

      <Text style={styles(theme).labelStyle}>
        Institution Name: {profile.name}
      </Text>

      <Text style={styles(theme).labelStyle}>
        Email/Phone : {profile.username}
      </Text>
      {profile.address && (
        <Text style={styles(theme).labelStyle}>
          Address:
          {profile.address[0].name}, {profile.address[0].line1},
          {profile.address[0].line2}, {profile.address[0].line3},
          {profile.address[0].region}, {profile.address[0].country} -{" "}
          {profile.address[0].zip}
        </Text>
      )}
      {profile.kampusRegistration && (
        <Text style={styles(theme).labelStyle}>
          Current Volunteers: {profile.kampusRegistration.volunteerCount}
        </Text>
      )}

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => getUsernamesFromExcelFile()}
      >
        <Text style={styles(theme).buttonTextStyle}>Select Excel File</Text>
      </TouchableOpacity>
      {submittedUsernames.length == 0 ? (
        <Text style={styles(theme).textCenterStyle}>
          Excel file not yet selected
        </Text>
      ) : (
        <>
          <Text style={styles(theme).textCenterStyle}>
            Selected Excel file contains {submittedUsernames.length} email/phone
            number(s).
          </Text>
          <Text style={styles(theme).textCenterStyle}> maximum limit :10</Text>
        </>
      )}

      <View style={styles(theme).termsViewStyle}>
        <TouchableOpacity
          onPress={() => setCheckboxSelected(!checkboxSelected)}
        >
          <AntDesign
            name={
              theme.type == "fill"
                ? !checkboxSelected
                  ? "closecircle"
                  : "checkcircle"
                : !checkboxSelected
                ? "closecircleo"
                : "checkcircleo"
            }
            size={50}
            color={!checkboxSelected ? "red" : theme.colors.standardTextColor}
          />
        </TouchableOpacity>

        <Text
          style={{
            flex: 1,
            flexWrap: "wrap",
            color:
              theme.type == "fill"
                ? !checkboxSelected
                  ? "red"
                  : theme.colors.standardTextColor
                : !checkboxSelected
                ? "red"
                : theme.colors.standardTextColor,
            paddingLeft: 20,
            textAlign: "justify",
          }}
        >
          I have read the terms and conditions and I agree
        </Text>
      </View>

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => navigation.navigate("KampusHome")}
      >
        <Text style={styles(theme).buttonTextStyle}>Back</Text>
      </TouchableOpacity>
      <TouchableOpacity
        disabled={!(submittedUsernames.length > 0 && checkboxSelected)}
        style={
          submittedUsernames.length > 0 && checkboxSelected
            ? styles(theme).buttonStyle
            : styles(theme).disabledButtonStyle
        }
        onPress={() => {
          enrollVolunteers();
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Enroll</Text>
      </TouchableOpacity>

      <AwesomeAlert
        show={moreUsers}
        title="ENROLL LIMIT EXCEDED"
        message={
          "At a time, only 10 volunteers can be enrolled. If there are more than 10 volunteers, they can be enrolled in different batches."
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setSubmittedUsernames([]);
          setMoreUsers(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={enrollStatus}
        title="ENROLLMENT STATUS"
        message={enrollMessage}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setSubmittedUsernames([]);
          setCheckboxSelected(false);
          setEnrollStatus(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={noUsers}
        title="NO EMAILS OR MOBILE NUMBERS"
        message={
          "The excel work book does not contain any emails or mobile numbers"
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setSubmittedUsernames([]);
          setNoUsers(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },

    labelStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    disabledButtonStyle: {
      width: "80%",
      backgroundColor: theme.colors.disabledButtonColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.disabledButtonColor,
      margin: 10,
    },

    termsViewStyle: {
      width: "80%",
      flexDirection: "row",
      alignSelf: "center",
      alignItems: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });

export default EnrollVolunteers;
