import React, { useContext } from "react";

import { NavigationContainer } from "@react-navigation/native";
import { Dimensions } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import * as Linking from "expo-linking";

import { ThemeContext } from "../../context/ThemeContext";
import { DeviceContext } from "../../context/DeviceContext";

import Feedback from "../feedback/Feedback";

import KampusAccount from "../kampus/kampusAccount/KampusAccount";
import KampusEventDetails from "../kampus/kampusAccount/KampusEventDetails";

import ChooseSettingsForKampusEvent from "../kampus/kampusEvent/ChooseSettingsForKampusEvent";
import ReviewAndConfirmKampusEvent from "../kampus/kampusEvent/ReviewAndConfirmKampusEvent";
import SelectKardForKampusEvent from "../kampus/kampusEvent/SelectKardForKampusEvent";
import SetDetailsForKampusEvent from "../kampus/kampusEvent/SetDetailsForKampusEvent";
import SetAreaForKampusEvent from "../kampus/kampusEvent/SetAreaForKampusEvent";
import SetViewsForKampusEvent from "../kampus/kampusEvent/SetViewsForKampusEvent";

import ChooseSettingsForKampusKard from "../kampus/kampusKard/ChooseSettingsForKampusKard";
import ReviewAndConfirmKampusKard from "../kampus/kampusKard/ReviewAndConfirmKampusKard";
import SelectKampusKard from "../kampus/kampusKard/SelectKampusKard";

import MarkBoundaries from "../kampus/kampusRegistration/MarkBoundaries";
import MarkInstitution from "../kampus/kampusRegistration/MarkInstitution";
import KampusRegistration from "../kampus/kampusRegistration/KampusRegistration";

import EnrollVolunteers from "../kampus/volunteers/EnrollVolunteers";

import InformationBrochure from "../kampus/InformationBrochure";
import KampusHome from "../kampus/KampusHome";

import HeaderTitle from "./HeaderTitle";
import MenuIcon from "./MenuIcon";
import Settings from "./Settings";
import SideMenuLayout from "./SideMenuLayout";

import ChangeDisplayArea from "../myKards/ChangeDisplayArea";
import ChangeKard from "../myKards/ChangeKard";
import CurrentKardDetails from "../myKards/CurrentKardDetails";
import CurrentKards from "../myKards/CurrentKards";
import PreviousKardDetails from "../myKards/PreviousKardDetails";
import PreviousKards from "../myKards/PreviousKards";

import ChangeMyLocation from "../myLocation/ChangeMyLocation";
import MyLocation from "../myLocation/MyLocation";

import BillingDetailsForNewKard from "../newKard/BillingDetailsForNewKard";
import ChooseTypeForNewKard from "../newKard/ChooseTypeForNewKard";
import ChooseSettingsForNewKard from "../newKard/ChooseSettingsForNewKard";
import ReviewAndConfirmNewKard from "../newKard/ReviewAndConfirmNewKard";
import SelectNewKard from "../newKard/SelectNewKard";
import SetAreaForNewKard from "../newKard/SetAreaForNewKard";

import PendingFeedback from "../office/feedback/PendingFeedback";

import PendingKampusEventDetails from "../office/kampus/PendingKampusEventDetails";
import PendingKampusEvents from "../office/kampus/PendingKampusEvents";
import PendingKampusRegistrationDetails from "../office/kampus/PendingKampusRegistrationDetails";
import PendingKampusRegistrations from "../office/kampus/PendingKampusRegistrations";

import PaymentOptions from "../office/paymentTesting/PaymentOptions";
import PaymentTesting from "../office/paymentTesting/PaymentTesting";

import ChooseSettingsForPromoKard from "../office/promoKard/ChooseSettingsForPromoKard";
import ReviewAndConfirmPromoKard from "../office/promoKard/ReviewAndConfirmPromoKard";
import SelectPromoKard from "../office/promoKard/SelectPromoKard";
import SetAreaForPromoKard from "../office/promoKard/SetAreaForPromoKard";

import SearchKard from "../office/search/SearchKard";
import SearchKardDetails from "../office/search/SearchKardDetails";

import PendingTransactions from "../office/transactions/PendingTransactions";

import Dashboard from "../office/Dashboard";

import KampusCreditCardPayment from "../payment/creditCard/KampusCreditCardPayment";
import KardCreditCardPayment from "../payment/creditCard/KardCreditCardPayment";
import TestCreditCardPayment from "../payment/creditCard/TestCreditCardPayment";

import KampusDebitCardPayment from "../payment/debitCard/KampusDebitCardPayment";
import KardDebitCardPayment from "../payment/debitCard/KardDebitCardPayment";
import TestDebitCardPayment from "../payment/debitCard/TestDebitCardPayment";

import KampusLinkPayment from "../payment/linkPayment/KampusLinkPayment";
import KardLinkPayment from "../payment/linkPayment/KardLinkPayment";
import TestLinkPayment from "../payment/linkPayment/TestLinkPayment";

import KampusNetBankingPayment from "../payment/netBanking/KampusNetBankingPayment";
import KardNetBankingPayment from "../payment/netBanking/KardNetBankingPayment";
import TestNetBankingPayment from "../payment/netBanking/TestNetBankingPayment";

import KampusQrCodePayment from "../payment/qrCode/KampusQrCodePayment";
import KardQrCodePayment from "../payment/qrCode/KardQrCodePayment";
import TestQrCodePayment from "../payment/qrCode/TestQrCodePayment";

import KampusTransactionSuccess from "../payment/transaction/KampusTransactionSuccess";
import KampusTransactionFailure from "../payment/transaction/KampusTransactionFailure";
import KardTransactionSuccess from "../payment/transaction/KardTransactionSuccess";
import KardTransactionFailure from "../payment/transaction/KardTransactionFailure";
import TestTransactionSuccess from "../payment/transaction/TestTransactionSuccess";
import TestTransactionFailure from "../payment/transaction/TestTransactionFailure";

import KampusUpiIdPayment from "../payment/upi/KampusUpiIdPayment";
import KampusUpiPollingScreen from "../payment/upi/KampusUpiPollingScreen";
import KardUpiIdPayment from "../payment/upi/KardUpiIdPayment";
import KardUpiPollingScreen from "../payment/upi/KardUpiPollingScreen";
import TestUpiIdPayment from "../payment/upi/TestUpiIdPayment";
import TestUpiPollingScreen from "../payment/upi/TestUpiPollingScreen";

import HtmlFormPost from "../payment/HtmlFormPost";
import SelectKampusPaymentMethod from "../payment/SelectKampusPaymentMethod";
import SelectKardPaymentMethod from "../payment/SelectKardPaymentMethod";
import SelectTestPaymentMethod from "../payment/SelectTestPaymentMethod";

import Profile from "../profile/Profile";
import AddNewAddress from "../profile/AddNewAddress";

import SignOut from "../registration/SignOut";
import Registration from "../registration/Registration";

import Themes from "../theme/Themes";
import FillTheme from "../theme/FillTheme";
import OutlineTheme from "../theme/OutlineTheme";

import AboutUs from "../AboutUs";
import ContactUs from "../ContactUs";
import DisplayKards from "../DisplayKards";
import Help from "../Help";
import Home from "../Home";
import Privacy from "../Privacy";
import ShareUs from "../ShareUs";
import Terms from "../Terms";
import UseCases from "../UseCases";

const SCREEN_WIDTH = Dimensions.get("screen").width;
const prefix = Linking.createURL("/");

const MainLayout = () => {
  const MobileStack = createStackNavigator();
  const NonMobileStack = createStackNavigator();
  const { theme, changeTheme } = useContext(ThemeContext);
  const { deviceType, setDeviceType } = useContext(DeviceContext);

  // const getLocationPermissions = async () => {
  //   let { status } = await Location.requestBackgroundPermissionsAsync();
  //   if (status !== "granted") {
  //     alert("Permission to access location was denied");
  //     return;
  //   }
  // };

  // const checkLocationPermissions = async () => {
  //   try {
  //     let loc = await Location.getCurrentPositionAsync();
  //     // console.log(loc);
  //     if (loc.coords.latitude) {
  //       const obj = {
  //         latitude: loc.coords.latitude,
  //         longitude: loc.coords.longitude,
  //       };
  // setCurrentLocation(obj);
  //       setDisplayLocation(obj);
  //     }
  //   } catch (err) {
  //     console.log("error in initial location function" + err);
  //   }
  // };

  // useEffect(async () => {
  //   await checkLocationPermissions();
  // }, []);

  const nonMobileLinking = {
    prefixes: [prefix],
    config: {
      screens: {
        SideMenuLayout: {
          screens: {
            KardTransactionSuccess: {
              path: "kardTransactionSuccess",
              exact: true,
            },
            KardTransactionFailure: {
              path: "kardTransactionFailure",
              exact: true,
            },
            KampusTransactionSuccess: {
              path: "kampusTransactionSuccess",
              exact: true,
            },
            KampusTransactionFailure: {
              path: "kampusTransactionFailure",
              exact: true,
            },
            TestTransactionSuccess: {
              path: "testTransactionSuccess",
              exact: true,
            },
            TestTransactionFailure: {
              path: "testTransactionFailure",
              exact: true,
            },
            Themes: {
              path: "Themes",
              screens: {
                FillTheme: {
                  path: "FillTheme",
                  exact: true,
                },
                OutlineTheme: {
                  path: "OutlineTheme",
                  exact: true,
                },
              },
            },
            UseCases: {
              path: "UseCases",
              exact: true,
            },
          },
        },
      },
    },
  };

  const mobileLinking = {
    prefixes: [prefix],
    config: {
      screens: {
        KardTransactionSuccess: "kardTransactionSuccess",

        KardTransactionFailure: {
          path: "kardTransactionFailure",
          exact: true,
        },

        KampusTransactionSuccess: {
          path: "kampusTransactionSuccess",
          exact: true,
        },
        KampusTransactionFailure: {
          path: "kampusTransactionFailure",
          exact: true,
        },
        TestTransactionSuccess: {
          path: "testTransactionSuccess",
          exact: true,
        },
        TestTransactionFailure: {
          path: "testTransactionFailure",
          exact: true,
        },
        Themes: {
          path: "Themes",
          screens: {
            FillTheme: {
              path: "FillTheme",
              exact: true,
            },
            OutlineTheme: {
              path: "OutlineTheme",
              exact: true,
            },
          },
        },
        UseCases: {
          path: "UseCases",
          exact: true,
        },
      },
    },
  };
  const MobileLayout = () => {
    return (
      <NavigationContainer linking={mobileLinking}>
        <MobileStack.Navigator
          screenOptions={() => ({
            headerTitle: () => <HeaderTitle />,
            headerTitleStyle: {
              color: theme.colors.textColor,
            },
            headerTitleAlign: "center",
            headerStyle: {
              backgroundColor: theme.colors.backgroundColor,
            },
            headerTintColor: theme.colors.textColor,
            cardStyle: { flex: 1 },
            headerLeft: () => <MenuIcon />,
          })}
        >
          <MobileStack.Screen name="Home" component={Home} />
          <MobileStack.Screen name="UseCases" component={UseCases} />
          <MobileStack.Screen name="DisplayKards" component={DisplayKards} />
          <MobileStack.Screen name="Settings" component={Settings} />
          <MobileStack.Screen name="Registration" component={Registration} />
          <MobileStack.Screen name="SignOut" component={SignOut} />
          <MobileStack.Screen name="Profile" component={Profile} />
          <MobileStack.Screen
            name="KampusEventDetails"
            component={KampusEventDetails}
          />
          {/*  newKard Kards */}
          <MobileStack.Screen name="CurrentKards" component={CurrentKards} />
          <MobileStack.Screen name="MyLocation" component={MyLocation} />
          <MobileStack.Screen name="Themes" component={Themes} />
          <MobileStack.Screen name="Fill" component={FillTheme} />
          <MobileStack.Screen name="Outline" component={OutlineTheme} />
          <MobileStack.Screen name="KampusHome" component={KampusHome} />
          <MobileStack.Screen
            name="InformationBrochure"
            component={InformationBrochure}
          />
          <MobileStack.Screen name="KampusAccount" component={KampusAccount} />
          <MobileStack.Screen
            name="KampusRegistration"
            component={KampusRegistration}
          />
          <MobileStack.Screen
            name="MarkInstitution"
            component={MarkInstitution}
          />
          <MobileStack.Screen
            name="MarkBoundaries"
            component={MarkBoundaries}
          />
          <MobileStack.Screen name="ShareUs" component={ShareUs} />
          <MobileStack.Screen name="Feedback" component={Feedback} />
          <MobileStack.Screen name="Privacy" component={Privacy} />
          <MobileStack.Screen name="Help" component={Help} />
          <MobileStack.Screen name="Terms" component={Terms} />
          <MobileStack.Screen name="ContactUs" component={ContactUs} />
          <MobileStack.Screen name="AboutUs" component={AboutUs} />
          <MobileStack.Screen name="AddNewAddress" component={AddNewAddress} />
          <MobileStack.Screen
            name="ChangeMyLocation"
            component={ChangeMyLocation}
          />
          <MobileStack.Screen
            // options={{}}
            name="ChooseTypeForNewKard"
            component={ChooseTypeForNewKard}
          />
          <MobileStack.Screen
            // options={{
            //   title: "Select Kard",
            // }}
            name="SelectNewKard"
            component={SelectNewKard}
          />
          <MobileStack.Screen
            // options={{
            //   title: "Choose Settings",
            // }}
            name="ChooseSettingsForNewKard"
            component={ChooseSettingsForNewKard}
          />
          <MobileStack.Screen
            // options={{
            //   title: "Set Area",
            // }}
            name="SetAreaForNewKard"
            component={SetAreaForNewKard}
          />
          <MobileStack.Screen
            // options={{
            //   title: "Billing Details",
            // }}
            name="BillingDetailsForNewKard"
            component={BillingDetailsForNewKard}
          />
          <MobileStack.Screen
            // options={{
            //   title: "Review And Confirm ",
            // }}
            name="ReviewAndConfirmNewKard"
            component={ReviewAndConfirmNewKard}
          />
          <MobileStack.Screen
            name="SelectKardPaymentMethod"
            component={SelectKardPaymentMethod}
            // options={{
            //   title: "Select Payment Method",
            //   headerBackTitle: "Select Payment Method",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KardQrCodePayment"
            component={KardQrCodePayment}
            // options={{
            //   title: "QR Code Payment",
            //   headerBackTitle: "QR Code Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KardUpiIdPayment"
            component={KardUpiIdPayment}
            // options={{
            //   title: "UPI Payment",
            //   headerBackTitle: "UPI Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KardLinkPayment"
            component={KardLinkPayment}
            // options={{
            //   title: "Payment Link",
            //   headerBackTitle: "Payment Link",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KardDebitCardPayment"
            component={KardDebitCardPayment}
            // options={{
            //   title: "Debit Card Payment",
            //   headerBackTitle: "Debit Card Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KardCreditCardPayment"
            component={KardCreditCardPayment}
            // options={{
            //   title: "Credit Card Payment",
            //   headerBackTitle: "Credit Card Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KardNetBankingPayment"
            component={KardNetBankingPayment}
            // options={{
            //   title: "Net Banking Payment",
            //   headerBackTitle: "Net Banking Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="HtmlFormPost"
            component={HtmlFormPost}
            // options={{
            //   title: "Processing Payment",
            //   headerBackTitle: "Processing Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KardUpiPollingScreen"
            component={KardUpiPollingScreen}
            // options={{
            //   title: "Waiting For Payment",
            //   headerBackTitle: "Waiting For Payment",
            //   headerLeft: null,
            // }}
          />

          <MobileStack.Screen
            // options={{
            //   title: "Transaction Success",
            // }}
            name="KardTransactionSuccess"
            component={KardTransactionSuccess}
          />
          <MobileStack.Screen
            // options={{
            //   title: "Transaction Failure",
            // }}
            name="KardTransactionFailure"
            component={KardTransactionFailure}
          />

          <MobileStack.Screen
            name="SelectKampusPaymentMethod"
            component={SelectKampusPaymentMethod}
            // options={{
            //   title: "Select Payment Method",
            //   headerBackTitle: "Select Payment Method",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KampusQrCodePayment"
            component={KampusQrCodePayment}
            // options={{
            //   title: "QR Code Payment",
            //   headerBackTitle: "QR Code Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KampusUpiIdPayment"
            component={KampusUpiIdPayment}
            // options={{
            //   title: "UPI Payment",
            //   headerBackTitle: "UPI Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KampusLinkPayment"
            component={KampusLinkPayment}
            // options={{
            //   title: "Payment Link",
            //   headerBackTitle: "Payment Link",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KampusDebitCardPayment"
            component={KampusDebitCardPayment}
            // options={{
            //   title: "Debit Card Payment",
            //   headerBackTitle: "Debit Card Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KampusCreditCardPayment"
            component={KampusCreditCardPayment}
            // options={{
            //   title: "Credit Card Payment",
            //   headerBackTitle: "Credit Card Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="KampusNetBankingPayment"
            component={KampusNetBankingPayment}
            // options={{
            //   title: "Net Banking Payment",
            //   headerBackTitle: "Net Banking Payment",
            //   headerLeft: null,
            // }}
          />

          <MobileStack.Screen
            name="KampusUpiPollingScreen"
            component={KampusUpiPollingScreen}
            // options={{
            //   title: "Waiting For Payment",
            //   headerBackTitle: "Waiting For Payment",
            //   headerLeft: null,
            // }}
          />

          <MobileStack.Screen
            // options={{
            //   title: "Transaction Success",
            // }}
            name="KampusTransactionSuccess"
            component={KampusTransactionSuccess}
          />
          <MobileStack.Screen
            // options={{
            //   title: "Transaction Failure",
            // }}
            name="KampusTransactionFailure"
            component={KampusTransactionFailure}
          />

          <MobileStack.Screen
            name="SelectTestPaymentMethod"
            component={SelectTestPaymentMethod}
            // options={{
            //   title: "Select Payment Method",
            //   headerBackTitle: "Select Payment Method",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="TestQrCodePayment"
            component={TestQrCodePayment}
            // options={{
            //   title: "QR Code Payment",
            //   headerBackTitle: "QR Code Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="TestUpiIdPayment"
            component={TestUpiIdPayment}
            // options={{
            //   title: "UPI Payment",
            //   headerBackTitle: "UPI Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="TestLinkPayment"
            component={TestLinkPayment}
            // options={{
            //   title: "Payment Link",
            //   headerBackTitle: "Payment Link",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="TestDebitCardPayment"
            component={TestDebitCardPayment}
            // options={{
            //   title: "Debit Card Payment",
            //   headerBackTitle: "Debit Card Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="TestCreditCardPayment"
            component={TestCreditCardPayment}
            // options={{
            //   title: "Credit Card Payment",
            //   headerBackTitle: "Credit Card Payment",
            //   headerLeft: null,
            // }}
          />
          <MobileStack.Screen
            name="TestNetBankingPayment"
            component={TestNetBankingPayment}
            // options={{
            //   title: "Net Banking Payment",
            //   headerBackTitle: "Net Banking Payment",
            //   headerLeft: null,
            // }}
          />

          <MobileStack.Screen
            name="TestUpiPollingScreen"
            component={TestUpiPollingScreen}
            // options={{
            //   title: "Waiting For Payment",
            //   headerBackTitle: "Waiting For Payment",
            //   headerLeft: null,
            // }}
          />

          <MobileStack.Screen
            // options={{
            //   title: "Transaction Success",
            // }}
            name="TestTransactionSuccess"
            component={TestTransactionSuccess}
          />
          <MobileStack.Screen
            // options={{
            //   title: "Transaction Failure",
            // }}
            name="TestTransactionFailure"
            component={TestTransactionFailure}
          />

          <MobileStack.Screen name="Dashboard" component={Dashboard} />
          <MobileStack.Screen
            name="PendingKampusRegistrations"
            component={PendingKampusRegistrations}
          />
          <MobileStack.Screen
            name="PendingKampusRegistrationDetails"
            component={PendingKampusRegistrationDetails}
          />
          <MobileStack.Screen
            name="PendingKampusEvents"
            component={PendingKampusEvents}
          />
          <MobileStack.Screen
            name="PendingKampusEventDetails"
            component={PendingKampusEventDetails}
          />
          <MobileStack.Screen
            name="EnrollVolunteers"
            component={EnrollVolunteers}
          />
          <MobileStack.Screen
            name="SetDetailsForKampusEvent"
            component={SetDetailsForKampusEvent}
          />
          <MobileStack.Screen
            name="SelectKardForKampusEvent"
            component={SelectKardForKampusEvent}
          />
          <MobileStack.Screen
            name="ChooseSettingsForKampusEvent"
            component={ChooseSettingsForKampusEvent}
          />
          <MobileStack.Screen
            name="SetViewsForKampusEvent"
            component={SetViewsForKampusEvent}
          />
          <MobileStack.Screen
            name="SetAreaForKampusEvent"
            component={SetAreaForKampusEvent}
          />
          <MobileStack.Screen
            name="ReviewAndConfirmKampusEvent"
            component={ReviewAndConfirmKampusEvent}
          />

          <MobileStack.Screen
            name="SelectKampusKard"
            component={SelectKampusKard}
          />
          <MobileStack.Screen
            name="ChooseSettingsForKampusKard"
            component={ChooseSettingsForKampusKard}
          />
          <MobileStack.Screen
            name="ReviewAndConfirmKampusKard"
            component={ReviewAndConfirmKampusKard}
          />
          <MobileStack.Screen
            name="SelectPromoKard"
            component={SelectPromoKard}
          />
          <MobileStack.Screen
            name="ChooseSettingsForPromoKard"
            component={ChooseSettingsForPromoKard}
          />
          <MobileStack.Screen
            name="SetAreaForPromoKard"
            component={SetAreaForPromoKard}
          />
          <MobileStack.Screen name="SearchKard" component={SearchKard} />
          <MobileStack.Screen
            name="SearchKardDetails"
            component={SearchKardDetails}
          />
          <MobileStack.Screen
            name="ReviewAndConfirmPromoKard"
            component={ReviewAndConfirmPromoKard}
          />

          {/* <MobileStack.Screen name="Refund" component={Refund} /> */}
          <MobileStack.Screen
            name="CurrentKardDetails"
            component={CurrentKardDetails}
          />
          <MobileStack.Screen name="PreviousKards" component={PreviousKards} />
          <MobileStack.Screen
            name="PreviousKardDetails"
            component={PreviousKardDetails}
          />
          <MobileStack.Screen name="ChangeKard" component={ChangeKard} />
          <MobileStack.Screen
            name="ChangeDisplayArea"
            component={ChangeDisplayArea}
          />
          <MobileStack.Screen
            name="PendingFeedback"
            component={PendingFeedback}
          />
          <MobileStack.Screen
            name="PaymentTesting"
            component={PaymentTesting}
          />
          <MobileStack.Screen
            name="PendingTransactions"
            component={PendingTransactions}
          />
          <MobileStack.Screen
            name="PaymentOptions"
            component={PaymentOptions}
          />
        </MobileStack.Navigator>
      </NavigationContainer>
    );
  };

  const NonMobileLayout = () => {
    return (
      <NavigationContainer linking={nonMobileLinking}>
        <NonMobileStack.Navigator
          screenOptions={{
            cardStyle: { flex: 1 },
            headerTitleStyle: {
              color: theme.colors.textColor,
            },
            headerStyle: {
              backgroundColor: theme.colors.backgroundColor,
            },
            headerTintColor: theme.colors.textColor,
          }}
        >
          <NonMobileStack.Screen
            name="SideMenuLayout"
            component={SideMenuLayout}
            options={() => ({
              headerTitle: () => null,
              headerStyle: {
                backgroundColor: theme.colors.backgroundColor,
              },
              headerLeft: () => <HeaderTitle />,
            })}
          />
        </NonMobileStack.Navigator>
      </NavigationContainer>
    );
  };

  return (
    <>
      {deviceType == "MOBILE" && <MobileLayout />}
      {deviceType == "NOT_MOBILE" && <NonMobileLayout />}
    </>
  );
};

export default MainLayout;
