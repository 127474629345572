import React, { useState, useContext } from "react";
import {
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";

import { ThemeContext } from "../../../context/ThemeContext";
const PaymentOptions = (props) => {
  const item = props.route.params.formBody;
  const [upiTab, setUpiTab] = useState(false);
  const [walletsTab, setWalletsTab] = useState(false);
  const [cardsTab, setCardsTab] = useState(false);
  const [nbTab, setNbTab] = useState(false);
  const { theme, changeTheme } = useContext(ThemeContext);
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <TouchableOpacity
        onPress={() => {
          setUpiTab(!upiTab);
          setWalletsTab(false);
          setCardsTab(false);
          setNbTab(false);
        }}
      >
        <View style={styles(theme).dashboardSubHeaderBoxStyle}>
          {upiTab ? (
            <Ionicons
              name="radio-button-on"
              size={20}
              color={theme.colors.standardTextColor}
            />
          ) : (
            <Ionicons
              name="radio-button-off"
              size={20}
              color={theme.colors.standardTextColor}
            />
          )}
          <Text style={styles(theme).dashboardSubHeaderTextStyle}>UPI</Text>
        </View>
      </TouchableOpacity>
      {upiTab && (
        <View style={styles(theme).selectedPaymentViewStyle}>
          <Text>This is upi tab</Text>
          <Text>{item.mid}</Text>
          <Text>{item.orderId}</Text>
          <Text>{item.txnToken}</Text>
        </View>
      )}
      <TouchableOpacity
        onPress={() => {
          setWalletsTab(!walletsTab);
          setUpiTab(false);
          setCardsTab(false);
          setNbTab(false);
        }}
      >
        <View style={styles(theme).dashboardSubHeaderBoxStyle}>
          {walletsTab ? (
            <Ionicons
              name="radio-button-on"
              size={20}
              color={theme.colors.standardTextColor}
            />
          ) : (
            <Ionicons
              name="radio-button-off"
              size={20}
              color={theme.colors.standardTextColor}
            />
          )}
          <Text style={styles(theme).dashboardSubHeaderTextStyle}>WALLET</Text>
        </View>
      </TouchableOpacity>
      {walletsTab && (
        <View style={styles(theme).selectedPaymentViewStyle}>
          <Text>This is wallet tab</Text>
          <Text>{item.mid}</Text>
          <Text>{item.orderId}</Text>
          <Text>{item.txnToken}</Text>
        </View>
      )}
      <TouchableOpacity
        onPress={() => {
          setCardsTab(!cardsTab);
          setWalletsTab(false);
          setUpiTab(false);
          setNbTab(false);
        }}
      >
        <View style={styles(theme).dashboardSubHeaderBoxStyle}>
          {cardsTab ? (
            <Ionicons
              name="radio-button-on"
              size={20}
              color={theme.colors.standardTextColor}
            />
          ) : (
            <Ionicons
              name="radio-button-off"
              size={20}
              color={theme.colors.standardTextColor}
            />
          )}
          <Text style={styles(theme).dashboardSubHeaderTextStyle}>
            CREDIT AND DEBIT CARDS
          </Text>
        </View>
      </TouchableOpacity>
      {cardsTab && (
        <View style={styles(theme).selectedPaymentViewStyle}>
          <Text>This is upi tab</Text>
          <Text>{item.mid}</Text>
          <Text>{item.orderId}</Text>
          <Text>{item.txnToken}</Text>
        </View>
      )}
      <TouchableOpacity
        onPress={() => {
          setCardsTab(false);
          setWalletsTab(false);
          setUpiTab(false);
          setNbTab(!nbTab);
        }}
      >
        <View style={styles(theme).dashboardSubHeaderBoxStyle}>
          {nbTab ? (
            <Ionicons
              name="radio-button-on"
              size={20}
              color={theme.colors.standardTextColor}
            />
          ) : (
            <Ionicons
              name="radio-button-off"
              size={20}
              color={theme.colors.standardTextColor}
            />
          )}
          <Text style={styles(theme).dashboardSubHeaderTextStyle}>
            NET BANKING
          </Text>
        </View>
      </TouchableOpacity>
      {nbTab && (
        <View style={styles(theme).selectedPaymentViewStyle}>
          <Text>This is upi tab</Text>
          <Text>{item.mid}</Text>
          <Text>{item.orderId}</Text>
          <Text>{item.txnToken}</Text>
        </View>
      )}
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    dashboardSubHeaderBoxStyle: {
      width: "80%",
      flexDirection: "row",
      alignSelf: "center",
      // borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      alignItems: "center",
      margin: 5,
      paddingLeft: 10,
    },
    dashboardSubHeaderTextStyle: {
      fontSize: 18,
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      paddingLeft: 20,
    },
    selectedPaymentViewStyle: {
      width: "80%",
      alignSelf: "center",
    },
  });

export default PaymentOptions;
