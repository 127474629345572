import React, { useContext } from "react";
import { StyleSheet, Text, View, Linking } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { IconButton } from "react-native-paper";

import { ThemeContext } from "../context/ThemeContext";
import { milestones } from "../data/Milestones";

const AboutUs = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>MILESTONES</Text>
      {milestones.map((item, key) => (
        <View key={key} style={styles(theme).rowGroupStyle}>
          <Text style={styles(theme).textJustifyStyle}>
            {item.date} - {item.text}
          </Text>
        </View>
      ))}
      <View style={styles(theme).dividerStyle} />
      <Text style={styles(theme).headerStyle}>KNOW MORE @</Text>
      <View style={styles(theme).rowGroupSpaceEvenlyStyle}>
        <IconButton
          icon="facebook"
          color={"#4267B2"}
          size={50}
          onPress={() =>
            Linking.openURL(
              "https://www.facebook.com/profile.php?id=100085411579530"
            )
          }
        />
        <IconButton
          icon="youtube"
          color={"red"}
          size={50}
          onPress={() =>
            Linking.openURL(
              "https://www.youtube.com/channel/UCuui8-CFo6-PkvEzZG4nD2A"
            )
          }
        />
        <IconButton
          icon="twitter"
          color={"#1DA1F2"}
          size={50}
          onPress={() => Linking.openURL("https://twitter.com/kabutar_app")}
        />
        <IconButton
          icon="instagram"
          color={"orange"}
          size={50}
          onPress={() =>
            Linking.openURL("https://www.instagram.com/kabutarapp/")
          }
        />
      </View>
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    dividerStyle: {
      width: "80%",
      alignSelf: "center",
      borderBottomColor: theme.colors.standardTextColor,
      borderBottomWidth: 5,
      margin: 10,
    },
    rowGroupStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      margin: 10,
    },

    rowGroupSpaceEvenlyStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: 10,
    },
  });

export default AboutUs;
