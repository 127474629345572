import React, { useContext } from "react";
import { Authenticator } from "aws-amplify-react-native";

import ConfirmSignUp from "./ConfirmSignUp";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordSubmit from "./ForgotPasswordSubmit";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import SignOut from "./SignOut";
import { UserContext } from "../../context/UserContext";

const Registration = () => {
  const { user } = useContext(UserContext);
  return user ? (
    <SignOut />
  ) : (
    <Authenticator
      usernameAttributes={"email" || "phone_number"}
      hideDefault={true}
      authState="signIn"
      // onStateChange={(authState) => console.log("authState...", authState)}
    >
      <SignIn />
      <SignUp />
      <ConfirmSignUp />
      <ForgotPassword />
      <ForgotPasswordSubmit />
      {/* <ResendCode /> */}
    </Authenticator>
  );
};

export default Registration;
