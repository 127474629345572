import React, { useState, useContext } from "react";
import { Text, View, StyleSheet, Image } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";
import AwesomeAlert from "react-native-awesome-alerts";

import { ThemeContext } from "../../../context/ThemeContext";
import { KampusKardContext } from "../../../context/KampusKardContext";

const SelectKampusKard = () => {
  const navigation = useNavigation();
  const { theme, changeTheme } = useContext(ThemeContext);
  const { kard, setKard, kardSelected, setKardSelected } =
    useContext(KampusKardContext);
  const [selectKard, setSelectKard] = useState(false);
  const [kardNotSelected, setKardNotSelected] = useState(false);
  const [verifyKardDimensions, setVerifyKardDimensions] = useState(false);

  const checkkardSelected = () => {
    if (kardSelected) {
      navigation.navigate("ChooseSettingsForKampusKard");
    } else {
      setKardNotSelected(true);
    }
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: true,
      quality: 0.1,
    });
    if (!result.cancelled) {
      const fileSize = result.base64.length * (3 / 4) - 2;
      if (result.height <= 1200 && result.width <= 900 && fileSize < 500000) {
        setKard(result.uri);
        setKardSelected(true);
      } else {
        setVerifyKardDimensions(true);
      }
    }
  };

  return (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>SELECT KARD</Text>
        <TouchableOpacity onPress={() => setSelectKard(true)}>
          <Ionicons
            name={
              theme.type == "fill"
                ? "information-circle"
                : "information-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <View>
        <Text style={styles(theme).textCenterStyle}>Step 1 of 3</Text>
      </View>
      <TouchableOpacity onPress={pickImage}>
        <Image source={{ uri: kard }} style={styles(theme).kardStyle} />
      </TouchableOpacity>
      <View style={styles(theme).rowGroupSpaceEvenlyStyle}>
        <TouchableOpacity onPress={() => navigation.navigate("KampusHome")}>
          <AntDesign
            name={theme.type == "fill" ? "leftcircle" : "leftcircleo"}
            size={50}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => checkkardSelected()}>
          <AntDesign
            name={theme.type == "fill" ? "rightcircle" : "rightcircleo"}
            size={50}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <AwesomeAlert
        show={selectKard}
        title="SELECT KARD"
        message={
          "The kard should be stored in your device in image format for selection. For best results, the images should be compressed using image compression tools before selection. JPEG /JPG, PNG, WEBP, GIF and SVG formats are supported. The image size less than 500 Kb are recommended. The pixel resolution less than 900 in width and 1200 in height are recommended. The images of higher size and higher resolution will be compressed automamtically."
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setSelectKard(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={kardNotSelected}
        title="KARD NOT SELECTED"
        message={
          "The kard is not selected. kard must be selected to proceed to the next step."
        }
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setKardNotSelected(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={verifyKardDimensions}
        title="VERIFY KARD DIMENSIONS"
        message="The image width should be less than or equal to 900px, height should be less than or equal
        to 1200px and size should be less than 500KB. Please use online tools and resize the image with proper dimensions(in pixels) and size."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="Ok, got it"
        onConfirmPressed={() => {
          setVerifyKardDimensions(false);
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    kardStyle: {
      height: 550,
      width: 350,
      alignSelf: "center",
      borderRadius: 20,
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },

    rowGroupSpaceEvenlyStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default SelectKampusKard;
