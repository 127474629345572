import React, { useContext, useState, useEffect } from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { ThemeContext } from "../context/ThemeContext";
import { DeviceContext } from "../context/DeviceContext";

const Home = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const [install, setInstall] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState();
  const { deviceType, setDeviceType } = useContext(DeviceContext);

  const navigation = useNavigation();

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      console.log("yet to install app");
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can install the PWA
      setInstall(true);
    });

    window.addEventListener("install", () => {
      // Log install to analytics
      console.log("INSTALL: Success");
    });
  }, []);

  const handleInstallClick = () => {
    // console.log("in handle install click");
    // Hide the app provided install promotion
    setInstall(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // e.preventDefault();
    // Stash the event so it can be triggered later.
    // setDeferredPrompt(e);
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
        // setInstall(true);
      }
    });
  };

  return (
    <View style={styles(theme).homeViewStyle}>
      <ScrollView contentContainerStyle={styles(theme).homeScrollViewStyle}>
        <View>
          <Image
            style={styles(theme).image}
            source={require("../assets/icon.png")}
          />
          <Text style={styles(theme).textCenterStyle}>
            Know what's happening in any area.
          </Text>

          <TouchableOpacity
            style={styles(theme).buttonStyle}
            onPress={() => navigation.navigate("DisplayKards")}
          >
            <Text style={styles(theme).buttonTextStyle}>Display Kards</Text>
          </TouchableOpacity>
          {deviceType == "MOBILE" ? (
            <TouchableOpacity
              style={styles(theme).buttonStyle}
              onPress={() => navigation.navigate("Settings")}
            >
              <Text style={styles(theme).buttonTextStyle}>
                See Other Features
              </Text>
            </TouchableOpacity>
          ) : null}

          {install && (
            <TouchableOpacity
              style={styles(theme).buttonStyle}
              onPress={() => handleInstallClick()}
            >
              <Text style={styles(theme).buttonTextStyle}>Install</Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={styles(theme).buttonStyle2}>
          <Text style={styles(theme).buttonTextStyle2}>Version 2.3.0</Text>
          <Text style={styles(theme).buttonTextStyle2}>
            We connect students, teachers, parents, managers and all other
            stakeholders of educational institutions across the globe! Please
            support us to serve better.
          </Text>
          <Text style={styles(theme).buttonTextStyle2}></Text>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    homeViewStyle: {
      flex: 1,
      width: "100%",
      backgroundColor: "white",
      alignItems: "center",
      justifyContent: "center",
    },
    homeScrollViewStyle: {
      flexGrow: 1,
      justifyContent: "center",
      marginTop: 50,
      marginBottom: 50,
    },
    image: {
      width: 100,
      justifyContent: "center",
      height: 100,
      alignSelf: "center",
    },
    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },
    buttonStyle: {
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 3,
      backgroundColor: theme.colors.standardTextColor,
    },
    buttonTextStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    buttonStyle2: {
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 3,
      backgroundColor: theme.colors.backgroundColor,
    },
    buttonTextStyle2: {
      color: theme.colors.textColor,
      fontSize: 18,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
  });

export default Home;
