import React, { useEffect, useState, useContext } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { API } from "aws-amplify";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";

import * as queries from "../../../src/graphql/queries";
import * as mutations from "../../../src/graphql/mutations";
import { ThemeContext } from "../../../context/ThemeContext";
import { UserContext } from "../../../context/UserContext";
const PendingTransactions = () => {
  const { user } = useContext(UserContext);
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const { theme, changeTheme } = useContext(ThemeContext);

  useEffect(() => {
    getPendingTransactions();
  }, []);

  const stagingTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "stagingTeam"
    );
  const devTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "devTeam"
    );
  const txnPendingTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "txnPendingTeam"
    );
  const officeTeam =
    user &&
    typeof user.signInUserSession.accessToken.payload["cognito:groups"] !=
      "undefined" &&
    user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      "officeTeam"
    );

  const getPendingTransactions = async () => {
    var itemArray = [];
    try {
      const transactions = await API.graphql({
        query: queries.listKardsByKardStatus,
        variables: {
          kardStatus: "txnPending",
        },
      });
      if (transactions.data.listKardsByKardStatus.items.length != 0) {
        transactions.data.listKardsByKardStatus.items.map((item) => {
          if ((stagingTeam || devTeam) && (txnPendingTeam || officeTeam)) {
            itemArray.push(item);
          } else if (
            (new Date().getTime() - new Date(item.transactionDate).getTime()) /
              (1000 * 60 * 60 * 24) >
            1.0
          ) {
            itemArray.push(item);
          }
        });
      }
      setPendingTransactions(itemArray);
    } catch (err) {
      console.log(err);
    }
  };
  const deleteTransaction = async (item) => {
    // const imageName = item.imageName;
    try {
      const deleteRecord = await API.graphql({
        query: mutations.deleteKard,
        variables: {
          input: {
            id: item.id,
          },
        },
      });
      // already added in kard db stream processor under "REMOVE". Hence no need to remove from storage again
      // const deleteKard = await Storage.remove(imageName);
      getPendingTransactions();
    } catch (err) {
      console.log(err);
    }
  };
  return pendingTransactions.length != 0 ? (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>PENDING TRANSACTIONS</Text>

        <TouchableOpacity onPress={() => getPendingTransactions()}>
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      {pendingTransactions.map((item, key) => {
        return (
          <View key={key} style={styles(theme).boxStyle}>
            <Text style={styles(theme).textJustifyStyle}>
              userId: {item.userId}, Email / Phone: {item.username}
            </Text>

            <Text style={styles(theme).textJustifyStyle}>
              Id: {item.id}, Request Date:{" "}
              {new Date(item.transactionDate).toLocaleDateString()}
            </Text>
            <Text style={styles(theme).textJustifyStyle}>
              Kard Type: {item.kardType}, Charges: {item.charges}, Discount:{" "}
              {item.discount}, Taxes: {item.taxes}, Total: {item.total}
            </Text>
            <Text style={styles(theme).textJustifyStyle}>
              Display Area: {item.displayArea}
            </Text>
            <Text style={styles(theme).textJustifyStyle}>
              Kard Status: {item.kardStatus}
            </Text>
            <View style={styles(theme).headerViewStyle}>
              <TouchableOpacity onPress={() => deleteTransaction(item)}>
                <MaterialCommunityIcons
                  name={
                    theme.type == "fill"
                      ? "delete-circle"
                      : "delete-circle-outline"
                  }
                  size={50}
                  color={theme.colors.standardTextColor}
                />
              </TouchableOpacity>
            </View>
          </View>
        );
      })}
    </ScrollView>
  ) : (
    <ScrollView style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Text style={styles(theme).headerStyle}>PENDING TRANSACTIONS</Text>

        <TouchableOpacity onPress={() => getPendingTransactions()}>
          <Ionicons
            name={
              theme.type == "fill" ? "refresh-circle" : "refresh-circle-outline"
            }
            size={25}
            color={theme.colors.standardTextColor}
          />
        </TouchableOpacity>
      </View>
      <View style={styles(theme).rowGroupCenterStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          You do not have any pending transactions to process
        </Text>
      </View>
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
    rowGroupCenterStyle: {
      width: "80%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
    },
  });

export default PendingTransactions;
