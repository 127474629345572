import React, { useContext } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";

import FaqItem from "../components/FaqItem";
import { ThemeContext } from "../context/ThemeContext";
import {
  faq1,
  faq2,
  faq3,
  faq4,
  faq5,
  faq6,
  faq7,
  faq8,
  faq9,
  faq10,
  faq11,
  faq12,
  faq13,
  faq14,
} from "../data/Faq";

const Help = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>HELP AND FAQ</Text>
      {faq1.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>Use Cases</Text>
      {faq2.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>About Kards</Text>
      {faq3.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>Viewing Kards</Text>
      {faq4.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>Posting Kards</Text>
      {faq5.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>Displaying Kards</Text>
      {faq6.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>Kabutar Kampus</Text>
      {faq7.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>Signin / Signup</Text>
      {faq8.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>Pricing</Text>
      {faq9.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>Refund</Text>
      {faq10.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>Technical Details</Text>
      {faq11.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>
        Feedback, Rate and Report
      </Text>
      {faq12.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>Theming</Text>
      {faq13.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
      <Text style={styles(theme).labelBoldStyle}>Download</Text>
      {faq14.map((i, key) => {
        return (
          <View key={key}>
            <FaqItem question={i.question}>
              <Text style={styles(theme).answerTextStyle}>{i.answer}</Text>
            </FaqItem>
          </View>
        );
      })}
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    labelBoldStyle: {
      width: "80%",
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      textAlign: "justify",
      margin: 5,
    },

    answerTextStyle: {
      width: "90%",
      color: theme.colors.textColor,
      alignSelf: "center",
      textAlign: "justify",
    },
  });

export default Help;
