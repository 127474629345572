import { DefaultTheme, DarkTheme } from "react-native-paper";

export const DefaultF = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    backgroundColor: "#6200ee",
    textColor: "white",
    standardTextColor: "#6200ee",
    borderColor: "#6200ee",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const DefaultO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#6200ee",
    textColor: "#6200ee",
    borderColor: "#6200ee",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const CrimsonF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#AE0E36",
    standardTextColor: "#AE0E36",
    textColor: "white",
    borderColor: "#AE0E36",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const CrimsonO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#AE0E36",
    textColor: "#AE0E36",
    borderColor: "#AE0E36",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const SpringGreenF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#00e676",
    standardTextColor: "#00e676",
    textColor: "white",
    borderColor: "#00e676",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const SpringGreenO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#00e676",
    textColor: "#00e676",
    borderColor: "#00e676",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const FuriousOrangeF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#ff5722",
    standardTextColor: "#ff5722",
    textColor: "white",
    borderColor: "#ff5722",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const FuriousOrangeO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#ff5722",
    textColor: "#ff5722",
    borderColor: "#ff5722",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const RubyF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#d81b60",
    standardTextColor: "#d81b60",
    textColor: "white",
    borderColor: "#d81b60",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const RubyO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#d81b60",
    textColor: "#d81b60",
    borderColor: "#d81b60",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const ElectricPurpleF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#aa00ff",
    standardTextColor: "#aa00ff",
    textColor: "white",
    borderColor: "#aa00ff",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const ElectricPurpleO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#aa00ff",
    textColor: "#aa00ff",
    borderColor: "#aa00ff",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const IndigoF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#4B0082",
    standardTextColor: "#4B0082",
    textColor: "white",
    borderColor: "#4B0082",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const IndigoO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#4B0082",
    textColor: "#4B0082",
    borderColor: "#4B0082",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const CyberYellowF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#FDD20E",
    standardTextColor: "#FDD20E",
    textColor: "white",
    borderColor: "#FDD20E",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const CyberYellowO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#FDD20E",
    textColor: "#FDD20E",
    borderColor: "#FDD20E",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const SailorBlueF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#00203F",
    standardTextColor: "#00203F",
    textColor: "white",
    borderColor: "#00203F",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const SailorBlueO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#00203F",
    textColor: "#00203F",
    borderColor: "#00203F",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const DeepSkyBlueF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#00b0ff",
    standardTextColor: "#00b0ff",
    textColor: "white",
    borderColor: "#00b0ff",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const DeepSkyBlueO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#00b0ff",
    textColor: "#00b0ff",
    borderColor: "#00b0ff",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const WindsorWineF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#643E46",
    standardTextColor: "#643E46",
    textColor: "white",
    borderColor: "#643E46",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const WindsorWineO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#643E46",
    textColor: "#643E46",
    borderColor: "#643E46",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const PestoF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#827717",
    standardTextColor: "#827717",
    textColor: "white",
    borderColor: "#827717",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const PestoO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#827717",
    textColor: "#827717",
    borderColor: "#827717",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const BrownPodF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#3e2723",
    standardTextColor: "#3e2723",
    textColor: "white",
    borderColor: "#3e2723",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const BrownPodO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#3e2723",
    textColor: "#3e2723",
    borderColor: "#3e2723",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const NeonBlueF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#304ffe",
    standardTextColor: "#304ffe",
    textColor: "white",
    borderColor: "#304ffe",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const NeonBlueO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#304ffe",
    textColor: "#304ffe",
    borderColor: "#304ffe",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const SpaceCherryF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#990011",
    standardTextColor: "#990011",
    textColor: "white",
    borderColor: "#990011",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const SpaceCherryO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#990011",
    textColor: "#990011",
    borderColor: "#990011",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const MosqueF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#006064",
    standardTextColor: "#006064",
    textColor: "white",
    borderColor: "#006064",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const MosqueO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#006064",
    textColor: "#006064",
    borderColor: "#006064",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const DarkOrangeF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#ff8f00",
    standardTextColor: "#ff8f00",
    textColor: "white",
    borderColor: "#ff8f00",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const DarkOrangeO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#ff8f00",
    textColor: "#ff8f00",
    borderColor: "#ff8f00",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const RosePinkF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#DF6589",
    standardTextColor: "#DF6589",
    textColor: "white",
    borderColor: "#DF6589",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const RosePinkO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#DF6589",
    textColor: "#DF6589",
    borderColor: "#DF6589",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const DarkGoldenRodF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#B8860B",
    standardTextColor: "#B8860B",
    textColor: "white",
    borderColor: "#B8860B",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const DarkGoldenRodO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#B8860B",
    textColor: "#B8860B",
    borderColor: "#B8860B",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const BismarkF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#455a64",
    standardTextColor: "#455a64",
    textColor: "white",
    borderColor: "#455a64",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const BismarkO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#455a64",
    textColor: "#455a64",
    borderColor: "#455a64",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const SteelBlueF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#4682B4",
    standardTextColor: "#4682B4",
    textColor: "white",
    borderColor: "#4682B4",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const SteelBlueO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#4682B4",
    textColor: "#4682B4",
    borderColor: "#4682B4",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const BrownSugarF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#A07855",
    standardTextColor: "#A07855",
    textColor: "white",
    borderColor: "#A07855",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const BrownSugarO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#A07855",
    textColor: "#A07855",
    borderColor: "#A07855",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const DarkKhakiF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#BDB76B",
    standardTextColor: "#BDB76B",
    textColor: "white",
    borderColor: "#BDB76B",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const DarkKhakiO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#BDB76B",
    textColor: "#BDB76B",
    borderColor: "#BDB76B",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const PeruF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#CD853F",
    standardTextColor: "#CD853F",
    textColor: "white",
    borderColor: "#CD853F",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const PeruO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#CD853F",
    textColor: "#CD853F",
    borderColor: "#CD853F",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const NavyF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#000080",
    standardTextColor: "#000080",
    textColor: "white",
    borderColor: "#000080",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const NavyO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#000080",
    textColor: "#000080",
    borderColor: "#000080",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const CherryTomatoF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#E94B3C",
    standardTextColor: "#E94B3C",
    textColor: "white",
    borderColor: "#E94B3C",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const CherryTomatoO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#E94B3C",
    textColor: "#E94B3C",
    borderColor: "#E94B3C",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const IslandGreenF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "#2BAE66",
    standardTextColor: "#2BAE66",
    textColor: "white",
    borderColor: "#2BAE66",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const IslandGreenO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#2BAE66",
    textColor: "#2BAE66",
    borderColor: "#2BAE66",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const ChocolateF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#D2691E",
    standardTextColor: "#D2691E",
    textColor: "white",
    borderColor: "#D2691E",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const ChocolateO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#D2691E",
    textColor: "#D2691E",
    borderColor: "#D2691E",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const DeepPinkF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#FF1493",
    standardTextColor: "#FF1493",
    textColor: "white",
    borderColor: "#FF1493",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const DeepPinkO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#FF1493",
    textColor: "#FF1493",
    borderColor: "#FF1493",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const DuskyCitronF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#E3CD81",
    standardTextColor: "#E3CD81",
    textColor: "white",
    borderColor: "#E3CD81",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const DuskyCitronO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#E3CD81",
    textColor: "#E3CD81",
    borderColor: "#E3CD81",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const DimGreyF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#616161",
    standardTextColor: "#616161",
    textColor: "white",
    borderColor: "#616161",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const DimGreyO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#616161",
    textColor: "#616161",
    borderColor: "#616161",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const DarkSlateBlueF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#483D8B",
    standardTextColor: "#483D8B",
    textColor: "white",
    borderColor: "#483D8B",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const DarkSlateBlueO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#483D8B",
    textColor: "#483D8B",
    borderColor: "#483D8B",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const BlueF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#0000FF",
    standardTextColor: "#0000FF",
    textColor: "white",
    borderColor: "#0000FF",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const BlueO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#0000FF",
    textColor: "#0000FF",
    borderColor: "#0000FF",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const ForestBiomeF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#184A45",
    standardTextColor: "#184A45",
    textColor: "white",
    borderColor: "#184A45",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const ForestBiomeO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#184A45",
    textColor: "#184A45",
    borderColor: "#184A45",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const FuchsiaF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#FF00FF",
    standardTextColor: "#FF00FF",
    textColor: "white",
    borderColor: "#FF00FF",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const FuchsiaO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#FF00FF",
    textColor: "#FF00FF",
    borderColor: "#FF00FF",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const IceFlowF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#A2A2A1",
    standardTextColor: "#A2A2A1",
    textColor: "white",
    borderColor: "#A2A2A1",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const IceFlowO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#A2A2A1",
    textColor: "#A2A2A1",
    borderColor: "#A2A2A1",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
export const AquaF = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,

    backgroundColor: "#18ffff",
    standardTextColor: "#18ffff",
    textColor: "white",
    borderColor: "#18ffff",
    disabledButtonColor: "#808080",
  },
  type: "fill",
};
export const AquaO = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    backgroundColor: "white",
    standardTextColor: "#18ffff",
    textColor: "#18ffff",
    borderColor: "#18ffff",
    disabledButtonColor: "#808080",
  },
  type: "outline",
};
