import React, { useContext } from "react";
import {
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Platform,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Auth } from "aws-amplify";

import { ThemeContext } from "../../context/ThemeContext";
import { ProfileContext } from "../../context/ProfileContext";
import noAvatar from "../../assets/noAvatar.png";

const SignOut = () => {
  const navigation = useNavigation();
  const { theme, changeTheme } = useContext(ThemeContext);
  const noAvatarUri =
    Platform.OS === "web"
      ? require("../../assets/noAvatar.png")
      : Image.resolveAssetSource(noAvatar).uri;
  const { setAvatar } = useContext(ProfileContext);
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>SIGN OUT</Text>
      <Text style={styles(theme).textCenterStyle}>
        Click the button below to Sign Out...
      </Text>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          Auth.signOut();
          setAvatar(noAvatarUri);
          navigation.navigate("Home");
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Sign Out</Text>
      </TouchableOpacity>
    </ScrollView>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    textCenterStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "center",
      margin: 5,
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
  });

export default SignOut;
