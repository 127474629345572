import React, { useState, useContext } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { ThemeContext } from "../context/ThemeContext";

const FaqItem = ({ question, children }) => {
  const [open, setOpen] = useState(false);
  const { theme, changeTheme } = useContext(ThemeContext);

  return (
    <View>
      <TouchableOpacity onPress={() => setOpen(!open)}>
        <View style={styles(theme).questionViewStyle}>
          {open ? (
            <MaterialIcons
              name="keyboard-arrow-up"
              size={30}
              color={theme.colors.standardTextColor}
            />
          ) : (
            <MaterialIcons
              name="keyboard-arrow-down"
              size={30}
              color={theme.colors.standardTextColor}
            />
          )}
          <Text style={styles(theme).questionStyle}>{question}</Text>
        </View>
      </TouchableOpacity>
      {open && <View style={styles(theme).answerViewStyle}>{children}</View>}
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    questionViewStyle: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: 5,
      paddingLeft: 24,
      width: "90%",
    },
    answerViewStyle: {
      padding: 10,
      paddingLeft: 24,
      backgroundColor: theme.colors.backgroundColor,
    },
    questionStyle: {
      color: theme.colors.standardTextColor,
      marginLeft: 20,
    },
  });
export default FaqItem;
