import React, { useState, useContext, useEffect } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { API, graphqlOperation } from "aws-amplify";
import { ThemeContext } from "../../../context/ThemeContext";
import { ProfileContext } from "../../../context/ProfileContext";
import * as mutations from "../../../src/graphql/mutations";
const KampusTransactionSuccess = (props) => {
  const navigation = useNavigation();
  const orderId = props.route.params.orderId;
  const txnAmount = props.route.params.txnAmount;
  const txnId = props.route.params.txnId;
  const [message, setMessage] = useState("Your Kampus Account is revalidated");

  const { theme, changeTheme } = useContext(ThemeContext);
  const { profile, setProfile } = useContext(ProfileContext);

  const updateTransaction = async () => {
    try {
      const kampusEndDate =
        profile.kampusStatus == 4
          ? new Date(
              new Date().setFullYear(new Date().getFullYear() + 3)
            ).toISOString()
          : new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            ).toISOString();

      setMessage(
        `Your Kampus Account is revalidated upto ${new Date(
          kampusEndDate
        ).toLocaleString()} .`
      );
      // create KampusRenewal
      const charges = ((txnAmount / 118) * 100).toFixed(2);
      const taxes = (txnAmount - charges).toFixed(2);
      var kampusStatus = profile.kampusStatus;
      var kampusId = profile.id;

      const createRenewal = await API.graphql(
        graphqlOperation(mutations.createKampusRenewal, {
          input: {
            id: orderId,
            kampusStatus: kampusStatus,
            transactionId: txnId,
            transactionDate: new Date().toISOString(),
            renewalCharges: charges,
            renewalTaxes: taxes,
            renewalAmount: txnAmount,
            profileKampusRenewalId: kampusId,
          },
        })
      );
      // update profile
      const updatedProfile = await API.graphql({
        query: mutations.updateProfile,
        variables: {
          input: {
            id: profile.id,
            kampusEndDate:
              profile.kampusStatus == 4
                ? new Date(
                    new Date().setFullYear(new Date().getFullYear() + 3)
                  ).toISOString()
                : new Date(
                    new Date().setFullYear(new Date().getFullYear() + 1)
                  ).toISOString(),
          },
        },
      });
      setProfile(updatedProfile.data.updateProfile);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (profile.id != null && profile.kampusStatus != null) {
      updateTransaction();
    }
  }, [profile.id, profile.kampusStatus, orderId]);

  return (
    <View style={styles(theme).screenStyle}>
      <View style={styles(theme).headerViewStyle}>
        <Ionicons
          name={
            theme.type == "fill"
              ? "checkmark-circle-sharp"
              : "checkmark-circle-outline"
          }
          size={100}
          color={theme.colors.standardTextColor}
        />
      </View>
      <Text style={styles(theme).subHeaderStyle}>
        Amount Paid: ₹ {txnAmount}
      </Text>

      <Text style={styles(theme).subHeaderStyle}>{message}</Text>
      <Text style={styles(theme).subHeaderStyle}>orderID is: {orderId}</Text>

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          navigation.navigate("KampusHome");
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Back</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },

    buttonStyle: {
      width: "80%",
      alignSelf: "center",
      marginBottom: 10,
      marginTop: 10,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      borderWidth: 3,
      backgroundColor: theme.colors.backgroundColor,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },
  });
export default KampusTransactionSuccess;
