import React, { useContext, useEffect, useState } from "react";
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import MapView, { PROVIDER_GOOGLE } from "react-native-maps";
import { API } from "aws-amplify";
import AwesomeAlert from "react-native-awesome-alerts";

import { ThemeContext } from "../../../context/ThemeContext";
import { KampusRegistrationContext } from "../../../context/KampusRegistrationContext";
import * as mutations from "../../../src/graphql/mutations";

// const SCREEN_WIDTH = Dimensions.get("screen").width;
// const SCREEN_HEIGHT = Dimensions.get("screen").height;
// const ASPECT_RATIO = SCREEN_WIDTH / SCREEN_HEIGHT;
// const LATITUDE_DELTA = 0.0922;
// const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;
const PendingKampusRegistrationDetails = (props) => {
  const navigation = useNavigation();
  const item = props.route.params.item;
  const isFocused = useIsFocused();
  const {
    mapRegionForKampusRegistration,
    setMapRegionForKampusRegistration,
    latDelta,
    lonDelta,
    polygon,
    setPolygon,
  } = useContext(KampusRegistrationContext);
  const [rejectModal, setRejectModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const { theme, changeTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (isFocused) {
      constructPolygon();
      constructMarker();
    }
  }, [props, isFocused]);

  const constructPolygon = () => {
    var arr1 = [];
    var arr2 = [];
    arr1 = JSON.parse(item.displayArea).coordinates;
    arr2 = arr1[0];
    arr2.map((item, index) => {
      var newItem = {};
      if (index != arr2.length - 1) {
        if (Platform.OS == "web") {
          newItem.lat = item[1];
          newItem.lng = item[0];
        } else {
          newItem.latitude = item[1];
          newItem.longitude = item[0];
        }
        setPolygon((prev) => [...prev, newItem]);
      }
    });
  };

  const constructMarker = () => {
    var arr1 = [];
    arr1 = JSON.parse(item.kampusLocation);
    setMapRegionForKampusRegistration({
      latitude: parseFloat(arr1[0]),
      longitude: parseFloat(arr1[1]),
      latitudeDelta: latDelta,
      longitudeDelta: lonDelta,
    });
  };

  const rejectRegistration = async () => {
    try {
      const updateStatusToReject = await API.graphql({
        query: mutations.updateKampusRegistration,
        variables: {
          input: {
            id: item.id,
            status: "rejected",
            expiryDate: Math.round(
              new Date(Date.now() + 1000).getTime() / 1000
            ),
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const approveRegistration = async () => {
    try {
      const updateStatusToSuccess = await API.graphql({
        query: mutations.updateKampusRegistration,
        variables: {
          input: {
            id: item.id,
            status: "approved",
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <ScrollView style={styles(theme).screenStyle}>
      <Text style={styles(theme).headerStyle}>
        PENDING KAMPUS REGISTRATION DETAILS
      </Text>
      <Text style={styles(theme).subHeaderStyle}>Institution Boundaries</Text>

      <MapView
        style={styles(theme).kampusRegistrationMapStyle}
        provider={PROVIDER_GOOGLE}
        initialRegion={mapRegionForKampusRegistration}
      >
        <MapView.Marker
          coordinate={mapRegionForKampusRegistration}
          pinColor={theme.colors.standardTextColor}
        />
        {Platform.OS == "web" ? (
          <MapView.Polygon
            path={polygon}
            options={{
              strokeColor: theme.colors.standardTextColor,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: theme.colors.standardTextColor,
              fillOpacity: 0.35,
            }}
          />
        ) : (
          <MapView.Polygon
            coordinates={polygon}
            strokeColor={theme.colors.standardTextColor}
            fillColor={theme.colors.standardTextColor}
          />
        )}
      </MapView>
      <Text style={styles(theme).subHeaderStyle}>Institution Details </Text>
      <View style={styles(theme).boxStyle}>
        <Text style={styles(theme).textJustifyStyle}>
          Name: {item.profile.name}
        </Text>
        <Text style={styles(theme).textJustifyStyle}>
          Address: {item.address.type}, {item.address.name},{" "}
          {item.address.line1}, {item.address.line2}, {item.address.line3},{" "}
          {item.address.region}, {item.address.country}, {item.address.zip}
        </Text>
        <Text style={styles(theme).textJustifyStyle}>
          Email: {item.profile.username}
        </Text>
      </View>
      <Text style={styles(theme).subHeaderStyle}>Status Details </Text>
      <View style={styles(theme).boxStyle}>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Request Date: </Text>
          <Text style={styles(theme).textBoldStyle}>
            {new Date(item.requestDate).toLocaleDateString()}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Request Time:</Text>
          <Text style={styles(theme).textBoldStyle}>
            {new Date(item.requestDate).toLocaleTimeString()}
          </Text>
        </View>
        <View style={styles(theme).boxTextStyle}>
          <Text style={styles(theme).textStyle}>Status:</Text>
          <Text style={styles(theme).textBoldStyle}>{item.status}</Text>
        </View>
      </View>

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setRejectModal(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>Reject</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => setApproveModal(true)}
      >
        <Text style={styles(theme).buttonTextStyle}>Approve</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={styles(theme).buttonStyle}
        onPress={() => {
          setPolygon([]);
          navigation.goBack();
        }}
      >
        <Text style={styles(theme).buttonTextStyle}>Back</Text>
      </TouchableOpacity>
      <AwesomeAlert
        show={rejectModal}
        showProgress={false}
        title="REJECT REGISTRATION"
        message=" Are you sure you want to reject this registration?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, reject it"
        onCancelPressed={() => setRejectModal(false)}
        onConfirmPressed={() => {
          setRejectModal(false);
          rejectRegistration();
          setPolygon([]);
          navigation.navigate("PendingKampusRegistrations");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
      <AwesomeAlert
        show={approveModal}
        showProgress={false}
        title="APPROVE REGISTRATION"
        message=" Are you sure you want to approve this registration ?"
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, approve it"
        onCancelPressed={() => setApproveModal(false)}
        onConfirmPressed={() => {
          setApproveModal(false);
          approveRegistration();
          setPolygon([]);
          navigation.navigate("PendingKampusRegistrations");
        }}
        alertContainerStyle={styles(theme).alertContainerStyle}
        contentContainerStyle={styles(theme).contentContainerStyle}
        overlayStyle={styles(theme).overlayStyle}
        titleStyle={styles(theme).titleStyle}
        messageStyle={styles(theme).alertMessageStyle}
        actionContainerStyle={styles(theme).actionContainerStyle}
        cancelButtonStyle={styles(theme).alertButtonStyle}
        confirmButtonStyle={styles(theme).alertButtonStyle}
        cancelButtonTextStyle={styles(theme).alertButtonTextStyle}
        confirmButtonTextStyle={styles(theme).alertButtonTextStyle}
      />
    </ScrollView>
  );
};
const styles = (theme) =>
  StyleSheet.create({
    screenStyle: {
      flex: 1,
      backgroundColor: "white",
    },
    headerViewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    headerStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
      paddingRight: 5,
    },

    subHeaderStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      alignSelf: "center",
      margin: 10,
    },

    textStyle: {
      color: theme.colors.standardTextColor,
      margin: 5,
    },

    textBoldStyle: {
      color: theme.colors.standardTextColor,
      fontWeight: "bold",
      margin: 5,
    },
    textJustifyStyle: {
      color: theme.colors.standardTextColor,
      textAlign: "justify",
      margin: 5,
    },

    boxStyle: {
      width: "80%",
      alignSelf: "center",
      borderWidth: 1,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
      padding: 10,
    },

    boxTextStyle: {
      flex: 1,
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
    },

    buttonStyle: {
      width: "80%",
      backgroundColor: theme.colors.backgroundColor,
      alignSelf: "center",
      borderWidth: 3,
      borderRadius: 10,
      borderColor: theme.colors.borderColor,
      margin: 10,
    },
    buttonTextStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
      textAlign: "center",
      margin: 10,
    },

    kampusRegistrationMapStyle: {
      height: 550,
      width: "80%",
      alignSelf: "center",
      borderRadius: 20,
      margin: 10,
    },

    alertContainerStyle: {
      backgroundColor: "transparent",
    },
    contentContainerStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.borderColor,
    },
    overlayStyle: {
      height: "100%",
    },
    titleStyle: {
      color: theme.colors.textColor,
      fontWeight: "bold",
    },
    alertMessageStyle: {
      color: theme.colors.textColor,
    },
    actionContainerStyle: {
      backgroundColor: "white",
      justifyContent: "space-evenly",
      borderRadius: 20,
    },

    alertButtonStyle: {
      backgroundColor: theme.colors.backgroundColor,
      borderWidth: 2,
      borderRadius: 20,
      borderColor: theme.colors.textColor,
    },
    alertButtonTextStyle: { color: theme.colors.textColor },
  });
export default PendingKampusRegistrationDetails;
